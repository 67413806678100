import React from 'react';
import { Box, Button, Chip, Collapse, Paper, TextField, Typography } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { gql } from '@apollo/client';
import { toast } from 'sonner';
import { Link as RouterLink } from 'react-router-dom';

import { usePatientNotesQuery, useAddPatientNoteMutation } from '@/generated/graphql';

import Loading from '@/components/Loading';
import { ErrorDisplay } from '@/components/ErrorDisplay';
import { isDefined } from '@/helpers/isDefined';
import { useMePermission } from '@/hooks/useAuth';
import { UserName } from '@/components/UserName';

export const NOTES_FRAGMENT = gql`
  fragment PatientNote on PatientNote {
    id
    createdAt
    text
    createdBy {
      id
      email
      firstName
      lastName
      isSelfCare
    }
    organization {
      id
      name
    }
    checkup {
      id
    }
  }
`;

export const QUERY_PATIENT_NOTES = gql`
  query PatientNotes($PatientId: ID!) {
    patient(id: $PatientId) {
      id
      createdAt
      firstName
      lastName
      notes {
        ...PatientNote
      }
      selfCare {
        id
      }
    }
  }
`;

export const ADD_PATIENT_NOTE = gql`
  mutation AddPatientNote($PatientId: ID!, $text: String!) {
    addPatientNote(PatientId: $PatientId, text: $text) {
      id
    }
  }
`;

export interface AddNoteFormValues {
  text: string;
}

interface PatientNotesTabProps {
  patientId: string;
}

export default function PatientNotesTab({ patientId }: PatientNotesTabProps) {
  const classes = useStyles();
  const { t } = useTranslation();
  const canAddPatientNotes = useMePermission('add_patient_notes');

  const schema = Yup.object().shape({
    text: Yup.string().required('Text is required').min(1, 'Text is required'),
  });

  const [isInputFocused, setIsInputFocused] = React.useState(false);

  const {
    data,
    loading: isLoadingNotes,
    error: fetchError,
    refetch,
  } = usePatientNotesQuery({
    variables: { PatientId: patientId },
  });

  const notes = data?.patient?.notes ?? [];

  const [addNoteMutation, { loading: isSubmitting }] = useAddPatientNoteMutation({
    onCompleted: () => {
      formik.resetForm();
      refetch();
    },
    onError: () => toast.error("An error occurred when adding the note to the patient's record"),
  });

  const handleSubmit = async (values: AddNoteFormValues) => {
    await addNoteMutation({
      variables: {
        PatientId: patientId,
        text: values.text,
      },
    });
  };

  const formik = useFormik({
    initialValues: {
      text: '',
    },
    validationSchema: schema,
    onSubmit: handleSubmit,
    validateOnMount: true,
    validateOnBlur: true,
  });

  if (fetchError) {
    return (
      <ErrorDisplay
        message="Failed to fetch patient notes. Press retry to try again."
        retry={refetch}
        showIcon
      />
    );
  }

  return (
    <Box display="flex" justifyContent="center">
      <Box margin={3} flex="1 1 auto" maxWidth={1080}>
        {canAddPatientNotes ? (
          <Paper
            elevation={0}
            component="form"
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            onSubmit={formik.handleSubmit as any}
            className={classes.form}>
            <TextField
              fullWidth
              multiline
              name="text"
              placeholder="Type your note here"
              variant="standard"
              required
              value={formik.values.text}
              onChange={formik.handleChange}
              disabled={isLoadingNotes || isSubmitting}
              inputProps={{
                'aria-label': 'Type your note here',
              }}
              InputProps={{
                disableUnderline: true,
                'aria-label': 'Type your note here',
              }}
              onFocus={() => setIsInputFocused(true)}
              onBlur={() => setIsInputFocused(false)}
              onKeyUp={(e) => {
                if (e.key === 'Escape') {
                  formik.resetForm();
                  (e.target as HTMLInputElement).blur();
                }
              }}
            />
            <Collapse in={formik.dirty || isInputFocused}>
              <div className={classes.submitButtonWrap}>
                <Button
                  type="reset"
                  onClick={async () => {
                    formik.resetForm();
                  }}
                  size="small"
                  disableElevation
                  disabled={isLoadingNotes || isSubmitting || !formik.isValid}>
                  Clear
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  size="small"
                  disableElevation
                  disabled={isLoadingNotes || isSubmitting || !formik.isValid}>
                  Save note
                </Button>
              </div>
            </Collapse>
          </Paper>
        ) : null}
        <Box marginTop={2}>
          {isLoadingNotes && data === undefined ? (
            <Loading showLoading />
          ) : (
            <ul className={classes.noteList}>
              {!isLoadingNotes && notes.length === 0 ? (
                <Typography className={classes.noNotesText}>
                  No notes logged for this patient
                </Typography>
              ) : (
                notes.map((note, key) => (
                  <Paper component="li" className={classes.noteItem} key={key} elevation={2}>
                    <span className={classes.noteHeader}>
                      <span className={classes.noteDate}>
                        {t('DATETIME_LONG', {
                          val: new Date(note.createdAt),
                        }).toString()}
                      </span>
                      <span>
                        {isDefined(note.checkup) && (
                          <Chip
                            className={classes.checkupLink}
                            component={RouterLink}
                            to={`/patient/${patientId}/checkup/${note.checkup.id}`}
                            label="Linked to Check-up"
                            size="small"
                            color="primary"
                          />
                        )}
                      </span>
                    </span>
                    <Typography className={classes.noteText}>{note.text}</Typography>
                    <div className={classes.reporterAndShowMore}>
                      <span className={classes.reporter}>
                        <UserName
                          user={note.createdBy}
                          userActingOrganization={note.organization}
                        />
                      </span>
                    </div>
                  </Paper>
                ))
              )}
            </ul>
          )}
        </Box>
      </Box>
    </Box>
  );
}

const useStyles = makeStyles((theme) => ({
  noteList: {
    margin: 0,
    padding: 0,
  },
  noteItem: {
    display: 'flex',
    flexDirection: 'column',
    margin: theme.spacing(3, 0),
    padding: theme.spacing(2),
  },
  noteHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  noteDate: {
    fontWeight: 500,
    color: theme.palette.grey[800],
  },
  noteText: {
    margin: theme.spacing(1.5, 0),
  },
  form: {
    padding: theme.spacing(2),
    width: '100%',
  },
  reporterAndShowMore: {
    display: 'flex',
    gap: theme.spacing(1),
    alignItems: 'flex-end',
  },
  reporter: {
    fontWeight: 500,
    color: theme.palette.grey[800],
  },
  submitButtonWrap: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  noNotesText: {
    textAlign: 'center',
    marginTop: theme.spacing(3),
    color: theme.palette.grey[600],
    fontSize: theme.typography.h6.fontSize,
  },
  checkupLink: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
    },
  },
}));
