import React, { useEffect } from 'react';

import { Box, Grid, MenuItem, Stack, TextField, Typography } from '@mui/material';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { toast } from 'sonner';

import { useAddUserJourneyContext } from '../types';
import { useAddUserJourneyCreateUserMutation } from '@/generated/graphql';
import { muiFormikGetFieldProps } from '@/helpers/formik';
import { getMutationErrors } from '@/AuthorizedApolloClientProvider';
import { useAuthMe } from '@/hooks/useAuth';
import { gql } from '@apollo/client';

const createUserSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Email is required'),
  role: Yup.string().required('Role is required'),
});

export const CREATE_USER = gql`
  mutation AddUserJourneyCreateUser($user: UserInput!, $role: String!, $portalUrl: String!) {
    createUser(user: $user, role: $role, portalUrl: $portalUrl) {
      id
      firstName
      lastName
      email
      organizations {
        id
      }
    }
  }
`;

export function AddUserStep() {
  const { handleStep, setIsSubmitting, gotoNextStep } = useAddUserJourneyContext();

  const [createUser, { loading, error }] = useAddUserJourneyCreateUserMutation({
    onCompleted: (result) => {
      gotoNextStep({
        userHasName: Boolean(
          result.createUser.firstName?.trim() || result.createUser.lastName?.trim(),
        ),
        user: result.createUser,
      });
    },
    onError: () => {
      toast.error('Failed to create user');
    },
  });

  const availableRoles = useAuthMe('actingOrganization.roles', []);

  useEffect(() => {
    setIsSubmitting(loading);
    () => setIsSubmitting(false);
  }, [loading, setIsSubmitting]);

  // Disable automatic progression, as we need to handle the form submission
  handleStep(() => false);

  const formik = useFormik({
    initialValues: {
      email: '',
      role: '',
    },
    validationSchema: createUserSchema,
    onSubmit: (values) => {
      createUser({
        variables: {
          user: { email: values.email },
          role: values.role,
          portalUrl: window.location.origin,
        },
      });
    },
  });

  const { argErrors } = getMutationErrors(error);
  const getFieldProps = muiFormikGetFieldProps(formik, (argErrors ?? {}).user, createUserSchema);

  return (
    <div>
      <Box marginBottom={3}>
        <Typography variant="body2" color="textSecondary">
          To invite a user to your organisation, you must provide an email and select a role.
        </Typography>
      </Box>
      <form id="userCreateForm" noValidate onSubmit={formik.handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              autoFocus
              fullWidth
              InputLabelProps={{ shrink: true }}
              variant="outlined"
              label="Email login"
              placeholder="Enter email"
              type="email"
              {...getFieldProps('email')}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              InputLabelProps={{ shrink: true }}
              variant="outlined"
              label="Role"
              placeholder="Select role"
              select
              {...getFieldProps('role', {
                defaultHelperText:
                  "The role chosen will determine the user's permissions within your organisation. This can be changed later.",
              })}>
              {availableRoles.map(
                (role: { isSystemRole: boolean; name: string; description: string }) =>
                  !role.isSystemRole && (
                    <MenuItem
                      key={role.name}
                      value={role.name}
                      aria-label={role.name}
                      aria-details={role.description}>
                      <Stack>
                        <Typography textTransform="capitalize" variant="body1" color="primary">
                          {role.name}
                        </Typography>
                        <Typography variant="caption" lineHeight={1.2} sx={{ textWrap: 'balance' }}>
                          {role.description}
                        </Typography>
                      </Stack>
                    </MenuItem>
                  ),
              )}
            </TextField>
          </Grid>
        </Grid>
      </form>
    </div>
  );
}
