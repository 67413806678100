import React from 'react';
import {
  AlertTitle,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogProps,
  DialogTitle,
  FormControl,
  FormGroup,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import { ShowFnOutput, useModal } from 'mui-modal-provider';
import { gql } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import Alert from '@mui/material/Alert';
import { toast } from 'sonner';

import {
  DischargePatientAdmissionDetailsFragment,
  DischargePatientDetailsFragment,
  useDischargePatientFromWardMutation,
} from '@/generated/graphql';
import { useFormik } from 'formik';

export const DISCHARGE_PATIENT_FRAGMENT = gql`
  fragment DischargePatientDetails on Patient {
    id
    firstName
    lastName
    birthDate
    nhsNumber
    wardAdmission {
      ...DischargePatientAdmissionDetails
    }
  }

  fragment DischargePatientAdmissionDetails on WardAdmission {
    admittedAt
    ward {
      id
      name
    }
    carePathway {
      name
    }
  }
`;

export const DISCHARGE_PATIENT_FROM_WARD = gql`
  mutation DischargePatientFromWard(
    $PatientId: ID!
    $WardId: ID!
    $dischargedAt: Date
    $reason: String
  ) {
    dischargePatientFromWard(
      PatientId: $PatientId
      WardId: $WardId
      dischargedAt: $dischargedAt
      reason: $reason
    )
  }
`;

interface PatientDischargeModalProps extends DialogProps {
  patient: DischargePatientDetailsFragment;
  currentAdmission: DischargePatientAdmissionDetailsFragment;
  onPatientDischarged: () => void;
  onCancel: () => void;
}

export const PatientDischargeModal = ({
  open,
  patient,
  currentAdmission,
  onPatientDischarged,
  onCancel,
}: PatientDischargeModalProps) => {
  const { t } = useTranslation();
  const patientFullName = `${patient.firstName} ${patient.lastName}`;

  const [dischargePatient] = useDischargePatientFromWardMutation({
    variables: {
      PatientId: patient.id,
      WardId: currentAdmission.ward.id,
    },
    onCompleted: () => {
      onPatientDischarged();
    },
    onError: () => toast.error('An error occurred when trying to discharge the patient'),
  });

  const formik = useFormik({
    initialValues: {
      patientName: '',
    },
    onSubmit: async () => {
      if (!isConfirmationValid) return;
      await dischargePatient();
    },
  });

  const isConfirmationValid =
    formik.values.patientName.trim().localeCompare(patientFullName, undefined, {
      sensitivity: 'accent',
    }) === 0;

  return (
    <Dialog
      open={open}
      onClose={onCancel}
      maxWidth="md"
      fullWidth
      aria-labelledby="patient_discharge-dialog-title"
      aria-describedby="patient_discharge-dialog-description">
      <form onSubmit={formik.handleSubmit}>
        <DialogTitle id="patient_discharge-dialog-title">
          Discharge {patientFullName} (
          {patient.nhsNumber ??
            `DOB: ${t('DATE_SHORT', {
              val: new Date(patient.birthDate),
              formatParams: { val: { timeZone: 'UTC' } },
            })}`}
          )
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="patient_discharge-dialog-description">
            <Grid container spacing={2} marginY={0}>
              <Grid item xs={12}>
                <Typography
                  component="h3"
                  sx={(theme) => ({
                    color: theme.palette.primary.dark,
                    fontWeight: 500,
                    fontSize: theme.typography.pxToRem(18),
                    margin: 0,
                  })}>
                  Current admission (since {t('DATE_SHORT', currentAdmission.admittedAt)})
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Ward"
                  variant="outlined"
                  fullWidth
                  InputProps={{ readOnly: true }}
                  InputLabelProps={{ shrink: true }}
                  value={currentAdmission.ward.name}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Care Pathway"
                  variant="outlined"
                  fullWidth
                  InputProps={{ readOnly: true }}
                  InputLabelProps={{ shrink: true }}
                  value={currentAdmission.carePathway?.name}
                />
              </Grid>
            </Grid>
          </DialogContentText>
          <DialogContentText marginTop={2}>
            <Alert severity="warning" sx={{ marginBottom: 1 }}>
              <AlertTitle>
                Once discharged, they will no longer appear under any wards or be subject to any
                care pathways
              </AlertTitle>
              <Typography variant="body2" gutterBottom>
                Discharging a patient will have the following additional effects:
              </Typography>
              <Box component="ul" sx={{ paddingLeft: 3, margin: 0 }}>
                <li>Any open alerts and continuous sessions will be closed.</li>
                <li>All pathway customisation overrides on this patient will be removed.</li>
                <li>A discharge summary will be available for download on their patient record.</li>
              </Box>
            </Alert>
          </DialogContentText>
          <FormControl component="fieldset" style={{ width: '100%' }}>
            <FormGroup>
              <TextField
                fullWidth
                sx={{ marginTop: 2 }}
                label="Confirm patient name"
                value={formik.values.patientName}
                onChange={formik.handleChange}
                name="patientName"
                type="string"
                variant="outlined"
                placeholder={patientFullName}
                helperText="Please type the patient's full name to confirm"
                required
                autoFocus
                InputLabelProps={{ shrink: true }}
                inputProps={{
                  'aria-label': 'Patient discharge confirm patient name',
                }}
                InputProps={{
                  'aria-label': 'Patient discharge confirm patient name',
                }}
              />
            </FormGroup>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={onCancel}>Cancel</Button>
          <Button
            color="primary"
            type="submit"
            variant="contained"
            className="e2e__dischargepatientconfirmsubmit"
            disabled={!isConfirmationValid}
            name="patient_discharge_button_confirm">
            Discharge
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

interface UsePatientDischargeModalProps {
  onPatientDischarged: () => void;
}

export const usePatientDischargeModal = ({
  onPatientDischarged,
}: UsePatientDischargeModalProps) => {
  const { showModal } = useModal();

  return {
    showPatientDischargeModal: ({
      patient,
      currentAdmission,
    }: Pick<PatientDischargeModalProps, 'patient' | 'currentAdmission'>) => {
      const modal: ShowFnOutput<PatientDischargeModalProps> = showModal(
        PatientDischargeModal,
        {
          patient,
          currentAdmission,
          onPatientDischarged: () => {
            onPatientDischarged();
            modal.hide();
          },
          onCancel: () => {
            return modal.hide();
          },
        },
        { destroyOnClose: true },
      );

      return modal;
    },
  };
};
