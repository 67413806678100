// used to handle url query param changes
// TODO: this file is used to bypass React Router and do History.push(`/`) from everywhere
// this is an anti-pattern and we should remove it
// also we should change <Router> to <BrowserRouter> https://reactrouter.com/web/api/BrowserRouter
import { createBrowserHistory } from 'history';

const history = createBrowserHistory();

history.listen((location) => {
  // see https://developers.google.com/analytics/devguides/collection/gtagjs/single-page-applications
  window.gtag('config', process.env.REACT_APP_GOOGLE_ANALYTICS_PROPERTY_ID, {
    page_path: location.pathname,
  });
});

export default history;
