import React, { useEffect, useRef } from 'react';

import { Box, Button, Grid, IconButton, Stack, TextField, Typography } from '@mui/material';
import { Alert, AlertTitle } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useFormik } from 'formik';
import { gql } from '@apollo/client';
import * as Yup from 'yup';
import { toast } from 'sonner';

import { isDefined } from '@/helpers/isDefined';
import { useCreateSelfCareLoginMutation } from '@/generated/graphql';
import { getMutationErrors } from '@/AuthorizedApolloClientProvider';
import { muiFormikGetFieldProps } from '@/helpers/formik';
import { useSetPasswordModal } from '@/pages/ManagePatients/components/SetPasswordModal';

import { useAdmitPatientJourneyContext } from '../types';
import { useCopyToClipboard } from '@/hooks/useCopyToClipboard';
import { generatePassphrase } from '@/helpers/passphrase';

export const CREATE_SELF_CARE_LOGIN_MUTATION = gql`
  mutation CreateSelfCareLogin($patientId: ID!, $email: String!, $password: String) {
    createSelfCareLogin(patientId: $patientId, selfCare: { email: $email, password: $password }) {
      id
      email
    }
  }
`;

const loginFormSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Email required'),
  password: Yup.string().required('Password required'),
});

export function SetupLoginStep() {
  const {
    currentJourneyState: { patient, selfCareLoginCreated },
    updateJourneyState,
    handleStep,
    gotoNextStep,
    setIsSubmitting,
  } = useAdmitPatientJourneyContext();

  // If we need to create the user early in the case of the password reset
  // then we note that we'll have to manually progress to the next step
  // instead of using the mutation's onCompleted handler
  const manuallyHandlingStepProgression = useRef(false);

  const [createSelfCareLogin, { error, loading }] = useCreateSelfCareLoginMutation({
    onCompleted: (data) => {
      updateJourneyState({
        patient: {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          ...patient!,
          selfCare: data.createSelfCareLogin,
        },
        selfCareLoginCreated: true,
      });

      if (manuallyHandlingStepProgression.current === false) {
        gotoNextStep();
      }
    },
    onError: () => {
      toast.error('Failed to create patient login');
    },
  });

  const { showSetPasswordModal } = useSetPasswordModal();

  handleStep(() => false);

  useEffect(() => {
    setIsSubmitting(loading);
  }, [loading, setIsSubmitting]);

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: loginFormSchema,
    onSubmit: async (values) => {
      // Shouldn't happen, but just in case
      if (!patient) {
        toast.error('Failed to create patient login');
        return;
      }

      if (!selfCareLoginCreated) {
        await createSelfCareLogin({
          variables: {
            patientId: patient.id,
            email: values.email,
            password: values.password,
          },
        });
      }
    },
  });

  const updateExistingSelfCareUser = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!patient || !patient?.selfCare) {
      toast.error('Failed to create patient login');
      return;
    }

    if (!selfCareLoginCreated) {
      await createSelfCareLogin({
        variables: {
          patientId: patient.id,
          email: patient.selfCare.email,
        },
      });
    }

    if (manuallyHandlingStepProgression.current || selfCareLoginCreated) {
      gotoNextStep();
    }
  };

  const { argErrors } = getMutationErrors(error);
  const getFieldProps = muiFormikGetFieldProps(formik, argErrors, loginFormSchema);

  const { canCopy, copyToClipboard } = useCopyToClipboard();

  // We should never get to this step without a patient, but just in case...
  if (!patient) {
    return null;
  }

  const selfCareLogin = patient.selfCare;

  const handleGeneratePassword = async () => {
    await formik.setFieldValue('password', generatePassphrase());
    await formik.setFieldTouched('password', true);
    await formik.validateField('password');
  };

  return (
    <Box marginTop={2}>
      {isDefined(selfCareLogin) ? (
        <form id="selfCareLoginForm" onSubmit={updateExistingSelfCareUser} noValidate>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Alert severity="info">
                <AlertTitle>Already registered</AlertTitle>
                This patient has already been registered with login credentials using the email
                username below. You can set a new password for the patient by clicking &quot;Reset
                password&quot; and then confirming.
              </Alert>
            </Grid>
            <Grid item xs={12}>
              <Stack spacing={2} direction="row" alignItems="flex-end">
                <TextField
                  fullWidth
                  label="Email"
                  value={selfCareLogin.email}
                  variant="outlined"
                  InputProps={{
                    readOnly: true,
                    endAdornment: canCopy && (
                      <IconButton onClick={() => copyToClipboard(selfCareLogin.email)}>
                        <ContentCopyIcon fontSize="small" />
                      </IconButton>
                    ),
                  }}
                  InputLabelProps={{ shrink: true }}
                />

                <Button
                  variant="outlined"
                  sx={{
                    flexShrink: 0,
                    textWrap: 'none',
                  }}
                  onClick={async () => {
                    if (patient && patient.selfCare) {
                      manuallyHandlingStepProgression.current = true;

                      // We have to ensure that the patient has a user linked to the organization
                      // before we can reset the password. This request does nothing if the user
                      // already is linked to the organization but is required for new patients
                      // being added to new organizations.
                      await createSelfCareLogin({
                        variables: {
                          patientId: patient.id,
                          email: patient.selfCare.email,
                        },
                      });
                    }

                    showSetPasswordModal({
                      user: selfCareLogin,
                    });
                  }}>
                  Reset password
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </form>
      ) : (
        <form id="selfCareLoginForm" onSubmit={formik.handleSubmit} noValidate>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="body2" gutterBottom>
                A username in the format of an email address is required for the patient to log in
                to Feebris.
              </Typography>
              <Typography variant="body2" gutterBottom>
                If the patient doesn&apos;t have an email, please refer to your organisation for how
                to obtain one.
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Patient's email address"
                value={null}
                variant="outlined"
                placeholder="e.g. harrold@gmail.com"
                InputLabelProps={{ shrink: true }}
                {...getFieldProps('email')}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Password"
                placeholder="Click 'Generate' to create a password"
                variant="outlined"
                InputProps={{
                  readOnly: true,
                  endAdornment: (
                    <Button
                      variant="contained"
                      size="small"
                      onClick={() => handleGeneratePassword()}>
                      Generate
                    </Button>
                  ),
                }}
                InputLabelProps={{ shrink: true }}
                {...getFieldProps('password', { fireOnChange: false })}
              />
            </Grid>
          </Grid>
        </form>
      )}
    </Box>
  );
}
