import React, { useState } from 'react';

import {
  Box,
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Link,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useModal } from 'mui-modal-provider';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import {
  EhrIntegrationStatus,
  IntegrationEventItemFragment,
  useRetryEhrIntegrationEventMutation,
} from '@/generated/graphql';
import { StatusChip } from './StatusChip';
import { useTranslation } from 'react-i18next';
import { Alert, AlertTitle } from '@mui/material';
import { formatIntegrationTriggerType } from '@/components/EhrIntegrations/format';
import { gql } from '@apollo/client';
import { toast } from 'sonner';
import { useMePermission } from '@/hooks/useAuth';
import { Link as RouterLink } from 'react-router-dom';

interface IntegrationEventModalProps extends DialogProps {
  integrationEvent: IntegrationEventItemFragment;
  onCancel: () => void;
}

export const RETRY_INTEGRATION_EVENT_MUTATION = gql`
  mutation retryEhrIntegrationEvent($ehrIntegrationEventId: ID!) {
    retryEhrIntegrationEvent(ehrIntegrationEventId: $ehrIntegrationEventId) {
      ...IntegrationEventItem
    }
  }
`;

function IntegrationEventModal({
  integrationEvent,
  open,
  onClose,
  onCancel,
}: IntegrationEventModalProps) {
  const classes = useStyles();

  const [showConsultationNote, setShowConsultationNote] = useState(false);
  const [showCodedDataItems, setShowCodedDataItems] = useState(false);

  const hasRetryEhrIntegrationEventsPermission = useMePermission('retry_ehr_integration_events');

  const [retryEhrIntegrationEvent, { loading: retrying }] = useRetryEhrIntegrationEventMutation({
    variables: { ehrIntegrationEventId: integrationEvent.id },
    onCompleted: () => {
      toast.success('Successfully scheduled retry');
      onCancel();
    },
    onError: () => toast.error('Something went wrong retrying. Please try again.'),
    update: (cache, { data }) => {
      if (!data?.retryEhrIntegrationEvent) return;
      // Since the retry is scheduled onto the queue, it won't necessarily be updated immediately in the database.
      // So we'll just update the cache "optimistically" to reflect that it's pending.
      cache.modify({
        id: cache.identify(integrationEvent),
        fields: {
          status: () => EhrIntegrationStatus.Pending,
          isRetryable: () => false,
          response: () => null,
          updatedAt: () => new Date().toISOString(),
        },
      });
    },
  });

  const { t } = useTranslation();

  const requestBody = integrationEvent.requestBody;

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>
        <Box display="flex" flexDirection="row" justifyContent="space-between">
          Integration Event
          <StatusChip status={integrationEvent.status} response={integrationEvent.response} />
        </Box>
      </DialogTitle>
      <DialogContent>
        {integrationEvent.status === EhrIntegrationStatus.ActionRequired && (
          <Box marginBottom={2}>
            <Alert severity="warning">
              <AlertTitle>Action is required before this event can be processed</AlertTitle>
              {integrationEvent.response && (
                <Typography gutterBottom>{integrationEvent.response.message}</Typography>
              )}
              {hasRetryEhrIntegrationEventsPermission ? (
                <Typography variant="body2">
                  Once the action has been completed, click retry.
                </Typography>
              ) : null}
            </Alert>
          </Box>
        )}
        {integrationEvent.status === EhrIntegrationStatus.Rejected && (
          <Box marginBottom={2}>
            <Alert severity="error">
              <AlertTitle>Integration event rejected by user with reason:</AlertTitle>
              {integrationEvent.response && (
                <Typography gutterBottom>{integrationEvent.response.message}</Typography>
              )}
            </Alert>
          </Box>
        )}
        <Box marginBottom={2}>
          <Typography variant="h6" gutterBottom>
            Patient
          </Typography>
          <Typography variant="body2" gutterBottom>
            <strong>Name:</strong> {integrationEvent.patient.firstName}{' '}
            {integrationEvent.patient.lastName}
          </Typography>
          <Typography variant="body2" gutterBottom>
            <strong>NHS Number:</strong> {integrationEvent.patient.nhsNumber ?? 'N/A'}
          </Typography>
          <Typography variant="body2" gutterBottom>
            <strong>Birth Date:</strong>{' '}
            {t('DATE_SHORT', {
              val: new Date(integrationEvent.patient.birthDate),
              formatParams: { val: { timeZone: 'UTC' } },
            })}
          </Typography>
        </Box>
        <Box marginBottom={2}>
          <Typography variant="h6" gutterBottom>
            Event Details
          </Typography>
          <Typography variant="body2" gutterBottom>
            <strong>Trigger:</strong>{' '}
            {formatIntegrationTriggerType(
              integrationEvent.integration.triggerType,
              integrationEvent.integration.triggerConfig,
            )}
          </Typography>
          {integrationEvent.summaryPeriod && (
            <div>
              <Typography variant="body2" gutterBottom>
                <strong>Summary Period:</strong>{' '}
                <span className={classes.emphasis}>
                  {t('DATETIME_SHORT', {
                    val: new Date(integrationEvent.summaryPeriod.from),
                  })}
                </span>{' '}
                to{' '}
                <span className={classes.emphasis}>
                  {t('DATETIME_SHORT', {
                    val: new Date(integrationEvent.summaryPeriod.to),
                  })}{' '}
                </span>
              </Typography>
            </div>
          )}
          {integrationEvent.checkup && (
            <div>
              <Typography variant="body2" gutterBottom>
                <strong>Check-up:</strong>{' '}
                <Link
                  component={RouterLink}
                  className={classes.emphasis}
                  to={`/patient/${integrationEvent.patient.id}/checkup/${integrationEvent.checkup.id}`}>
                  {integrationEvent.checkup.type}{' '}
                  {t('DATETIME_SHORT', {
                    val: new Date(integrationEvent.checkup.endedAt),
                    formatParams: { val: { timeZone: 'UTC' } },
                  })}
                </Link>
              </Typography>
            </div>
          )}
          {integrationEvent.continuousMonitoring && (
            <div>
              <Typography variant="body2" gutterBottom>
                <strong>Continuous Monitoring:</strong>{' '}
                {t('DATETIME_SHORT', {
                  val: new Date(integrationEvent.continuousMonitoring.bucketStartAt),
                })}{' '}
                -{' '}
                {t('DATETIME_SHORT', {
                  val: new Date(integrationEvent.continuousMonitoring.bucketEndAt),
                })}
              </Typography>
            </div>
          )}
          <Typography variant="body2" gutterBottom>
            <strong>Created:</strong>{' '}
            {t('DATETIME_SHORT', {
              val: new Date(integrationEvent.createdAt),
            })}
          </Typography>
        </Box>
        {requestBody && (
          <>
            <Box marginBottom={2}>
              {requestBody.consultationNote && (
                <CollapsibleSection
                  heading="Consultation Note"
                  toggle={() => {
                    setShowConsultationNote(!showConsultationNote);
                  }}
                  expanded={showConsultationNote}>
                  <Box className={classes.consultationNote}>{requestBody.consultationNote}</Box>
                </CollapsibleSection>
              )}
            </Box>
            <Box marginBottom={2}>
              {requestBody.codedDataItems.length > 0 && (
                <CollapsibleSection
                  heading={`Coded Data Items (${requestBody.codedDataItems.length})`}
                  toggle={() => {
                    setShowCodedDataItems(!showCodedDataItems);
                  }}
                  expanded={showCodedDataItems}>
                  <Table aria-label="coded data items">
                    <TableHead>
                      <TableRow>
                        <TableCell>Code</TableCell>
                        <TableCell>Value</TableCell>
                        <TableCell>Additional Text</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {requestBody.codedDataItems.map((item) => (
                        <TableRow key={item.code}>
                          <TableCell component="th" scope="row">
                            {item.code}
                          </TableCell>
                          <TableCell>
                            {item.value} {item.units}
                          </TableCell>
                          <TableCell>{item.additionalText}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </CollapsibleSection>
              )}
            </Box>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          flex="1 1 auto"
          marginLeft={2}>
          <Typography className={classes.lastUpdated}>
            Last Updated:{' '}
            {t('DATETIME_SHORT', {
              val: new Date(integrationEvent.updatedAt),
              formatParams: { val: { timeZone: 'UTC' } },
            })}
          </Typography>
          <Box>
            <Button disabled={retrying} onClick={() => onCancel()}>
              Close
            </Button>

            {integrationEvent.isRetryable && hasRetryEhrIntegrationEventsPermission ? (
              <Button
                variant="contained"
                color="primary"
                disabled={retrying}
                onClick={() => retryEhrIntegrationEvent()}>
                Retry
              </Button>
            ) : null}
          </Box>
        </Box>
      </DialogActions>
    </Dialog>
  );
}

const CollapsibleSection = ({
  heading,
  expanded,
  toggle,
  children,
}: {
  heading: string;
  children: React.ReactNode;
  expanded: boolean;
  toggle: () => void;
}) => {
  const classes = useStyles();

  return (
    <>
      <Box
        marginBottom={1}
        display="flex"
        className={classes.collapsibleHeading}
        onClick={(e) => {
          e.stopPropagation();
          toggle();
        }}>
        <Typography variant="h6">{heading}</Typography>
        <Box marginRight={1} display="flex" flexGrow={1} justifyContent="flex-end">
          <IconButton aria-label={expanded ? `Hide ${heading}` : `Show ${heading}`} size="large">
            {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>
        </Box>
      </Box>

      <Collapse in={expanded}>{children}</Collapse>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  emphasis: {
    fontWeight: 500,
    color: theme.palette.primary.main,
  },

  lastUpdated: {
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.grey[600],
  },

  collapsibleHeading: {
    cursor: 'pointer',
    alignItems: 'center',
  },

  consultationNote: {
    fontSize: theme.typography.body2.fontSize,
    backgroundColor: theme.palette.background.default,
    color: theme.palette.grey[800],
    borderColor: theme.palette.grey[300],
    borderRadius: theme.shape.borderRadius * 2,
    borderStyle: 'solid',
    borderWidth: '1px',
    padding: theme.spacing(2),
    width: '100%',
    whiteSpace: 'pre-line',
  },
}));

interface UseIntegrationEventModalOptions {
  integrationEvent: IntegrationEventItemFragment;
}

export function useIntegrationEventModal() {
  const { showModal } = useModal();

  return {
    showIntegrationEventModal: ({ integrationEvent }: UseIntegrationEventModalOptions) => {
      const modal = showModal(IntegrationEventModal, {
        integrationEvent,
        onCancel: () => {
          modal.hide();
        },
      });
    },
  };
}
