import React from 'react';

import { Box, Tooltip, Typography } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

import Moment from 'moment';
import clsx from 'clsx';
import { useTranslation, TFunction } from 'react-i18next';

import { feebrisFormatter } from '@/helpers/LocaleFormatting';

import {
  VirtualWardPatientActivityMonitoringSessionFragment,
  VirtualWardPatientCheckupFragment,
  VirtualWardPatientContinuousMonitoringFragment,
  VirtualWardPatientContinuousMonitoringWithSessionFragment,
} from '@/generated/graphql';

import { isDefined } from '@/helpers/isDefined';
import { newsPaletteForRiskLevel } from '@/styles/NEWSColors';
import { CheckupValue } from './CheckupValue';
import { ContinuousMonitoringSessionStatus } from './ContinuousMonitoringSessionStatus';
import { ActivityMonitoringSessionStatus } from './ActivityMonitoringSessionStatus';
import { formatCheckupTypeName } from '../formatters';

interface VitalsValuesProps {
  checkup: VirtualWardPatientCheckupFragment | null | undefined;
  continuousMonitoringWithSession:
    | VirtualWardPatientContinuousMonitoringWithSessionFragment
    | null
    | undefined;
  activityMonitoringSession: Maybe<VirtualWardPatientActivityMonitoringSessionFragment>;
  isContinuousMonitoring: boolean;
}

export function VitalsValues({
  checkup,
  continuousMonitoringWithSession,
  activityMonitoringSession,
  isContinuousMonitoring,
}: VitalsValuesProps) {
  const classes = useStyles();

  const { t } = useTranslation();

  const continuousMonitoring = continuousMonitoringWithSession?.continuousMonitoring;

  return (
    <Box display="flex" flexDirection="row" alignItems="center">
      {isContinuousMonitoring ? (
        <>
          <Tooltip title={`EWS total score, coloured by risk level`}>
            <Box
              data-testid="ews-score-continuous"
              className={clsx(classes.newsContainer)}
              style={newsPaletteForRiskLevel(continuousMonitoring?.thresholdScores?.riskLevel ?? 0)}
              flexShrink={0}>
              {continuousMonitoring?.thresholdScores?.totalScore ?? '?'}
            </Box>
          </Tooltip>
          <ContinuousMonitoringInfo
            //eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            continuousMonitoringWithSession={continuousMonitoringWithSession!}
            activityMonitoringSession={activityMonitoringSession}
          />
        </>
      ) : (
        <>
          <Tooltip title={`EWS total score, coloured by risk level`}>
            <Box
              data-testid="ews-score-intermittent"
              className={clsx(classes.newsContainer)}
              style={newsPaletteForRiskLevel(checkup?.ewsScores?.riskLevel ?? 0)}
              flexShrink={0}>
              {checkup?.ewsScores?.totalScore ?? '?'}
            </Box>
          </Tooltip>
          {/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */}
          <CheckupInfo checkup={checkup!} activityMonitoringSession={activityMonitoringSession} />
        </>
      )}
      <Box display="flex" flexWrap="wrap" data-testid="vitals">
        <CheckupValue
          shortLabel="BP"
          longLabel="Blood Pressure"
          width={75}
          value={
            isContinuousMonitoring
              ? null
              : checkup?.bloodPressureData &&
                `${checkup?.bloodPressureData.systolic}/${checkup?.bloodPressureData.diastolic}`
          }
          score={
            isContinuousMonitoring
              ? continuousMonitoring?.thresholdScores?.BPScore
              : checkup?.ewsScores?.BPScore
          }
          popoverFields={
            isContinuousMonitoring
              ? undefined
              : checkupPopoverFields(t, checkup, 'bloodPressureData', [
                  checkup?.bloodPressureData
                    ? {
                        label: 'Position',
                        value: checkup?.bloodPressureData?.position
                          ? t(`BLOOD_PRESSURE_POSITION.${checkup?.bloodPressureData?.position}`)
                          : '-',
                      }
                    : undefined,
                ])
          }
        />
        <CheckupValue
          shortLabel="SpO2"
          longLabel="Oxygen Saturation"
          value={
            isContinuousMonitoring
              ? continuousMonitoring?.spo2?.value
              : checkup?.pulseOxiData?.averageSpO2
          }
          score={
            isContinuousMonitoring
              ? continuousMonitoring?.thresholdScores?.SpO2Score
              : checkup?.ewsScores?.SpO2Score
          }
          popoverFields={
            isContinuousMonitoring
              ? continuousMonitoringPopoverFields(t, continuousMonitoring, 'spo2')
              : checkupPopoverFields(t, checkup, 'pulseOxiData')
          }
        />
        <CheckupValue
          shortLabel="RR"
          longLabel="Respiratory Rate"
          value={
            isContinuousMonitoring
              ? continuousMonitoring?.respiratoryRate?.value
              : checkup?.respiratoryRate?.value
          }
          score={
            isContinuousMonitoring
              ? continuousMonitoring?.thresholdScores?.RRScore
              : checkup?.ewsScores?.RRScore
          }
          popoverFields={
            isContinuousMonitoring
              ? continuousMonitoringPopoverFields(t, continuousMonitoring, 'respiratoryRate')
              : checkupPopoverFields(t, checkup, 'respiratoryRate', [
                  checkup?.respiratoryRate?.source
                    ? {
                        label: 'Source',
                        value: t(`RESPIRATORY_RATE_SOURCE.${checkup?.respiratoryRate.source}`),
                      }
                    : undefined,
                ])
          }
        />
        <CheckupValue
          shortLabel="Pulse"
          value={
            isContinuousMonitoring
              ? continuousMonitoring?.pulseRate?.value
              : checkup?.pulseRate?.value
          }
          score={
            isContinuousMonitoring
              ? continuousMonitoring?.thresholdScores?.HRScore
              : checkup?.ewsScores?.HRScore
          }
          popoverFields={
            isContinuousMonitoring
              ? continuousMonitoringPopoverFields(t, continuousMonitoring, 'pulseRate')
              : checkupPopoverFields(t, checkup, 'pulseRate', [
                  checkup?.pulseRate?.source && {
                    label: 'Source',
                    value: t(`PULSE_RATE_SOURCE.${checkup?.pulseRate.source}`),
                  },
                  checkup?.pulseRate?.isManual
                    ? {
                        label: 'Manual reading',
                        value: '',
                      }
                    : undefined,
                ])
          }
        />
        <CheckupValue
          shortLabel="Temp"
          longLabel="Temperature"
          width={70}
          value={feebrisFormatter.formatTemperature(
            isContinuousMonitoring
              ? continuousMonitoring?.temperature?.value
              : checkup?.temperature,
          )}
          score={
            isContinuousMonitoring
              ? continuousMonitoring?.thresholdScores?.tempScore
              : checkup?.ewsScores?.tempScore
          }
          popoverFields={
            isContinuousMonitoring
              ? continuousMonitoringPopoverFields(t, continuousMonitoring, 'temperature', [
                  {
                    label: 'Units',
                    value: feebrisFormatter.temperatureUnits(),
                  },
                ])
              : checkupPopoverFields(t, checkup, 'temperature', [
                  {
                    label: 'Units',
                    value: feebrisFormatter.temperatureUnits(),
                  },
                ])
          }
        />
        <CheckupValue
          shortLabel="LOC"
          longLabel="Level of Consciousness"
          value={isContinuousMonitoring ? null : checkup?.consciousness}
          longValue={
            isContinuousMonitoring
              ? null
              : checkup?.consciousness &&
                `${t(`CONSCIOUSNESS_SHORT.${checkup?.consciousness}`)} - ${t(
                  `CONSCIOUSNESS_LONG.${checkup?.consciousness}`,
                )}`
          }
          score={
            isContinuousMonitoring
              ? continuousMonitoring?.thresholdScores?.consciousnessScore
              : checkup?.ewsScores?.consciousnessScore
          }
          popoverFields={
            isContinuousMonitoring ? undefined : checkupPopoverFields(t, checkup, 'consciousness')
          }
        />
        <CheckupValue
          shortLabel="Action"
          smallValue
          value={
            isContinuousMonitoring
              ? t('N/A')
              : t([`CHECKUP_ACTIONS_SHORT.${checkup?.selectedAction}`, 'N/A'])
          }
          longValue={
            isContinuousMonitoring
              ? t('N/A')
              : t([`CHECKUP_ACTIONS.${checkup?.selectedAction}`, 'N/A'])
          }
        />
      </Box>
    </Box>
  );
}

function CheckupInfo({
  checkup,
  activityMonitoringSession,
}: {
  checkup: VirtualWardPatientCheckupFragment;
  activityMonitoringSession: Maybe<VirtualWardPatientActivityMonitoringSessionFragment>;
}) {
  const classes = useStyles();

  return (
    <Box width={175}>
      <Box display="flex" alignItems="center" gap={6}>
        <Typography className={classes.label}>{formatCheckupTypeName(checkup)}</Typography>
        {isDefined(activityMonitoringSession) && !isDefined(activityMonitoringSession.endedAt) && (
          <ActivityMonitoringSessionStatus activityMonitoringSession={activityMonitoringSession} />
        )}
      </Box>
      <Typography className={classes.checkupDate}>
        {Moment(checkup.endedAt).format('MMM Do YYYY, h:mm a')}
      </Typography>
    </Box>
  );
}

function ContinuousMonitoringInfo({
  continuousMonitoringWithSession,
  activityMonitoringSession,
}: {
  continuousMonitoringWithSession: VirtualWardPatientContinuousMonitoringWithSessionFragment;
  activityMonitoringSession: Maybe<VirtualWardPatientActivityMonitoringSessionFragment>;
}) {
  const classes = useStyles();
  const { t } = useTranslation();

  const { continuousMonitoringSession, continuousMonitoring } = continuousMonitoringWithSession;
  const isSessionEnded = isDefined(continuousMonitoringSession.endedAt);

  return (
    <Box width={175}>
      <Box display="flex" alignItems="center" gap={6}>
        <Typography className={classes.label}>{t('Continuous')}</Typography>
        {!isSessionEnded && (
          <ContinuousMonitoringSessionStatus
            continuousMonitoringWithSession={continuousMonitoringWithSession}
          />
        )}
        {isDefined(activityMonitoringSession) && !isDefined(activityMonitoringSession.endedAt) && (
          <ActivityMonitoringSessionStatus activityMonitoringSession={activityMonitoringSession} />
        )}
      </Box>
      <Typography className={classes.checkupDate}>
        {Moment(
          continuousMonitoring
            ? continuousMonitoring.bucketEndAt
            : continuousMonitoringSession.createdAt,
        ).format('MMM Do YYYY, h:mm a')}
      </Typography>
    </Box>
  );
}

function continuousMonitoringPopoverFields(
  t: TFunction,
  continuousMonitoring: VirtualWardPatientContinuousMonitoringFragment | null | undefined,
  continuousMonitoringKey: 'spo2' | 'respiratoryRate' | 'heartRate' | 'pulseRate' | 'temperature',
  extraFields: ({ label: string; value: string } | undefined)[] = [],
): { label: string; value: string }[] {
  const label = {
    spo2: 'SPO2',
    respiratoryRate: 'RR',
    heartRate: 'HR',
    pulseRate: 'Pulse',
    temperature: 'Temp',
  }[continuousMonitoringKey];
  return continuousMonitoring?.[continuousMonitoringKey]
    ? [
        ...extraFields,
        {
          label: t(`Min ${label}`),
          value: `${continuousMonitoring?.[continuousMonitoringKey]?.min ?? ''}`,
        },
        {
          label: t(`Max ${label}`),
          value: `${continuousMonitoring?.[continuousMonitoringKey]?.max ?? ''}`,
        },
        {
          label: t(`Median ${label}`),
          value: `${continuousMonitoring?.[continuousMonitoringKey]?.median ?? ''}`,
        },
        {
          label: t('Captured on'),
          value: Moment(continuousMonitoring?.bucketEndAt).format('MMM Do YYYY, h:mm a'),
        },
      ].filter(isDefined)
    : [];
}

function checkupPopoverFields(
  t: TFunction,
  checkup: VirtualWardPatientCheckupFragment | null | undefined,
  checkupKey:
    | 'bloodPressureData'
    | 'pulseOxiData'
    | 'respiratoryRate'
    | 'pulseRate'
    | 'temperature'
    | 'consciousness',
  extraFields: ({ label: string; value: string } | undefined)[] = [],
): { label: string; value: string }[] {
  return [
    ...extraFields,
    checkup?.[checkupKey]
      ? {
          label: t('Captured on'),
          value: Moment(checkup?.endedAt).format('MMM Do YYYY, h:mm a'),
        }
      : undefined,
  ].filter(isDefined);
}

const useStyles = makeStyles((theme) => ({
  newsContainer: {
    display: 'flex',
    marginRight: theme.spacing(2),
    justifyContent: 'center',
    alignItems: 'center',
    width: 42,
    height: 42,
    borderRadius: 21,
    border: '1px solid transparent',
    color: theme.palette.grey[600],
    fontWeight: 500,
    lineHeight: 1,
    fontSize: theme.typography.pxToRem(18),
  },
  checkupDate: {
    fontSize: theme.typography.pxToRem(14),
  },
  label: {
    textTransform: 'capitalize',
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 500,
  },
}));
