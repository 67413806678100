import React, { useMemo } from 'react';

import _ from 'lodash';
import { gql } from '@apollo/client';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  TextField,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useFormik } from 'formik';
import { ShowFnOutput, useModal } from 'mui-modal-provider';
import { Alert, Autocomplete } from '@mui/material';
import { toast } from 'sonner';

import {
  useAdmitPatientMutation,
  useGetWardsForSelectQuery,
  useGetCarePathwaysForSelectQuery,
} from '@/generated/graphql';

import { getMutationErrors } from '@/AuthorizedApolloClientProvider';
import { muiFormikGetFieldProps } from '@/helpers/formik';

export const GET_WARDS_FOR_SELECT_QUERY = gql`
  query GetWardsForSelect {
    wards {
      id
      name
    }
  }
`;

export const GET_CAREPATHWAYS_FOR_SELECT_QUERY = gql`
  query GetCarePathwaysForSelect {
    carePathways(includeShared: true) {
      id
      name
    }
  }
`;

export const QUERY_ASSIGN_WARD = gql`
  mutation AdmitPatient($patientId: ID!, $wardId: ID!, $carePathwayId: ID!) {
    admitPatientToWard(patientId: $patientId, wardId: $wardId, carePathwayId: $carePathwayId) {
      patient {
        id
        wardAdmission {
          ...PatientAdmissionOverview
        }
      }
      admittedAt
    }
  }
`;

interface PatientWardAdmissionModalProps extends DialogProps {
  patientId: string;
  patientName: string;
  onAdmitted: () => void;
  onCancel: () => void;
}

export function PatientWardAdmissionModal({
  open,
  patientId,
  patientName,
  onAdmitted,
  onCancel,
}: PatientWardAdmissionModalProps) {
  const classes = useStyles();

  const { data: wardsData, loading: wardsLoading } = useGetWardsForSelectQuery({
    onError: () => toast.error('An error occurred when fetching wards for selection'),
  });
  const { data: carePathwaysData, loading: carePathwaysLoading } = useGetCarePathwaysForSelectQuery(
    {
      onError: () => toast.error('An error occurred when fetching care pathways for selection'),
    },
  );

  const sortedWards = useMemo(
    () => _.sortBy(wardsData?.wards ?? [], (w) => w.name),
    [wardsData?.wards],
  );

  const sortedCarePathways = useMemo(
    () => _.sortBy(carePathwaysData?.carePathways ?? [], (cp) => cp.name),
    [carePathwaysData?.carePathways],
  );

  const [admitPatient, { loading: isAdmitting, error: admitToWardError }] = useAdmitPatientMutation(
    {
      onCompleted: () => {
        onAdmitted();
      },
      onError: () => toast.error('An error occured when admitting the patient to the ward'),
    },
  );

  const { argErrors } = getMutationErrors(admitToWardError);

  const formik = useFormik({
    initialValues: {
      wardId: '',
      carePathwayId: '',
    },
    onSubmit: async (values) => {
      await admitPatient({
        variables: {
          patientId,
          wardId: values.wardId,
          carePathwayId: values.carePathwayId,
        },
      });
    },
  });

  const getFieldProps = muiFormikGetFieldProps(formik, argErrors);
  const wardIdFieldProps = getFieldProps('wardId', { fireOnChange: false });
  const carePathwayIdFieldProps = getFieldProps('wardId', { fireOnChange: false });

  return (
    <Dialog open={open} maxWidth="sm" fullWidth>
      <DialogTitle>Admit {patientName} to a Ward</DialogTitle>
      <form onSubmit={formik.handleSubmit}>
        <DialogContent>
          <Alert severity="info">
            Once admitted, a patient&apos;s Ward and assigned Care Pathway may only be revoked by
            formally discharging them from the Ward.
          </Alert>
          <Autocomplete
            className={classes.select}
            loading={wardsLoading}
            options={sortedWards}
            getOptionLabel={(option) => option.name}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            onChange={(_, value) => formik.setFieldValue('wardId', value?.id || '')}
            renderInput={(params) => (
              <TextField
                {...params}
                {...wardIdFieldProps}
                placeholder="Select a Ward"
                label="Ward"
                InputLabelProps={{
                  shrink: true,
                }}
                required
                inputProps={{
                  ...params.inputProps,
                }}
              />
            )}
          />
          <Autocomplete
            className={classes.select}
            loading={carePathwaysLoading}
            options={sortedCarePathways}
            getOptionLabel={(option) => option.name}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            onChange={(_, value) => formik.setFieldValue('carePathwayId', value?.id || '')}
            renderInput={(params) => (
              <TextField
                {...params}
                {...carePathwayIdFieldProps}
                placeholder="Select a Care Pathway"
                label="Care Pathway"
                InputLabelProps={{
                  shrink: true,
                }}
                required
                inputProps={{
                  ...params.inputProps,
                }}
              />
            )}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onCancel} disabled={isAdmitting}>
            Cancel
          </Button>
          <Button variant="contained" color="primary" type="submit" disabled={isAdmitting}>
            Admit
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

const useStyles = makeStyles((theme) => ({
  select: {
    marginTop: theme.spacing(2),
  },
}));

interface UsePatientWardAdmissionModalProps {
  patientId: string;
  patientName: string;
  onAdmitted: () => void;
}

export const usePatientWardAdmissionModal = ({
  onAdmitted,
  patientId,
  patientName,
}: UsePatientWardAdmissionModalProps) => {
  const { showModal } = useModal();

  return {
    showAdmitPatientModal: () => {
      const modal: ShowFnOutput<PatientWardAdmissionModalProps> = showModal(
        PatientWardAdmissionModal,
        {
          patientId,
          patientName,
          onAdmitted: () => {
            onAdmitted();
            modal.hide();
          },
          onCancel: () => modal.hide(),
        },
        { destroyOnClose: true },
      );

      return modal;
    },
  };
};
