import React from 'react';

import { gql } from '@apollo/client';
import { Column } from '@material-table/core';
import { Box, Link } from '@mui/material';
import { format } from 'date-fns';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { toast } from 'sonner';

import { SettingsShareTokenFragment, useSettingsPatientSharingQuery } from '@/generated/graphql';

import { ErrorDisplay } from '@/components/ErrorDisplay';
import MaterialTableWithIcons from '@/components/MaterialTableWithIcons';
import { UserName } from '@/components/UserName';

export const QUERY_SETTINGS_SHARE_TOKENS = gql`
  fragment SettingsShareToken on ShareToken {
    id
    createdAt
    expiresAt
    user {
      id
      firstName
      lastName
      email
    }
    patient {
      id
      firstName
      lastName
    }
  }

  query SettingsPatientSharing {
    shareTokens {
      ...SettingsShareToken
    }
  }
`;

function PatientSharing() {
  const { data, refetch, error } = useSettingsPatientSharingQuery({
    onError: () => toast.error('An error occurred while loading data'),
  });
  const { t } = useTranslation();

  const columns = [
    {
      field: 'user',
      title: 'User',
      // NOTE: Normally the userActingOrganization prop is required for the UserName component, but
      //       in this case it's not possible for users to see another organisations ShareTokens. So
      //       for that reason the rowData.user will always be in the same org as loggedInUser
      render: (rowData) => <UserName user={rowData.user} />,
    },
    {
      field: 'patient',
      title: 'Patient',
      render: (rowData) => (
        <Link component={RouterLink} to={`/patient/${rowData.patient.id}`} color="primary">
          {`${rowData.patient.firstName} ${rowData.patient.lastName}`}
        </Link>
      ),
    },
    {
      field: 'expiresAt',
      title: 'Expires At',
      render: (rowData) => format(new Date(rowData.expiresAt), "yyyy-MM-dd 'at' HH:mm"),
    },
  ] as Column<SettingsShareTokenFragment>[];

  return (
    <>
      <Helmet>
        <title>Patient Record Sharing</title>
      </Helmet>
      <Box className="e2e__patientsharingtable">
        <MaterialTableWithIcons
          title="Patient Record Sharing"
          columns={columns}
          data={data?.shareTokens ?? []}
          options={{ search: false }}
          actions={
            // TODO: Add support for those with delete_share_token permissions to destroy ShareTokens
            []
          }
          localization={{
            body: {
              emptyDataSourceMessage: error ? (
                <ErrorDisplay
                  message="Failed to load patient sharing data. Press retry to try again."
                  retry={refetch}
                />
              ) : (
                t('Organization has no patient records shared outside Feebris')
              ),
            },
          }}
        />
      </Box>
    </>
  );
}

export default PatientSharing;
