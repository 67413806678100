import { useJourneyModalContext } from '@/components/JourneyModal';
import { User } from '@/generated/graphql';

export interface AddUserJourneyContext {
  userHasName: boolean | null;
  userDetailsUpdated: boolean;
  user: Pick<User, 'firstName' | 'lastName' | 'email' | 'id'> | null;
}

export type AddUserJourneyStep = 'addUser' | 'assignWards' | 'success';

export const useAddUserJourneyContext = () =>
  useJourneyModalContext<AddUserJourneyContext, AddUserJourneyStep>();
