import { ConsciousnessType } from '../controllers/types';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

/* eslint-disable */
/* This file is automatically generated by graphql-codegen DO NOT EDIT!!! */

export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: string;
  JSON: any;
  JSONObject: Record<string, any>;
};

export type AcceptedTerms = {
  __typename?: 'AcceptedTerms';
  acceptedBy: User;
  id: Scalars['ID'];
  organization: Organization;
  version: Scalars['Int'];
};

export type ActionLog = {
  __typename?: 'ActionLog';
  affectedOrganization?: Maybe<Organization>;
  createdAt: Scalars['Date'];
  id: Scalars['ID'];
  loggedInAs?: Maybe<User>;
  message: Scalars['String'];
  messageEntities?: Maybe<Array<MessageEntity>>;
  user?: Maybe<User>;
};

export type ActionLogs = {
  __typename?: 'ActionLogs';
  logs: Array<ActionLog>;
  nextActionLogId?: Maybe<Scalars['ID']>;
};

export type Address = {
  __typename?: 'Address';
  address: Scalars['String'];
  postcode: Scalars['String'];
};

export type AggregateMetadata = {
  __typename?: 'AggregateMetadata';
  count?: Maybe<Scalars['Int']>;
};

export type Alert = {
  __typename?: 'Alert';
  _aggregate?: Maybe<AggregateMetadata>;
  alertRule?: Maybe<AlertRule>;
  checkup?: Maybe<Checkup>;
  checkupScheduleEvent?: Maybe<CheckupScheduleEvent>;
  continuousMonitoring?: Maybe<ContinuousMonitoring>;
  endedAt?: Maybe<Scalars['Date']>;
  id: Scalars['ID'];
  pacsanaEvent?: Maybe<PacsanaEvent>;
  startedAt?: Maybe<Scalars['Date']>;
  status: AlertStatus;
  type?: Maybe<AlertType>;
};

export type AlertRule = {
  __typename?: 'AlertRule';
  condition?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  type: AlertRuleTypes;
};

export enum AlertRuleTypes {
  ContinuousMonitoring = 'continuous_monitoring',
  NewCheckup = 'new_checkup'
}

export enum AlertStatus {
  Closed = 'closed',
  Open = 'open'
}

export enum AlertType {
  AlertRule = 'AlertRule',
  CheckupScheduleEvent = 'CheckupScheduleEvent',
  PacsanaEvent = 'PacsanaEvent',
  PatientCallbackRequest = 'PatientCallbackRequest'
}

export type BatteryStats = {
  __typename?: 'BatteryStats';
  ecg?: Maybe<Scalars['Float']>;
  spo2?: Maybe<Scalars['Float']>;
  temperature?: Maybe<Scalars['Float']>;
};

export enum BatteryStatus {
  EndOfLife = 'EndOfLife',
  Expiring = 'Expiring',
  Offline = 'Offline',
  Okay = 'Okay'
}

export type BloodPressureCuffTileConfig = CheckupTileConfig & {
  __typename?: 'BloodPressureCuffTileConfig';
  manualEntry?: Maybe<Scalars['Boolean']>;
  rule: CheckupTileRule;
};

export type BucketStats = {
  __typename?: 'BucketStats';
  max?: Maybe<Scalars['Float']>;
  median?: Maybe<Scalars['Float']>;
  min?: Maybe<Scalars['Float']>;
  value?: Maybe<Scalars['Float']>;
};

export type CarePathway = {
  __typename?: 'CarePathway';
  alertRules: Array<AlertRule>;
  checkupTypes: Array<CheckupType>;
  createdAt: Scalars['Date'];
  createdBy: User;
  deletedAt?: Maybe<Scalars['Date']>;
  ewsThresholds?: Maybe<EwsThresholds>;
  id: Scalars['ID'];
  integrations: Array<EhrIntegration>;
  name: Scalars['String'];
  numberOfPatients: Scalars['Int'];
  organization?: Maybe<Organization>;
  publishedAt?: Maybe<Scalars['Date']>;
  updatedAt: Scalars['Date'];
};


export type CarePathwayCheckupTypesArgs = {
  includeDeleted?: InputMaybe<Scalars['Boolean']>;
  includeShared?: InputMaybe<Scalars['Boolean']>;
};


export type CarePathwayNumberOfPatientsArgs = {
  includeDischarged?: InputMaybe<Scalars['Boolean']>;
};

export type Checkup = {
  __typename?: 'Checkup';
  bloodPressureData?: Maybe<Scalars['JSONObject']>;
  checkupType?: Maybe<CheckupType>;
  comment?: Maybe<Scalars['String']>;
  consciousness?: Maybe<ConsciousnessType>;
  createdAt: Scalars['Date'];
  createdBy?: Maybe<User>;
  createdById: Scalars['ID'];
  createdUsingAppVersion: Scalars['String'];
  csvRecLocation?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['Date']>;
  endedAt: Scalars['Date'];
  ewsScores?: Maybe<EwsScores>;
  ewsThresholds?: Maybe<EwsThresholds>;
  glucose?: Maybe<Scalars['JSONObject']>;
  id: Scalars['ID'];
  isStable?: Maybe<Scalars['Boolean']>;
  /** @deprecated Use 'lungSounds' instead */
  lungRecLocation?: Maybe<Scalars['String']>;
  lungSounds?: Maybe<Array<LungSoundRecording>>;
  /** @deprecated Use 'notes' instead. This will continue to return the first note only. */
  notes: Array<PatientNote>;
  organization?: Maybe<Organization>;
  patient?: Maybe<Patient>;
  picture?: Maybe<CheckupPicture>;
  pulseOxiData?: Maybe<Scalars['JSONObject']>;
  pulseRate?: Maybe<PulseRate>;
  questionnaire?: Maybe<Array<Scalars['JSONObject']>>;
  respiratoryRate?: Maybe<RespiratoryRate>;
  selectedAction?: Maybe<Scalars['Int']>;
  startedAt: Scalars['Date'];
  subtype: Scalars['String'];
  temperature?: Maybe<Scalars['Float']>;
  type: Scalars['String'];
  updatedAt: Scalars['Date'];
  weight?: Maybe<Scalars['Float']>;
};

export type CheckupConfig = {
  __typename?: 'CheckupConfig';
  checkupTiles: CheckupTiles;
  description: Scalars['String'];
  heading: Scalars['String'];
  name: Scalars['String'];
  subheading: Scalars['String'];
};

export type CheckupPicture = {
  __typename?: 'CheckupPicture';
  caption?: Maybe<Scalars['String']>;
  url: Scalars['String'];
};

export type CheckupSchedule = {
  __typename?: 'CheckupSchedule';
  id: Scalars['ID'];
  rrule: Scalars['String'];
  tolerance: CheckupScheduleTolerance;
};

export type CheckupScheduleEvent = {
  __typename?: 'CheckupScheduleEvent';
  checkupExpectedAt: Scalars['Date'];
  checkupSchedule: CheckupSchedule;
  id: Scalars['ID'];
};

export type CheckupScheduleTolerance = {
  __typename?: 'CheckupScheduleTolerance';
  early: Scalars['Int'];
  late: Scalars['Int'];
};

export type CheckupTileConfig = {
  rule: CheckupTileRule;
};

export enum CheckupTileRule {
  Disabled = 'disabled',
  Optional = 'optional',
  Required = 'required'
}

export type CheckupTiles = {
  __typename?: 'CheckupTiles';
  bloodPressureCuff?: Maybe<BloodPressureCuffTileConfig>;
  consciousness?: Maybe<GenericCheckupTileConfig>;
  glucose?: Maybe<GenericCheckupTileConfig>;
  picture?: Maybe<GenericCheckupTileConfig>;
  pulseOximeter?: Maybe<PulseOximeterTileConfig>;
  respiratoryRate?: Maybe<GenericCheckupTileConfig>;
  stethoscope?: Maybe<StethoscopeTileConfig>;
  symptomsQuestionnaire?: Maybe<SymptomsQuestionnaireTileConfig>;
  temperature?: Maybe<GenericCheckupTileConfig>;
  weight?: Maybe<GenericCheckupTileConfig>;
};

export type CheckupType = {
  __typename?: 'CheckupType';
  checkupTiles: CheckupTiles;
  createdAt: Scalars['Date'];
  deletedAt?: Maybe<Scalars['Date']>;
  description: Scalars['String'];
  heading: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  numberOfCheckups: Scalars['Int'];
  schedule?: Maybe<CheckupSchedule>;
  subheading: Scalars['String'];
  updatedAt: Scalars['Date'];
};

export type CodedDataItem = {
  __typename?: 'CodedDataItem';
  additionalText: Scalars['String'];
  code: Scalars['String'];
  units: Scalars['String'];
  value: Scalars['Float'];
};

export enum CodedVital {
  DiastolicBloodPressure = 'diastolicBloodPressure',
  DiastolicBloodPressureLying = 'diastolicBloodPressure_lying',
  DiastolicBloodPressureSitting = 'diastolicBloodPressure_sitting',
  DiastolicBloodPressureStanding = 'diastolicBloodPressure_standing',
  Glucose = 'glucose',
  PulseRate = 'pulseRate',
  RespiratoryRate = 'respiratoryRate',
  SpO2 = 'spO2',
  SystolicBloodPressure = 'systolicBloodPressure',
  SystolicBloodPressureLying = 'systolicBloodPressure_lying',
  SystolicBloodPressureSitting = 'systolicBloodPressure_sitting',
  SystolicBloodPressureStanding = 'systolicBloodPressure_standing',
  Temperature = 'temperature',
  Weight = 'weight'
}

export { ConsciousnessType };

export type ContactDetailsInput = {
  address?: InputMaybe<InputAddress>;
  telephone?: InputMaybe<Scalars['String']>;
};

export type ContinuousMonitoring = {
  __typename?: 'ContinuousMonitoring';
  battery?: Maybe<BatteryStats>;
  bucketEndAt: Scalars['Date'];
  bucketSize: Scalars['Int'];
  bucketStartAt: Scalars['Date'];
  heartRate?: Maybe<BucketStats>;
  id: Scalars['ID'];
  patient?: Maybe<Patient>;
  pulseRate?: Maybe<BucketStats>;
  respiratoryRate?: Maybe<BucketStats>;
  spo2?: Maybe<BucketStats>;
  temperature?: Maybe<BucketStats>;
  thresholdScores?: Maybe<EwsScores>;
};

export type ContinuousMonitoringPages = {
  __typename?: 'ContinuousMonitoringPages';
  data: Array<ContinuousMonitoring>;
  earliest?: Maybe<Scalars['Date']>;
  from?: Maybe<Scalars['Date']>;
  latest?: Maybe<Scalars['Date']>;
  to?: Maybe<Scalars['Date']>;
};

export type ContinuousMonitoringSession = {
  __typename?: 'ContinuousMonitoringSession';
  createdAt: Scalars['Date'];
  earliestBySensor?: Maybe<VivalinkSensorData>;
  endedAt?: Maybe<Scalars['Date']>;
  id: Scalars['ID'];
  latestBySensor?: Maybe<VivalinkSensorData>;
  organization: Organization;
  patient: Patient;
  vivalinkSubjectId: Scalars['String'];
};

export type ContinuousMonitoringWithSession = {
  __typename?: 'ContinuousMonitoringWithSession';
  continuousMonitoring?: Maybe<ContinuousMonitoring>;
  continuousMonitoringSession: ContinuousMonitoringSession;
};

export type CreateEhrIntegrationInput = {
  dataPointTriggerConfig?: InputMaybe<EhrIntegrationDataPointTriggerConfigInput>;
  integrationType: EhrIntegrationType;
  periodicTriggerConfig?: InputMaybe<EhrIntegrationPeriodicTriggerConfigInput>;
  triggerType: EhrIntegrationTriggerType;
};

export type CreatePatientInput = {
  address?: InputMaybe<InputAddress>;
  birthDate: Scalars['Date'];
  firstName: Scalars['String'];
  gender: Scalars['String'];
  lastName: Scalars['String'];
  nhsNumber?: InputMaybe<Scalars['String']>;
  preExistingConditions?: InputMaybe<Scalars['String']>;
  selfCare?: InputMaybe<SelfCareInput>;
  telephone?: InputMaybe<Scalars['String']>;
  type: Scalars['String'];
};

export type EwsScores = {
  __typename?: 'EWSScores';
  BPScore?: Maybe<Scalars['Int']>;
  HRScore?: Maybe<Scalars['Int']>;
  RRScore?: Maybe<Scalars['Int']>;
  SpO2Score?: Maybe<Scalars['Int']>;
  consciousnessScore?: Maybe<Scalars['Int']>;
  riskLevel?: Maybe<Scalars['Int']>;
  tempScore?: Maybe<Scalars['Int']>;
  totalScore?: Maybe<Scalars['Int']>;
};

export type EwsThresholds = {
  __typename?: 'EWSThresholds';
  createdAt: Scalars['Date'];
  createdBy: User;
  id: Scalars['ID'];
  patient?: Maybe<Patient>;
  thresholds?: Maybe<Scalars['JSONObject']>;
  updatedAt: Scalars['Date'];
};

export type EhrClinicalTermMapping = {
  __typename?: 'EhrClinicalTermMapping';
  softSigns: Array<SoftSignCoding>;
  vitals: Array<VitalCoding>;
};

export type EhrClinicalTermMappingInput = {
  softSigns: Array<EhrSoftSignCodingInput>;
  vitals: Array<EhrVitalCodingInput>;
};

export enum EhrIdentityType {
  Quicksilva = 'Quicksilva'
}

export type EhrIntegration = {
  __typename?: 'EhrIntegration';
  authorizedAutomaticApprover?: Maybe<User>;
  createdAt: Scalars['Date'];
  deletedAt?: Maybe<Scalars['Date']>;
  id: Scalars['ID'];
  integrationType: EhrIntegrationType;
  triggerConfig?: Maybe<EhrIntegrationTriggerConfig>;
  triggerType: EhrIntegrationTriggerType;
  updatedAt: Scalars['Date'];
};

export type EhrIntegrationDataPointTriggerConfig = {
  __typename?: 'EhrIntegrationDataPointTriggerConfig';
  totalScoreThreshold?: Maybe<Scalars['Int']>;
  vitalSignScoreThresholds?: Maybe<EhrIntegrationDataPointVitalScoreTriggerConfig>;
};

export type EhrIntegrationDataPointTriggerConfigInput = {
  totalScoreThreshold?: InputMaybe<Scalars['Int']>;
  vitalSignScoreThresholds?: InputMaybe<EhrIntegrationDataPointVitalScoreTriggerConfigInput>;
};

export type EhrIntegrationDataPointVitalScoreTriggerConfig = {
  __typename?: 'EhrIntegrationDataPointVitalScoreTriggerConfig';
  bloodPressure?: Maybe<Scalars['Int']>;
  pulse?: Maybe<Scalars['Int']>;
  respiratoryRate?: Maybe<Scalars['Int']>;
  spO2?: Maybe<Scalars['Int']>;
  temperature?: Maybe<Scalars['Int']>;
};

export type EhrIntegrationDataPointVitalScoreTriggerConfigInput = {
  bloodPressure?: InputMaybe<Scalars['Int']>;
  pulse?: InputMaybe<Scalars['Int']>;
  respiratoryRate?: InputMaybe<Scalars['Int']>;
  spO2?: InputMaybe<Scalars['Int']>;
  temperature?: InputMaybe<Scalars['Int']>;
};

export type EhrIntegrationEvent = {
  __typename?: 'EhrIntegrationEvent';
  /** Only available for data point events */
  checkup?: Maybe<Checkup>;
  continuousMonitoring?: Maybe<ContinuousMonitoring>;
  createdAt: Scalars['Date'];
  id: Scalars['ID'];
  integration: EhrIntegration;
  isRetryable: Scalars['Boolean'];
  patient: Patient;
  requestBody?: Maybe<EhrIntegrationEventRequestBody>;
  response?: Maybe<EhrIntegrationResponse>;
  status: EhrIntegrationStatus;
  /** Only available for periodic summary events */
  summaryPeriod?: Maybe<EhrSummaryPeriod>;
  updatedAt: Scalars['Date'];
};

export type EhrIntegrationEventQuerySort = {
  direction: SortDirection;
  field: EhrIntegrationEventQuerySortField;
};

export enum EhrIntegrationEventQuerySortField {
  CreatedAt = 'createdAt',
  Status = 'status',
  UpdatedAt = 'updatedAt'
}

export type EhrIntegrationEventRequestBody = {
  __typename?: 'EhrIntegrationEventRequestBody';
  codedDataItems: Array<CodedDataItem>;
  consultationNote?: Maybe<Scalars['String']>;
};

export enum EhrIntegrationEventRequestType {
  ToolbarInstantMessage = 'ToolbarInstantMessage'
}

export type EhrIntegrationEvents = {
  __typename?: 'EhrIntegrationEvents';
  events: Array<EhrIntegrationEvent>;
  totalCount: Scalars['Int'];
};

export type EhrIntegrationIdentity = {
  __typename?: 'EhrIntegrationIdentity';
  createdAt: Scalars['Date'];
  id: Scalars['ID'];
  identityType: EhrIdentityType;
};

export type EhrIntegrationPeriodicTriggerConfig = {
  __typename?: 'EhrIntegrationPeriodicTriggerConfig';
  rrule: Scalars['String'];
};

export type EhrIntegrationPeriodicTriggerConfigInput = {
  rrule: Scalars['String'];
};

export type EhrIntegrationResponse = {
  __typename?: 'EhrIntegrationResponse';
  innerError?: Maybe<Scalars['String']>;
  message: Scalars['String'];
};

export enum EhrIntegrationStatus {
  ActionRequired = 'action_required',
  Error = 'error',
  Pending = 'pending',
  Rejected = 'rejected',
  Skipped = 'skipped',
  Submitted = 'submitted',
  Success = 'success'
}

export type EhrIntegrationTriggerConfig = EhrIntegrationDataPointTriggerConfig | EhrIntegrationPeriodicTriggerConfig;

export enum EhrIntegrationTriggerType {
  DataPoint = 'DataPoint',
  PeriodicSummary = 'PeriodicSummary'
}

export enum EhrIntegrationType {
  QuicksilvaMessage = 'QuicksilvaMessage'
}

export type EhrSoftSignCodingInput = {
  code: Scalars['String'];
  softSign: Scalars['String'];
};

export type EhrSummaryPeriod = {
  __typename?: 'EhrSummaryPeriod';
  from: Scalars['Date'];
  to: Scalars['Date'];
};

export type EhrVitalCodingInput = {
  code: Scalars['String'];
  unitText?: InputMaybe<Scalars['String']>;
  vital: CodedVital;
};

export enum GatewayStatus {
  AllGatewaysOffline = 'AllGatewaysOffline',
  AllGatewaysOnline = 'AllGatewaysOnline',
  NoGateways = 'NoGateways',
  SomeGatewaysOffline = 'SomeGatewaysOffline'
}

export enum Gender {
  Female = 'female',
  Male = 'male'
}

export type GenericCheckupTileConfig = CheckupTileConfig & {
  __typename?: 'GenericCheckupTileConfig';
  rule: CheckupTileRule;
};

export type InputAddress = {
  address: Scalars['String'];
  postcode: Scalars['String'];
};

export type IntegrationApiKey = IntegrationApiKeyInterface & {
  __typename?: 'IntegrationApiKey';
  apiKey: Scalars['String'];
  createdAt: Scalars['Date'];
  createdBy: User;
  deletedAt?: Maybe<Scalars['Date']>;
  id: Scalars['ID'];
};

export type IntegrationApiKeyInterface = {
  apiKey: Scalars['String'];
  createdAt: Scalars['Date'];
  deletedAt?: Maybe<Scalars['Date']>;
  id: Scalars['ID'];
};

export type IntegrationApiKeyWithSecret = IntegrationApiKeyInterface & {
  __typename?: 'IntegrationApiKeyWithSecret';
  apiKey: Scalars['String'];
  createdAt: Scalars['Date'];
  createdBy: User;
  deletedAt?: Maybe<Scalars['Date']>;
  id: Scalars['ID'];
  secret: Scalars['String'];
};

export type LungSoundRecording = {
  __typename?: 'LungSoundRecording';
  url: Scalars['String'];
};

export type Me = UserInterface & {
  __typename?: 'Me';
  actingOrganization: Organization;
  canResetPassword: Scalars['Boolean'];
  createdAt?: Maybe<Scalars['Date']>;
  email: Scalars['String'];
  enrolledInTotpMfa: Scalars['Boolean'];
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  intercomUserHash?: Maybe<Scalars['String']>;
  isFeebroid: Scalars['Boolean'];
  isQuicksilvaIdentitySetup: Scalars['Boolean'];
  lastName?: Maybe<Scalars['String']>;
  organizations: Array<Organization>;
  permissions: Scalars['JSONObject'];
  roles: Array<Scalars['String']>;
  wards: Array<Ward>;
};

export type MeInput = {
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
};

export type MessageEntity = {
  __typename?: 'MessageEntity';
  indices?: Maybe<Array<Scalars['Int']>>;
  instance?: Maybe<MessageEntityInstance>;
  text?: Maybe<Scalars['String']>;
  type: Scalars['String'];
};

export type MessageEntityInstance = AcceptedTerms | Alert | AlertRule | CarePathway | Checkup | CheckupConfig | CheckupSchedule | CheckupScheduleEvent | CheckupType | ContinuousMonitoring | ContinuousMonitoringSession | EwsThresholds | EhrIntegration | EhrIntegrationEvent | EhrIntegrationIdentity | IntegrationApiKey | NhsNumber | Organization | OrganizationPatient | PacsanaEvent | PacsanaSession | Patient | PatientNote | Picture | ShareToken | User | UserOrganization | Ward | WardStaff;

export type MetricAlgorithmExecutionInput = {
  algorithmId: Scalars['String'];
  algorithmVersion: Scalars['String'];
  context?: InputMaybe<Scalars['String']>;
  deviceId: Scalars['String'];
  executionTime: Scalars['Int'];
  id: Scalars['ID'];
  value: Scalars['Float'];
};

export type MetricAppActionInput = {
  deviceId: Scalars['String'];
  id: Scalars['ID'];
  screen: Scalars['String'];
  userEvent: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  _empty?: Maybe<Scalars['String']>;
  acceptTerms?: Maybe<Scalars['Boolean']>;
  addPatientNote: PatientNote;
  addQuicksilvaIdentity: Scalars['Boolean'];
  admitPatientToWard: WardPatient;
  assignPatientToPractice: OrganizationPatient;
  assignStaffToWard: WardStaff;
  captureMetricAlgorithmExecution?: Maybe<Scalars['Boolean']>;
  captureMetricAppAction?: Maybe<Scalars['Boolean']>;
  createEWSThresholds: EwsThresholds;
  createEhrIntegration: EhrIntegration;
  createIntegrationApiKey: IntegrationApiKeyWithSecret;
  createPatient: Patient;
  createPatientCallbackRequest?: Maybe<Alert>;
  createPractice: Organization;
  createSelfCareLogin: User;
  createUser: User;
  createVideoCallRoomAndSendSMS: VideoCallDetails;
  createWard: Ward;
  deleteEhrIntegration: Scalars['Boolean'];
  deletePatient: Scalars['Boolean'];
  deleteUser: Scalars['Boolean'];
  deleteWard?: Maybe<Ward>;
  didLogin: Scalars['Boolean'];
  didStayLoggedIn: Scalars['Boolean'];
  didSwitchActingOrganization: Scalars['Boolean'];
  dischargePatientFromWard: Scalars['Boolean'];
  endContinuousMonitoringSession: ContinuousMonitoringSession;
  endPacsanaSession: PacsanaSession;
  expireIntegrationApiKey: Scalars['Boolean'];
  markMyEmailAsVerified: Scalars['Boolean'];
  passwordResetUrl: Scalars['String'];
  registerAsEhrIntegrationAutoApprover: EhrIntegration;
  removeEhrIntegrationAutoApprover: EhrIntegration;
  removeQuicksilvaIdentity: Scalars['Boolean'];
  removeStaffFromWard: Scalars['Boolean'];
  retryEhrIntegrationEvent: EhrIntegrationEvent;
  setAlertStatus?: Maybe<Scalars['Boolean']>;
  setEhrClinicalTermMapping: EhrClinicalTermMapping;
  setPasswordForSelfCareUser: Scalars['Boolean'];
  startContinuousMonitoringSession: ContinuousMonitoringSession;
  startPacsanaSession: PacsanaSession;
  unassignPatientFromPractice: Scalars['Boolean'];
  undoExpireIntegrationApiKey: Scalars['Boolean'];
  unenrollFromTwoFactorAuth: Scalars['Boolean'];
  unenrollMyTwoFactorAuth: Scalars['Boolean'];
  updateMe: Scalars['Boolean'];
  updatePatient: Patient;
  updatePatientContactDetails: Patient;
  updateUserOrganization: UserOrganization;
  updateWard: Ward;
};


export type MutationAddPatientNoteArgs = {
  PatientId: Scalars['ID'];
  text: Scalars['String'];
};


export type MutationAddQuicksilvaIdentityArgs = {
  accessCode: Scalars['String'];
};


export type MutationAdmitPatientToWardArgs = {
  carePathwayId: Scalars['ID'];
  patientId: Scalars['ID'];
  wardId: Scalars['ID'];
};


export type MutationAssignPatientToPracticeArgs = {
  patientId: Scalars['ID'];
  practiceId: Scalars['ID'];
};


export type MutationAssignStaffToWardArgs = {
  userId: Scalars['ID'];
  wardId: Scalars['ID'];
};


export type MutationCaptureMetricAlgorithmExecutionArgs = {
  checkupId: Scalars['ID'];
  metricAlgorithmExecution: MetricAlgorithmExecutionInput;
  patientId: Scalars['ID'];
};


export type MutationCaptureMetricAppActionArgs = {
  checkupId?: InputMaybe<Scalars['ID']>;
  metricAppAction: MetricAppActionInput;
  patientId?: InputMaybe<Scalars['ID']>;
};


export type MutationCreateEwsThresholdsArgs = {
  patientId: Scalars['ID'];
  thresholds?: InputMaybe<ThresholdsInput>;
};


export type MutationCreateEhrIntegrationArgs = {
  carePathwayId: Scalars['ID'];
  ehrIntegration: CreateEhrIntegrationInput;
};


export type MutationCreatePatientArgs = {
  patient: CreatePatientInput;
};


export type MutationCreatePracticeArgs = {
  organization: PracticeInput;
  user: UserInput;
};


export type MutationCreateSelfCareLoginArgs = {
  patientId: Scalars['ID'];
  selfCare: SelfCareInput;
};


export type MutationCreateUserArgs = {
  portalUrl?: InputMaybe<Scalars['String']>;
  role: Scalars['String'];
  user: UserInput;
};


export type MutationCreateVideoCallRoomAndSendSmsArgs = {
  patientId: Scalars['ID'];
};


export type MutationCreateWardArgs = {
  name: Scalars['String'];
};


export type MutationDeleteEhrIntegrationArgs = {
  ehrIntegrationId: Scalars['ID'];
};


export type MutationDeletePatientArgs = {
  patientId: Scalars['ID'];
};


export type MutationDeleteUserArgs = {
  userId: Scalars['ID'];
};


export type MutationDeleteWardArgs = {
  id: Scalars['ID'];
};


export type MutationDischargePatientFromWardArgs = {
  PatientId: Scalars['ID'];
  WardId: Scalars['ID'];
  dischargedAt?: InputMaybe<Scalars['Date']>;
  reason?: InputMaybe<Scalars['String']>;
};


export type MutationEndContinuousMonitoringSessionArgs = {
  patientId: Scalars['ID'];
};


export type MutationEndPacsanaSessionArgs = {
  patientId: Scalars['ID'];
};


export type MutationExpireIntegrationApiKeyArgs = {
  at?: InputMaybe<Scalars['Date']>;
  id: Scalars['ID'];
};


export type MutationPasswordResetUrlArgs = {
  portalUrl: Scalars['String'];
  userId: Scalars['ID'];
};


export type MutationRegisterAsEhrIntegrationAutoApproverArgs = {
  ehrIntegrationId: Scalars['ID'];
};


export type MutationRemoveEhrIntegrationAutoApproverArgs = {
  ehrIntegrationId: Scalars['ID'];
};


export type MutationRemoveStaffFromWardArgs = {
  userId: Scalars['ID'];
  wardId: Scalars['ID'];
};


export type MutationRetryEhrIntegrationEventArgs = {
  ehrIntegrationEventId: Scalars['ID'];
};


export type MutationSetAlertStatusArgs = {
  id: Scalars['ID'];
  status: Scalars['String'];
};


export type MutationSetEhrClinicalTermMappingArgs = {
  clinicalTerms: EhrClinicalTermMappingInput;
};


export type MutationSetPasswordForSelfCareUserArgs = {
  password: Scalars['String'];
  userId: Scalars['ID'];
};


export type MutationStartContinuousMonitoringSessionArgs = {
  patientId: Scalars['ID'];
  vivalinkSubjectId: Scalars['String'];
};


export type MutationStartPacsanaSessionArgs = {
  pacsanaUserName: Scalars['String'];
  patientId: Scalars['ID'];
};


export type MutationUnassignPatientFromPracticeArgs = {
  patientId: Scalars['ID'];
  practiceId: Scalars['ID'];
};


export type MutationUndoExpireIntegrationApiKeyArgs = {
  id: Scalars['ID'];
};


export type MutationUnenrollFromTwoFactorAuthArgs = {
  userId: Scalars['ID'];
};


export type MutationUpdateMeArgs = {
  user: MeInput;
};


export type MutationUpdatePatientArgs = {
  patient: UpdatePatientInput;
};


export type MutationUpdatePatientContactDetailsArgs = {
  newContact: ContactDetailsInput;
  patientId: Scalars['ID'];
};


export type MutationUpdateUserOrganizationArgs = {
  roles: Array<Scalars['String']>;
  userId: Scalars['ID'];
};


export type MutationUpdateWardArgs = {
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type NewsScoringBandInput = {
  high?: InputMaybe<Scalars['Float']>;
  low?: InputMaybe<Scalars['Float']>;
  score: Scalars['Int'];
};

/**
 * A restricted view of a neighbor organization.
 * Neighbor organizations are other organizations that share patients with the queried organization.
 */
export type NeighborOrganization = {
  __typename?: 'NeighborOrganization';
  address: Address;
  id: Scalars['ID'];
  name: Scalars['String'];
  type: Scalars['String'];
};

export type NhsNumber = {
  __typename?: 'NhsNumber';
  id: Scalars['ID'];
  nhsNumber: Scalars['String'];
  patient: Patient;
};

export type NhsNumberResponseDetails = {
  __typename?: 'NhsNumberResponseDetails';
  editable?: Maybe<Scalars['Boolean']>;
  latestRetrievalTime?: Maybe<Scalars['Date']>;
  latestStatus: NhsNumberRetrievalStatus;
  nhsNumber?: Maybe<Scalars['String']>;
};

export type NhsNumberRetrievalStatus = {
  __typename?: 'NhsNumberRetrievalStatus';
  message: Scalars['String'];
  status: Scalars['String'];
};

export type OrgLevelCheckupTiles = {
  __typename?: 'OrgLevelCheckupTiles';
  checkupTiles?: Maybe<Array<Scalars['String']>>;
};

export type Organization = {
  __typename?: 'Organization';
  acceptedTerms: Scalars['Boolean'];
  address: Address;
  carePathways: Array<CarePathway>;
  checkupConfig: OrganizationCheckupConfig;
  defaultCarePathway?: Maybe<CarePathway>;
  ehrClinicalTermMapping?: Maybe<EhrClinicalTermMapping>;
  features?: Maybe<Scalars['JSONObject']>;
  id: Scalars['ID'];
  kits: Scalars['JSONObject'];
  name: Scalars['String'];
  /**
   * The neighbors of this organization. Neighbors are other organizations that share patients with
   * this organization.
   */
  neighbors: Array<NeighborOrganization>;
  roles: Array<Role>;
  type: Scalars['String'];
  wards: Array<Ward>;
};


export type OrganizationCarePathwaysArgs = {
  includeShared?: InputMaybe<Scalars['Boolean']>;
};

export type OrganizationCheckupConfig = {
  __typename?: 'OrganizationCheckupConfig';
  bloodPressureCuffDeviceType?: Maybe<Scalars['String']>;
  checkupConfigs?: Maybe<Array<CheckupConfig>>;
  checkupTypes?: Maybe<Array<Scalars['String']>>;
  custom?: Maybe<OrgLevelCheckupTiles>;
  pulseOximeterDeviceType?: Maybe<Scalars['String']>;
  questionnaire?: Maybe<Array<Scalars['String']>>;
  stethoscopeMandatory?: Maybe<Scalars['Boolean']>;
  temperatureDeviceType?: Maybe<Scalars['String']>;
};

export type OrganizationPatient = {
  __typename?: 'OrganizationPatient';
  organization: Organization;
  patient: Patient;
};

export type PacsanaDailyMetrics = {
  __typename?: 'PacsanaDailyMetrics';
  activeMinutes: Scalars['Int'];
  activityDate: Scalars['Date'];
  exerciseMinutes: Scalars['Int'];
  gaitSpeed?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  scores?: Maybe<PacsanaScores>;
};

export type PacsanaDailyMetricsMetadata = {
  __typename?: 'PacsanaDailyMetricsMetadata';
  earliestAvailableMetrics?: Maybe<Scalars['Date']>;
  latestAvailableMetrics?: Maybe<Scalars['Date']>;
};

export type PacsanaEvent = {
  __typename?: 'PacsanaEvent';
  createdAt: Scalars['Date'];
  eventId: Scalars['Int'];
  eventKey: PacsanaWebhookEventIds;
  eventName: Scalars['String'];
  generatedAt: Scalars['Date'];
  id: Scalars['ID'];
  pacsanaUserUid: Scalars['String'];
  patient?: Maybe<Patient>;
};

export type PacsanaKitWithActiveSession = {
  __typename?: 'PacsanaKitWithActiveSession';
  activeSession?: Maybe<PacsanaSession>;
  kitId: Scalars['String'];
};

export type PacsanaScores = {
  __typename?: 'PacsanaScores';
  gaitSpeedScore: Scalars['Int'];
};

export type PacsanaSession = {
  __typename?: 'PacsanaSession';
  createdAt: Scalars['Date'];
  endedAt?: Maybe<Scalars['Date']>;
  id: Scalars['ID'];
  organization: Organization;
  pacsanaUserName: Scalars['String'];
  patient: Patient;
  status: PacsanaSessionStatus;
};

export type PacsanaSessionStatus = {
  __typename?: 'PacsanaSessionStatus';
  batteryStatus?: Maybe<BatteryStatus>;
  gatewayStatus?: Maybe<GatewayStatus>;
  latestEventDate?: Maybe<Scalars['Date']>;
  latestMetricsDate?: Maybe<Scalars['Date']>;
  noMetricsForPreviousDay: Scalars['Boolean'];
  zeroesAcrossMetricsForPreviousDay: Scalars['Boolean'];
};

export enum PacsanaWebhookEventIds {
  AlertButton = 'AlertButton',
  AlertRecovery = 'AlertRecovery',
  BraceletBattery = 'BraceletBattery',
  BraceletInactiveAlert = 'BraceletInactiveAlert',
  FallDetection = 'FallDetection',
  InTheBedroomForTwentyFourHours = 'InTheBedroomForTwentyFourHours',
  LostConnection = 'LostConnection',
  LowActiveMinutes = 'LowActiveMinutes',
  SleepLessThanFiveHours = 'SleepLessThanFiveHours',
  SleepMoreThanTenHours = 'SleepMoreThanTenHours',
  StepIncreaseInDaytimeRest = 'StepIncreaseInDaytimeRest',
  StepReductionInBedroomHours = 'StepReductionInBedroomHours',
  ThirtyMinuteNightTimeAbsence = 'ThirtyMinuteNightTimeAbsence',
  TwelveHoursRestDuringDaytime = 'TwelveHoursRestDuringDaytime',
  TwentyFourHourAbsence = 'TwentyFourHourAbsence',
  UnknownEventId = 'UnknownEventId',
  UserInactiveAlert = 'UserInactiveAlert'
}

export type Patient = {
  __typename?: 'Patient';
  activityMonitoringSession?: Maybe<PacsanaSession>;
  address?: Maybe<Address>;
  agreedAt: Scalars['Date'];
  agreedDataProcessing: Scalars['Boolean'];
  agreedDataSharing: Scalars['Boolean'];
  agreedDataTransfer: Scalars['Boolean'];
  alerts: Array<Alert>;
  birthDate: Scalars['Date'];
  createdAt: Scalars['Date'];
  createdUsingAppVersion: Scalars['String'];
  deletedAt?: Maybe<Scalars['Date']>;
  ewsThresholds?: Maybe<EwsThresholds>;
  features: Scalars['JSONObject'];
  firstName: Scalars['String'];
  gender: Gender;
  id: Scalars['ID'];
  isTestPatient: Scalars['Boolean'];
  lastName: Scalars['String'];
  latestCheckup?: Maybe<Checkup>;
  latestContinuousMonitoring?: Maybe<ContinuousMonitoringWithSession>;
  nhsNumber?: Maybe<Scalars['String']>;
  nhsNumberResponseDetails?: Maybe<NhsNumberResponseDetails>;
  notes: Array<PatientNote>;
  numSimilarNames: Scalars['Int'];
  organizations: Array<Organization>;
  preExistingConditions?: Maybe<Scalars['String']>;
  selfCare?: Maybe<User>;
  softSignsSummary: SoftSignsSummary;
  telephone?: Maybe<Scalars['String']>;
  type: PatientType;
  updatedAt: Scalars['Date'];
  vitalsSummary: VitalsSummary;
  wardAdmission?: Maybe<WardAdmission>;
  wardDischarges: Array<PatientDischarge>;
  wards: Array<Ward>;
};


export type PatientAlertsArgs = {
  aggregate?: InputMaybe<Scalars['Boolean']>;
  status?: InputMaybe<Scalars['String']>;
};


export type PatientNotesArgs = {
  endDate?: InputMaybe<Scalars['Date']>;
  startDate?: InputMaybe<Scalars['Date']>;
};


export type PatientOrganizationsArgs = {
  type?: InputMaybe<Scalars['String']>;
};


export type PatientSoftSignsSummaryArgs = {
  endDate: Scalars['Date'];
  intervalHours?: InputMaybe<Scalars['Int']>;
  startDate: Scalars['Date'];
};


export type PatientVitalsSummaryArgs = {
  endDate: Scalars['Date'];
  intervalHours?: InputMaybe<Scalars['Int']>;
  startDate: Scalars['Date'];
};

export type PatientDischarge = {
  __typename?: 'PatientDischarge';
  PatientId: Scalars['ID'];
  WardId: Scalars['ID'];
  admittedAt: Scalars['Date'];
  carePathway?: Maybe<CarePathway>;
  createdAt: Scalars['Date'];
  dischargedAt: Scalars['Date'];
  dischargedBy?: Maybe<User>;
  id: Scalars['ID'];
  reason?: Maybe<Scalars['String']>;
  updatedAt: Scalars['Date'];
  ward: Ward;
};

export type PatientLookupInput = {
  birthDate: Scalars['Date'];
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  nhsNumber?: InputMaybe<Scalars['String']>;
};

export type PatientNote = {
  __typename?: 'PatientNote';
  checkup?: Maybe<Checkup>;
  createdAt: Scalars['Date'];
  createdBy?: Maybe<User>;
  createdById: Scalars['ID'];
  id: Scalars['ID'];
  organization?: Maybe<Organization>;
  patient: Patient;
  text: Scalars['String'];
  updatedAt: Scalars['Date'];
};

export enum PatientType {
  Child = 'child',
  Elderly = 'elderly'
}

export type Picture = {
  __typename?: 'Picture';
  caption?: Maybe<Scalars['String']>;
  checkup: Checkup;
  id: Scalars['ID'];
  location: Scalars['String'];
};

export type PracticeInput = {
  address: InputAddress;
  name: Scalars['String'];
  telephone?: InputMaybe<Scalars['String']>;
};

export type PulseOximeterTileConfig = CheckupTileConfig & {
  __typename?: 'PulseOximeterTileConfig';
  manualEntry?: Maybe<Scalars['Boolean']>;
  rule: CheckupTileRule;
  usePlethAI?: Maybe<Scalars['Boolean']>;
};

export type PulseRate = {
  __typename?: 'PulseRate';
  isManual: Scalars['Boolean'];
  source: PulseRateSource;
  value: Scalars['Int'];
};

export enum PulseRateSource {
  BpCuff = 'bpCuff',
  PulseOximeter = 'pulseOximeter'
}

export type Query = {
  __typename?: 'Query';
  actionLogs: ActionLogs;
  alerts: Array<Alert>;
  carePathway: CarePathway;
  carePathways: Array<CarePathway>;
  checkup?: Maybe<Checkup>;
  checkupsByPatient: Array<Checkup>;
  continuousMonitoring: ContinuousMonitoringPages;
  continuousMonitoringSession?: Maybe<ContinuousMonitoringSession>;
  ehrIntegrationEvents: EhrIntegrationEvents;
  ewsThresholds?: Maybe<EwsThresholds>;
  integrationApiKeys: Array<IntegrationApiKey>;
  isAuthorized: Scalars['Boolean'];
  lookupPatient?: Maybe<Patient>;
  me?: Maybe<Me>;
  pacsanaDailyMetricsForPatient: Array<PacsanaDailyMetrics>;
  pacsanaDailyMetricsMetadata?: Maybe<PacsanaDailyMetricsMetadata>;
  pacsanaKitsWithActiveSession: Array<PacsanaKitWithActiveSession>;
  pacsanaSession?: Maybe<PacsanaSession>;
  patient: Patient;
  patients: Array<Patient>;
  practices: Array<Organization>;
  recommendedMobileVersion?: Maybe<Scalars['String']>;
  shareTokens: Array<ShareToken>;
  userOrganizations: Array<UserOrganization>;
  users: Array<Maybe<User>>;
  version?: Maybe<Scalars['String']>;
  virtualWardPatients: VirtualWardPatients;
  vivalinkKitsWithActiveSession: Array<VivalinkKitWithActiveSession>;
  ward: Ward;
  wards: Array<Ward>;
};


export type QueryActionLogsArgs = {
  fromActionLogId?: InputMaybe<Scalars['ID']>;
  patientId: Scalars['ID'];
  take?: InputMaybe<Scalars['Int']>;
};


export type QueryAlertsArgs = {
  alertRuleId?: InputMaybe<Scalars['ID']>;
  checkupScheduleId?: InputMaybe<Scalars['ID']>;
  endDate?: InputMaybe<Scalars['Date']>;
  includeRecentlyClosed?: InputMaybe<Scalars['Boolean']>;
  pacsanaEventId?: InputMaybe<Scalars['Int']>;
  patientId: Scalars['ID'];
  startDate?: InputMaybe<Scalars['Date']>;
  status?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};


export type QueryCarePathwayArgs = {
  id: Scalars['ID'];
};


export type QueryCarePathwaysArgs = {
  includeShared?: InputMaybe<Scalars['Boolean']>;
};


export type QueryCheckupArgs = {
  id: Scalars['ID'];
};


export type QueryCheckupsByPatientArgs = {
  endDate?: InputMaybe<Scalars['Date']>;
  patientId: Scalars['ID'];
  startDate?: InputMaybe<Scalars['Date']>;
};


export type QueryContinuousMonitoringArgs = {
  currentBucketStartAt?: InputMaybe<Scalars['Date']>;
  numPrevBuckets: Scalars['Int'];
  patientId: Scalars['ID'];
};


export type QueryContinuousMonitoringSessionArgs = {
  patientId: Scalars['ID'];
};


export type QueryEhrIntegrationEventsArgs = {
  carePathwayId?: InputMaybe<Scalars['ID']>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<EhrIntegrationEventQuerySort>;
  status?: InputMaybe<Array<EhrIntegrationStatus>>;
  take?: InputMaybe<Scalars['Int']>;
};


export type QueryEwsThresholdsArgs = {
  at?: InputMaybe<Scalars['Date']>;
  patientId: Scalars['ID'];
};


export type QueryIsAuthorizedArgs = {
  permission: Scalars['String'];
};


export type QueryLookupPatientArgs = {
  fields: PatientLookupInput;
};


export type QueryPacsanaDailyMetricsForPatientArgs = {
  endDate?: InputMaybe<Scalars['Date']>;
  patientId: Scalars['ID'];
  startDate?: InputMaybe<Scalars['Date']>;
};


export type QueryPacsanaDailyMetricsMetadataArgs = {
  patientId: Scalars['ID'];
};


export type QueryPacsanaSessionArgs = {
  patientId: Scalars['ID'];
};


export type QueryPatientArgs = {
  id: Scalars['ID'];
};


export type QueryPatientsArgs = {
  isTestPatient?: InputMaybe<Scalars['Boolean']>;
};


export type QueryVirtualWardPatientsArgs = {
  isTestPatient?: InputMaybe<Scalars['Boolean']>;
  nameOrNhsNumber?: InputMaybe<Scalars['String']>;
  neighborIds?: InputMaybe<Array<Scalars['ID']>>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<InputMaybe<VirtualWardPatientsSortInput>>>;
  take?: InputMaybe<Scalars['Int']>;
  wardIds?: InputMaybe<Array<Scalars['ID']>>;
  withCheckupsInLastHours?: InputMaybe<Scalars['Int']>;
};


export type QueryWardArgs = {
  id: Scalars['ID'];
};

export enum QuestionnaireSectionType {
  BarthelActivitiesDailyLiving = 'BARTHEL_Activities_Daily_Living',
  HolywellUnwellPatient_20240415 = 'Holywell_UnwellPatient_20240415',
  JpuhAsthma_20231124 = 'JPUH_Asthma_20231124',
  JpuhFrailty_20231124 = 'JPUH_Frailty_20231124',
  JpuhHeartFailure_20231124 = 'JPUH_Heart_Failure_20231124',
  JpuhOsteomyelitis_20240102 = 'JPUH_Osteomyelitis_20240102',
  JpuhPneumonia_20240102 = 'JPUH_Pneumonia_20240102',
  JpuhSurgical_20231124 = 'JPUH_Surgical_20231124',
  NchcCardiacCp1_20230918 = 'NCHC_Cardiac_CP1_20230918',
  NchcFrailtyCp1_20230703 = 'NCHC_Frailty_CP1_20230703',
  NchcRespiratoryCp1_20230918 = 'NCHC_Respiratory_CP1_20230918',
  NnuhCmCp1_20231113 = 'NNUH_CM_CP1_20231113',
  NnuhCardiac_20240401 = 'NNUH_Cardiac_20240401',
  NnuhIvTherapyCp1_20230703 = 'NNUH_IVTherapy_CP1_20230703',
  NnuhRespiratory_20240401 = 'NNUH_Respiratory_20240401',
  Phq_9EmotionalWellbeing = 'PHQ_9_Emotional_Wellbeing',
  PainManagement = 'Pain_Management',
  QehAki_20240415 = 'QEH_AKI_20240415',
  QehCellulitis_20231012 = 'QEH_Cellulitis_20231012',
  QehHeartFailure_20240110 = 'QEH_HeartFailure_20240110',
  QehLrti_1y_4y = 'QEH_LRTI_1y_4y',
  QehLrti_5y_12y = 'QEH_LRTI_5y_12y',
  QehLrti_6wk_11mo = 'QEH_LRTI_6wk_11mo',
  QehPaedsGastro_20240429 = 'QEH_Paeds_Gastro_20240429',
  Emergency = 'emergency',
  Gastrointestinal = 'gastrointestinal',
  General = 'general',
  Nervous = 'nervous',
  Respiratory = 'respiratory'
}

export type RespiratoryRate = {
  __typename?: 'RespiratoryRate';
  source?: Maybe<RespiratoryRateSource>;
  value: Scalars['Int'];
};

export enum RespiratoryRateSource {
  Manual = 'manual',
  Masimo = 'masimo',
  PlethAi = 'plethAI'
}

export type Role = {
  __typename?: 'Role';
  description?: Maybe<Scalars['String']>;
  isSystemRole: Scalars['Boolean'];
  name: Scalars['String'];
};

export type SelfCareInput = {
  email: Scalars['String'];
  password?: InputMaybe<Scalars['String']>;
};

export type ShareToken = {
  __typename?: 'ShareToken';
  createdAt: Scalars['Date'];
  expiresAt: Scalars['Date'];
  id: Scalars['ID'];
  patient: Patient;
  user: User;
};

export type SoftSignCoding = {
  __typename?: 'SoftSignCoding';
  code: Scalars['String'];
  softSign: Scalars['String'];
};

export type SoftSignsCount = {
  __typename?: 'SoftSignsCount';
  count: Scalars['Int'];
  questionKey: Scalars['String'];
};

export type SoftSignsSummary = {
  __typename?: 'SoftSignsSummary';
  frames: Array<SoftSignsSummaryFrame>;
};

export type SoftSignsSummaryFrame = {
  __typename?: 'SoftSignsSummaryFrame';
  end: Scalars['Date'];
  start: Scalars['Date'];
  values: Array<SoftSignsCount>;
};

export enum SortDirection {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type StethoscopeTileConfig = CheckupTileConfig & {
  __typename?: 'StethoscopeTileConfig';
  forceOnAbnormalRespiratoryRate?: Maybe<Scalars['Boolean']>;
  forceOnAbnormalSpO2?: Maybe<Scalars['Boolean']>;
  forceOnRespiratorySoftSigns?: Maybe<Scalars['Boolean']>;
  rule: CheckupTileRule;
};

export type SymptomsQuestionnaireTileConfig = CheckupTileConfig & {
  __typename?: 'SymptomsQuestionnaireTileConfig';
  questionnaireSections?: Maybe<Array<QuestionnaireSectionType>>;
  rule: CheckupTileRule;
};

export type ThresholdsInput = {
  bloodPressure: Array<NewsScoringBandInput>;
  gaitSpeed: Array<NewsScoringBandInput>;
  pulse: Array<NewsScoringBandInput>;
  respiratoryRate: Array<NewsScoringBandInput>;
  spO2: Array<NewsScoringBandInput>;
  temperature: Array<NewsScoringBandInput>;
};

export type UpdatePatientInput = {
  address?: InputMaybe<InputAddress>;
  birthDate?: InputMaybe<Scalars['Date']>;
  firstName?: InputMaybe<Scalars['String']>;
  gender?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  lastName?: InputMaybe<Scalars['String']>;
  nhsNumber?: InputMaybe<Scalars['String']>;
  preExistingConditions?: InputMaybe<Scalars['String']>;
  telephone?: InputMaybe<Scalars['String']>;
};

export type User = UserInterface & {
  __typename?: 'User';
  canResetPassword: Scalars['Boolean'];
  email: Scalars['String'];
  enrolledInTotpMfa: Scalars['Boolean'];
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isQuicksilvaIdentitySetup: Scalars['Boolean'];
  isSelfCare: Scalars['Boolean'];
  lastName?: Maybe<Scalars['String']>;
  organizations: Array<Organization>;
  wards: Array<Ward>;
};

export type UserInput = {
  email: Scalars['String'];
  firebaseUid?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
};

export type UserInterface = {
  canResetPassword: Scalars['Boolean'];
  email: Scalars['String'];
  enrolledInTotpMfa: Scalars['Boolean'];
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isQuicksilvaIdentitySetup: Scalars['Boolean'];
  lastName?: Maybe<Scalars['String']>;
  organizations: Array<Organization>;
  wards: Array<Ward>;
};

export type UserOrganization = {
  __typename?: 'UserOrganization';
  createdAt: Scalars['Date'];
  lastLoggedInAt?: Maybe<Scalars['Date']>;
  organization: Organization;
  roles: Array<Maybe<Scalars['String']>>;
  updatedAt: Scalars['Date'];
  user: User;
};

export type VideoCallDetails = {
  __typename?: 'VideoCallDetails';
  hostRoomUrl: Scalars['String'];
  patientRoomUrl: Scalars['String'];
};

export type VirtualWardPatients = {
  __typename?: 'VirtualWardPatients';
  patients: Array<Patient>;
  total: Scalars['Int'];
};

export enum VirtualWardPatientsSortField {
  AlertCount = 'alertCount',
  EwsScore = 'ewsScore',
  LatestVitals = 'latestVitals'
}

export type VirtualWardPatientsSortInput = {
  direction: SortDirection;
  field: VirtualWardPatientsSortField;
};

export type VitalAggregate = {
  __typename?: 'VitalAggregate';
  max: Scalars['Float'];
  median: Scalars['Float'];
  min: Scalars['Float'];
};

export type VitalCoding = {
  __typename?: 'VitalCoding';
  code: Scalars['String'];
  unitText?: Maybe<Scalars['String']>;
  vital: CodedVital;
};

export type VitalsAggregates = {
  __typename?: 'VitalsAggregates';
  diastolicBloodPressure?: Maybe<VitalAggregate>;
  glucose?: Maybe<VitalAggregate>;
  pulseRate?: Maybe<VitalAggregate>;
  respiratoryRate?: Maybe<VitalAggregate>;
  spO2?: Maybe<VitalAggregate>;
  systolicBloodPressure?: Maybe<VitalAggregate>;
  temperature?: Maybe<VitalAggregate>;
  weight?: Maybe<VitalAggregate>;
};

export type VitalsSummary = {
  __typename?: 'VitalsSummary';
  frames: Array<VitalsSummaryFrame>;
};

export type VitalsSummaryFrame = {
  __typename?: 'VitalsSummaryFrame';
  end: Scalars['Date'];
  start: Scalars['Date'];
  values: VitalsAggregates;
};

export type VivalinkKitWithActiveSession = {
  __typename?: 'VivalinkKitWithActiveSession';
  activeSession?: Maybe<ContinuousMonitoringSession>;
  kitId: Scalars['String'];
};

export type VivalinkSensorData = {
  __typename?: 'VivalinkSensorData';
  ecg?: Maybe<ContinuousMonitoring>;
  spo2?: Maybe<ContinuousMonitoring>;
  temperature?: Maybe<ContinuousMonitoring>;
};

export type Ward = {
  __typename?: 'Ward';
  createdAt: Scalars['Date'];
  deletedAt?: Maybe<Scalars['Date']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  numberOfPatients: Scalars['Int'];
  numberOfStaff: Scalars['Int'];
  organization: Organization;
  updatedAt: Scalars['Date'];
  wardPatients: Array<WardPatient>;
  wardStaff: Array<WardStaff>;
};

export type WardAdmission = {
  __typename?: 'WardAdmission';
  admittedAt: Scalars['Date'];
  carePathway?: Maybe<CarePathway>;
  ward: Ward;
};

export type WardPatient = {
  __typename?: 'WardPatient';
  admittedAt: Scalars['Date'];
  carePathway?: Maybe<CarePathway>;
  patient: Patient;
};

export type WardStaff = {
  __typename?: 'WardStaff';
  assignedAt: Scalars['Date'];
  user: User;
};

export type AlertRuleDisplayFragment = (
  { __typename?: 'AlertRule' }
  & Pick<AlertRule, 'id' | 'name' | 'description' | 'condition'>
);

export type CheckupTypeDisplayFragment = (
  { __typename?: 'CheckupType' }
  & Pick<CheckupType, 'id' | 'name' | 'heading' | 'description' | 'updatedAt'>
  & { schedule?: Maybe<(
    { __typename?: 'CheckupSchedule' }
    & Pick<CheckupSchedule, 'rrule'>
    & { tolerance: (
      { __typename?: 'CheckupScheduleTolerance' }
      & Pick<CheckupScheduleTolerance, 'early' | 'late'>
    ) }
  )>, checkupTiles: (
    { __typename?: 'CheckupTiles' }
    & { symptomsQuestionnaire?: Maybe<(
      { __typename?: 'SymptomsQuestionnaireTileConfig' }
      & Pick<SymptomsQuestionnaireTileConfig, 'questionnaireSections' | 'rule'>
    )>, consciousness?: Maybe<(
      { __typename?: 'GenericCheckupTileConfig' }
      & Pick<GenericCheckupTileConfig, 'rule'>
    )>, pulseOximeter?: Maybe<(
      { __typename?: 'PulseOximeterTileConfig' }
      & Pick<PulseOximeterTileConfig, 'rule' | 'manualEntry' | 'usePlethAI'>
    )>, respiratoryRate?: Maybe<(
      { __typename?: 'GenericCheckupTileConfig' }
      & Pick<GenericCheckupTileConfig, 'rule'>
    )>, bloodPressureCuff?: Maybe<(
      { __typename?: 'BloodPressureCuffTileConfig' }
      & Pick<BloodPressureCuffTileConfig, 'rule' | 'manualEntry'>
    )>, temperature?: Maybe<(
      { __typename?: 'GenericCheckupTileConfig' }
      & Pick<GenericCheckupTileConfig, 'rule'>
    )>, stethoscope?: Maybe<(
      { __typename?: 'StethoscopeTileConfig' }
      & Pick<StethoscopeTileConfig, 'forceOnAbnormalRespiratoryRate' | 'forceOnAbnormalSpO2' | 'forceOnRespiratorySoftSigns' | 'rule'>
    )>, picture?: Maybe<(
      { __typename?: 'GenericCheckupTileConfig' }
      & Pick<GenericCheckupTileConfig, 'rule'>
    )>, weight?: Maybe<(
      { __typename?: 'GenericCheckupTileConfig' }
      & Pick<GenericCheckupTileConfig, 'rule'>
    )>, glucose?: Maybe<(
      { __typename?: 'GenericCheckupTileConfig' }
      & Pick<GenericCheckupTileConfig, 'rule'>
    )> }
  ) }
);

export type AddUserJourneyCreateUserMutationVariables = Exact<{
  user: UserInput;
  role: Scalars['String'];
  portalUrl: Scalars['String'];
}>;


export type AddUserJourneyCreateUserMutation = (
  { __typename?: 'Mutation' }
  & { createUser: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'firstName' | 'lastName' | 'email'>
    & { organizations: Array<(
      { __typename?: 'Organization' }
      & Pick<Organization, 'id'>
    )> }
  ) }
);

export type UpdatePatientContactDetailsMutationVariables = Exact<{
  patientId: Scalars['ID'];
  newContact: ContactDetailsInput;
}>;


export type UpdatePatientContactDetailsMutation = (
  { __typename?: 'Mutation' }
  & { updatePatientContactDetails: (
    { __typename?: 'Patient' }
    & Pick<Patient, 'id' | 'telephone'>
    & { address?: Maybe<(
      { __typename?: 'Address' }
      & Pick<Address, 'address' | 'postcode'>
    )> }
  ) }
);

export type GetAdmissionWardAndCarePathwayOptionsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAdmissionWardAndCarePathwayOptionsQuery = (
  { __typename?: 'Query' }
  & { wards: Array<(
    { __typename?: 'Ward' }
    & Pick<Ward, 'id' | 'name'>
  )>, carePathways: Array<(
    { __typename?: 'CarePathway' }
    & Pick<CarePathway, 'id' | 'name'>
  )> }
);

export type PatientLookupViewFragment = (
  { __typename?: 'Patient' }
  & Pick<Patient, 'id' | 'nhsNumber' | 'birthDate' | 'firstName' | 'lastName' | 'numSimilarNames' | 'gender' | 'telephone'>
  & { selfCare?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'email'>
  )>, address?: Maybe<(
    { __typename?: 'Address' }
    & Pick<Address, 'address' | 'postcode'>
  )>, wardAdmission?: Maybe<(
    { __typename?: 'WardAdmission' }
    & Pick<WardAdmission, 'admittedAt'>
    & { ward: (
      { __typename?: 'Ward' }
      & Pick<Ward, 'id' | 'name'>
    ), carePathway?: Maybe<(
      { __typename?: 'CarePathway' }
      & Pick<CarePathway, 'id' | 'name'>
    )> }
  )> }
);

export type LookupPatientQueryVariables = Exact<{
  fields: PatientLookupInput;
}>;


export type LookupPatientQuery = (
  { __typename?: 'Query' }
  & { lookupPatient?: Maybe<(
    { __typename?: 'Patient' }
    & PatientLookupViewFragment
  )> }
);

export type CreateSelfCareLoginMutationVariables = Exact<{
  patientId: Scalars['ID'];
  email: Scalars['String'];
  password?: InputMaybe<Scalars['String']>;
}>;


export type CreateSelfCareLoginMutation = (
  { __typename?: 'Mutation' }
  & { createSelfCareLogin: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'email'>
  ) }
);

export type PatientNhsNumberDetailsDialogFragment = (
  { __typename?: 'Patient' }
  & { nhsNumberResponseDetails?: Maybe<(
    { __typename?: 'NhsNumberResponseDetails' }
    & Pick<NhsNumberResponseDetails, 'nhsNumber' | 'latestRetrievalTime' | 'editable'>
    & { latestStatus: (
      { __typename?: 'NhsNumberRetrievalStatus' }
      & Pick<NhsNumberRetrievalStatus, 'message' | 'status'>
    ) }
  )> }
);

export type PatientNhsNumberDetailsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type PatientNhsNumberDetailsQuery = (
  { __typename?: 'Query' }
  & { patient: (
    { __typename?: 'Patient' }
    & PatientNhsNumberDetailsDialogFragment
  ) }
);

export type PacsanaSessionItemFragment = (
  { __typename?: 'PacsanaSession' }
  & Pick<PacsanaSession, 'id'>
);

export type GetActivityMonitoringSessionQueryVariables = Exact<{
  patientId: Scalars['ID'];
}>;


export type GetActivityMonitoringSessionQuery = (
  { __typename?: 'Query' }
  & { pacsanaSession?: Maybe<(
    { __typename?: 'PacsanaSession' }
    & PacsanaSessionItemFragment
  )> }
);

export type CreateVideoCallRoomAndSendSmsMutationVariables = Exact<{
  patientId: Scalars['ID'];
}>;


export type CreateVideoCallRoomAndSendSmsMutation = (
  { __typename?: 'Mutation' }
  & { createVideoCallRoomAndSendSMS: (
    { __typename?: 'VideoCallDetails' }
    & Pick<VideoCallDetails, 'hostRoomUrl' | 'patientRoomUrl'>
  ) }
);

export type DischargePatientDetailsFragment = (
  { __typename?: 'Patient' }
  & Pick<Patient, 'id' | 'firstName' | 'lastName' | 'birthDate' | 'nhsNumber'>
  & { wardAdmission?: Maybe<(
    { __typename?: 'WardAdmission' }
    & DischargePatientAdmissionDetailsFragment
  )> }
);

export type DischargePatientAdmissionDetailsFragment = (
  { __typename?: 'WardAdmission' }
  & Pick<WardAdmission, 'admittedAt'>
  & { ward: (
    { __typename?: 'Ward' }
    & Pick<Ward, 'id' | 'name'>
  ), carePathway?: Maybe<(
    { __typename?: 'CarePathway' }
    & Pick<CarePathway, 'name'>
  )> }
);

export type DischargePatientFromWardMutationVariables = Exact<{
  PatientId: Scalars['ID'];
  WardId: Scalars['ID'];
  dischargedAt?: InputMaybe<Scalars['Date']>;
  reason?: InputMaybe<Scalars['String']>;
}>;


export type DischargePatientFromWardMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'dischargePatientFromWard'>
);

export type GetWardsForSelectQueryVariables = Exact<{ [key: string]: never; }>;


export type GetWardsForSelectQuery = (
  { __typename?: 'Query' }
  & { wards: Array<(
    { __typename?: 'Ward' }
    & Pick<Ward, 'id' | 'name'>
  )> }
);

export type GetCarePathwaysForSelectQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCarePathwaysForSelectQuery = (
  { __typename?: 'Query' }
  & { carePathways: Array<(
    { __typename?: 'CarePathway' }
    & Pick<CarePathway, 'id' | 'name'>
  )> }
);

export type AdmitPatientMutationVariables = Exact<{
  patientId: Scalars['ID'];
  wardId: Scalars['ID'];
  carePathwayId: Scalars['ID'];
}>;


export type AdmitPatientMutation = (
  { __typename?: 'Mutation' }
  & { admitPatientToWard: (
    { __typename?: 'WardPatient' }
    & Pick<WardPatient, 'admittedAt'>
    & { patient: (
      { __typename?: 'Patient' }
      & Pick<Patient, 'id'>
      & { wardAdmission?: Maybe<(
        { __typename?: 'WardAdmission' }
        & PatientAdmissionOverviewFragment
      )> }
    ) }
  ) }
);

export type DidSwitchActingOrganizationMutationVariables = Exact<{ [key: string]: never; }>;


export type DidSwitchActingOrganizationMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'didSwitchActingOrganization'>
);

export type GetTotpUserEnrolmentQueryVariables = Exact<{ [key: string]: never; }>;


export type GetTotpUserEnrolmentQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Me' }
    & Pick<Me, 'email' | 'enrolledInTotpMfa'>
  )> }
);

export type UnenrollMyTwoFactorAuthMutationVariables = Exact<{ [key: string]: never; }>;


export type UnenrollMyTwoFactorAuthMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'unenrollMyTwoFactorAuth'>
);

export type MarkMyEmailAsVerifiedMutationVariables = Exact<{ [key: string]: never; }>;


export type MarkMyEmailAsVerifiedMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'markMyEmailAsVerified'>
);

export type AddQuicksilvaIdentityMutationVariables = Exact<{
  accessCode: Scalars['String'];
}>;


export type AddQuicksilvaIdentityMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'addQuicksilvaIdentity'>
);

export type RemoveQuicksilvaIdentityMutationVariables = Exact<{ [key: string]: never; }>;


export type RemoveQuicksilvaIdentityMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'removeQuicksilvaIdentity'>
);

export type IsQuicksilvaIdentitySetupQueryVariables = Exact<{ [key: string]: never; }>;


export type IsQuicksilvaIdentitySetupQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Me' }
    & Pick<Me, 'isQuicksilvaIdentitySetup'>
  )> }
);

export type UpdateMeMutationVariables = Exact<{
  user: MeInput;
}>;


export type UpdateMeMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateMe'>
);

export type CheckupDetailsFragment = (
  { __typename: 'Checkup' }
  & Pick<Checkup, 'id' | 'temperature' | 'subtype' | 'pulseOxiData' | 'consciousness' | 'bloodPressureData' | 'questionnaire' | 'weight' | 'glucose' | 'endedAt'>
  & { ewsScores?: Maybe<(
    { __typename: 'EWSScores' }
    & Pick<EwsScores, 'BPScore' | 'consciousnessScore' | 'HRScore' | 'riskLevel' | 'RRScore' | 'SpO2Score' | 'tempScore' | 'totalScore'>
  )>, notes: Array<(
    { __typename: 'PatientNote' }
    & Pick<PatientNote, 'id' | 'text' | 'createdAt' | 'updatedAt'>
    & { patient: (
      { __typename?: 'Patient' }
      & Pick<Patient, 'id'>
    ), createdBy?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id'>
    )>, checkup?: Maybe<(
      { __typename?: 'Checkup' }
      & Pick<Checkup, 'id'>
    )> }
  )>, pulseRate?: Maybe<(
    { __typename: 'PulseRate' }
    & Pick<PulseRate, 'isManual' | 'source' | 'value'>
  )>, respiratoryRate?: Maybe<(
    { __typename: 'RespiratoryRate' }
    & Pick<RespiratoryRate, 'value' | 'source'>
  )>, picture?: Maybe<(
    { __typename: 'CheckupPicture' }
    & Pick<CheckupPicture, 'url' | 'caption'>
  )>, lungSounds?: Maybe<Array<(
    { __typename: 'LungSoundRecording' }
    & Pick<LungSoundRecording, 'url'>
  )>>, createdBy?: Maybe<(
    { __typename: 'User' }
    & Pick<User, 'email' | 'firstName' | 'lastName' | 'isSelfCare'>
  )>, organization?: Maybe<(
    { __typename?: 'Organization' }
    & Pick<Organization, 'id' | 'name'>
  )> }
);

export type GetCheckupDetailsQueryVariables = Exact<{
  checkupId: Scalars['ID'];
}>;


export type GetCheckupDetailsQuery = (
  { __typename?: 'Query' }
  & { checkup?: Maybe<(
    { __typename?: 'Checkup' }
    & CheckupDetailsFragment
  )> }
);

export type RetryEhrIntegrationEventMutationVariables = Exact<{
  ehrIntegrationEventId: Scalars['ID'];
}>;


export type RetryEhrIntegrationEventMutation = (
  { __typename?: 'Mutation' }
  & { retryEhrIntegrationEvent: (
    { __typename?: 'EhrIntegrationEvent' }
    & IntegrationEventItemFragment
  ) }
);

type FormattableIntegrationTriggerConfig_EhrIntegrationDataPointTriggerConfig_Fragment = { __typename: 'EhrIntegrationDataPointTriggerConfig' };

type FormattableIntegrationTriggerConfig_EhrIntegrationPeriodicTriggerConfig_Fragment = (
  { __typename: 'EhrIntegrationPeriodicTriggerConfig' }
  & Pick<EhrIntegrationPeriodicTriggerConfig, 'rrule'>
);

export type FormattableIntegrationTriggerConfigFragment = FormattableIntegrationTriggerConfig_EhrIntegrationDataPointTriggerConfig_Fragment | FormattableIntegrationTriggerConfig_EhrIntegrationPeriodicTriggerConfig_Fragment;

export type IntegrationEventItemFragment = (
  { __typename: 'EhrIntegrationEvent' }
  & Pick<EhrIntegrationEvent, 'id' | 'status' | 'isRetryable' | 'createdAt' | 'updatedAt'>
  & { response?: Maybe<(
    { __typename?: 'EhrIntegrationResponse' }
    & Pick<EhrIntegrationResponse, 'message' | 'innerError'>
  )>, requestBody?: Maybe<(
    { __typename?: 'EhrIntegrationEventRequestBody' }
    & Pick<EhrIntegrationEventRequestBody, 'consultationNote'>
    & { codedDataItems: Array<(
      { __typename?: 'CodedDataItem' }
      & Pick<CodedDataItem, 'code' | 'value' | 'units' | 'additionalText'>
    )> }
  )>, patient: (
    { __typename?: 'Patient' }
    & Pick<Patient, 'id' | 'firstName' | 'lastName' | 'nhsNumber' | 'birthDate'>
  ), integration: (
    { __typename?: 'EhrIntegration' }
    & Pick<EhrIntegration, 'integrationType' | 'triggerType'>
    & { triggerConfig?: Maybe<(
      { __typename?: 'EhrIntegrationDataPointTriggerConfig' }
      & FormattableIntegrationTriggerConfig_EhrIntegrationDataPointTriggerConfig_Fragment
    ) | (
      { __typename?: 'EhrIntegrationPeriodicTriggerConfig' }
      & FormattableIntegrationTriggerConfig_EhrIntegrationPeriodicTriggerConfig_Fragment
    )> }
  ), summaryPeriod?: Maybe<(
    { __typename?: 'EhrSummaryPeriod' }
    & Pick<EhrSummaryPeriod, 'from' | 'to'>
  )>, checkup?: Maybe<(
    { __typename?: 'Checkup' }
    & Pick<Checkup, 'id' | 'type' | 'endedAt'>
  )>, continuousMonitoring?: Maybe<(
    { __typename?: 'ContinuousMonitoring' }
    & Pick<ContinuousMonitoring, 'bucketStartAt' | 'bucketEndAt'>
  )> }
);

export type GetIntegrationEventsQueryVariables = Exact<{
  take: Scalars['Int'];
  skip: Scalars['Int'];
  status?: InputMaybe<Array<EhrIntegrationStatus> | EhrIntegrationStatus>;
  sort?: InputMaybe<EhrIntegrationEventQuerySort>;
}>;


export type GetIntegrationEventsQuery = (
  { __typename?: 'Query' }
  & { ehrIntegrationEvents: (
    { __typename?: 'EhrIntegrationEvents' }
    & Pick<EhrIntegrationEvents, 'totalCount'>
    & { events: Array<(
      { __typename?: 'EhrIntegrationEvent' }
      & IntegrationEventItemFragment
    )> }
  ) }
);

export type ManageCarePathwayQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ManageCarePathwayQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Me' }
    & Pick<Me, 'isQuicksilvaIdentitySetup'>
  )>, carePathway: (
    { __typename?: 'CarePathway' }
    & { checkupTypes: Array<(
      { __typename?: 'CheckupType' }
      & CheckupTypeDisplayFragment
    )>, alertRules: Array<(
      { __typename?: 'AlertRule' }
      & AlertRuleDisplayFragment
    )>, integrations: Array<(
      { __typename?: 'EhrIntegration' }
      & IntegrationDisplayFragment
    )> }
    & ManageCarePathwayItemFragment
  ) }
);

export type ManageCarePathwayItemFragment = (
  { __typename?: 'CarePathway' }
  & Pick<CarePathway, 'id' | 'name' | 'createdAt' | 'updatedAt'>
  & { organization?: Maybe<(
    { __typename?: 'Organization' }
    & Pick<Organization, 'id'>
  )> }
);

export type ManageCarePathwaysQueryVariables = Exact<{ [key: string]: never; }>;


export type ManageCarePathwaysQuery = (
  { __typename?: 'Query' }
  & { carePathways: Array<(
    { __typename?: 'CarePathway' }
    & ManageCarePathwayItemFragment
  )> }
);

export type IntegrationDisplayFragment = (
  { __typename?: 'EhrIntegration' }
  & Pick<EhrIntegration, 'id' | 'integrationType' | 'triggerType' | 'createdAt' | 'updatedAt'>
  & { authorizedAutomaticApprover?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'email'>
  )>, triggerConfig?: Maybe<(
    { __typename?: 'EhrIntegrationDataPointTriggerConfig' }
    & FormattableIntegrationTriggerConfig_EhrIntegrationDataPointTriggerConfig_Fragment
  ) | (
    { __typename?: 'EhrIntegrationPeriodicTriggerConfig' }
    & FormattableIntegrationTriggerConfig_EhrIntegrationPeriodicTriggerConfig_Fragment
  )> }
);

export type RegisterAsApproverMutationVariables = Exact<{
  integrationId: Scalars['ID'];
}>;


export type RegisterAsApproverMutation = (
  { __typename?: 'Mutation' }
  & { registerAsEhrIntegrationAutoApprover: (
    { __typename?: 'EhrIntegration' }
    & Pick<EhrIntegration, 'id'>
    & { authorizedAutomaticApprover?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'email'>
    )> }
  ) }
);

export type RemoveAutoApproverMutationVariables = Exact<{
  integrationId: Scalars['ID'];
}>;


export type RemoveAutoApproverMutation = (
  { __typename?: 'Mutation' }
  & { removeEhrIntegrationAutoApprover: (
    { __typename?: 'EhrIntegration' }
    & Pick<EhrIntegration, 'id'>
  ) }
);

export type ContinuousMonitoringSessionItemFragment = (
  { __typename?: 'ContinuousMonitoringSession' }
  & Pick<ContinuousMonitoringSession, 'id'>
  & { organization: (
    { __typename?: 'Organization' }
    & Pick<Organization, 'id'>
  ) }
);

export type GetContinuousMonitoringSessionQueryVariables = Exact<{
  patientId: Scalars['ID'];
}>;


export type GetContinuousMonitoringSessionQuery = (
  { __typename?: 'Query' }
  & { continuousMonitoringSession?: Maybe<(
    { __typename?: 'ContinuousMonitoringSession' }
    & ContinuousMonitoringSessionItemFragment
  )> }
);

export type DeletePatientMutationVariables = Exact<{
  patientId: Scalars['ID'];
}>;


export type DeletePatientMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deletePatient'>
);

export type PatientPracticeAssignmentFragment = (
  { __typename?: 'Organization' }
  & Pick<Organization, 'id' | 'name'>
  & { address: (
    { __typename?: 'Address' }
    & Pick<Address, 'address' | 'postcode'>
  ) }
);

export type NhsNumberEditableQueryVariables = Exact<{
  patientId: Scalars['ID'];
}>;


export type NhsNumberEditableQuery = (
  { __typename?: 'Query' }
  & { patient: (
    { __typename?: 'Patient' }
    & { nhsNumberResponseDetails?: Maybe<(
      { __typename?: 'NhsNumberResponseDetails' }
      & Pick<NhsNumberResponseDetails, 'editable'>
    )> }
  ) }
);

export type UpdatePatientMutationVariables = Exact<{
  patient: UpdatePatientInput;
}>;


export type UpdatePatientMutation = (
  { __typename?: 'Mutation' }
  & { patient: (
    { __typename?: 'Patient' }
    & PatientDetailsFragment
  ) }
);

export type CreatePatientMutationVariables = Exact<{
  patient: CreatePatientInput;
}>;


export type CreatePatientMutation = (
  { __typename?: 'Mutation' }
  & { patient: (
    { __typename?: 'Patient' }
    & PatientDetailsFragment
  ) }
);

export type SetPasswordForSelfCareUserMutationVariables = Exact<{
  userId: Scalars['ID'];
  password: Scalars['String'];
}>;


export type SetPasswordForSelfCareUserMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'setPasswordForSelfCareUser'>
);

export type ManagePatientItemFragment = (
  { __typename?: 'Patient' }
  & Pick<Patient, 'id' | 'createdAt' | 'firstName' | 'lastName' | 'gender' | 'birthDate' | 'telephone' | 'preExistingConditions' | 'nhsNumber'>
  & { address?: Maybe<(
    { __typename?: 'Address' }
    & Pick<Address, 'address' | 'postcode'>
  )>, wards: Array<(
    { __typename?: 'Ward' }
    & Pick<Ward, 'id' | 'name' | 'createdAt'>
  )>, practices: Array<(
    { __typename?: 'Organization' }
    & PatientPracticeAssignmentFragment
  )>, selfCare?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'email' | 'canResetPassword'>
  )> }
);

export type GetPatientsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetPatientsQuery = (
  { __typename?: 'Query' }
  & { patients: Array<(
    { __typename?: 'Patient' }
    & ManagePatientItemFragment
  )> }
);

export type AssignStaffToWardMutationVariables = Exact<{
  wardId: Scalars['ID'];
  staffId: Scalars['ID'];
}>;


export type AssignStaffToWardMutation = (
  { __typename?: 'Mutation' }
  & { assignStaffToWard: (
    { __typename?: 'WardStaff' }
    & Pick<WardStaff, 'assignedAt'>
  ) }
);

export type OrganizationUsersQueryVariables = Exact<{ [key: string]: never; }>;


export type OrganizationUsersQuery = (
  { __typename?: 'Query' }
  & { userOrganizations: Array<(
    { __typename?: 'UserOrganization' }
    & { user: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'email' | 'firstName' | 'lastName'>
    ) }
  )> }
);

export type GetWardByIdQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetWardByIdQuery = (
  { __typename?: 'Query' }
  & { ward: (
    { __typename?: 'Ward' }
    & Pick<Ward, 'id'>
    & WardHeaderInfoFragment
  ) }
);

export type RemoveStaffFromWardMutationVariables = Exact<{
  wardId: Scalars['ID'];
  userId: Scalars['ID'];
}>;


export type RemoveStaffFromWardMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'removeStaffFromWard'>
);

export type WardHeaderInfoFragment = (
  { __typename?: 'Ward' }
  & Pick<Ward, 'name' | 'numberOfPatients' | 'numberOfStaff'>
);

export type WardPatientItemFragment = (
  { __typename?: 'WardPatient' }
  & Pick<WardPatient, 'admittedAt'>
  & { patient: (
    { __typename?: 'Patient' }
    & Pick<Patient, 'id' | 'firstName' | 'lastName' | 'birthDate' | 'gender' | 'nhsNumber'>
  ), carePathway?: Maybe<(
    { __typename?: 'CarePathway' }
    & Pick<CarePathway, 'id' | 'name'>
  )> }
);

export type WardPatientsQueryVariables = Exact<{
  wardId: Scalars['ID'];
}>;


export type WardPatientsQuery = (
  { __typename?: 'Query' }
  & { ward: (
    { __typename?: 'Ward' }
    & Pick<Ward, 'id'>
    & { wardPatients: Array<(
      { __typename?: 'WardPatient' }
      & WardPatientItemFragment
    )> }
  ) }
);

export type WardStaffItemFragment = (
  { __typename?: 'WardStaff' }
  & Pick<WardStaff, 'assignedAt'>
  & { user: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'firstName' | 'lastName' | 'email'>
  ) }
);

export type WardStaffQueryVariables = Exact<{
  wardId: Scalars['ID'];
}>;


export type WardStaffQuery = (
  { __typename?: 'Query' }
  & { ward: (
    { __typename?: 'Ward' }
    & Pick<Ward, 'id'>
    & { wardStaff: Array<(
      { __typename?: 'WardStaff' }
      & WardStaffItemFragment
    )> }
  ) }
);

export type GetWardsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetWardsQuery = (
  { __typename?: 'Query' }
  & { wards: Array<(
    { __typename?: 'Ward' }
    & ManageWardItemFragment
  )> }
);

export type CreateWardMutationVariables = Exact<{
  name: Scalars['String'];
}>;


export type CreateWardMutation = (
  { __typename?: 'Mutation' }
  & { createWard: (
    { __typename?: 'Ward' }
    & Pick<Ward, 'id' | 'name' | 'createdAt' | 'updatedAt'>
  ) }
);

export type DeleteWardMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteWardMutation = (
  { __typename?: 'Mutation' }
  & { deleteWard?: Maybe<(
    { __typename?: 'Ward' }
    & Pick<Ward, 'id'>
  )> }
);

export type EditWardMutationVariables = Exact<{
  id: Scalars['ID'];
  name: Scalars['String'];
}>;


export type EditWardMutation = (
  { __typename?: 'Mutation' }
  & { updateWard: (
    { __typename?: 'Ward' }
    & Pick<Ward, 'id' | 'name' | 'createdAt' | 'updatedAt'>
  ) }
);

export type ManageWardItemFragment = (
  { __typename?: 'Ward' }
  & Pick<Ward, 'id' | 'name' | 'createdAt' | 'updatedAt' | 'numberOfPatients' | 'numberOfStaff'>
);

export type AcceptedTermsInstanceFragment = (
  { __typename?: 'AcceptedTerms' }
  & { acceptedTermsId: AcceptedTerms['id'] }
);

export type AlertInstanceFragment = (
  { __typename?: 'Alert' }
  & { alertType: Alert['type'] }
  & { alertRule?: Maybe<(
    { __typename?: 'AlertRule' }
    & AlertRuleInstanceFragment
  )> }
);

export type AlertRuleInstanceFragment = (
  { __typename?: 'AlertRule' }
  & { alertRuleName: AlertRule['name'], alertRuleType: AlertRule['type'] }
);

export type CarePathwayInstanceFragment = (
  { __typename?: 'CarePathway' }
  & { carePathwayName: CarePathway['name'] }
);

export type CheckupInstanceFragment = (
  { __typename?: 'Checkup' }
  & { checkupType: Checkup['type'] }
);

export type CheckupConfigInstanceFragment = (
  { __typename?: 'CheckupConfig' }
  & { checkupConfigName: CheckupConfig['name'] }
);

export type CheckupScheduleInstanceFragment = (
  { __typename?: 'CheckupSchedule' }
  & { checkupScheduleId: CheckupSchedule['id'] }
);

export type CheckupScheduleEventInstanceFragment = (
  { __typename?: 'CheckupScheduleEvent' }
  & Pick<CheckupScheduleEvent, 'checkupExpectedAt'>
);

export type CheckupTypeInstanceFragment = (
  { __typename?: 'CheckupType' }
  & { checkupTypeName: CheckupType['name'] }
);

export type ContinuousMonitoringInstanceFragment = (
  { __typename?: 'ContinuousMonitoring' }
  & { continousMonitoringId: ContinuousMonitoring['id'] }
);

export type ContinuousMonitoringSessionInstanceFragment = (
  { __typename?: 'ContinuousMonitoringSession' }
  & { sessionCreatedAt: ContinuousMonitoringSession['createdAt'], sessionEndedAt: ContinuousMonitoringSession['endedAt'] }
);

export type PacsanaSessionInstanceFragment = (
  { __typename?: 'PacsanaSession' }
  & { sessionCreatedAt: PacsanaSession['createdAt'], sessionEndedAt: PacsanaSession['endedAt'] }
);

export type PacsanaEventInstanceFragment = (
  { __typename?: 'PacsanaEvent' }
  & Pick<PacsanaEvent, 'eventName'>
);

export type EhrIntegrationInstanceFragment = (
  { __typename?: 'EhrIntegration' }
  & Pick<EhrIntegration, 'integrationType' | 'triggerType'>
  & { triggerConfig?: Maybe<(
    { __typename?: 'EhrIntegrationDataPointTriggerConfig' }
    & FormattableIntegrationTriggerConfig_EhrIntegrationDataPointTriggerConfig_Fragment
  ) | (
    { __typename?: 'EhrIntegrationPeriodicTriggerConfig' }
    & FormattableIntegrationTriggerConfig_EhrIntegrationPeriodicTriggerConfig_Fragment
  )> }
);

export type EhrIntegrationEventInstanceFragment = (
  { __typename?: 'EhrIntegrationEvent' }
  & { integration: (
    { __typename?: 'EhrIntegration' }
    & EhrIntegrationInstanceFragment
  ), summaryPeriod?: Maybe<(
    { __typename?: 'EhrSummaryPeriod' }
    & Pick<EhrSummaryPeriod, 'from' | 'to'>
  )> }
);

export type EhrIntegrationIdentityInstanceFragment = (
  { __typename?: 'EhrIntegrationIdentity' }
  & Pick<EhrIntegrationIdentity, 'identityType'>
);

export type OrganizationInstanceFragment = (
  { __typename?: 'Organization' }
  & { organizationName: Organization['name'] }
);

export type OrganizationPatientInstanceFragment = (
  { __typename?: 'OrganizationPatient' }
  & { patient: (
    { __typename?: 'Patient' }
    & PatientInstanceFragment
  ), organization: (
    { __typename?: 'Organization' }
    & OrganizationInstanceFragment
  ) }
);

export type NhsNumberInstanceFragment = (
  { __typename?: 'NhsNumber' }
  & { nhsNumberId: NhsNumber['id'] }
);

export type PatientInstanceFragment = (
  { __typename?: 'Patient' }
  & { patientId: Patient['id'], patientFirstName: Patient['firstName'], patientLastName: Patient['lastName'] }
);

export type PatientNoteInstanceFragment = (
  { __typename?: 'PatientNote' }
  & { patientNoteText: PatientNote['text'] }
);

export type EwsThresholdsInstanceFragment = (
  { __typename?: 'EWSThresholds' }
  & { ewsThresholdsId: EwsThresholds['id'] }
);

export type IntegrationApiKeyInstanceFragment = (
  { __typename?: 'IntegrationApiKey' }
  & { integrationApiKeyId: IntegrationApiKey['id'] }
);

export type PictureInstanceFragment = (
  { __typename?: 'Picture' }
  & Pick<Picture, 'caption'>
  & { pictureId: Picture['id'] }
);

export type ShareTokenInstanceFragment = (
  { __typename?: 'ShareToken' }
  & { shareTokenId: ShareToken['id'], shareTokenCreatedAt: ShareToken['createdAt'], shareTokenExpiresAt: ShareToken['expiresAt'] }
);

export type UserInstanceFragment = (
  { __typename?: 'User' }
  & Pick<User, 'email'>
  & { userFirstName: User['firstName'], userLastName: User['lastName'] }
);

export type UserOrganizationInstanceFragment = (
  { __typename?: 'UserOrganization' }
  & Pick<UserOrganization, 'roles'>
  & { user: (
    { __typename?: 'User' }
    & UserInstanceFragment
  ) }
);

export type WardInstanceFragment = (
  { __typename?: 'Ward' }
  & { wardName: Ward['name'] }
);

export type MessageEntityContentFragment = (
  { __typename?: 'MessageEntity' }
  & Pick<MessageEntity, 'text' | 'type'>
  & { instance?: Maybe<(
    { __typename?: 'AcceptedTerms' }
    & AcceptedTermsInstanceFragment
  ) | (
    { __typename?: 'Alert' }
    & AlertInstanceFragment
  ) | (
    { __typename?: 'AlertRule' }
    & AlertRuleInstanceFragment
  ) | (
    { __typename?: 'CarePathway' }
    & CarePathwayInstanceFragment
  ) | (
    { __typename?: 'Checkup' }
    & CheckupInstanceFragment
  ) | (
    { __typename?: 'CheckupConfig' }
    & CheckupConfigInstanceFragment
  ) | (
    { __typename?: 'CheckupSchedule' }
    & CheckupScheduleInstanceFragment
  ) | (
    { __typename?: 'CheckupScheduleEvent' }
    & CheckupScheduleEventInstanceFragment
  ) | (
    { __typename?: 'CheckupType' }
    & CheckupTypeInstanceFragment
  ) | { __typename?: 'ContinuousMonitoring' } | (
    { __typename?: 'ContinuousMonitoringSession' }
    & ContinuousMonitoringSessionInstanceFragment
  ) | (
    { __typename?: 'EWSThresholds' }
    & EwsThresholdsInstanceFragment
  ) | (
    { __typename?: 'EhrIntegration' }
    & EhrIntegrationInstanceFragment
  ) | (
    { __typename?: 'EhrIntegrationEvent' }
    & EhrIntegrationEventInstanceFragment
  ) | (
    { __typename?: 'EhrIntegrationIdentity' }
    & EhrIntegrationIdentityInstanceFragment
  ) | (
    { __typename?: 'IntegrationApiKey' }
    & IntegrationApiKeyInstanceFragment
  ) | (
    { __typename?: 'NhsNumber' }
    & NhsNumberInstanceFragment
  ) | (
    { __typename?: 'Organization' }
    & OrganizationInstanceFragment
  ) | (
    { __typename?: 'OrganizationPatient' }
    & OrganizationPatientInstanceFragment
  ) | (
    { __typename?: 'PacsanaEvent' }
    & PacsanaEventInstanceFragment
  ) | (
    { __typename?: 'PacsanaSession' }
    & PacsanaSessionInstanceFragment
  ) | (
    { __typename?: 'Patient' }
    & PatientInstanceFragment
  ) | (
    { __typename?: 'PatientNote' }
    & PatientNoteInstanceFragment
  ) | (
    { __typename?: 'Picture' }
    & PictureInstanceFragment
  ) | (
    { __typename?: 'ShareToken' }
    & ShareTokenInstanceFragment
  ) | (
    { __typename?: 'User' }
    & UserInstanceFragment
  ) | (
    { __typename?: 'UserOrganization' }
    & UserOrganizationInstanceFragment
  ) | (
    { __typename?: 'Ward' }
    & WardInstanceFragment
  ) | { __typename?: 'WardStaff' }> }
);

export type ActionLogItemFragment = (
  { __typename?: 'ActionLog' }
  & Pick<ActionLog, 'id' | 'message' | 'createdAt'>
  & { user?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'firstName' | 'lastName' | 'email' | 'isSelfCare'>
  )>, affectedOrganization?: Maybe<(
    { __typename?: 'Organization' }
    & Pick<Organization, 'id' | 'name'>
  )>, loggedInAs?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id'>
  )>, messageEntities?: Maybe<Array<(
    { __typename?: 'MessageEntity' }
    & MessageEntityContentFragment
  )>> }
);

export type GetActionLogsQueryVariables = Exact<{
  patientId: Scalars['ID'];
  fromActionLogId?: InputMaybe<Scalars['ID']>;
}>;


export type GetActionLogsQuery = (
  { __typename?: 'Query' }
  & { actionLogs: (
    { __typename?: 'ActionLogs' }
    & Pick<ActionLogs, 'nextActionLogId'>
    & { logs: Array<(
      { __typename?: 'ActionLog' }
      & ActionLogItemFragment
    )> }
  ) }
);

export type ActivityMonitoringMetricFragment = (
  { __typename?: 'PacsanaDailyMetrics' }
  & Pick<PacsanaDailyMetrics, 'id' | 'activityDate' | 'exerciseMinutes' | 'activeMinutes' | 'gaitSpeed'>
  & { scores?: Maybe<(
    { __typename?: 'PacsanaScores' }
    & Pick<PacsanaScores, 'gaitSpeedScore'>
  )> }
);

export type GetPacsanaDailyMetricsQueryVariables = Exact<{
  patientId: Scalars['ID'];
  startDate?: InputMaybe<Scalars['Date']>;
  endDate?: InputMaybe<Scalars['Date']>;
}>;


export type GetPacsanaDailyMetricsQuery = (
  { __typename?: 'Query' }
  & { pacsanaDailyMetricsForPatient: Array<(
    { __typename?: 'PacsanaDailyMetrics' }
    & ActivityMonitoringMetricFragment
  )> }
);

export type ActivityMonitoringMetadataFragment = (
  { __typename?: 'PacsanaDailyMetricsMetadata' }
  & Pick<PacsanaDailyMetricsMetadata, 'earliestAvailableMetrics' | 'latestAvailableMetrics'>
);

export type GetPacsanaDailyMetricsMetadataQueryVariables = Exact<{
  patientId: Scalars['ID'];
}>;


export type GetPacsanaDailyMetricsMetadataQuery = (
  { __typename?: 'Query' }
  & { pacsanaDailyMetricsMetadata?: Maybe<(
    { __typename?: 'PacsanaDailyMetricsMetadata' }
    & ActivityMonitoringMetadataFragment
  )> }
);

export type CarePathwayDetailsFragment = (
  { __typename?: 'CarePathway' }
  & Pick<CarePathway, 'id' | 'name'>
  & { checkupTypes: Array<(
    { __typename?: 'CheckupType' }
    & CheckupTypeDisplayFragment
  )>, alertRules: Array<(
    { __typename?: 'AlertRule' }
    & AlertRuleDisplayFragment
  )> }
);

export type GetCarePathwayDetailsQueryVariables = Exact<{
  carePathwayId: Scalars['ID'];
}>;


export type GetCarePathwayDetailsQuery = (
  { __typename?: 'Query' }
  & { carePathway: (
    { __typename?: 'CarePathway' }
    & CarePathwayDetailsFragment
  ) }
);

export type ContinuousMonitoringItemFragment = (
  { __typename?: 'ContinuousMonitoring' }
  & Pick<ContinuousMonitoring, 'bucketStartAt' | 'bucketEndAt'>
  & { respiratoryRate?: Maybe<(
    { __typename?: 'BucketStats' }
    & Pick<BucketStats, 'value' | 'median' | 'min' | 'max'>
  )>, spo2?: Maybe<(
    { __typename?: 'BucketStats' }
    & Pick<BucketStats, 'value' | 'median' | 'min' | 'max'>
  )>, pulseRate?: Maybe<(
    { __typename?: 'BucketStats' }
    & Pick<BucketStats, 'value' | 'median' | 'min' | 'max'>
  )>, heartRate?: Maybe<(
    { __typename?: 'BucketStats' }
    & Pick<BucketStats, 'value' | 'median' | 'min' | 'max'>
  )>, temperature?: Maybe<(
    { __typename?: 'BucketStats' }
    & Pick<BucketStats, 'value' | 'median' | 'min' | 'max'>
  )>, battery?: Maybe<(
    { __typename?: 'BatteryStats' }
    & Pick<BatteryStats, 'ecg' | 'spo2' | 'temperature'>
  )>, thresholdScores?: Maybe<(
    { __typename?: 'EWSScores' }
    & Pick<EwsScores, 'RRScore' | 'SpO2Score' | 'HRScore' | 'tempScore'>
  )> }
);

export type GetContinuousMonitoringQueryVariables = Exact<{
  patientId: Scalars['ID'];
  numPrevBuckets: Scalars['Int'];
  currentBucketStartAt?: InputMaybe<Scalars['Date']>;
}>;


export type GetContinuousMonitoringQuery = (
  { __typename?: 'Query' }
  & { continuousMonitoring: (
    { __typename?: 'ContinuousMonitoringPages' }
    & Pick<ContinuousMonitoringPages, 'from' | 'to' | 'earliest' | 'latest'>
    & { data: Array<(
      { __typename?: 'ContinuousMonitoring' }
      & ContinuousMonitoringItemFragment
    )> }
  ) }
);

export type EndPacsanaSessionMutationVariables = Exact<{
  patientId: Scalars['ID'];
}>;


export type EndPacsanaSessionMutation = (
  { __typename?: 'Mutation' }
  & { endPacsanaSession: (
    { __typename?: 'PacsanaSession' }
    & Pick<PacsanaSession, 'id'>
  ) }
);

export type EndContinuousMonitoringSessionMutationVariables = Exact<{
  patientId: Scalars['ID'];
}>;


export type EndContinuousMonitoringSessionMutation = (
  { __typename?: 'Mutation' }
  & { endContinuousMonitoringSession: (
    { __typename?: 'ContinuousMonitoringSession' }
    & Pick<ContinuousMonitoringSession, 'id'>
  ) }
);

export type PatientNoteFragment = (
  { __typename?: 'PatientNote' }
  & Pick<PatientNote, 'id' | 'createdAt' | 'text'>
  & { createdBy?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'email' | 'firstName' | 'lastName' | 'isSelfCare'>
  )>, organization?: Maybe<(
    { __typename?: 'Organization' }
    & Pick<Organization, 'id' | 'name'>
  )>, checkup?: Maybe<(
    { __typename?: 'Checkup' }
    & Pick<Checkup, 'id'>
  )> }
);

export type PatientNotesQueryVariables = Exact<{
  PatientId: Scalars['ID'];
}>;


export type PatientNotesQuery = (
  { __typename?: 'Query' }
  & { patient: (
    { __typename?: 'Patient' }
    & Pick<Patient, 'id' | 'createdAt' | 'firstName' | 'lastName'>
    & { notes: Array<(
      { __typename?: 'PatientNote' }
      & PatientNoteFragment
    )>, selfCare?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id'>
    )> }
  ) }
);

export type AddPatientNoteMutationVariables = Exact<{
  PatientId: Scalars['ID'];
  text: Scalars['String'];
}>;


export type AddPatientNoteMutation = (
  { __typename?: 'Mutation' }
  & { addPatientNote: (
    { __typename?: 'PatientNote' }
    & Pick<PatientNote, 'id'>
  ) }
);

export type PatientDischargeOverviewFragment = (
  { __typename?: 'PatientDischarge' }
  & Pick<PatientDischarge, 'admittedAt' | 'dischargedAt' | 'WardId'>
  & { dischargedBy?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'email' | 'firstName' | 'lastName'>
  )>, ward: (
    { __typename?: 'Ward' }
    & Pick<Ward, 'name'>
    & { organization: (
      { __typename?: 'Organization' }
      & Pick<Organization, 'id' | 'name'>
    ) }
  ) }
);

export type PatientReportPatientDataFragment = (
  { __typename?: 'Patient' }
  & Pick<Patient, 'id' | 'createdAt' | 'firstName' | 'lastName' | 'birthDate' | 'gender'>
  & { address?: Maybe<(
    { __typename?: 'Address' }
    & Pick<Address, 'address'>
  )>, nhsNumberResponseDetails?: Maybe<(
    { __typename?: 'NhsNumberResponseDetails' }
    & Pick<NhsNumberResponseDetails, 'nhsNumber'>
  )>, organizations: Array<(
    { __typename?: 'Organization' }
    & { address: (
      { __typename?: 'Address' }
      & Pick<Address, 'address'>
    ) }
  )> }
);

export type PatientReportAdmissionDataFragment = (
  { __typename?: 'WardAdmission' }
  & Pick<WardAdmission, 'admittedAt'>
  & { ward: (
    { __typename?: 'Ward' }
    & Pick<Ward, 'name'>
    & { organization: (
      { __typename?: 'Organization' }
      & Pick<Organization, 'id' | 'name'>
    ) }
  ), carePathway?: Maybe<(
    { __typename?: 'CarePathway' }
    & Pick<CarePathway, 'name'>
  )> }
);

export type PatientReportDischargeDataFragment = (
  { __typename?: 'PatientDischarge' }
  & Pick<PatientDischarge, 'admittedAt' | 'dischargedAt' | 'WardId'>
  & { dischargedBy?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'email' | 'firstName' | 'lastName'>
  )>, ward: (
    { __typename?: 'Ward' }
    & Pick<Ward, 'name'>
    & { organization: (
      { __typename?: 'Organization' }
      & Pick<Organization, 'id' | 'name'>
    ) }
  ), carePathway?: Maybe<(
    { __typename?: 'CarePathway' }
    & Pick<CarePathway, 'name'>
  )> }
);

export type PatientReportCheckupDataFragment = (
  { __typename?: 'Checkup' }
  & Pick<Checkup, 'id' | 'createdAt' | 'startedAt' | 'glucose' | 'bloodPressureData' | 'temperature' | 'weight' | 'isStable' | 'questionnaire' | 'selectedAction' | 'pulseOxiData' | 'consciousness'>
  & { ewsScores?: Maybe<(
    { __typename?: 'EWSScores' }
    & Pick<EwsScores, 'BPScore' | 'consciousnessScore' | 'HRScore' | 'RRScore' | 'SpO2Score' | 'tempScore' | 'totalScore' | 'riskLevel'>
  )>, respiratoryRate?: Maybe<(
    { __typename?: 'RespiratoryRate' }
    & Pick<RespiratoryRate, 'value' | 'source'>
  )>, pulseRate?: Maybe<(
    { __typename?: 'PulseRate' }
    & Pick<PulseRate, 'value' | 'source' | 'isManual'>
  )>, notes: Array<(
    { __typename?: 'PatientNote' }
    & Pick<PatientNote, 'text'>
  )>, lungSounds?: Maybe<Array<(
    { __typename?: 'LungSoundRecording' }
    & Pick<LungSoundRecording, 'url'>
  )>>, createdBy?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'email' | 'firstName' | 'lastName'>
  )>, organization?: Maybe<(
    { __typename?: 'Organization' }
    & Pick<Organization, 'id' | 'name'>
  )> }
);

export type PatientReportAlertsDataFragment = (
  { __typename?: 'Alert' }
  & Pick<Alert, 'id' | 'startedAt'>
  & { alertRule?: Maybe<(
    { __typename?: 'AlertRule' }
    & Pick<AlertRule, 'name' | 'description'>
  )> }
);

export type PatientReportNotesDataFragment = (
  { __typename?: 'PatientNote' }
  & Pick<PatientNote, 'id' | 'text' | 'createdAt'>
  & { createdBy?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'email' | 'firstName' | 'lastName'>
  )>, organization?: Maybe<(
    { __typename?: 'Organization' }
    & Pick<Organization, 'id' | 'name'>
  )> }
);

export type PatientReportVitalsAggregateFragment = (
  { __typename?: 'VitalsSummaryFrame' }
  & Pick<VitalsSummaryFrame, 'start' | 'end'>
  & { values: (
    { __typename?: 'VitalsAggregates' }
    & { pulseRate?: Maybe<(
      { __typename?: 'VitalAggregate' }
      & Pick<VitalAggregate, 'median' | 'min' | 'max'>
    )>, respiratoryRate?: Maybe<(
      { __typename?: 'VitalAggregate' }
      & Pick<VitalAggregate, 'median' | 'min' | 'max'>
    )>, systolicBloodPressure?: Maybe<(
      { __typename?: 'VitalAggregate' }
      & Pick<VitalAggregate, 'median' | 'min' | 'max'>
    )>, diastolicBloodPressure?: Maybe<(
      { __typename?: 'VitalAggregate' }
      & Pick<VitalAggregate, 'median' | 'min' | 'max'>
    )>, spO2?: Maybe<(
      { __typename?: 'VitalAggregate' }
      & Pick<VitalAggregate, 'median' | 'min' | 'max'>
    )>, temperature?: Maybe<(
      { __typename?: 'VitalAggregate' }
      & Pick<VitalAggregate, 'median' | 'min' | 'max'>
    )>, weight?: Maybe<(
      { __typename?: 'VitalAggregate' }
      & Pick<VitalAggregate, 'median' | 'min' | 'max'>
    )>, glucose?: Maybe<(
      { __typename?: 'VitalAggregate' }
      & Pick<VitalAggregate, 'median' | 'min' | 'max'>
    )> }
  ) }
);

export type PatientReportSoftSignsAggregateFragment = (
  { __typename?: 'SoftSignsSummaryFrame' }
  & Pick<SoftSignsSummaryFrame, 'start' | 'end'>
  & { values: Array<(
    { __typename?: 'SoftSignsCount' }
    & Pick<SoftSignsCount, 'questionKey' | 'count'>
  )> }
);

export type PatientReportDataQueryVariables = Exact<{
  patientId: Scalars['ID'];
  startDate: Scalars['Date'];
  endDate: Scalars['Date'];
  aggregateIntervalHours?: InputMaybe<Scalars['Int']>;
  queryAggregates: Scalars['Boolean'];
  querySoftSignsAggregates: Scalars['Boolean'];
  queryCheckups: Scalars['Boolean'];
  queryNotes: Scalars['Boolean'];
  queryAlerts: Scalars['Boolean'];
  queryWardAdmissions: Scalars['Boolean'];
}>;


export type PatientReportDataQuery = (
  { __typename?: 'Query' }
  & { patient: (
    { __typename?: 'Patient' }
    & { wardDischarges?: Array<(
      { __typename?: 'PatientDischarge' }
      & PatientReportDischargeDataFragment
    )>, wardAdmission?: Maybe<(
      { __typename?: 'WardAdmission' }
      & PatientReportAdmissionDataFragment
    )>, notes?: Array<(
      { __typename?: 'PatientNote' }
      & PatientReportNotesDataFragment
    )>, vitalsSummary?: (
      { __typename?: 'VitalsSummary' }
      & { frames: Array<(
        { __typename?: 'VitalsSummaryFrame' }
        & PatientReportVitalsAggregateFragment
      )> }
    ), softSignsSummary?: (
      { __typename?: 'SoftSignsSummary' }
      & { frames: Array<(
        { __typename?: 'SoftSignsSummaryFrame' }
        & PatientReportSoftSignsAggregateFragment
      )> }
    ) }
    & PatientReportPatientDataFragment
  ), checkupsByPatient?: Array<(
    { __typename?: 'Checkup' }
    & PatientReportCheckupDataFragment
  )>, alerts?: Array<(
    { __typename?: 'Alert' }
    & PatientReportAlertsDataFragment
  )> }
);

export type GetPacsanaKitsWithActiveSessionQueryVariables = Exact<{ [key: string]: never; }>;


export type GetPacsanaKitsWithActiveSessionQuery = (
  { __typename?: 'Query' }
  & { pacsanaKitsWithActiveSession: Array<(
    { __typename?: 'PacsanaKitWithActiveSession' }
    & Pick<PacsanaKitWithActiveSession, 'kitId'>
    & { activeSession?: Maybe<(
      { __typename?: 'PacsanaSession' }
      & Pick<PacsanaSession, 'id'>
      & { patient: (
        { __typename?: 'Patient' }
        & Pick<Patient, 'firstName' | 'lastName'>
      ) }
    )> }
  )> }
);

export type StartPacsanaSessionMutationVariables = Exact<{
  pacsanaUserName: Scalars['String'];
  patientId: Scalars['ID'];
}>;


export type StartPacsanaSessionMutation = (
  { __typename?: 'Mutation' }
  & { startPacsanaSession: (
    { __typename?: 'PacsanaSession' }
    & Pick<PacsanaSession, 'id'>
  ) }
);

export type GetVivalinkKitsWithActiveSessionQueryVariables = Exact<{ [key: string]: never; }>;


export type GetVivalinkKitsWithActiveSessionQuery = (
  { __typename?: 'Query' }
  & { vivalinkKitsWithActiveSession: Array<(
    { __typename?: 'VivalinkKitWithActiveSession' }
    & Pick<VivalinkKitWithActiveSession, 'kitId'>
    & { activeSession?: Maybe<(
      { __typename?: 'ContinuousMonitoringSession' }
      & Pick<ContinuousMonitoringSession, 'id'>
      & { patient: (
        { __typename?: 'Patient' }
        & Pick<Patient, 'firstName' | 'lastName'>
      ) }
    )> }
  )> }
);

export type StartContinuousMonitoringSessionMutationVariables = Exact<{
  vivalinkSubjectId: Scalars['String'];
  patientId: Scalars['ID'];
}>;


export type StartContinuousMonitoringSessionMutation = (
  { __typename?: 'Mutation' }
  & { startContinuousMonitoringSession: (
    { __typename?: 'ContinuousMonitoringSession' }
    & Pick<ContinuousMonitoringSession, 'id'>
  ) }
);

export type PatientCheckupsTableItemFragment = (
  { __typename?: 'Checkup' }
  & Pick<Checkup, 'id' | 'endedAt' | 'type' | 'subtype' | 'pulseOxiData' | 'bloodPressureData' | 'temperature' | 'consciousness' | 'questionnaire' | 'selectedAction' | 'weight'>
  & { respiratoryRate?: Maybe<(
    { __typename?: 'RespiratoryRate' }
    & Pick<RespiratoryRate, 'value' | 'source'>
  )>, pulseRate?: Maybe<(
    { __typename?: 'PulseRate' }
    & Pick<PulseRate, 'value'>
  )>, ewsScores?: Maybe<(
    { __typename?: 'EWSScores' }
    & Pick<EwsScores, 'BPScore' | 'consciousnessScore' | 'HRScore' | 'RRScore' | 'SpO2Score' | 'tempScore' | 'totalScore' | 'riskLevel'>
  )> }
);

export type GetPatientCheckupsQueryVariables = Exact<{
  patientId: Scalars['ID'];
}>;


export type GetPatientCheckupsQuery = (
  { __typename?: 'Query' }
  & { checkupsByPatient: Array<(
    { __typename?: 'Checkup' }
    & PatientCheckupsTableItemFragment
  )> }
);

export type PatientAdmissionOverviewFragment = (
  { __typename?: 'WardAdmission' }
  & Pick<WardAdmission, 'admittedAt'>
  & { ward: (
    { __typename?: 'Ward' }
    & Pick<Ward, 'id' | 'name'>
  ), carePathway?: Maybe<(
    { __typename?: 'CarePathway' }
    & Pick<CarePathway, 'id' | 'name'>
    & { ewsThresholds?: Maybe<(
      { __typename?: 'EWSThresholds' }
      & Pick<EwsThresholds, 'thresholds'>
    )> }
  )> }
);

export type PatientDetailsFragment = (
  { __typename?: 'Patient' }
  & Pick<Patient, 'id' | 'firstName' | 'lastName' | 'numSimilarNames' | 'createdAt' | 'birthDate' | 'gender' | 'telephone' | 'features' | 'nhsNumber' | 'preExistingConditions'>
  & { address?: Maybe<(
    { __typename?: 'Address' }
    & Pick<Address, 'address' | 'postcode'>
  )>, organizations: Array<(
    { __typename?: 'Organization' }
    & { address: (
      { __typename?: 'Address' }
      & Pick<Address, 'address' | 'postcode'>
    ) }
  )>, selfCare?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'email' | 'canResetPassword'>
  )>, nhsNumberResponseDetails?: Maybe<(
    { __typename?: 'NhsNumberResponseDetails' }
    & Pick<NhsNumberResponseDetails, 'nhsNumber'>
  )>, latestCheckup?: Maybe<(
    { __typename?: 'Checkup' }
    & Pick<Checkup, 'endedAt'>
  )>, activityMonitoringSession?: Maybe<(
    { __typename?: 'PacsanaSession' }
    & Pick<PacsanaSession, 'pacsanaUserName'>
  )>, latestContinuousMonitoring?: Maybe<(
    { __typename?: 'ContinuousMonitoringWithSession' }
    & { continuousMonitoringSession: (
      { __typename?: 'ContinuousMonitoringSession' }
      & Pick<ContinuousMonitoringSession, 'endedAt' | 'vivalinkSubjectId'>
    ) }
  )>, wardAdmission?: Maybe<(
    { __typename?: 'WardAdmission' }
    & PatientAdmissionOverviewFragment
  )>, wardDischarges: Array<(
    { __typename?: 'PatientDischarge' }
    & PatientDischargeOverviewFragment
  )>, ewsThresholds?: Maybe<(
    { __typename?: 'EWSThresholds' }
    & Pick<EwsThresholds, 'createdAt' | 'thresholds'>
    & { createdBy: (
      { __typename?: 'User' }
      & Pick<User, 'firstName' | 'lastName' | 'email'>
    ) }
  )>, wards: Array<(
    { __typename?: 'Ward' }
    & Pick<Ward, 'id' | 'name'>
  )> }
);

export type GetPatientDetailsQueryVariables = Exact<{
  patientId: Scalars['ID'];
}>;


export type GetPatientDetailsQuery = (
  { __typename?: 'Query' }
  & { patient: (
    { __typename?: 'Patient' }
    & PatientDetailsFragment
    & DischargePatientDetailsFragment
  ) }
);

export type ApiKeyFragment = (
  { __typename?: 'IntegrationApiKey' }
  & Pick<IntegrationApiKey, 'id' | 'apiKey' | 'deletedAt' | 'createdAt'>
  & { createdBy: (
    { __typename?: 'User' }
    & Pick<User, 'email' | 'firstName' | 'lastName'>
  ) }
);

export type SettingsApiKeysQueryVariables = Exact<{ [key: string]: never; }>;


export type SettingsApiKeysQuery = (
  { __typename?: 'Query' }
  & { integrationApiKeys: Array<(
    { __typename?: 'IntegrationApiKey' }
    & ApiKeyFragment
  )> }
);

export type SettingsUndoExpireApiKeyMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type SettingsUndoExpireApiKeyMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'undoExpireIntegrationApiKey'>
);

export type SettingsShareTokenFragment = (
  { __typename?: 'ShareToken' }
  & Pick<ShareToken, 'id' | 'createdAt' | 'expiresAt'>
  & { user: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'firstName' | 'lastName' | 'email'>
  ), patient: (
    { __typename?: 'Patient' }
    & Pick<Patient, 'id' | 'firstName' | 'lastName'>
  ) }
);

export type SettingsPatientSharingQueryVariables = Exact<{ [key: string]: never; }>;


export type SettingsPatientSharingQuery = (
  { __typename?: 'Query' }
  & { shareTokens: Array<(
    { __typename?: 'ShareToken' }
    & SettingsShareTokenFragment
  )> }
);

export type UserSettingsFragment = (
  { __typename?: 'UserOrganization' }
  & Pick<UserOrganization, 'roles' | 'lastLoggedInAt'>
  & { user: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'email' | 'firstName' | 'lastName' | 'enrolledInTotpMfa' | 'isQuicksilvaIdentitySetup' | 'canResetPassword'>
    & { wards: Array<(
      { __typename?: 'Ward' }
      & Pick<Ward, 'id' | 'name'>
    )> }
  ) }
);

export type UserSettingsUsersQueryVariables = Exact<{ [key: string]: never; }>;


export type UserSettingsUsersQuery = (
  { __typename?: 'Query' }
  & { userOrganizations: Array<(
    { __typename?: 'UserOrganization' }
    & UserSettingsFragment
  )> }
);

export type UnenrollFromTwoFactorAuthMutationVariables = Exact<{
  userId: Scalars['ID'];
}>;


export type UnenrollFromTwoFactorAuthMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'unenrollFromTwoFactorAuth'>
);

export type CreatedApiKeyFragment = (
  { __typename?: 'IntegrationApiKeyWithSecret' }
  & Pick<IntegrationApiKeyWithSecret, 'apiKey' | 'secret'>
);

export type SettingsCreateApiKeyMutationVariables = Exact<{ [key: string]: never; }>;


export type SettingsCreateApiKeyMutation = (
  { __typename?: 'Mutation' }
  & { createIntegrationApiKey: (
    { __typename?: 'IntegrationApiKeyWithSecret' }
    & CreatedApiKeyFragment
  ) }
);

export type SettingsExpireApiKeyMutationVariables = Exact<{
  id: Scalars['ID'];
  expiresAt?: InputMaybe<Scalars['Date']>;
}>;


export type SettingsExpireApiKeyMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'expireIntegrationApiKey'>
);

export type UpdateUserOrganizationMutationVariables = Exact<{
  userId: Scalars['ID'];
  roles: Array<Scalars['String']> | Scalars['String'];
}>;


export type UpdateUserOrganizationMutation = (
  { __typename?: 'Mutation' }
  & { updateUserOrganization: (
    { __typename?: 'UserOrganization' }
    & { user: (
      { __typename?: 'User' }
      & Pick<User, 'id'>
    ) }
  ) }
);

export type UserWardFragment = (
  { __typename?: 'Ward' }
  & Pick<Ward, 'id' | 'name'>
);

export type UserNeighborFragment = (
  { __typename?: 'NeighborOrganization' }
  & Pick<NeighborOrganization, 'id' | 'name'>
);

export type GetVirtualWardFilterValuesQueryVariables = Exact<{
  isActingOrgPractice: Scalars['Boolean'];
  wardsEnabled: Scalars['Boolean'];
}>;


export type GetVirtualWardFilterValuesQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Me' }
    & { wards?: Array<(
      { __typename?: 'Ward' }
      & UserWardFragment
    )>, actingOrganization: (
      { __typename?: 'Organization' }
      & { neighbors?: Array<(
        { __typename?: 'NeighborOrganization' }
        & UserNeighborFragment
      )> }
    ) }
  )> }
);

export type VirtualWardPatientItemFragment = (
  { __typename?: 'Patient' }
  & Pick<Patient, 'id' | 'firstName' | 'lastName' | 'numSimilarNames' | 'birthDate' | 'nhsNumber'>
  & { wardAdmission?: Maybe<(
    { __typename?: 'WardAdmission' }
    & { ward: (
      { __typename?: 'Ward' }
      & Pick<Ward, 'id' | 'name'>
    ), carePathway?: Maybe<(
      { __typename?: 'CarePathway' }
      & Pick<CarePathway, 'id' | 'name'>
    )> }
  )>, latestCheckup?: Maybe<(
    { __typename?: 'Checkup' }
    & VirtualWardPatientCheckupFragment
  )>, latestContinuousMonitoring?: Maybe<(
    { __typename?: 'ContinuousMonitoringWithSession' }
    & VirtualWardPatientContinuousMonitoringWithSessionFragment
  )>, activityMonitoringSession?: Maybe<(
    { __typename?: 'PacsanaSession' }
    & VirtualWardPatientActivityMonitoringSessionFragment
  )>, alerts: Array<(
    { __typename?: 'Alert' }
    & AlertChipFieldsFragment
  )> }
);

export type GetVirtualWardPatientsQueryVariables = Exact<{
  wardIds: Array<Scalars['ID']> | Scalars['ID'];
  neighborIds?: InputMaybe<Array<Scalars['ID']> | Scalars['ID']>;
  take?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  nameOrNhsNumber?: InputMaybe<Scalars['String']>;
  withCheckupsInLastHours?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<InputMaybe<VirtualWardPatientsSortInput>> | InputMaybe<VirtualWardPatientsSortInput>>;
}>;


export type GetVirtualWardPatientsQuery = (
  { __typename?: 'Query' }
  & { virtualWardPatients: (
    { __typename?: 'VirtualWardPatients' }
    & Pick<VirtualWardPatients, 'total'>
    & { patients: Array<(
      { __typename?: 'Patient' }
      & VirtualWardPatientItemFragment
    )> }
  ) }
);

export type AlertChipFieldsFragment = (
  { __typename?: 'Alert' }
  & Pick<Alert, 'id' | 'type' | 'status' | 'startedAt'>
  & { _aggregate?: Maybe<(
    { __typename?: 'AggregateMetadata' }
    & Pick<AggregateMetadata, 'count'>
  )>, alertRule?: Maybe<(
    { __typename?: 'AlertRule' }
    & Pick<AlertRule, 'id' | 'name' | 'description'>
  )>, checkupScheduleEvent?: Maybe<(
    { __typename?: 'CheckupScheduleEvent' }
    & Pick<CheckupScheduleEvent, 'id' | 'checkupExpectedAt'>
    & { checkupSchedule: (
      { __typename?: 'CheckupSchedule' }
      & Pick<CheckupSchedule, 'id'>
      & { tolerance: (
        { __typename?: 'CheckupScheduleTolerance' }
        & Pick<CheckupScheduleTolerance, 'early' | 'late'>
      ) }
    ) }
  )>, pacsanaEvent?: Maybe<(
    { __typename?: 'PacsanaEvent' }
    & Pick<PacsanaEvent, 'eventId' | 'eventKey'>
  )> }
);

export type GetAlertsQueryVariables = Exact<{
  patientId: Scalars['ID'];
  alertRuleId?: InputMaybe<Scalars['ID']>;
  checkupScheduleId?: InputMaybe<Scalars['ID']>;
  pacsanaEventId?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<Scalars['String']>;
}>;


export type GetAlertsQuery = (
  { __typename?: 'Query' }
  & { alerts: Array<(
    { __typename?: 'Alert' }
    & Pick<Alert, 'id' | 'type' | 'status' | 'startedAt'>
    & { alertRule?: Maybe<(
      { __typename?: 'AlertRule' }
      & Pick<AlertRule, 'id' | 'name' | 'type' | 'description'>
    )>, checkupScheduleEvent?: Maybe<(
      { __typename?: 'CheckupScheduleEvent' }
      & Pick<CheckupScheduleEvent, 'checkupExpectedAt'>
      & { checkupSchedule: (
        { __typename?: 'CheckupSchedule' }
        & Pick<CheckupSchedule, 'id'>
        & { tolerance: (
          { __typename?: 'CheckupScheduleTolerance' }
          & Pick<CheckupScheduleTolerance, 'early' | 'late'>
        ) }
      ) }
    )>, checkup?: Maybe<(
      { __typename?: 'Checkup' }
      & Pick<Checkup, 'id' | 'endedAt'>
    )>, continuousMonitoring?: Maybe<(
      { __typename?: 'ContinuousMonitoring' }
      & Pick<ContinuousMonitoring, 'bucketEndAt'>
    )>, pacsanaEvent?: Maybe<(
      { __typename?: 'PacsanaEvent' }
      & Pick<PacsanaEvent, 'eventKey'>
    )> }
  )> }
);

export type VirtualWardPatientCheckupFragment = (
  { __typename?: 'Checkup' }
  & Pick<Checkup, 'id' | 'type' | 'endedAt' | 'questionnaire' | 'bloodPressureData' | 'pulseOxiData' | 'temperature' | 'consciousness' | 'selectedAction' | 'glucose' | 'weight'>
  & { checkupType?: Maybe<(
    { __typename?: 'CheckupType' }
    & Pick<CheckupType, 'name'>
  )>, notes: Array<(
    { __typename?: 'PatientNote' }
    & Pick<PatientNote, 'text'>
  )>, ewsScores?: Maybe<(
    { __typename?: 'EWSScores' }
    & Pick<EwsScores, 'BPScore' | 'consciousnessScore' | 'HRScore' | 'RRScore' | 'SpO2Score' | 'tempScore' | 'totalScore' | 'riskLevel'>
  )>, pulseRate?: Maybe<(
    { __typename?: 'PulseRate' }
    & Pick<PulseRate, 'value' | 'source' | 'isManual'>
  )>, respiratoryRate?: Maybe<(
    { __typename?: 'RespiratoryRate' }
    & Pick<RespiratoryRate, 'value' | 'source'>
  )> }
);

export type VirtualWardPatientContinuousMonitoringFragment = (
  { __typename?: 'ContinuousMonitoring' }
  & Pick<ContinuousMonitoring, 'bucketEndAt'>
  & { spo2?: Maybe<(
    { __typename?: 'BucketStats' }
    & Pick<BucketStats, 'min' | 'max' | 'median' | 'value'>
  )>, respiratoryRate?: Maybe<(
    { __typename?: 'BucketStats' }
    & Pick<BucketStats, 'min' | 'max' | 'median' | 'value'>
  )>, heartRate?: Maybe<(
    { __typename?: 'BucketStats' }
    & Pick<BucketStats, 'min' | 'max' | 'median' | 'value'>
  )>, pulseRate?: Maybe<(
    { __typename?: 'BucketStats' }
    & Pick<BucketStats, 'min' | 'max' | 'median' | 'value'>
  )>, temperature?: Maybe<(
    { __typename?: 'BucketStats' }
    & Pick<BucketStats, 'min' | 'max' | 'median' | 'value'>
  )>, battery?: Maybe<(
    { __typename?: 'BatteryStats' }
    & Pick<BatteryStats, 'ecg' | 'spo2' | 'temperature'>
  )>, thresholdScores?: Maybe<(
    { __typename?: 'EWSScores' }
    & Pick<EwsScores, 'BPScore' | 'consciousnessScore' | 'HRScore' | 'RRScore' | 'SpO2Score' | 'tempScore' | 'totalScore' | 'riskLevel'>
  )> }
);

export type VirtualWardPatientActivityMonitoringSessionFragment = (
  { __typename?: 'PacsanaSession' }
  & Pick<PacsanaSession, 'id' | 'createdAt' | 'endedAt' | 'pacsanaUserName'>
  & { status: (
    { __typename?: 'PacsanaSessionStatus' }
    & Pick<PacsanaSessionStatus, 'zeroesAcrossMetricsForPreviousDay' | 'noMetricsForPreviousDay' | 'batteryStatus' | 'latestMetricsDate' | 'latestEventDate'>
  ) }
);

export type VirtualWardPatientContinuousMonitoringWithSessionFragment = (
  { __typename?: 'ContinuousMonitoringWithSession' }
  & { continuousMonitoring?: Maybe<(
    { __typename?: 'ContinuousMonitoring' }
    & VirtualWardPatientContinuousMonitoringFragment
  )>, continuousMonitoringSession: (
    { __typename?: 'ContinuousMonitoringSession' }
    & Pick<ContinuousMonitoringSession, 'vivalinkSubjectId' | 'createdAt' | 'endedAt'>
  ) }
);

export const PatientLookupViewFragmentDoc = gql`
    fragment PatientLookupView on Patient {
  id
  nhsNumber
  birthDate
  firstName
  lastName
  numSimilarNames
  gender
  telephone
  selfCare {
    id
    email
  }
  address {
    address
    postcode
  }
  wardAdmission {
    admittedAt
    ward {
      id
      name
    }
    carePathway {
      id
      name
    }
  }
}
    `;
export const PatientNhsNumberDetailsDialogFragmentDoc = gql`
    fragment PatientNhsNumberDetailsDialog on Patient {
  nhsNumberResponseDetails {
    nhsNumber
    latestRetrievalTime
    editable
    latestStatus {
      message
      status
    }
  }
}
    `;
export const PacsanaSessionItemFragmentDoc = gql`
    fragment PacsanaSessionItem on PacsanaSession {
  id
}
    `;
export const DischargePatientAdmissionDetailsFragmentDoc = gql`
    fragment DischargePatientAdmissionDetails on WardAdmission {
  admittedAt
  ward {
    id
    name
  }
  carePathway {
    name
  }
}
    `;
export const DischargePatientDetailsFragmentDoc = gql`
    fragment DischargePatientDetails on Patient {
  id
  firstName
  lastName
  birthDate
  nhsNumber
  wardAdmission {
    ...DischargePatientAdmissionDetails
  }
}
    ${DischargePatientAdmissionDetailsFragmentDoc}`;
export const CheckupDetailsFragmentDoc = gql`
    fragment CheckupDetails on Checkup {
  __typename
  id
  temperature
  ewsScores {
    __typename
    BPScore
    consciousnessScore
    HRScore
    riskLevel
    BPScore
    RRScore
    SpO2Score
    tempScore
    totalScore
  }
  subtype
  notes {
    __typename
    id
    text
    patient {
      id
    }
    createdBy {
      id
    }
    createdAt
    updatedAt
    checkup {
      id
    }
  }
  pulseRate {
    __typename
    isManual
    source
    value
  }
  pulseOxiData
  respiratoryRate {
    __typename
    value
    source
  }
  consciousness
  bloodPressureData
  questionnaire
  weight
  glucose
  picture {
    __typename
    url
    caption
  }
  lungSounds {
    __typename
    url
  }
  endedAt
  createdBy {
    __typename
    email
    firstName
    lastName
    isSelfCare
  }
  organization {
    id
    name
  }
}
    `;
export const FormattableIntegrationTriggerConfigFragmentDoc = gql`
    fragment FormattableIntegrationTriggerConfig on EhrIntegrationTriggerConfig {
  __typename
  ... on EhrIntegrationPeriodicTriggerConfig {
    rrule
  }
}
    `;
export const IntegrationEventItemFragmentDoc = gql`
    fragment IntegrationEventItem on EhrIntegrationEvent {
  __typename
  id
  status
  isRetryable
  response {
    message
    innerError
  }
  createdAt
  updatedAt
  requestBody {
    consultationNote
    codedDataItems {
      code
      value
      units
      additionalText
    }
  }
  patient {
    id
    firstName
    lastName
    nhsNumber
    birthDate
  }
  integration {
    integrationType
    triggerType
    triggerConfig {
      ...FormattableIntegrationTriggerConfig
    }
  }
  summaryPeriod {
    from
    to
  }
  checkup {
    id
    type
    endedAt
  }
  continuousMonitoring {
    bucketStartAt
    bucketEndAt
  }
}
    ${FormattableIntegrationTriggerConfigFragmentDoc}`;
export const ManageCarePathwayItemFragmentDoc = gql`
    fragment ManageCarePathwayItem on CarePathway {
  id
  name
  createdAt
  updatedAt
  organization {
    id
  }
}
    `;
export const IntegrationDisplayFragmentDoc = gql`
    fragment IntegrationDisplay on EhrIntegration {
  id
  integrationType
  triggerType
  createdAt
  updatedAt
  authorizedAutomaticApprover {
    id
    email
  }
  triggerConfig {
    ...FormattableIntegrationTriggerConfig
  }
}
    ${FormattableIntegrationTriggerConfigFragmentDoc}`;
export const ContinuousMonitoringSessionItemFragmentDoc = gql`
    fragment ContinuousMonitoringSessionItem on ContinuousMonitoringSession {
  id
  organization {
    id
  }
}
    `;
export const PatientPracticeAssignmentFragmentDoc = gql`
    fragment PatientPracticeAssignment on Organization {
  id
  name
  address {
    address
    postcode
  }
}
    `;
export const ManagePatientItemFragmentDoc = gql`
    fragment ManagePatientItem on Patient {
  id
  createdAt
  firstName
  lastName
  gender
  birthDate
  address {
    address
    postcode
  }
  telephone
  wards {
    id
    name
    createdAt
  }
  practices: organizations(type: "practice") {
    ...PatientPracticeAssignment
  }
  preExistingConditions
  nhsNumber
  selfCare {
    id
    email
    canResetPassword
  }
}
    ${PatientPracticeAssignmentFragmentDoc}`;
export const WardHeaderInfoFragmentDoc = gql`
    fragment WardHeaderInfo on Ward {
  name
  numberOfPatients
  numberOfStaff
}
    `;
export const WardPatientItemFragmentDoc = gql`
    fragment WardPatientItem on WardPatient {
  patient {
    id
    firstName
    lastName
    birthDate
    gender
    nhsNumber
  }
  carePathway {
    id
    name
  }
  admittedAt
}
    `;
export const WardStaffItemFragmentDoc = gql`
    fragment WardStaffItem on WardStaff {
  user {
    id
    firstName
    lastName
    email
  }
  assignedAt
}
    `;
export const ManageWardItemFragmentDoc = gql`
    fragment ManageWardItem on Ward {
  id
  name
  createdAt
  updatedAt
  numberOfPatients
  numberOfStaff
}
    `;
export const ContinuousMonitoringInstanceFragmentDoc = gql`
    fragment continuousMonitoringInstance on ContinuousMonitoring {
  continousMonitoringId: id
}
    `;
export const AcceptedTermsInstanceFragmentDoc = gql`
    fragment acceptedTermsInstance on AcceptedTerms {
  acceptedTermsId: id
}
    `;
export const AlertRuleInstanceFragmentDoc = gql`
    fragment alertRuleInstance on AlertRule {
  alertRuleName: name
  alertRuleType: type
}
    `;
export const AlertInstanceFragmentDoc = gql`
    fragment alertInstance on Alert {
  alertRule {
    ...alertRuleInstance
  }
  alertType: type
}
    ${AlertRuleInstanceFragmentDoc}`;
export const CarePathwayInstanceFragmentDoc = gql`
    fragment carePathwayInstance on CarePathway {
  carePathwayName: name
}
    `;
export const CheckupInstanceFragmentDoc = gql`
    fragment checkupInstance on Checkup {
  checkupType: type
}
    `;
export const CheckupConfigInstanceFragmentDoc = gql`
    fragment checkupConfigInstance on CheckupConfig {
  checkupConfigName: name
}
    `;
export const CheckupScheduleInstanceFragmentDoc = gql`
    fragment checkupScheduleInstance on CheckupSchedule {
  checkupScheduleId: id
}
    `;
export const CheckupScheduleEventInstanceFragmentDoc = gql`
    fragment checkupScheduleEventInstance on CheckupScheduleEvent {
  checkupExpectedAt
}
    `;
export const CheckupTypeInstanceFragmentDoc = gql`
    fragment checkupTypeInstance on CheckupType {
  checkupTypeName: name
}
    `;
export const ContinuousMonitoringSessionInstanceFragmentDoc = gql`
    fragment continuousMonitoringSessionInstance on ContinuousMonitoringSession {
  sessionCreatedAt: createdAt
  sessionEndedAt: endedAt
}
    `;
export const PacsanaSessionInstanceFragmentDoc = gql`
    fragment pacsanaSessionInstance on PacsanaSession {
  sessionCreatedAt: createdAt
  sessionEndedAt: endedAt
}
    `;
export const PacsanaEventInstanceFragmentDoc = gql`
    fragment pacsanaEventInstance on PacsanaEvent {
  eventName
}
    `;
export const OrganizationInstanceFragmentDoc = gql`
    fragment organizationInstance on Organization {
  organizationName: name
}
    `;
export const PatientInstanceFragmentDoc = gql`
    fragment patientInstance on Patient {
  patientId: id
  patientFirstName: firstName
  patientLastName: lastName
}
    `;
export const OrganizationPatientInstanceFragmentDoc = gql`
    fragment organizationPatientInstance on OrganizationPatient {
  patient {
    ...patientInstance
  }
  organization {
    ...organizationInstance
  }
}
    ${PatientInstanceFragmentDoc}
${OrganizationInstanceFragmentDoc}`;
export const NhsNumberInstanceFragmentDoc = gql`
    fragment nhsNumberInstance on NhsNumber {
  nhsNumberId: id
}
    `;
export const PatientNoteInstanceFragmentDoc = gql`
    fragment patientNoteInstance on PatientNote {
  patientNoteText: text
}
    `;
export const EwsThresholdsInstanceFragmentDoc = gql`
    fragment ewsThresholdsInstance on EWSThresholds {
  ewsThresholdsId: id
}
    `;
export const EhrIntegrationInstanceFragmentDoc = gql`
    fragment ehrIntegrationInstance on EhrIntegration {
  integrationType
  triggerType
  triggerConfig {
    ...FormattableIntegrationTriggerConfig
  }
}
    ${FormattableIntegrationTriggerConfigFragmentDoc}`;
export const EhrIntegrationEventInstanceFragmentDoc = gql`
    fragment ehrIntegrationEventInstance on EhrIntegrationEvent {
  integration {
    ...ehrIntegrationInstance
  }
  summaryPeriod {
    from
    to
  }
}
    ${EhrIntegrationInstanceFragmentDoc}`;
export const EhrIntegrationIdentityInstanceFragmentDoc = gql`
    fragment ehrIntegrationIdentityInstance on EhrIntegrationIdentity {
  identityType
}
    `;
export const IntegrationApiKeyInstanceFragmentDoc = gql`
    fragment integrationApiKeyInstance on IntegrationApiKey {
  integrationApiKeyId: id
}
    `;
export const PictureInstanceFragmentDoc = gql`
    fragment pictureInstance on Picture {
  pictureId: id
  caption
}
    `;
export const ShareTokenInstanceFragmentDoc = gql`
    fragment shareTokenInstance on ShareToken {
  shareTokenId: id
  shareTokenCreatedAt: createdAt
  shareTokenExpiresAt: expiresAt
}
    `;
export const UserInstanceFragmentDoc = gql`
    fragment userInstance on User {
  userFirstName: firstName
  userLastName: lastName
  email
}
    `;
export const UserOrganizationInstanceFragmentDoc = gql`
    fragment userOrganizationInstance on UserOrganization {
  user {
    ...userInstance
  }
  roles
}
    ${UserInstanceFragmentDoc}`;
export const WardInstanceFragmentDoc = gql`
    fragment wardInstance on Ward {
  wardName: name
}
    `;
export const MessageEntityContentFragmentDoc = gql`
    fragment messageEntityContent on MessageEntity {
  text
  type
  instance {
    ...acceptedTermsInstance
    ...alertInstance
    ...alertRuleInstance
    ...carePathwayInstance
    ...checkupInstance
    ...checkupConfigInstance
    ...checkupScheduleInstance
    ...checkupScheduleEventInstance
    ...checkupTypeInstance
    ...continuousMonitoringSessionInstance
    ...pacsanaSessionInstance
    ...pacsanaEventInstance
    ...organizationInstance
    ...organizationPatientInstance
    ...nhsNumberInstance
    ...patientInstance
    ...patientNoteInstance
    ...ewsThresholdsInstance
    ...ehrIntegrationInstance
    ...ehrIntegrationEventInstance
    ...ehrIntegrationIdentityInstance
    ...integrationApiKeyInstance
    ...pictureInstance
    ...shareTokenInstance
    ...userInstance
    ...userOrganizationInstance
    ...wardInstance
  }
}
    ${AcceptedTermsInstanceFragmentDoc}
${AlertInstanceFragmentDoc}
${AlertRuleInstanceFragmentDoc}
${CarePathwayInstanceFragmentDoc}
${CheckupInstanceFragmentDoc}
${CheckupConfigInstanceFragmentDoc}
${CheckupScheduleInstanceFragmentDoc}
${CheckupScheduleEventInstanceFragmentDoc}
${CheckupTypeInstanceFragmentDoc}
${ContinuousMonitoringSessionInstanceFragmentDoc}
${PacsanaSessionInstanceFragmentDoc}
${PacsanaEventInstanceFragmentDoc}
${OrganizationInstanceFragmentDoc}
${OrganizationPatientInstanceFragmentDoc}
${NhsNumberInstanceFragmentDoc}
${PatientInstanceFragmentDoc}
${PatientNoteInstanceFragmentDoc}
${EwsThresholdsInstanceFragmentDoc}
${EhrIntegrationInstanceFragmentDoc}
${EhrIntegrationEventInstanceFragmentDoc}
${EhrIntegrationIdentityInstanceFragmentDoc}
${IntegrationApiKeyInstanceFragmentDoc}
${PictureInstanceFragmentDoc}
${ShareTokenInstanceFragmentDoc}
${UserInstanceFragmentDoc}
${UserOrganizationInstanceFragmentDoc}
${WardInstanceFragmentDoc}`;
export const ActionLogItemFragmentDoc = gql`
    fragment ActionLogItem on ActionLog {
  id
  message
  createdAt
  user {
    firstName
    lastName
    email
    isSelfCare
  }
  affectedOrganization {
    id
    name
  }
  loggedInAs {
    id
  }
  messageEntities {
    ...messageEntityContent
  }
}
    ${MessageEntityContentFragmentDoc}`;
export const ActivityMonitoringMetricFragmentDoc = gql`
    fragment ActivityMonitoringMetric on PacsanaDailyMetrics {
  id
  activityDate
  exerciseMinutes
  activeMinutes
  gaitSpeed
  scores {
    gaitSpeedScore
  }
}
    `;
export const ActivityMonitoringMetadataFragmentDoc = gql`
    fragment ActivityMonitoringMetadata on PacsanaDailyMetricsMetadata {
  earliestAvailableMetrics
  latestAvailableMetrics
}
    `;
export const CheckupTypeDisplayFragmentDoc = gql`
    fragment CheckupTypeDisplay on CheckupType {
  id
  name
  heading
  description
  updatedAt
  schedule {
    tolerance {
      early
      late
    }
    rrule
  }
  checkupTiles {
    symptomsQuestionnaire {
      questionnaireSections
      rule
    }
    consciousness {
      rule
    }
    pulseOximeter {
      rule
      manualEntry
      usePlethAI
    }
    respiratoryRate {
      rule
    }
    bloodPressureCuff {
      rule
      manualEntry
    }
    temperature {
      rule
    }
    stethoscope {
      forceOnAbnormalRespiratoryRate
      forceOnAbnormalSpO2
      forceOnRespiratorySoftSigns
      rule
    }
    picture {
      rule
    }
    weight {
      rule
    }
    glucose {
      rule
    }
  }
}
    `;
export const AlertRuleDisplayFragmentDoc = gql`
    fragment AlertRuleDisplay on AlertRule {
  id
  name
  description
  condition
}
    `;
export const CarePathwayDetailsFragmentDoc = gql`
    fragment CarePathwayDetails on CarePathway {
  id
  name
  checkupTypes {
    ...CheckupTypeDisplay
  }
  alertRules {
    ...AlertRuleDisplay
  }
}
    ${CheckupTypeDisplayFragmentDoc}
${AlertRuleDisplayFragmentDoc}`;
export const ContinuousMonitoringItemFragmentDoc = gql`
    fragment ContinuousMonitoringItem on ContinuousMonitoring {
  bucketStartAt
  bucketEndAt
  respiratoryRate {
    value
    median
    min
    max
  }
  spo2 {
    value
    median
    min
    max
  }
  pulseRate {
    value
    median
    min
    max
  }
  heartRate {
    value
    median
    min
    max
  }
  temperature {
    value
    median
    min
    max
  }
  battery {
    ecg
    spo2
    temperature
  }
  thresholdScores {
    RRScore
    SpO2Score
    HRScore
    tempScore
  }
}
    `;
export const PatientNoteFragmentDoc = gql`
    fragment PatientNote on PatientNote {
  id
  createdAt
  text
  createdBy {
    id
    email
    firstName
    lastName
    isSelfCare
  }
  organization {
    id
    name
  }
  checkup {
    id
  }
}
    `;
export const PatientReportPatientDataFragmentDoc = gql`
    fragment PatientReportPatientData on Patient {
  id
  createdAt
  firstName
  lastName
  birthDate
  gender
  address {
    address
  }
  nhsNumberResponseDetails {
    nhsNumber
  }
  organizations {
    address {
      address
    }
  }
}
    `;
export const PatientReportAdmissionDataFragmentDoc = gql`
    fragment PatientReportAdmissionData on WardAdmission {
  admittedAt
  ward {
    name
    organization {
      id
      name
    }
  }
  carePathway {
    name
  }
}
    `;
export const PatientReportDischargeDataFragmentDoc = gql`
    fragment PatientReportDischargeData on PatientDischarge {
  admittedAt
  dischargedAt
  dischargedBy {
    email
    firstName
    lastName
  }
  ward {
    name
    organization {
      id
      name
    }
  }
  carePathway {
    name
  }
  WardId
}
    `;
export const PatientReportCheckupDataFragmentDoc = gql`
    fragment PatientReportCheckupData on Checkup {
  id
  createdAt
  startedAt
  glucose
  ewsScores {
    BPScore
    consciousnessScore
    HRScore
    RRScore
    SpO2Score
    tempScore
    totalScore
    riskLevel
  }
  respiratoryRate {
    value
    source
  }
  bloodPressureData
  pulseRate {
    value
    source
    isManual
  }
  temperature
  weight
  notes {
    text
  }
  isStable
  questionnaire
  selectedAction
  lungSounds {
    url
  }
  pulseOxiData
  consciousness
  createdBy {
    email
    firstName
    lastName
  }
  organization {
    id
    name
  }
}
    `;
export const PatientReportAlertsDataFragmentDoc = gql`
    fragment PatientReportAlertsData on Alert {
  id
  startedAt
  alertRule {
    name
    description
  }
}
    `;
export const PatientReportNotesDataFragmentDoc = gql`
    fragment PatientReportNotesData on PatientNote {
  id
  text
  createdAt
  createdBy {
    email
    firstName
    lastName
  }
  organization {
    id
    name
  }
}
    `;
export const PatientReportVitalsAggregateFragmentDoc = gql`
    fragment PatientReportVitalsAggregate on VitalsSummaryFrame {
  start
  end
  values {
    pulseRate {
      median
      min
      max
    }
    respiratoryRate {
      median
      min
      max
    }
    systolicBloodPressure {
      median
      min
      max
    }
    diastolicBloodPressure {
      median
      min
      max
    }
    spO2 {
      median
      min
      max
    }
    temperature {
      median
      min
      max
    }
    weight {
      median
      min
      max
    }
    glucose {
      median
      min
      max
    }
  }
}
    `;
export const PatientReportSoftSignsAggregateFragmentDoc = gql`
    fragment PatientReportSoftSignsAggregate on SoftSignsSummaryFrame {
  start
  end
  values {
    questionKey
    count
  }
}
    `;
export const PatientCheckupsTableItemFragmentDoc = gql`
    fragment PatientCheckupsTableItem on Checkup {
  id
  endedAt
  type
  subtype
  respiratoryRate {
    value
    source
  }
  pulseOxiData
  bloodPressureData
  pulseRate {
    value
  }
  temperature
  consciousness
  ewsScores {
    BPScore
    consciousnessScore
    HRScore
    RRScore
    SpO2Score
    tempScore
    totalScore
    riskLevel
  }
  questionnaire
  selectedAction
  weight
}
    `;
export const PatientAdmissionOverviewFragmentDoc = gql`
    fragment PatientAdmissionOverview on WardAdmission {
  admittedAt
  ward {
    id
    name
  }
  carePathway {
    id
    name
    ewsThresholds {
      thresholds
    }
  }
}
    `;
export const PatientDischargeOverviewFragmentDoc = gql`
    fragment PatientDischargeOverview on PatientDischarge {
  admittedAt
  dischargedAt
  dischargedBy {
    email
    firstName
    lastName
  }
  ward {
    name
    organization {
      id
      name
    }
  }
  WardId
}
    `;
export const PatientDetailsFragmentDoc = gql`
    fragment PatientDetails on Patient {
  id
  firstName
  lastName
  numSimilarNames
  createdAt
  birthDate
  gender
  address {
    address
    postcode
  }
  telephone
  features
  organizations(type: "care_home") {
    address {
      address
      postcode
    }
  }
  selfCare {
    id
    email
    canResetPassword
  }
  nhsNumber
  preExistingConditions
  nhsNumberResponseDetails {
    nhsNumber
  }
  latestCheckup {
    endedAt
  }
  activityMonitoringSession {
    pacsanaUserName
  }
  latestContinuousMonitoring {
    continuousMonitoringSession {
      endedAt
      vivalinkSubjectId
    }
  }
  wardAdmission {
    ...PatientAdmissionOverview
  }
  wardDischarges {
    ...PatientDischargeOverview
  }
  ewsThresholds {
    createdAt
    createdBy {
      firstName
      lastName
      email
    }
    thresholds
  }
  wards {
    id
    name
  }
}
    ${PatientAdmissionOverviewFragmentDoc}
${PatientDischargeOverviewFragmentDoc}`;
export const ApiKeyFragmentDoc = gql`
    fragment ApiKey on IntegrationApiKey {
  id
  apiKey
  deletedAt
  createdAt
  createdBy {
    email
    firstName
    lastName
  }
}
    `;
export const SettingsShareTokenFragmentDoc = gql`
    fragment SettingsShareToken on ShareToken {
  id
  createdAt
  expiresAt
  user {
    id
    firstName
    lastName
    email
  }
  patient {
    id
    firstName
    lastName
  }
}
    `;
export const UserSettingsFragmentDoc = gql`
    fragment UserSettings on UserOrganization {
  user {
    id
    email
    firstName
    lastName
    wards {
      id
      name
    }
    enrolledInTotpMfa
    isQuicksilvaIdentitySetup
    canResetPassword
  }
  roles
  lastLoggedInAt
}
    `;
export const CreatedApiKeyFragmentDoc = gql`
    fragment CreatedApiKey on IntegrationApiKeyWithSecret {
  apiKey
  secret
}
    `;
export const UserWardFragmentDoc = gql`
    fragment UserWard on Ward {
  id
  name
}
    `;
export const UserNeighborFragmentDoc = gql`
    fragment UserNeighbor on NeighborOrganization {
  id
  name
}
    `;
export const VirtualWardPatientCheckupFragmentDoc = gql`
    fragment VirtualWardPatientCheckup on Checkup {
  id
  type
  checkupType {
    name
  }
  endedAt
  notes {
    text
  }
  questionnaire
  ewsScores {
    BPScore
    consciousnessScore
    HRScore
    RRScore
    SpO2Score
    tempScore
    totalScore
    riskLevel
  }
  bloodPressureData
  pulseRate {
    value
    source
    isManual
  }
  pulseOxiData
  respiratoryRate {
    value
    source
  }
  temperature
  consciousness
  selectedAction
  glucose
  weight
}
    `;
export const VirtualWardPatientContinuousMonitoringFragmentDoc = gql`
    fragment VirtualWardPatientContinuousMonitoring on ContinuousMonitoring {
  bucketEndAt
  spo2 {
    min
    max
    median
    value
  }
  respiratoryRate {
    min
    max
    median
    value
  }
  heartRate {
    min
    max
    median
    value
  }
  pulseRate {
    min
    max
    median
    value
  }
  temperature {
    min
    max
    median
    value
  }
  battery {
    ecg
    spo2
    temperature
  }
  thresholdScores {
    BPScore
    consciousnessScore
    HRScore
    RRScore
    SpO2Score
    tempScore
    totalScore
    riskLevel
  }
}
    `;
export const VirtualWardPatientContinuousMonitoringWithSessionFragmentDoc = gql`
    fragment VirtualWardPatientContinuousMonitoringWithSession on ContinuousMonitoringWithSession {
  continuousMonitoring {
    ...VirtualWardPatientContinuousMonitoring
  }
  continuousMonitoringSession {
    vivalinkSubjectId
    createdAt
    endedAt
  }
}
    ${VirtualWardPatientContinuousMonitoringFragmentDoc}`;
export const VirtualWardPatientActivityMonitoringSessionFragmentDoc = gql`
    fragment VirtualWardPatientActivityMonitoringSession on PacsanaSession {
  id
  createdAt
  endedAt
  pacsanaUserName
  status {
    zeroesAcrossMetricsForPreviousDay
    noMetricsForPreviousDay
    batteryStatus
    latestMetricsDate
    latestEventDate
  }
}
    `;
export const AlertChipFieldsFragmentDoc = gql`
    fragment AlertChipFields on Alert {
  _aggregate {
    count
  }
  id
  type
  status
  startedAt
  alertRule {
    id
    name
    description
  }
  checkupScheduleEvent {
    id
    checkupExpectedAt
    checkupSchedule {
      id
      tolerance {
        early
        late
      }
    }
  }
  pacsanaEvent {
    eventId
    eventKey
  }
}
    `;
export const VirtualWardPatientItemFragmentDoc = gql`
    fragment VirtualWardPatientItem on Patient {
  id
  firstName
  lastName
  numSimilarNames
  birthDate
  nhsNumber
  wardAdmission {
    ward {
      id
      name
    }
    carePathway {
      id
      name
    }
  }
  latestCheckup {
    ...VirtualWardPatientCheckup
  }
  latestContinuousMonitoring {
    ...VirtualWardPatientContinuousMonitoringWithSession
  }
  activityMonitoringSession {
    ...VirtualWardPatientActivityMonitoringSession
  }
  alerts(status: "open", aggregate: true) {
    ...AlertChipFields
  }
}
    ${VirtualWardPatientCheckupFragmentDoc}
${VirtualWardPatientContinuousMonitoringWithSessionFragmentDoc}
${VirtualWardPatientActivityMonitoringSessionFragmentDoc}
${AlertChipFieldsFragmentDoc}`;
export const AddUserJourneyCreateUserDocument = gql`
    mutation AddUserJourneyCreateUser($user: UserInput!, $role: String!, $portalUrl: String!) {
  createUser(user: $user, role: $role, portalUrl: $portalUrl) {
    id
    firstName
    lastName
    email
    organizations {
      id
    }
  }
}
    `;
export type AddUserJourneyCreateUserMutationFn = Apollo.MutationFunction<AddUserJourneyCreateUserMutation, AddUserJourneyCreateUserMutationVariables>;

/**
 * __useAddUserJourneyCreateUserMutation__
 *
 * To run a mutation, you first call `useAddUserJourneyCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddUserJourneyCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addUserJourneyCreateUserMutation, { data, loading, error }] = useAddUserJourneyCreateUserMutation({
 *   variables: {
 *      user: // value for 'user'
 *      role: // value for 'role'
 *      portalUrl: // value for 'portalUrl'
 *   },
 * });
 */
export function useAddUserJourneyCreateUserMutation(baseOptions?: Apollo.MutationHookOptions<AddUserJourneyCreateUserMutation, AddUserJourneyCreateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddUserJourneyCreateUserMutation, AddUserJourneyCreateUserMutationVariables>(AddUserJourneyCreateUserDocument, options);
      }
export type AddUserJourneyCreateUserMutationHookResult = ReturnType<typeof useAddUserJourneyCreateUserMutation>;
export type AddUserJourneyCreateUserMutationResult = Apollo.MutationResult<AddUserJourneyCreateUserMutation>;
export type AddUserJourneyCreateUserMutationOptions = Apollo.BaseMutationOptions<AddUserJourneyCreateUserMutation, AddUserJourneyCreateUserMutationVariables>;
export const UpdatePatientContactDetailsDocument = gql`
    mutation UpdatePatientContactDetails($patientId: ID!, $newContact: ContactDetailsInput!) {
  updatePatientContactDetails(patientId: $patientId, newContact: $newContact) {
    id
    telephone
    address {
      address
      postcode
    }
  }
}
    `;
export type UpdatePatientContactDetailsMutationFn = Apollo.MutationFunction<UpdatePatientContactDetailsMutation, UpdatePatientContactDetailsMutationVariables>;

/**
 * __useUpdatePatientContactDetailsMutation__
 *
 * To run a mutation, you first call `useUpdatePatientContactDetailsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePatientContactDetailsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePatientContactDetailsMutation, { data, loading, error }] = useUpdatePatientContactDetailsMutation({
 *   variables: {
 *      patientId: // value for 'patientId'
 *      newContact: // value for 'newContact'
 *   },
 * });
 */
export function useUpdatePatientContactDetailsMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePatientContactDetailsMutation, UpdatePatientContactDetailsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePatientContactDetailsMutation, UpdatePatientContactDetailsMutationVariables>(UpdatePatientContactDetailsDocument, options);
      }
export type UpdatePatientContactDetailsMutationHookResult = ReturnType<typeof useUpdatePatientContactDetailsMutation>;
export type UpdatePatientContactDetailsMutationResult = Apollo.MutationResult<UpdatePatientContactDetailsMutation>;
export type UpdatePatientContactDetailsMutationOptions = Apollo.BaseMutationOptions<UpdatePatientContactDetailsMutation, UpdatePatientContactDetailsMutationVariables>;
export const GetAdmissionWardAndCarePathwayOptionsDocument = gql`
    query GetAdmissionWardAndCarePathwayOptions {
  wards {
    id
    name
  }
  carePathways {
    id
    name
  }
}
    `;

/**
 * __useGetAdmissionWardAndCarePathwayOptionsQuery__
 *
 * To run a query within a React component, call `useGetAdmissionWardAndCarePathwayOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAdmissionWardAndCarePathwayOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdmissionWardAndCarePathwayOptionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAdmissionWardAndCarePathwayOptionsQuery(baseOptions?: Apollo.QueryHookOptions<GetAdmissionWardAndCarePathwayOptionsQuery, GetAdmissionWardAndCarePathwayOptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAdmissionWardAndCarePathwayOptionsQuery, GetAdmissionWardAndCarePathwayOptionsQueryVariables>(GetAdmissionWardAndCarePathwayOptionsDocument, options);
      }
export function useGetAdmissionWardAndCarePathwayOptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAdmissionWardAndCarePathwayOptionsQuery, GetAdmissionWardAndCarePathwayOptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAdmissionWardAndCarePathwayOptionsQuery, GetAdmissionWardAndCarePathwayOptionsQueryVariables>(GetAdmissionWardAndCarePathwayOptionsDocument, options);
        }
export type GetAdmissionWardAndCarePathwayOptionsQueryHookResult = ReturnType<typeof useGetAdmissionWardAndCarePathwayOptionsQuery>;
export type GetAdmissionWardAndCarePathwayOptionsLazyQueryHookResult = ReturnType<typeof useGetAdmissionWardAndCarePathwayOptionsLazyQuery>;
export type GetAdmissionWardAndCarePathwayOptionsQueryResult = Apollo.QueryResult<GetAdmissionWardAndCarePathwayOptionsQuery, GetAdmissionWardAndCarePathwayOptionsQueryVariables>;
export const LookupPatientDocument = gql`
    query LookupPatient($fields: PatientLookupInput!) {
  lookupPatient(fields: $fields) {
    ...PatientLookupView
  }
}
    ${PatientLookupViewFragmentDoc}`;

/**
 * __useLookupPatientQuery__
 *
 * To run a query within a React component, call `useLookupPatientQuery` and pass it any options that fit your needs.
 * When your component renders, `useLookupPatientQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLookupPatientQuery({
 *   variables: {
 *      fields: // value for 'fields'
 *   },
 * });
 */
export function useLookupPatientQuery(baseOptions: Apollo.QueryHookOptions<LookupPatientQuery, LookupPatientQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LookupPatientQuery, LookupPatientQueryVariables>(LookupPatientDocument, options);
      }
export function useLookupPatientLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LookupPatientQuery, LookupPatientQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LookupPatientQuery, LookupPatientQueryVariables>(LookupPatientDocument, options);
        }
export type LookupPatientQueryHookResult = ReturnType<typeof useLookupPatientQuery>;
export type LookupPatientLazyQueryHookResult = ReturnType<typeof useLookupPatientLazyQuery>;
export type LookupPatientQueryResult = Apollo.QueryResult<LookupPatientQuery, LookupPatientQueryVariables>;
export const CreateSelfCareLoginDocument = gql`
    mutation CreateSelfCareLogin($patientId: ID!, $email: String!, $password: String) {
  createSelfCareLogin(
    patientId: $patientId
    selfCare: {email: $email, password: $password}
  ) {
    id
    email
  }
}
    `;
export type CreateSelfCareLoginMutationFn = Apollo.MutationFunction<CreateSelfCareLoginMutation, CreateSelfCareLoginMutationVariables>;

/**
 * __useCreateSelfCareLoginMutation__
 *
 * To run a mutation, you first call `useCreateSelfCareLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSelfCareLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSelfCareLoginMutation, { data, loading, error }] = useCreateSelfCareLoginMutation({
 *   variables: {
 *      patientId: // value for 'patientId'
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useCreateSelfCareLoginMutation(baseOptions?: Apollo.MutationHookOptions<CreateSelfCareLoginMutation, CreateSelfCareLoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSelfCareLoginMutation, CreateSelfCareLoginMutationVariables>(CreateSelfCareLoginDocument, options);
      }
export type CreateSelfCareLoginMutationHookResult = ReturnType<typeof useCreateSelfCareLoginMutation>;
export type CreateSelfCareLoginMutationResult = Apollo.MutationResult<CreateSelfCareLoginMutation>;
export type CreateSelfCareLoginMutationOptions = Apollo.BaseMutationOptions<CreateSelfCareLoginMutation, CreateSelfCareLoginMutationVariables>;
export const PatientNhsNumberDetailsDocument = gql`
    query PatientNhsNumberDetails($id: ID!) {
  patient(id: $id) {
    ...PatientNhsNumberDetailsDialog
  }
}
    ${PatientNhsNumberDetailsDialogFragmentDoc}`;

/**
 * __usePatientNhsNumberDetailsQuery__
 *
 * To run a query within a React component, call `usePatientNhsNumberDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePatientNhsNumberDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePatientNhsNumberDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePatientNhsNumberDetailsQuery(baseOptions: Apollo.QueryHookOptions<PatientNhsNumberDetailsQuery, PatientNhsNumberDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PatientNhsNumberDetailsQuery, PatientNhsNumberDetailsQueryVariables>(PatientNhsNumberDetailsDocument, options);
      }
export function usePatientNhsNumberDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PatientNhsNumberDetailsQuery, PatientNhsNumberDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PatientNhsNumberDetailsQuery, PatientNhsNumberDetailsQueryVariables>(PatientNhsNumberDetailsDocument, options);
        }
export type PatientNhsNumberDetailsQueryHookResult = ReturnType<typeof usePatientNhsNumberDetailsQuery>;
export type PatientNhsNumberDetailsLazyQueryHookResult = ReturnType<typeof usePatientNhsNumberDetailsLazyQuery>;
export type PatientNhsNumberDetailsQueryResult = Apollo.QueryResult<PatientNhsNumberDetailsQuery, PatientNhsNumberDetailsQueryVariables>;
export const GetActivityMonitoringSessionDocument = gql`
    query GetActivityMonitoringSession($patientId: ID!) {
  pacsanaSession(patientId: $patientId) {
    ...PacsanaSessionItem
  }
}
    ${PacsanaSessionItemFragmentDoc}`;

/**
 * __useGetActivityMonitoringSessionQuery__
 *
 * To run a query within a React component, call `useGetActivityMonitoringSessionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActivityMonitoringSessionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActivityMonitoringSessionQuery({
 *   variables: {
 *      patientId: // value for 'patientId'
 *   },
 * });
 */
export function useGetActivityMonitoringSessionQuery(baseOptions: Apollo.QueryHookOptions<GetActivityMonitoringSessionQuery, GetActivityMonitoringSessionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetActivityMonitoringSessionQuery, GetActivityMonitoringSessionQueryVariables>(GetActivityMonitoringSessionDocument, options);
      }
export function useGetActivityMonitoringSessionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetActivityMonitoringSessionQuery, GetActivityMonitoringSessionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetActivityMonitoringSessionQuery, GetActivityMonitoringSessionQueryVariables>(GetActivityMonitoringSessionDocument, options);
        }
export type GetActivityMonitoringSessionQueryHookResult = ReturnType<typeof useGetActivityMonitoringSessionQuery>;
export type GetActivityMonitoringSessionLazyQueryHookResult = ReturnType<typeof useGetActivityMonitoringSessionLazyQuery>;
export type GetActivityMonitoringSessionQueryResult = Apollo.QueryResult<GetActivityMonitoringSessionQuery, GetActivityMonitoringSessionQueryVariables>;
export const CreateVideoCallRoomAndSendSmsDocument = gql`
    mutation createVideoCallRoomAndSendSMS($patientId: ID!) {
  createVideoCallRoomAndSendSMS(patientId: $patientId) {
    hostRoomUrl
    patientRoomUrl
  }
}
    `;
export type CreateVideoCallRoomAndSendSmsMutationFn = Apollo.MutationFunction<CreateVideoCallRoomAndSendSmsMutation, CreateVideoCallRoomAndSendSmsMutationVariables>;

/**
 * __useCreateVideoCallRoomAndSendSmsMutation__
 *
 * To run a mutation, you first call `useCreateVideoCallRoomAndSendSmsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateVideoCallRoomAndSendSmsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createVideoCallRoomAndSendSmsMutation, { data, loading, error }] = useCreateVideoCallRoomAndSendSmsMutation({
 *   variables: {
 *      patientId: // value for 'patientId'
 *   },
 * });
 */
export function useCreateVideoCallRoomAndSendSmsMutation(baseOptions?: Apollo.MutationHookOptions<CreateVideoCallRoomAndSendSmsMutation, CreateVideoCallRoomAndSendSmsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateVideoCallRoomAndSendSmsMutation, CreateVideoCallRoomAndSendSmsMutationVariables>(CreateVideoCallRoomAndSendSmsDocument, options);
      }
export type CreateVideoCallRoomAndSendSmsMutationHookResult = ReturnType<typeof useCreateVideoCallRoomAndSendSmsMutation>;
export type CreateVideoCallRoomAndSendSmsMutationResult = Apollo.MutationResult<CreateVideoCallRoomAndSendSmsMutation>;
export type CreateVideoCallRoomAndSendSmsMutationOptions = Apollo.BaseMutationOptions<CreateVideoCallRoomAndSendSmsMutation, CreateVideoCallRoomAndSendSmsMutationVariables>;
export const DischargePatientFromWardDocument = gql`
    mutation DischargePatientFromWard($PatientId: ID!, $WardId: ID!, $dischargedAt: Date, $reason: String) {
  dischargePatientFromWard(
    PatientId: $PatientId
    WardId: $WardId
    dischargedAt: $dischargedAt
    reason: $reason
  )
}
    `;
export type DischargePatientFromWardMutationFn = Apollo.MutationFunction<DischargePatientFromWardMutation, DischargePatientFromWardMutationVariables>;

/**
 * __useDischargePatientFromWardMutation__
 *
 * To run a mutation, you first call `useDischargePatientFromWardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDischargePatientFromWardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [dischargePatientFromWardMutation, { data, loading, error }] = useDischargePatientFromWardMutation({
 *   variables: {
 *      PatientId: // value for 'PatientId'
 *      WardId: // value for 'WardId'
 *      dischargedAt: // value for 'dischargedAt'
 *      reason: // value for 'reason'
 *   },
 * });
 */
export function useDischargePatientFromWardMutation(baseOptions?: Apollo.MutationHookOptions<DischargePatientFromWardMutation, DischargePatientFromWardMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DischargePatientFromWardMutation, DischargePatientFromWardMutationVariables>(DischargePatientFromWardDocument, options);
      }
export type DischargePatientFromWardMutationHookResult = ReturnType<typeof useDischargePatientFromWardMutation>;
export type DischargePatientFromWardMutationResult = Apollo.MutationResult<DischargePatientFromWardMutation>;
export type DischargePatientFromWardMutationOptions = Apollo.BaseMutationOptions<DischargePatientFromWardMutation, DischargePatientFromWardMutationVariables>;
export const GetWardsForSelectDocument = gql`
    query GetWardsForSelect {
  wards {
    id
    name
  }
}
    `;

/**
 * __useGetWardsForSelectQuery__
 *
 * To run a query within a React component, call `useGetWardsForSelectQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWardsForSelectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWardsForSelectQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetWardsForSelectQuery(baseOptions?: Apollo.QueryHookOptions<GetWardsForSelectQuery, GetWardsForSelectQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetWardsForSelectQuery, GetWardsForSelectQueryVariables>(GetWardsForSelectDocument, options);
      }
export function useGetWardsForSelectLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetWardsForSelectQuery, GetWardsForSelectQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetWardsForSelectQuery, GetWardsForSelectQueryVariables>(GetWardsForSelectDocument, options);
        }
export type GetWardsForSelectQueryHookResult = ReturnType<typeof useGetWardsForSelectQuery>;
export type GetWardsForSelectLazyQueryHookResult = ReturnType<typeof useGetWardsForSelectLazyQuery>;
export type GetWardsForSelectQueryResult = Apollo.QueryResult<GetWardsForSelectQuery, GetWardsForSelectQueryVariables>;
export const GetCarePathwaysForSelectDocument = gql`
    query GetCarePathwaysForSelect {
  carePathways(includeShared: true) {
    id
    name
  }
}
    `;

/**
 * __useGetCarePathwaysForSelectQuery__
 *
 * To run a query within a React component, call `useGetCarePathwaysForSelectQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCarePathwaysForSelectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCarePathwaysForSelectQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCarePathwaysForSelectQuery(baseOptions?: Apollo.QueryHookOptions<GetCarePathwaysForSelectQuery, GetCarePathwaysForSelectQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCarePathwaysForSelectQuery, GetCarePathwaysForSelectQueryVariables>(GetCarePathwaysForSelectDocument, options);
      }
export function useGetCarePathwaysForSelectLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCarePathwaysForSelectQuery, GetCarePathwaysForSelectQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCarePathwaysForSelectQuery, GetCarePathwaysForSelectQueryVariables>(GetCarePathwaysForSelectDocument, options);
        }
export type GetCarePathwaysForSelectQueryHookResult = ReturnType<typeof useGetCarePathwaysForSelectQuery>;
export type GetCarePathwaysForSelectLazyQueryHookResult = ReturnType<typeof useGetCarePathwaysForSelectLazyQuery>;
export type GetCarePathwaysForSelectQueryResult = Apollo.QueryResult<GetCarePathwaysForSelectQuery, GetCarePathwaysForSelectQueryVariables>;
export const AdmitPatientDocument = gql`
    mutation AdmitPatient($patientId: ID!, $wardId: ID!, $carePathwayId: ID!) {
  admitPatientToWard(
    patientId: $patientId
    wardId: $wardId
    carePathwayId: $carePathwayId
  ) {
    patient {
      id
      wardAdmission {
        ...PatientAdmissionOverview
      }
    }
    admittedAt
  }
}
    ${PatientAdmissionOverviewFragmentDoc}`;
export type AdmitPatientMutationFn = Apollo.MutationFunction<AdmitPatientMutation, AdmitPatientMutationVariables>;

/**
 * __useAdmitPatientMutation__
 *
 * To run a mutation, you first call `useAdmitPatientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdmitPatientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [admitPatientMutation, { data, loading, error }] = useAdmitPatientMutation({
 *   variables: {
 *      patientId: // value for 'patientId'
 *      wardId: // value for 'wardId'
 *      carePathwayId: // value for 'carePathwayId'
 *   },
 * });
 */
export function useAdmitPatientMutation(baseOptions?: Apollo.MutationHookOptions<AdmitPatientMutation, AdmitPatientMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdmitPatientMutation, AdmitPatientMutationVariables>(AdmitPatientDocument, options);
      }
export type AdmitPatientMutationHookResult = ReturnType<typeof useAdmitPatientMutation>;
export type AdmitPatientMutationResult = Apollo.MutationResult<AdmitPatientMutation>;
export type AdmitPatientMutationOptions = Apollo.BaseMutationOptions<AdmitPatientMutation, AdmitPatientMutationVariables>;
export const DidSwitchActingOrganizationDocument = gql`
    mutation didSwitchActingOrganization {
  didSwitchActingOrganization
}
    `;
export type DidSwitchActingOrganizationMutationFn = Apollo.MutationFunction<DidSwitchActingOrganizationMutation, DidSwitchActingOrganizationMutationVariables>;

/**
 * __useDidSwitchActingOrganizationMutation__
 *
 * To run a mutation, you first call `useDidSwitchActingOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDidSwitchActingOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [didSwitchActingOrganizationMutation, { data, loading, error }] = useDidSwitchActingOrganizationMutation({
 *   variables: {
 *   },
 * });
 */
export function useDidSwitchActingOrganizationMutation(baseOptions?: Apollo.MutationHookOptions<DidSwitchActingOrganizationMutation, DidSwitchActingOrganizationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DidSwitchActingOrganizationMutation, DidSwitchActingOrganizationMutationVariables>(DidSwitchActingOrganizationDocument, options);
      }
export type DidSwitchActingOrganizationMutationHookResult = ReturnType<typeof useDidSwitchActingOrganizationMutation>;
export type DidSwitchActingOrganizationMutationResult = Apollo.MutationResult<DidSwitchActingOrganizationMutation>;
export type DidSwitchActingOrganizationMutationOptions = Apollo.BaseMutationOptions<DidSwitchActingOrganizationMutation, DidSwitchActingOrganizationMutationVariables>;
export const GetTotpUserEnrolmentDocument = gql`
    query getTotpUserEnrolment {
  me {
    email
    enrolledInTotpMfa
  }
}
    `;

/**
 * __useGetTotpUserEnrolmentQuery__
 *
 * To run a query within a React component, call `useGetTotpUserEnrolmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTotpUserEnrolmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTotpUserEnrolmentQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTotpUserEnrolmentQuery(baseOptions?: Apollo.QueryHookOptions<GetTotpUserEnrolmentQuery, GetTotpUserEnrolmentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTotpUserEnrolmentQuery, GetTotpUserEnrolmentQueryVariables>(GetTotpUserEnrolmentDocument, options);
      }
export function useGetTotpUserEnrolmentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTotpUserEnrolmentQuery, GetTotpUserEnrolmentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTotpUserEnrolmentQuery, GetTotpUserEnrolmentQueryVariables>(GetTotpUserEnrolmentDocument, options);
        }
export type GetTotpUserEnrolmentQueryHookResult = ReturnType<typeof useGetTotpUserEnrolmentQuery>;
export type GetTotpUserEnrolmentLazyQueryHookResult = ReturnType<typeof useGetTotpUserEnrolmentLazyQuery>;
export type GetTotpUserEnrolmentQueryResult = Apollo.QueryResult<GetTotpUserEnrolmentQuery, GetTotpUserEnrolmentQueryVariables>;
export const UnenrollMyTwoFactorAuthDocument = gql`
    mutation unenrollMyTwoFactorAuth {
  unenrollMyTwoFactorAuth
}
    `;
export type UnenrollMyTwoFactorAuthMutationFn = Apollo.MutationFunction<UnenrollMyTwoFactorAuthMutation, UnenrollMyTwoFactorAuthMutationVariables>;

/**
 * __useUnenrollMyTwoFactorAuthMutation__
 *
 * To run a mutation, you first call `useUnenrollMyTwoFactorAuthMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnenrollMyTwoFactorAuthMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unenrollMyTwoFactorAuthMutation, { data, loading, error }] = useUnenrollMyTwoFactorAuthMutation({
 *   variables: {
 *   },
 * });
 */
export function useUnenrollMyTwoFactorAuthMutation(baseOptions?: Apollo.MutationHookOptions<UnenrollMyTwoFactorAuthMutation, UnenrollMyTwoFactorAuthMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UnenrollMyTwoFactorAuthMutation, UnenrollMyTwoFactorAuthMutationVariables>(UnenrollMyTwoFactorAuthDocument, options);
      }
export type UnenrollMyTwoFactorAuthMutationHookResult = ReturnType<typeof useUnenrollMyTwoFactorAuthMutation>;
export type UnenrollMyTwoFactorAuthMutationResult = Apollo.MutationResult<UnenrollMyTwoFactorAuthMutation>;
export type UnenrollMyTwoFactorAuthMutationOptions = Apollo.BaseMutationOptions<UnenrollMyTwoFactorAuthMutation, UnenrollMyTwoFactorAuthMutationVariables>;
export const MarkMyEmailAsVerifiedDocument = gql`
    mutation markMyEmailAsVerified {
  markMyEmailAsVerified
}
    `;
export type MarkMyEmailAsVerifiedMutationFn = Apollo.MutationFunction<MarkMyEmailAsVerifiedMutation, MarkMyEmailAsVerifiedMutationVariables>;

/**
 * __useMarkMyEmailAsVerifiedMutation__
 *
 * To run a mutation, you first call `useMarkMyEmailAsVerifiedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkMyEmailAsVerifiedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markMyEmailAsVerifiedMutation, { data, loading, error }] = useMarkMyEmailAsVerifiedMutation({
 *   variables: {
 *   },
 * });
 */
export function useMarkMyEmailAsVerifiedMutation(baseOptions?: Apollo.MutationHookOptions<MarkMyEmailAsVerifiedMutation, MarkMyEmailAsVerifiedMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MarkMyEmailAsVerifiedMutation, MarkMyEmailAsVerifiedMutationVariables>(MarkMyEmailAsVerifiedDocument, options);
      }
export type MarkMyEmailAsVerifiedMutationHookResult = ReturnType<typeof useMarkMyEmailAsVerifiedMutation>;
export type MarkMyEmailAsVerifiedMutationResult = Apollo.MutationResult<MarkMyEmailAsVerifiedMutation>;
export type MarkMyEmailAsVerifiedMutationOptions = Apollo.BaseMutationOptions<MarkMyEmailAsVerifiedMutation, MarkMyEmailAsVerifiedMutationVariables>;
export const AddQuicksilvaIdentityDocument = gql`
    mutation addQuicksilvaIdentity($accessCode: String!) {
  addQuicksilvaIdentity(accessCode: $accessCode)
}
    `;
export type AddQuicksilvaIdentityMutationFn = Apollo.MutationFunction<AddQuicksilvaIdentityMutation, AddQuicksilvaIdentityMutationVariables>;

/**
 * __useAddQuicksilvaIdentityMutation__
 *
 * To run a mutation, you first call `useAddQuicksilvaIdentityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddQuicksilvaIdentityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addQuicksilvaIdentityMutation, { data, loading, error }] = useAddQuicksilvaIdentityMutation({
 *   variables: {
 *      accessCode: // value for 'accessCode'
 *   },
 * });
 */
export function useAddQuicksilvaIdentityMutation(baseOptions?: Apollo.MutationHookOptions<AddQuicksilvaIdentityMutation, AddQuicksilvaIdentityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddQuicksilvaIdentityMutation, AddQuicksilvaIdentityMutationVariables>(AddQuicksilvaIdentityDocument, options);
      }
export type AddQuicksilvaIdentityMutationHookResult = ReturnType<typeof useAddQuicksilvaIdentityMutation>;
export type AddQuicksilvaIdentityMutationResult = Apollo.MutationResult<AddQuicksilvaIdentityMutation>;
export type AddQuicksilvaIdentityMutationOptions = Apollo.BaseMutationOptions<AddQuicksilvaIdentityMutation, AddQuicksilvaIdentityMutationVariables>;
export const RemoveQuicksilvaIdentityDocument = gql`
    mutation removeQuicksilvaIdentity {
  removeQuicksilvaIdentity
}
    `;
export type RemoveQuicksilvaIdentityMutationFn = Apollo.MutationFunction<RemoveQuicksilvaIdentityMutation, RemoveQuicksilvaIdentityMutationVariables>;

/**
 * __useRemoveQuicksilvaIdentityMutation__
 *
 * To run a mutation, you first call `useRemoveQuicksilvaIdentityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveQuicksilvaIdentityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeQuicksilvaIdentityMutation, { data, loading, error }] = useRemoveQuicksilvaIdentityMutation({
 *   variables: {
 *   },
 * });
 */
export function useRemoveQuicksilvaIdentityMutation(baseOptions?: Apollo.MutationHookOptions<RemoveQuicksilvaIdentityMutation, RemoveQuicksilvaIdentityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveQuicksilvaIdentityMutation, RemoveQuicksilvaIdentityMutationVariables>(RemoveQuicksilvaIdentityDocument, options);
      }
export type RemoveQuicksilvaIdentityMutationHookResult = ReturnType<typeof useRemoveQuicksilvaIdentityMutation>;
export type RemoveQuicksilvaIdentityMutationResult = Apollo.MutationResult<RemoveQuicksilvaIdentityMutation>;
export type RemoveQuicksilvaIdentityMutationOptions = Apollo.BaseMutationOptions<RemoveQuicksilvaIdentityMutation, RemoveQuicksilvaIdentityMutationVariables>;
export const IsQuicksilvaIdentitySetupDocument = gql`
    query isQuicksilvaIdentitySetup {
  me {
    isQuicksilvaIdentitySetup
  }
}
    `;

/**
 * __useIsQuicksilvaIdentitySetupQuery__
 *
 * To run a query within a React component, call `useIsQuicksilvaIdentitySetupQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsQuicksilvaIdentitySetupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsQuicksilvaIdentitySetupQuery({
 *   variables: {
 *   },
 * });
 */
export function useIsQuicksilvaIdentitySetupQuery(baseOptions?: Apollo.QueryHookOptions<IsQuicksilvaIdentitySetupQuery, IsQuicksilvaIdentitySetupQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IsQuicksilvaIdentitySetupQuery, IsQuicksilvaIdentitySetupQueryVariables>(IsQuicksilvaIdentitySetupDocument, options);
      }
export function useIsQuicksilvaIdentitySetupLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IsQuicksilvaIdentitySetupQuery, IsQuicksilvaIdentitySetupQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IsQuicksilvaIdentitySetupQuery, IsQuicksilvaIdentitySetupQueryVariables>(IsQuicksilvaIdentitySetupDocument, options);
        }
export type IsQuicksilvaIdentitySetupQueryHookResult = ReturnType<typeof useIsQuicksilvaIdentitySetupQuery>;
export type IsQuicksilvaIdentitySetupLazyQueryHookResult = ReturnType<typeof useIsQuicksilvaIdentitySetupLazyQuery>;
export type IsQuicksilvaIdentitySetupQueryResult = Apollo.QueryResult<IsQuicksilvaIdentitySetupQuery, IsQuicksilvaIdentitySetupQueryVariables>;
export const UpdateMeDocument = gql`
    mutation UpdateMe($user: MeInput!) {
  updateMe(user: $user)
}
    `;
export type UpdateMeMutationFn = Apollo.MutationFunction<UpdateMeMutation, UpdateMeMutationVariables>;

/**
 * __useUpdateMeMutation__
 *
 * To run a mutation, you first call `useUpdateMeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMeMutation, { data, loading, error }] = useUpdateMeMutation({
 *   variables: {
 *      user: // value for 'user'
 *   },
 * });
 */
export function useUpdateMeMutation(baseOptions?: Apollo.MutationHookOptions<UpdateMeMutation, UpdateMeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateMeMutation, UpdateMeMutationVariables>(UpdateMeDocument, options);
      }
export type UpdateMeMutationHookResult = ReturnType<typeof useUpdateMeMutation>;
export type UpdateMeMutationResult = Apollo.MutationResult<UpdateMeMutation>;
export type UpdateMeMutationOptions = Apollo.BaseMutationOptions<UpdateMeMutation, UpdateMeMutationVariables>;
export const GetCheckupDetailsDocument = gql`
    query GetCheckupDetails($checkupId: ID!) {
  checkup(id: $checkupId) {
    ...CheckupDetails
  }
}
    ${CheckupDetailsFragmentDoc}`;

/**
 * __useGetCheckupDetailsQuery__
 *
 * To run a query within a React component, call `useGetCheckupDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCheckupDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCheckupDetailsQuery({
 *   variables: {
 *      checkupId: // value for 'checkupId'
 *   },
 * });
 */
export function useGetCheckupDetailsQuery(baseOptions: Apollo.QueryHookOptions<GetCheckupDetailsQuery, GetCheckupDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCheckupDetailsQuery, GetCheckupDetailsQueryVariables>(GetCheckupDetailsDocument, options);
      }
export function useGetCheckupDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCheckupDetailsQuery, GetCheckupDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCheckupDetailsQuery, GetCheckupDetailsQueryVariables>(GetCheckupDetailsDocument, options);
        }
export type GetCheckupDetailsQueryHookResult = ReturnType<typeof useGetCheckupDetailsQuery>;
export type GetCheckupDetailsLazyQueryHookResult = ReturnType<typeof useGetCheckupDetailsLazyQuery>;
export type GetCheckupDetailsQueryResult = Apollo.QueryResult<GetCheckupDetailsQuery, GetCheckupDetailsQueryVariables>;
export const RetryEhrIntegrationEventDocument = gql`
    mutation retryEhrIntegrationEvent($ehrIntegrationEventId: ID!) {
  retryEhrIntegrationEvent(ehrIntegrationEventId: $ehrIntegrationEventId) {
    ...IntegrationEventItem
  }
}
    ${IntegrationEventItemFragmentDoc}`;
export type RetryEhrIntegrationEventMutationFn = Apollo.MutationFunction<RetryEhrIntegrationEventMutation, RetryEhrIntegrationEventMutationVariables>;

/**
 * __useRetryEhrIntegrationEventMutation__
 *
 * To run a mutation, you first call `useRetryEhrIntegrationEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRetryEhrIntegrationEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [retryEhrIntegrationEventMutation, { data, loading, error }] = useRetryEhrIntegrationEventMutation({
 *   variables: {
 *      ehrIntegrationEventId: // value for 'ehrIntegrationEventId'
 *   },
 * });
 */
export function useRetryEhrIntegrationEventMutation(baseOptions?: Apollo.MutationHookOptions<RetryEhrIntegrationEventMutation, RetryEhrIntegrationEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RetryEhrIntegrationEventMutation, RetryEhrIntegrationEventMutationVariables>(RetryEhrIntegrationEventDocument, options);
      }
export type RetryEhrIntegrationEventMutationHookResult = ReturnType<typeof useRetryEhrIntegrationEventMutation>;
export type RetryEhrIntegrationEventMutationResult = Apollo.MutationResult<RetryEhrIntegrationEventMutation>;
export type RetryEhrIntegrationEventMutationOptions = Apollo.BaseMutationOptions<RetryEhrIntegrationEventMutation, RetryEhrIntegrationEventMutationVariables>;
export const GetIntegrationEventsDocument = gql`
    query GetIntegrationEvents($take: Int!, $skip: Int!, $status: [EhrIntegrationStatus!], $sort: EhrIntegrationEventQuerySort) {
  ehrIntegrationEvents(take: $take, skip: $skip, status: $status, sort: $sort) {
    events {
      ...IntegrationEventItem
    }
    totalCount
  }
}
    ${IntegrationEventItemFragmentDoc}`;

/**
 * __useGetIntegrationEventsQuery__
 *
 * To run a query within a React component, call `useGetIntegrationEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetIntegrationEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetIntegrationEventsQuery({
 *   variables: {
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *      status: // value for 'status'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useGetIntegrationEventsQuery(baseOptions: Apollo.QueryHookOptions<GetIntegrationEventsQuery, GetIntegrationEventsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetIntegrationEventsQuery, GetIntegrationEventsQueryVariables>(GetIntegrationEventsDocument, options);
      }
export function useGetIntegrationEventsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetIntegrationEventsQuery, GetIntegrationEventsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetIntegrationEventsQuery, GetIntegrationEventsQueryVariables>(GetIntegrationEventsDocument, options);
        }
export type GetIntegrationEventsQueryHookResult = ReturnType<typeof useGetIntegrationEventsQuery>;
export type GetIntegrationEventsLazyQueryHookResult = ReturnType<typeof useGetIntegrationEventsLazyQuery>;
export type GetIntegrationEventsQueryResult = Apollo.QueryResult<GetIntegrationEventsQuery, GetIntegrationEventsQueryVariables>;
export const ManageCarePathwayDocument = gql`
    query ManageCarePathway($id: ID!) {
  me {
    isQuicksilvaIdentitySetup
  }
  carePathway(id: $id) {
    ...ManageCarePathwayItem
    checkupTypes {
      ...CheckupTypeDisplay
    }
    alertRules {
      ...AlertRuleDisplay
    }
    integrations {
      ...IntegrationDisplay
    }
  }
}
    ${ManageCarePathwayItemFragmentDoc}
${CheckupTypeDisplayFragmentDoc}
${AlertRuleDisplayFragmentDoc}
${IntegrationDisplayFragmentDoc}`;

/**
 * __useManageCarePathwayQuery__
 *
 * To run a query within a React component, call `useManageCarePathwayQuery` and pass it any options that fit your needs.
 * When your component renders, `useManageCarePathwayQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useManageCarePathwayQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useManageCarePathwayQuery(baseOptions: Apollo.QueryHookOptions<ManageCarePathwayQuery, ManageCarePathwayQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ManageCarePathwayQuery, ManageCarePathwayQueryVariables>(ManageCarePathwayDocument, options);
      }
export function useManageCarePathwayLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ManageCarePathwayQuery, ManageCarePathwayQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ManageCarePathwayQuery, ManageCarePathwayQueryVariables>(ManageCarePathwayDocument, options);
        }
export type ManageCarePathwayQueryHookResult = ReturnType<typeof useManageCarePathwayQuery>;
export type ManageCarePathwayLazyQueryHookResult = ReturnType<typeof useManageCarePathwayLazyQuery>;
export type ManageCarePathwayQueryResult = Apollo.QueryResult<ManageCarePathwayQuery, ManageCarePathwayQueryVariables>;
export const ManageCarePathwaysDocument = gql`
    query ManageCarePathways {
  carePathways {
    ...ManageCarePathwayItem
  }
}
    ${ManageCarePathwayItemFragmentDoc}`;

/**
 * __useManageCarePathwaysQuery__
 *
 * To run a query within a React component, call `useManageCarePathwaysQuery` and pass it any options that fit your needs.
 * When your component renders, `useManageCarePathwaysQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useManageCarePathwaysQuery({
 *   variables: {
 *   },
 * });
 */
export function useManageCarePathwaysQuery(baseOptions?: Apollo.QueryHookOptions<ManageCarePathwaysQuery, ManageCarePathwaysQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ManageCarePathwaysQuery, ManageCarePathwaysQueryVariables>(ManageCarePathwaysDocument, options);
      }
export function useManageCarePathwaysLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ManageCarePathwaysQuery, ManageCarePathwaysQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ManageCarePathwaysQuery, ManageCarePathwaysQueryVariables>(ManageCarePathwaysDocument, options);
        }
export type ManageCarePathwaysQueryHookResult = ReturnType<typeof useManageCarePathwaysQuery>;
export type ManageCarePathwaysLazyQueryHookResult = ReturnType<typeof useManageCarePathwaysLazyQuery>;
export type ManageCarePathwaysQueryResult = Apollo.QueryResult<ManageCarePathwaysQuery, ManageCarePathwaysQueryVariables>;
export const RegisterAsApproverDocument = gql`
    mutation RegisterAsApprover($integrationId: ID!) {
  registerAsEhrIntegrationAutoApprover(ehrIntegrationId: $integrationId) {
    id
    authorizedAutomaticApprover {
      id
      email
    }
  }
}
    `;
export type RegisterAsApproverMutationFn = Apollo.MutationFunction<RegisterAsApproverMutation, RegisterAsApproverMutationVariables>;

/**
 * __useRegisterAsApproverMutation__
 *
 * To run a mutation, you first call `useRegisterAsApproverMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterAsApproverMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerAsApproverMutation, { data, loading, error }] = useRegisterAsApproverMutation({
 *   variables: {
 *      integrationId: // value for 'integrationId'
 *   },
 * });
 */
export function useRegisterAsApproverMutation(baseOptions?: Apollo.MutationHookOptions<RegisterAsApproverMutation, RegisterAsApproverMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RegisterAsApproverMutation, RegisterAsApproverMutationVariables>(RegisterAsApproverDocument, options);
      }
export type RegisterAsApproverMutationHookResult = ReturnType<typeof useRegisterAsApproverMutation>;
export type RegisterAsApproverMutationResult = Apollo.MutationResult<RegisterAsApproverMutation>;
export type RegisterAsApproverMutationOptions = Apollo.BaseMutationOptions<RegisterAsApproverMutation, RegisterAsApproverMutationVariables>;
export const RemoveAutoApproverDocument = gql`
    mutation RemoveAutoApprover($integrationId: ID!) {
  removeEhrIntegrationAutoApprover(ehrIntegrationId: $integrationId) {
    id
  }
}
    `;
export type RemoveAutoApproverMutationFn = Apollo.MutationFunction<RemoveAutoApproverMutation, RemoveAutoApproverMutationVariables>;

/**
 * __useRemoveAutoApproverMutation__
 *
 * To run a mutation, you first call `useRemoveAutoApproverMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveAutoApproverMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeAutoApproverMutation, { data, loading, error }] = useRemoveAutoApproverMutation({
 *   variables: {
 *      integrationId: // value for 'integrationId'
 *   },
 * });
 */
export function useRemoveAutoApproverMutation(baseOptions?: Apollo.MutationHookOptions<RemoveAutoApproverMutation, RemoveAutoApproverMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveAutoApproverMutation, RemoveAutoApproverMutationVariables>(RemoveAutoApproverDocument, options);
      }
export type RemoveAutoApproverMutationHookResult = ReturnType<typeof useRemoveAutoApproverMutation>;
export type RemoveAutoApproverMutationResult = Apollo.MutationResult<RemoveAutoApproverMutation>;
export type RemoveAutoApproverMutationOptions = Apollo.BaseMutationOptions<RemoveAutoApproverMutation, RemoveAutoApproverMutationVariables>;
export const GetContinuousMonitoringSessionDocument = gql`
    query GetContinuousMonitoringSession($patientId: ID!) {
  continuousMonitoringSession(patientId: $patientId) {
    ...ContinuousMonitoringSessionItem
  }
}
    ${ContinuousMonitoringSessionItemFragmentDoc}`;

/**
 * __useGetContinuousMonitoringSessionQuery__
 *
 * To run a query within a React component, call `useGetContinuousMonitoringSessionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContinuousMonitoringSessionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContinuousMonitoringSessionQuery({
 *   variables: {
 *      patientId: // value for 'patientId'
 *   },
 * });
 */
export function useGetContinuousMonitoringSessionQuery(baseOptions: Apollo.QueryHookOptions<GetContinuousMonitoringSessionQuery, GetContinuousMonitoringSessionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetContinuousMonitoringSessionQuery, GetContinuousMonitoringSessionQueryVariables>(GetContinuousMonitoringSessionDocument, options);
      }
export function useGetContinuousMonitoringSessionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetContinuousMonitoringSessionQuery, GetContinuousMonitoringSessionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetContinuousMonitoringSessionQuery, GetContinuousMonitoringSessionQueryVariables>(GetContinuousMonitoringSessionDocument, options);
        }
export type GetContinuousMonitoringSessionQueryHookResult = ReturnType<typeof useGetContinuousMonitoringSessionQuery>;
export type GetContinuousMonitoringSessionLazyQueryHookResult = ReturnType<typeof useGetContinuousMonitoringSessionLazyQuery>;
export type GetContinuousMonitoringSessionQueryResult = Apollo.QueryResult<GetContinuousMonitoringSessionQuery, GetContinuousMonitoringSessionQueryVariables>;
export const DeletePatientDocument = gql`
    mutation DeletePatient($patientId: ID!) {
  deletePatient(patientId: $patientId)
}
    `;
export type DeletePatientMutationFn = Apollo.MutationFunction<DeletePatientMutation, DeletePatientMutationVariables>;

/**
 * __useDeletePatientMutation__
 *
 * To run a mutation, you first call `useDeletePatientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePatientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePatientMutation, { data, loading, error }] = useDeletePatientMutation({
 *   variables: {
 *      patientId: // value for 'patientId'
 *   },
 * });
 */
export function useDeletePatientMutation(baseOptions?: Apollo.MutationHookOptions<DeletePatientMutation, DeletePatientMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeletePatientMutation, DeletePatientMutationVariables>(DeletePatientDocument, options);
      }
export type DeletePatientMutationHookResult = ReturnType<typeof useDeletePatientMutation>;
export type DeletePatientMutationResult = Apollo.MutationResult<DeletePatientMutation>;
export type DeletePatientMutationOptions = Apollo.BaseMutationOptions<DeletePatientMutation, DeletePatientMutationVariables>;
export const NhsNumberEditableDocument = gql`
    query NhsNumberEditable($patientId: ID!) {
  patient(id: $patientId) {
    nhsNumberResponseDetails {
      editable
    }
  }
}
    `;

/**
 * __useNhsNumberEditableQuery__
 *
 * To run a query within a React component, call `useNhsNumberEditableQuery` and pass it any options that fit your needs.
 * When your component renders, `useNhsNumberEditableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNhsNumberEditableQuery({
 *   variables: {
 *      patientId: // value for 'patientId'
 *   },
 * });
 */
export function useNhsNumberEditableQuery(baseOptions: Apollo.QueryHookOptions<NhsNumberEditableQuery, NhsNumberEditableQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NhsNumberEditableQuery, NhsNumberEditableQueryVariables>(NhsNumberEditableDocument, options);
      }
export function useNhsNumberEditableLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NhsNumberEditableQuery, NhsNumberEditableQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NhsNumberEditableQuery, NhsNumberEditableQueryVariables>(NhsNumberEditableDocument, options);
        }
export type NhsNumberEditableQueryHookResult = ReturnType<typeof useNhsNumberEditableQuery>;
export type NhsNumberEditableLazyQueryHookResult = ReturnType<typeof useNhsNumberEditableLazyQuery>;
export type NhsNumberEditableQueryResult = Apollo.QueryResult<NhsNumberEditableQuery, NhsNumberEditableQueryVariables>;
export const UpdatePatientDocument = gql`
    mutation UpdatePatient($patient: UpdatePatientInput!) {
  patient: updatePatient(patient: $patient) {
    ...PatientDetails
  }
}
    ${PatientDetailsFragmentDoc}`;
export type UpdatePatientMutationFn = Apollo.MutationFunction<UpdatePatientMutation, UpdatePatientMutationVariables>;

/**
 * __useUpdatePatientMutation__
 *
 * To run a mutation, you first call `useUpdatePatientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePatientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePatientMutation, { data, loading, error }] = useUpdatePatientMutation({
 *   variables: {
 *      patient: // value for 'patient'
 *   },
 * });
 */
export function useUpdatePatientMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePatientMutation, UpdatePatientMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePatientMutation, UpdatePatientMutationVariables>(UpdatePatientDocument, options);
      }
export type UpdatePatientMutationHookResult = ReturnType<typeof useUpdatePatientMutation>;
export type UpdatePatientMutationResult = Apollo.MutationResult<UpdatePatientMutation>;
export type UpdatePatientMutationOptions = Apollo.BaseMutationOptions<UpdatePatientMutation, UpdatePatientMutationVariables>;
export const CreatePatientDocument = gql`
    mutation CreatePatient($patient: CreatePatientInput!) {
  patient: createPatient(patient: $patient) {
    ...PatientDetails
  }
}
    ${PatientDetailsFragmentDoc}`;
export type CreatePatientMutationFn = Apollo.MutationFunction<CreatePatientMutation, CreatePatientMutationVariables>;

/**
 * __useCreatePatientMutation__
 *
 * To run a mutation, you first call `useCreatePatientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePatientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPatientMutation, { data, loading, error }] = useCreatePatientMutation({
 *   variables: {
 *      patient: // value for 'patient'
 *   },
 * });
 */
export function useCreatePatientMutation(baseOptions?: Apollo.MutationHookOptions<CreatePatientMutation, CreatePatientMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePatientMutation, CreatePatientMutationVariables>(CreatePatientDocument, options);
      }
export type CreatePatientMutationHookResult = ReturnType<typeof useCreatePatientMutation>;
export type CreatePatientMutationResult = Apollo.MutationResult<CreatePatientMutation>;
export type CreatePatientMutationOptions = Apollo.BaseMutationOptions<CreatePatientMutation, CreatePatientMutationVariables>;
export const SetPasswordForSelfCareUserDocument = gql`
    mutation SetPasswordForSelfCareUser($userId: ID!, $password: String!) {
  setPasswordForSelfCareUser(userId: $userId, password: $password)
}
    `;
export type SetPasswordForSelfCareUserMutationFn = Apollo.MutationFunction<SetPasswordForSelfCareUserMutation, SetPasswordForSelfCareUserMutationVariables>;

/**
 * __useSetPasswordForSelfCareUserMutation__
 *
 * To run a mutation, you first call `useSetPasswordForSelfCareUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetPasswordForSelfCareUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setPasswordForSelfCareUserMutation, { data, loading, error }] = useSetPasswordForSelfCareUserMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useSetPasswordForSelfCareUserMutation(baseOptions?: Apollo.MutationHookOptions<SetPasswordForSelfCareUserMutation, SetPasswordForSelfCareUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetPasswordForSelfCareUserMutation, SetPasswordForSelfCareUserMutationVariables>(SetPasswordForSelfCareUserDocument, options);
      }
export type SetPasswordForSelfCareUserMutationHookResult = ReturnType<typeof useSetPasswordForSelfCareUserMutation>;
export type SetPasswordForSelfCareUserMutationResult = Apollo.MutationResult<SetPasswordForSelfCareUserMutation>;
export type SetPasswordForSelfCareUserMutationOptions = Apollo.BaseMutationOptions<SetPasswordForSelfCareUserMutation, SetPasswordForSelfCareUserMutationVariables>;
export const GetPatientsDocument = gql`
    query GetPatients {
  patients(isTestPatient: false) {
    ...ManagePatientItem
  }
}
    ${ManagePatientItemFragmentDoc}`;

/**
 * __useGetPatientsQuery__
 *
 * To run a query within a React component, call `useGetPatientsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPatientsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPatientsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPatientsQuery(baseOptions?: Apollo.QueryHookOptions<GetPatientsQuery, GetPatientsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPatientsQuery, GetPatientsQueryVariables>(GetPatientsDocument, options);
      }
export function useGetPatientsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPatientsQuery, GetPatientsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPatientsQuery, GetPatientsQueryVariables>(GetPatientsDocument, options);
        }
export type GetPatientsQueryHookResult = ReturnType<typeof useGetPatientsQuery>;
export type GetPatientsLazyQueryHookResult = ReturnType<typeof useGetPatientsLazyQuery>;
export type GetPatientsQueryResult = Apollo.QueryResult<GetPatientsQuery, GetPatientsQueryVariables>;
export const AssignStaffToWardDocument = gql`
    mutation AssignStaffToWard($wardId: ID!, $staffId: ID!) {
  assignStaffToWard(wardId: $wardId, userId: $staffId) {
    assignedAt
  }
}
    `;
export type AssignStaffToWardMutationFn = Apollo.MutationFunction<AssignStaffToWardMutation, AssignStaffToWardMutationVariables>;

/**
 * __useAssignStaffToWardMutation__
 *
 * To run a mutation, you first call `useAssignStaffToWardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignStaffToWardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignStaffToWardMutation, { data, loading, error }] = useAssignStaffToWardMutation({
 *   variables: {
 *      wardId: // value for 'wardId'
 *      staffId: // value for 'staffId'
 *   },
 * });
 */
export function useAssignStaffToWardMutation(baseOptions?: Apollo.MutationHookOptions<AssignStaffToWardMutation, AssignStaffToWardMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AssignStaffToWardMutation, AssignStaffToWardMutationVariables>(AssignStaffToWardDocument, options);
      }
export type AssignStaffToWardMutationHookResult = ReturnType<typeof useAssignStaffToWardMutation>;
export type AssignStaffToWardMutationResult = Apollo.MutationResult<AssignStaffToWardMutation>;
export type AssignStaffToWardMutationOptions = Apollo.BaseMutationOptions<AssignStaffToWardMutation, AssignStaffToWardMutationVariables>;
export const OrganizationUsersDocument = gql`
    query OrganizationUsers {
  userOrganizations {
    user {
      id
      email
      firstName
      lastName
    }
  }
}
    `;

/**
 * __useOrganizationUsersQuery__
 *
 * To run a query within a React component, call `useOrganizationUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useOrganizationUsersQuery(baseOptions?: Apollo.QueryHookOptions<OrganizationUsersQuery, OrganizationUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrganizationUsersQuery, OrganizationUsersQueryVariables>(OrganizationUsersDocument, options);
      }
export function useOrganizationUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrganizationUsersQuery, OrganizationUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrganizationUsersQuery, OrganizationUsersQueryVariables>(OrganizationUsersDocument, options);
        }
export type OrganizationUsersQueryHookResult = ReturnType<typeof useOrganizationUsersQuery>;
export type OrganizationUsersLazyQueryHookResult = ReturnType<typeof useOrganizationUsersLazyQuery>;
export type OrganizationUsersQueryResult = Apollo.QueryResult<OrganizationUsersQuery, OrganizationUsersQueryVariables>;
export const GetWardByIdDocument = gql`
    query GetWardById($id: ID!) {
  ward(id: $id) {
    id
    ...WardHeaderInfo
  }
}
    ${WardHeaderInfoFragmentDoc}`;

/**
 * __useGetWardByIdQuery__
 *
 * To run a query within a React component, call `useGetWardByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWardByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWardByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetWardByIdQuery(baseOptions: Apollo.QueryHookOptions<GetWardByIdQuery, GetWardByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetWardByIdQuery, GetWardByIdQueryVariables>(GetWardByIdDocument, options);
      }
export function useGetWardByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetWardByIdQuery, GetWardByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetWardByIdQuery, GetWardByIdQueryVariables>(GetWardByIdDocument, options);
        }
export type GetWardByIdQueryHookResult = ReturnType<typeof useGetWardByIdQuery>;
export type GetWardByIdLazyQueryHookResult = ReturnType<typeof useGetWardByIdLazyQuery>;
export type GetWardByIdQueryResult = Apollo.QueryResult<GetWardByIdQuery, GetWardByIdQueryVariables>;
export const RemoveStaffFromWardDocument = gql`
    mutation RemoveStaffFromWard($wardId: ID!, $userId: ID!) {
  removeStaffFromWard(wardId: $wardId, userId: $userId)
}
    `;
export type RemoveStaffFromWardMutationFn = Apollo.MutationFunction<RemoveStaffFromWardMutation, RemoveStaffFromWardMutationVariables>;

/**
 * __useRemoveStaffFromWardMutation__
 *
 * To run a mutation, you first call `useRemoveStaffFromWardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveStaffFromWardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeStaffFromWardMutation, { data, loading, error }] = useRemoveStaffFromWardMutation({
 *   variables: {
 *      wardId: // value for 'wardId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useRemoveStaffFromWardMutation(baseOptions?: Apollo.MutationHookOptions<RemoveStaffFromWardMutation, RemoveStaffFromWardMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveStaffFromWardMutation, RemoveStaffFromWardMutationVariables>(RemoveStaffFromWardDocument, options);
      }
export type RemoveStaffFromWardMutationHookResult = ReturnType<typeof useRemoveStaffFromWardMutation>;
export type RemoveStaffFromWardMutationResult = Apollo.MutationResult<RemoveStaffFromWardMutation>;
export type RemoveStaffFromWardMutationOptions = Apollo.BaseMutationOptions<RemoveStaffFromWardMutation, RemoveStaffFromWardMutationVariables>;
export const WardPatientsDocument = gql`
    query WardPatients($wardId: ID!) {
  ward(id: $wardId) {
    id
    wardPatients {
      ...WardPatientItem
    }
  }
}
    ${WardPatientItemFragmentDoc}`;

/**
 * __useWardPatientsQuery__
 *
 * To run a query within a React component, call `useWardPatientsQuery` and pass it any options that fit your needs.
 * When your component renders, `useWardPatientsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWardPatientsQuery({
 *   variables: {
 *      wardId: // value for 'wardId'
 *   },
 * });
 */
export function useWardPatientsQuery(baseOptions: Apollo.QueryHookOptions<WardPatientsQuery, WardPatientsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WardPatientsQuery, WardPatientsQueryVariables>(WardPatientsDocument, options);
      }
export function useWardPatientsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WardPatientsQuery, WardPatientsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WardPatientsQuery, WardPatientsQueryVariables>(WardPatientsDocument, options);
        }
export type WardPatientsQueryHookResult = ReturnType<typeof useWardPatientsQuery>;
export type WardPatientsLazyQueryHookResult = ReturnType<typeof useWardPatientsLazyQuery>;
export type WardPatientsQueryResult = Apollo.QueryResult<WardPatientsQuery, WardPatientsQueryVariables>;
export const WardStaffDocument = gql`
    query WardStaff($wardId: ID!) {
  ward(id: $wardId) {
    id
    wardStaff {
      ...WardStaffItem
    }
  }
}
    ${WardStaffItemFragmentDoc}`;

/**
 * __useWardStaffQuery__
 *
 * To run a query within a React component, call `useWardStaffQuery` and pass it any options that fit your needs.
 * When your component renders, `useWardStaffQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWardStaffQuery({
 *   variables: {
 *      wardId: // value for 'wardId'
 *   },
 * });
 */
export function useWardStaffQuery(baseOptions: Apollo.QueryHookOptions<WardStaffQuery, WardStaffQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WardStaffQuery, WardStaffQueryVariables>(WardStaffDocument, options);
      }
export function useWardStaffLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WardStaffQuery, WardStaffQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WardStaffQuery, WardStaffQueryVariables>(WardStaffDocument, options);
        }
export type WardStaffQueryHookResult = ReturnType<typeof useWardStaffQuery>;
export type WardStaffLazyQueryHookResult = ReturnType<typeof useWardStaffLazyQuery>;
export type WardStaffQueryResult = Apollo.QueryResult<WardStaffQuery, WardStaffQueryVariables>;
export const GetWardsDocument = gql`
    query GetWards {
  wards {
    ...ManageWardItem
  }
}
    ${ManageWardItemFragmentDoc}`;

/**
 * __useGetWardsQuery__
 *
 * To run a query within a React component, call `useGetWardsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWardsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWardsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetWardsQuery(baseOptions?: Apollo.QueryHookOptions<GetWardsQuery, GetWardsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetWardsQuery, GetWardsQueryVariables>(GetWardsDocument, options);
      }
export function useGetWardsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetWardsQuery, GetWardsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetWardsQuery, GetWardsQueryVariables>(GetWardsDocument, options);
        }
export type GetWardsQueryHookResult = ReturnType<typeof useGetWardsQuery>;
export type GetWardsLazyQueryHookResult = ReturnType<typeof useGetWardsLazyQuery>;
export type GetWardsQueryResult = Apollo.QueryResult<GetWardsQuery, GetWardsQueryVariables>;
export const CreateWardDocument = gql`
    mutation CreateWard($name: String!) {
  createWard(name: $name) {
    id
    name
    createdAt
    updatedAt
  }
}
    `;
export type CreateWardMutationFn = Apollo.MutationFunction<CreateWardMutation, CreateWardMutationVariables>;

/**
 * __useCreateWardMutation__
 *
 * To run a mutation, you first call `useCreateWardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateWardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createWardMutation, { data, loading, error }] = useCreateWardMutation({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useCreateWardMutation(baseOptions?: Apollo.MutationHookOptions<CreateWardMutation, CreateWardMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateWardMutation, CreateWardMutationVariables>(CreateWardDocument, options);
      }
export type CreateWardMutationHookResult = ReturnType<typeof useCreateWardMutation>;
export type CreateWardMutationResult = Apollo.MutationResult<CreateWardMutation>;
export type CreateWardMutationOptions = Apollo.BaseMutationOptions<CreateWardMutation, CreateWardMutationVariables>;
export const DeleteWardDocument = gql`
    mutation DeleteWard($id: ID!) {
  deleteWard(id: $id) {
    id
  }
}
    `;
export type DeleteWardMutationFn = Apollo.MutationFunction<DeleteWardMutation, DeleteWardMutationVariables>;

/**
 * __useDeleteWardMutation__
 *
 * To run a mutation, you first call `useDeleteWardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteWardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteWardMutation, { data, loading, error }] = useDeleteWardMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteWardMutation(baseOptions?: Apollo.MutationHookOptions<DeleteWardMutation, DeleteWardMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteWardMutation, DeleteWardMutationVariables>(DeleteWardDocument, options);
      }
export type DeleteWardMutationHookResult = ReturnType<typeof useDeleteWardMutation>;
export type DeleteWardMutationResult = Apollo.MutationResult<DeleteWardMutation>;
export type DeleteWardMutationOptions = Apollo.BaseMutationOptions<DeleteWardMutation, DeleteWardMutationVariables>;
export const EditWardDocument = gql`
    mutation EditWard($id: ID!, $name: String!) {
  updateWard(id: $id, name: $name) {
    id
    name
    createdAt
    updatedAt
  }
}
    `;
export type EditWardMutationFn = Apollo.MutationFunction<EditWardMutation, EditWardMutationVariables>;

/**
 * __useEditWardMutation__
 *
 * To run a mutation, you first call `useEditWardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditWardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editWardMutation, { data, loading, error }] = useEditWardMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useEditWardMutation(baseOptions?: Apollo.MutationHookOptions<EditWardMutation, EditWardMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditWardMutation, EditWardMutationVariables>(EditWardDocument, options);
      }
export type EditWardMutationHookResult = ReturnType<typeof useEditWardMutation>;
export type EditWardMutationResult = Apollo.MutationResult<EditWardMutation>;
export type EditWardMutationOptions = Apollo.BaseMutationOptions<EditWardMutation, EditWardMutationVariables>;
export const GetActionLogsDocument = gql`
    query GetActionLogs($patientId: ID!, $fromActionLogId: ID) {
  actionLogs(patientId: $patientId, fromActionLogId: $fromActionLogId) {
    logs {
      ...ActionLogItem
    }
    nextActionLogId
  }
}
    ${ActionLogItemFragmentDoc}`;

/**
 * __useGetActionLogsQuery__
 *
 * To run a query within a React component, call `useGetActionLogsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActionLogsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActionLogsQuery({
 *   variables: {
 *      patientId: // value for 'patientId'
 *      fromActionLogId: // value for 'fromActionLogId'
 *   },
 * });
 */
export function useGetActionLogsQuery(baseOptions: Apollo.QueryHookOptions<GetActionLogsQuery, GetActionLogsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetActionLogsQuery, GetActionLogsQueryVariables>(GetActionLogsDocument, options);
      }
export function useGetActionLogsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetActionLogsQuery, GetActionLogsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetActionLogsQuery, GetActionLogsQueryVariables>(GetActionLogsDocument, options);
        }
export type GetActionLogsQueryHookResult = ReturnType<typeof useGetActionLogsQuery>;
export type GetActionLogsLazyQueryHookResult = ReturnType<typeof useGetActionLogsLazyQuery>;
export type GetActionLogsQueryResult = Apollo.QueryResult<GetActionLogsQuery, GetActionLogsQueryVariables>;
export const GetPacsanaDailyMetricsDocument = gql`
    query GetPacsanaDailyMetrics($patientId: ID!, $startDate: Date, $endDate: Date) {
  pacsanaDailyMetricsForPatient(
    patientId: $patientId
    startDate: $startDate
    endDate: $endDate
  ) {
    ...ActivityMonitoringMetric
  }
}
    ${ActivityMonitoringMetricFragmentDoc}`;

/**
 * __useGetPacsanaDailyMetricsQuery__
 *
 * To run a query within a React component, call `useGetPacsanaDailyMetricsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPacsanaDailyMetricsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPacsanaDailyMetricsQuery({
 *   variables: {
 *      patientId: // value for 'patientId'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useGetPacsanaDailyMetricsQuery(baseOptions: Apollo.QueryHookOptions<GetPacsanaDailyMetricsQuery, GetPacsanaDailyMetricsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPacsanaDailyMetricsQuery, GetPacsanaDailyMetricsQueryVariables>(GetPacsanaDailyMetricsDocument, options);
      }
export function useGetPacsanaDailyMetricsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPacsanaDailyMetricsQuery, GetPacsanaDailyMetricsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPacsanaDailyMetricsQuery, GetPacsanaDailyMetricsQueryVariables>(GetPacsanaDailyMetricsDocument, options);
        }
export type GetPacsanaDailyMetricsQueryHookResult = ReturnType<typeof useGetPacsanaDailyMetricsQuery>;
export type GetPacsanaDailyMetricsLazyQueryHookResult = ReturnType<typeof useGetPacsanaDailyMetricsLazyQuery>;
export type GetPacsanaDailyMetricsQueryResult = Apollo.QueryResult<GetPacsanaDailyMetricsQuery, GetPacsanaDailyMetricsQueryVariables>;
export const GetPacsanaDailyMetricsMetadataDocument = gql`
    query GetPacsanaDailyMetricsMetadata($patientId: ID!) {
  pacsanaDailyMetricsMetadata(patientId: $patientId) {
    ...ActivityMonitoringMetadata
  }
}
    ${ActivityMonitoringMetadataFragmentDoc}`;

/**
 * __useGetPacsanaDailyMetricsMetadataQuery__
 *
 * To run a query within a React component, call `useGetPacsanaDailyMetricsMetadataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPacsanaDailyMetricsMetadataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPacsanaDailyMetricsMetadataQuery({
 *   variables: {
 *      patientId: // value for 'patientId'
 *   },
 * });
 */
export function useGetPacsanaDailyMetricsMetadataQuery(baseOptions: Apollo.QueryHookOptions<GetPacsanaDailyMetricsMetadataQuery, GetPacsanaDailyMetricsMetadataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPacsanaDailyMetricsMetadataQuery, GetPacsanaDailyMetricsMetadataQueryVariables>(GetPacsanaDailyMetricsMetadataDocument, options);
      }
export function useGetPacsanaDailyMetricsMetadataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPacsanaDailyMetricsMetadataQuery, GetPacsanaDailyMetricsMetadataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPacsanaDailyMetricsMetadataQuery, GetPacsanaDailyMetricsMetadataQueryVariables>(GetPacsanaDailyMetricsMetadataDocument, options);
        }
export type GetPacsanaDailyMetricsMetadataQueryHookResult = ReturnType<typeof useGetPacsanaDailyMetricsMetadataQuery>;
export type GetPacsanaDailyMetricsMetadataLazyQueryHookResult = ReturnType<typeof useGetPacsanaDailyMetricsMetadataLazyQuery>;
export type GetPacsanaDailyMetricsMetadataQueryResult = Apollo.QueryResult<GetPacsanaDailyMetricsMetadataQuery, GetPacsanaDailyMetricsMetadataQueryVariables>;
export const GetCarePathwayDetailsDocument = gql`
    query GetCarePathwayDetails($carePathwayId: ID!) {
  carePathway(id: $carePathwayId) {
    ...CarePathwayDetails
  }
}
    ${CarePathwayDetailsFragmentDoc}`;

/**
 * __useGetCarePathwayDetailsQuery__
 *
 * To run a query within a React component, call `useGetCarePathwayDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCarePathwayDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCarePathwayDetailsQuery({
 *   variables: {
 *      carePathwayId: // value for 'carePathwayId'
 *   },
 * });
 */
export function useGetCarePathwayDetailsQuery(baseOptions: Apollo.QueryHookOptions<GetCarePathwayDetailsQuery, GetCarePathwayDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCarePathwayDetailsQuery, GetCarePathwayDetailsQueryVariables>(GetCarePathwayDetailsDocument, options);
      }
export function useGetCarePathwayDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCarePathwayDetailsQuery, GetCarePathwayDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCarePathwayDetailsQuery, GetCarePathwayDetailsQueryVariables>(GetCarePathwayDetailsDocument, options);
        }
export type GetCarePathwayDetailsQueryHookResult = ReturnType<typeof useGetCarePathwayDetailsQuery>;
export type GetCarePathwayDetailsLazyQueryHookResult = ReturnType<typeof useGetCarePathwayDetailsLazyQuery>;
export type GetCarePathwayDetailsQueryResult = Apollo.QueryResult<GetCarePathwayDetailsQuery, GetCarePathwayDetailsQueryVariables>;
export const GetContinuousMonitoringDocument = gql`
    query GetContinuousMonitoring($patientId: ID!, $numPrevBuckets: Int!, $currentBucketStartAt: Date) {
  continuousMonitoring(
    patientId: $patientId
    numPrevBuckets: $numPrevBuckets
    currentBucketStartAt: $currentBucketStartAt
  ) {
    data {
      ...ContinuousMonitoringItem
    }
    from
    to
    earliest
    latest
  }
}
    ${ContinuousMonitoringItemFragmentDoc}`;

/**
 * __useGetContinuousMonitoringQuery__
 *
 * To run a query within a React component, call `useGetContinuousMonitoringQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContinuousMonitoringQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContinuousMonitoringQuery({
 *   variables: {
 *      patientId: // value for 'patientId'
 *      numPrevBuckets: // value for 'numPrevBuckets'
 *      currentBucketStartAt: // value for 'currentBucketStartAt'
 *   },
 * });
 */
export function useGetContinuousMonitoringQuery(baseOptions: Apollo.QueryHookOptions<GetContinuousMonitoringQuery, GetContinuousMonitoringQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetContinuousMonitoringQuery, GetContinuousMonitoringQueryVariables>(GetContinuousMonitoringDocument, options);
      }
export function useGetContinuousMonitoringLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetContinuousMonitoringQuery, GetContinuousMonitoringQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetContinuousMonitoringQuery, GetContinuousMonitoringQueryVariables>(GetContinuousMonitoringDocument, options);
        }
export type GetContinuousMonitoringQueryHookResult = ReturnType<typeof useGetContinuousMonitoringQuery>;
export type GetContinuousMonitoringLazyQueryHookResult = ReturnType<typeof useGetContinuousMonitoringLazyQuery>;
export type GetContinuousMonitoringQueryResult = Apollo.QueryResult<GetContinuousMonitoringQuery, GetContinuousMonitoringQueryVariables>;
export const EndPacsanaSessionDocument = gql`
    mutation EndPacsanaSession($patientId: ID!) {
  endPacsanaSession(patientId: $patientId) {
    id
  }
}
    `;
export type EndPacsanaSessionMutationFn = Apollo.MutationFunction<EndPacsanaSessionMutation, EndPacsanaSessionMutationVariables>;

/**
 * __useEndPacsanaSessionMutation__
 *
 * To run a mutation, you first call `useEndPacsanaSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEndPacsanaSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [endPacsanaSessionMutation, { data, loading, error }] = useEndPacsanaSessionMutation({
 *   variables: {
 *      patientId: // value for 'patientId'
 *   },
 * });
 */
export function useEndPacsanaSessionMutation(baseOptions?: Apollo.MutationHookOptions<EndPacsanaSessionMutation, EndPacsanaSessionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EndPacsanaSessionMutation, EndPacsanaSessionMutationVariables>(EndPacsanaSessionDocument, options);
      }
export type EndPacsanaSessionMutationHookResult = ReturnType<typeof useEndPacsanaSessionMutation>;
export type EndPacsanaSessionMutationResult = Apollo.MutationResult<EndPacsanaSessionMutation>;
export type EndPacsanaSessionMutationOptions = Apollo.BaseMutationOptions<EndPacsanaSessionMutation, EndPacsanaSessionMutationVariables>;
export const EndContinuousMonitoringSessionDocument = gql`
    mutation EndContinuousMonitoringSession($patientId: ID!) {
  endContinuousMonitoringSession(patientId: $patientId) {
    id
  }
}
    `;
export type EndContinuousMonitoringSessionMutationFn = Apollo.MutationFunction<EndContinuousMonitoringSessionMutation, EndContinuousMonitoringSessionMutationVariables>;

/**
 * __useEndContinuousMonitoringSessionMutation__
 *
 * To run a mutation, you first call `useEndContinuousMonitoringSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEndContinuousMonitoringSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [endContinuousMonitoringSessionMutation, { data, loading, error }] = useEndContinuousMonitoringSessionMutation({
 *   variables: {
 *      patientId: // value for 'patientId'
 *   },
 * });
 */
export function useEndContinuousMonitoringSessionMutation(baseOptions?: Apollo.MutationHookOptions<EndContinuousMonitoringSessionMutation, EndContinuousMonitoringSessionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EndContinuousMonitoringSessionMutation, EndContinuousMonitoringSessionMutationVariables>(EndContinuousMonitoringSessionDocument, options);
      }
export type EndContinuousMonitoringSessionMutationHookResult = ReturnType<typeof useEndContinuousMonitoringSessionMutation>;
export type EndContinuousMonitoringSessionMutationResult = Apollo.MutationResult<EndContinuousMonitoringSessionMutation>;
export type EndContinuousMonitoringSessionMutationOptions = Apollo.BaseMutationOptions<EndContinuousMonitoringSessionMutation, EndContinuousMonitoringSessionMutationVariables>;
export const PatientNotesDocument = gql`
    query PatientNotes($PatientId: ID!) {
  patient(id: $PatientId) {
    id
    createdAt
    firstName
    lastName
    notes {
      ...PatientNote
    }
    selfCare {
      id
    }
  }
}
    ${PatientNoteFragmentDoc}`;

/**
 * __usePatientNotesQuery__
 *
 * To run a query within a React component, call `usePatientNotesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePatientNotesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePatientNotesQuery({
 *   variables: {
 *      PatientId: // value for 'PatientId'
 *   },
 * });
 */
export function usePatientNotesQuery(baseOptions: Apollo.QueryHookOptions<PatientNotesQuery, PatientNotesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PatientNotesQuery, PatientNotesQueryVariables>(PatientNotesDocument, options);
      }
export function usePatientNotesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PatientNotesQuery, PatientNotesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PatientNotesQuery, PatientNotesQueryVariables>(PatientNotesDocument, options);
        }
export type PatientNotesQueryHookResult = ReturnType<typeof usePatientNotesQuery>;
export type PatientNotesLazyQueryHookResult = ReturnType<typeof usePatientNotesLazyQuery>;
export type PatientNotesQueryResult = Apollo.QueryResult<PatientNotesQuery, PatientNotesQueryVariables>;
export const AddPatientNoteDocument = gql`
    mutation AddPatientNote($PatientId: ID!, $text: String!) {
  addPatientNote(PatientId: $PatientId, text: $text) {
    id
  }
}
    `;
export type AddPatientNoteMutationFn = Apollo.MutationFunction<AddPatientNoteMutation, AddPatientNoteMutationVariables>;

/**
 * __useAddPatientNoteMutation__
 *
 * To run a mutation, you first call `useAddPatientNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddPatientNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addPatientNoteMutation, { data, loading, error }] = useAddPatientNoteMutation({
 *   variables: {
 *      PatientId: // value for 'PatientId'
 *      text: // value for 'text'
 *   },
 * });
 */
export function useAddPatientNoteMutation(baseOptions?: Apollo.MutationHookOptions<AddPatientNoteMutation, AddPatientNoteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddPatientNoteMutation, AddPatientNoteMutationVariables>(AddPatientNoteDocument, options);
      }
export type AddPatientNoteMutationHookResult = ReturnType<typeof useAddPatientNoteMutation>;
export type AddPatientNoteMutationResult = Apollo.MutationResult<AddPatientNoteMutation>;
export type AddPatientNoteMutationOptions = Apollo.BaseMutationOptions<AddPatientNoteMutation, AddPatientNoteMutationVariables>;
export const PatientReportDataDocument = gql`
    query PatientReportData($patientId: ID!, $startDate: Date!, $endDate: Date!, $aggregateIntervalHours: Int, $queryAggregates: Boolean!, $querySoftSignsAggregates: Boolean!, $queryCheckups: Boolean!, $queryNotes: Boolean!, $queryAlerts: Boolean!, $queryWardAdmissions: Boolean!) {
  patient(id: $patientId) {
    ...PatientReportPatientData
    wardDischarges @include(if: $queryWardAdmissions) {
      ...PatientReportDischargeData
    }
    wardAdmission @include(if: $queryWardAdmissions) {
      ...PatientReportAdmissionData
    }
    notes(startDate: $startDate, endDate: $endDate) @include(if: $queryNotes) {
      ...PatientReportNotesData
    }
    vitalsSummary(
      startDate: $startDate
      endDate: $endDate
      intervalHours: $aggregateIntervalHours
    ) @include(if: $queryAggregates) {
      frames {
        ...PatientReportVitalsAggregate
      }
    }
    softSignsSummary(startDate: $startDate, endDate: $endDate, intervalHours: 24) @include(if: $querySoftSignsAggregates) {
      frames {
        ...PatientReportSoftSignsAggregate
      }
    }
  }
  checkupsByPatient(
    patientId: $patientId
    startDate: $startDate
    endDate: $endDate
  ) @include(if: $queryCheckups) {
    ...PatientReportCheckupData
  }
  alerts(
    patientId: $patientId
    startDate: $startDate
    endDate: $endDate
    type: "AlertRule"
  ) @include(if: $queryAlerts) {
    ...PatientReportAlertsData
  }
}
    ${PatientReportPatientDataFragmentDoc}
${PatientReportDischargeDataFragmentDoc}
${PatientReportAdmissionDataFragmentDoc}
${PatientReportNotesDataFragmentDoc}
${PatientReportVitalsAggregateFragmentDoc}
${PatientReportSoftSignsAggregateFragmentDoc}
${PatientReportCheckupDataFragmentDoc}
${PatientReportAlertsDataFragmentDoc}`;

/**
 * __usePatientReportDataQuery__
 *
 * To run a query within a React component, call `usePatientReportDataQuery` and pass it any options that fit your needs.
 * When your component renders, `usePatientReportDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePatientReportDataQuery({
 *   variables: {
 *      patientId: // value for 'patientId'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      aggregateIntervalHours: // value for 'aggregateIntervalHours'
 *      queryAggregates: // value for 'queryAggregates'
 *      querySoftSignsAggregates: // value for 'querySoftSignsAggregates'
 *      queryCheckups: // value for 'queryCheckups'
 *      queryNotes: // value for 'queryNotes'
 *      queryAlerts: // value for 'queryAlerts'
 *      queryWardAdmissions: // value for 'queryWardAdmissions'
 *   },
 * });
 */
export function usePatientReportDataQuery(baseOptions: Apollo.QueryHookOptions<PatientReportDataQuery, PatientReportDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PatientReportDataQuery, PatientReportDataQueryVariables>(PatientReportDataDocument, options);
      }
export function usePatientReportDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PatientReportDataQuery, PatientReportDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PatientReportDataQuery, PatientReportDataQueryVariables>(PatientReportDataDocument, options);
        }
export type PatientReportDataQueryHookResult = ReturnType<typeof usePatientReportDataQuery>;
export type PatientReportDataLazyQueryHookResult = ReturnType<typeof usePatientReportDataLazyQuery>;
export type PatientReportDataQueryResult = Apollo.QueryResult<PatientReportDataQuery, PatientReportDataQueryVariables>;
export const GetPacsanaKitsWithActiveSessionDocument = gql`
    query GetPacsanaKitsWithActiveSession {
  pacsanaKitsWithActiveSession {
    kitId
    activeSession {
      id
      patient {
        firstName
        lastName
      }
    }
  }
}
    `;

/**
 * __useGetPacsanaKitsWithActiveSessionQuery__
 *
 * To run a query within a React component, call `useGetPacsanaKitsWithActiveSessionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPacsanaKitsWithActiveSessionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPacsanaKitsWithActiveSessionQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPacsanaKitsWithActiveSessionQuery(baseOptions?: Apollo.QueryHookOptions<GetPacsanaKitsWithActiveSessionQuery, GetPacsanaKitsWithActiveSessionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPacsanaKitsWithActiveSessionQuery, GetPacsanaKitsWithActiveSessionQueryVariables>(GetPacsanaKitsWithActiveSessionDocument, options);
      }
export function useGetPacsanaKitsWithActiveSessionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPacsanaKitsWithActiveSessionQuery, GetPacsanaKitsWithActiveSessionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPacsanaKitsWithActiveSessionQuery, GetPacsanaKitsWithActiveSessionQueryVariables>(GetPacsanaKitsWithActiveSessionDocument, options);
        }
export type GetPacsanaKitsWithActiveSessionQueryHookResult = ReturnType<typeof useGetPacsanaKitsWithActiveSessionQuery>;
export type GetPacsanaKitsWithActiveSessionLazyQueryHookResult = ReturnType<typeof useGetPacsanaKitsWithActiveSessionLazyQuery>;
export type GetPacsanaKitsWithActiveSessionQueryResult = Apollo.QueryResult<GetPacsanaKitsWithActiveSessionQuery, GetPacsanaKitsWithActiveSessionQueryVariables>;
export const StartPacsanaSessionDocument = gql`
    mutation StartPacsanaSession($pacsanaUserName: String!, $patientId: ID!) {
  startPacsanaSession(pacsanaUserName: $pacsanaUserName, patientId: $patientId) {
    id
  }
}
    `;
export type StartPacsanaSessionMutationFn = Apollo.MutationFunction<StartPacsanaSessionMutation, StartPacsanaSessionMutationVariables>;

/**
 * __useStartPacsanaSessionMutation__
 *
 * To run a mutation, you first call `useStartPacsanaSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartPacsanaSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startPacsanaSessionMutation, { data, loading, error }] = useStartPacsanaSessionMutation({
 *   variables: {
 *      pacsanaUserName: // value for 'pacsanaUserName'
 *      patientId: // value for 'patientId'
 *   },
 * });
 */
export function useStartPacsanaSessionMutation(baseOptions?: Apollo.MutationHookOptions<StartPacsanaSessionMutation, StartPacsanaSessionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<StartPacsanaSessionMutation, StartPacsanaSessionMutationVariables>(StartPacsanaSessionDocument, options);
      }
export type StartPacsanaSessionMutationHookResult = ReturnType<typeof useStartPacsanaSessionMutation>;
export type StartPacsanaSessionMutationResult = Apollo.MutationResult<StartPacsanaSessionMutation>;
export type StartPacsanaSessionMutationOptions = Apollo.BaseMutationOptions<StartPacsanaSessionMutation, StartPacsanaSessionMutationVariables>;
export const GetVivalinkKitsWithActiveSessionDocument = gql`
    query GetVivalinkKitsWithActiveSession {
  vivalinkKitsWithActiveSession {
    kitId
    activeSession {
      id
      patient {
        firstName
        lastName
      }
    }
  }
}
    `;

/**
 * __useGetVivalinkKitsWithActiveSessionQuery__
 *
 * To run a query within a React component, call `useGetVivalinkKitsWithActiveSessionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVivalinkKitsWithActiveSessionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVivalinkKitsWithActiveSessionQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetVivalinkKitsWithActiveSessionQuery(baseOptions?: Apollo.QueryHookOptions<GetVivalinkKitsWithActiveSessionQuery, GetVivalinkKitsWithActiveSessionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetVivalinkKitsWithActiveSessionQuery, GetVivalinkKitsWithActiveSessionQueryVariables>(GetVivalinkKitsWithActiveSessionDocument, options);
      }
export function useGetVivalinkKitsWithActiveSessionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetVivalinkKitsWithActiveSessionQuery, GetVivalinkKitsWithActiveSessionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetVivalinkKitsWithActiveSessionQuery, GetVivalinkKitsWithActiveSessionQueryVariables>(GetVivalinkKitsWithActiveSessionDocument, options);
        }
export type GetVivalinkKitsWithActiveSessionQueryHookResult = ReturnType<typeof useGetVivalinkKitsWithActiveSessionQuery>;
export type GetVivalinkKitsWithActiveSessionLazyQueryHookResult = ReturnType<typeof useGetVivalinkKitsWithActiveSessionLazyQuery>;
export type GetVivalinkKitsWithActiveSessionQueryResult = Apollo.QueryResult<GetVivalinkKitsWithActiveSessionQuery, GetVivalinkKitsWithActiveSessionQueryVariables>;
export const StartContinuousMonitoringSessionDocument = gql`
    mutation StartContinuousMonitoringSession($vivalinkSubjectId: String!, $patientId: ID!) {
  startContinuousMonitoringSession(
    vivalinkSubjectId: $vivalinkSubjectId
    patientId: $patientId
  ) {
    id
  }
}
    `;
export type StartContinuousMonitoringSessionMutationFn = Apollo.MutationFunction<StartContinuousMonitoringSessionMutation, StartContinuousMonitoringSessionMutationVariables>;

/**
 * __useStartContinuousMonitoringSessionMutation__
 *
 * To run a mutation, you first call `useStartContinuousMonitoringSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartContinuousMonitoringSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startContinuousMonitoringSessionMutation, { data, loading, error }] = useStartContinuousMonitoringSessionMutation({
 *   variables: {
 *      vivalinkSubjectId: // value for 'vivalinkSubjectId'
 *      patientId: // value for 'patientId'
 *   },
 * });
 */
export function useStartContinuousMonitoringSessionMutation(baseOptions?: Apollo.MutationHookOptions<StartContinuousMonitoringSessionMutation, StartContinuousMonitoringSessionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<StartContinuousMonitoringSessionMutation, StartContinuousMonitoringSessionMutationVariables>(StartContinuousMonitoringSessionDocument, options);
      }
export type StartContinuousMonitoringSessionMutationHookResult = ReturnType<typeof useStartContinuousMonitoringSessionMutation>;
export type StartContinuousMonitoringSessionMutationResult = Apollo.MutationResult<StartContinuousMonitoringSessionMutation>;
export type StartContinuousMonitoringSessionMutationOptions = Apollo.BaseMutationOptions<StartContinuousMonitoringSessionMutation, StartContinuousMonitoringSessionMutationVariables>;
export const GetPatientCheckupsDocument = gql`
    query GetPatientCheckups($patientId: ID!) {
  checkupsByPatient(patientId: $patientId) {
    ...PatientCheckupsTableItem
  }
}
    ${PatientCheckupsTableItemFragmentDoc}`;

/**
 * __useGetPatientCheckupsQuery__
 *
 * To run a query within a React component, call `useGetPatientCheckupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPatientCheckupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPatientCheckupsQuery({
 *   variables: {
 *      patientId: // value for 'patientId'
 *   },
 * });
 */
export function useGetPatientCheckupsQuery(baseOptions: Apollo.QueryHookOptions<GetPatientCheckupsQuery, GetPatientCheckupsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPatientCheckupsQuery, GetPatientCheckupsQueryVariables>(GetPatientCheckupsDocument, options);
      }
export function useGetPatientCheckupsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPatientCheckupsQuery, GetPatientCheckupsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPatientCheckupsQuery, GetPatientCheckupsQueryVariables>(GetPatientCheckupsDocument, options);
        }
export type GetPatientCheckupsQueryHookResult = ReturnType<typeof useGetPatientCheckupsQuery>;
export type GetPatientCheckupsLazyQueryHookResult = ReturnType<typeof useGetPatientCheckupsLazyQuery>;
export type GetPatientCheckupsQueryResult = Apollo.QueryResult<GetPatientCheckupsQuery, GetPatientCheckupsQueryVariables>;
export const GetPatientDetailsDocument = gql`
    query GetPatientDetails($patientId: ID!) {
  patient(id: $patientId) {
    ...PatientDetails
    ...DischargePatientDetails
  }
}
    ${PatientDetailsFragmentDoc}
${DischargePatientDetailsFragmentDoc}`;

/**
 * __useGetPatientDetailsQuery__
 *
 * To run a query within a React component, call `useGetPatientDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPatientDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPatientDetailsQuery({
 *   variables: {
 *      patientId: // value for 'patientId'
 *   },
 * });
 */
export function useGetPatientDetailsQuery(baseOptions: Apollo.QueryHookOptions<GetPatientDetailsQuery, GetPatientDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPatientDetailsQuery, GetPatientDetailsQueryVariables>(GetPatientDetailsDocument, options);
      }
export function useGetPatientDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPatientDetailsQuery, GetPatientDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPatientDetailsQuery, GetPatientDetailsQueryVariables>(GetPatientDetailsDocument, options);
        }
export type GetPatientDetailsQueryHookResult = ReturnType<typeof useGetPatientDetailsQuery>;
export type GetPatientDetailsLazyQueryHookResult = ReturnType<typeof useGetPatientDetailsLazyQuery>;
export type GetPatientDetailsQueryResult = Apollo.QueryResult<GetPatientDetailsQuery, GetPatientDetailsQueryVariables>;
export const SettingsApiKeysDocument = gql`
    query SettingsApiKeys {
  integrationApiKeys {
    ...ApiKey
  }
}
    ${ApiKeyFragmentDoc}`;

/**
 * __useSettingsApiKeysQuery__
 *
 * To run a query within a React component, call `useSettingsApiKeysQuery` and pass it any options that fit your needs.
 * When your component renders, `useSettingsApiKeysQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSettingsApiKeysQuery({
 *   variables: {
 *   },
 * });
 */
export function useSettingsApiKeysQuery(baseOptions?: Apollo.QueryHookOptions<SettingsApiKeysQuery, SettingsApiKeysQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SettingsApiKeysQuery, SettingsApiKeysQueryVariables>(SettingsApiKeysDocument, options);
      }
export function useSettingsApiKeysLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SettingsApiKeysQuery, SettingsApiKeysQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SettingsApiKeysQuery, SettingsApiKeysQueryVariables>(SettingsApiKeysDocument, options);
        }
export type SettingsApiKeysQueryHookResult = ReturnType<typeof useSettingsApiKeysQuery>;
export type SettingsApiKeysLazyQueryHookResult = ReturnType<typeof useSettingsApiKeysLazyQuery>;
export type SettingsApiKeysQueryResult = Apollo.QueryResult<SettingsApiKeysQuery, SettingsApiKeysQueryVariables>;
export const SettingsUndoExpireApiKeyDocument = gql`
    mutation SettingsUndoExpireApiKey($id: ID!) {
  undoExpireIntegrationApiKey(id: $id)
}
    `;
export type SettingsUndoExpireApiKeyMutationFn = Apollo.MutationFunction<SettingsUndoExpireApiKeyMutation, SettingsUndoExpireApiKeyMutationVariables>;

/**
 * __useSettingsUndoExpireApiKeyMutation__
 *
 * To run a mutation, you first call `useSettingsUndoExpireApiKeyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSettingsUndoExpireApiKeyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [settingsUndoExpireApiKeyMutation, { data, loading, error }] = useSettingsUndoExpireApiKeyMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSettingsUndoExpireApiKeyMutation(baseOptions?: Apollo.MutationHookOptions<SettingsUndoExpireApiKeyMutation, SettingsUndoExpireApiKeyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SettingsUndoExpireApiKeyMutation, SettingsUndoExpireApiKeyMutationVariables>(SettingsUndoExpireApiKeyDocument, options);
      }
export type SettingsUndoExpireApiKeyMutationHookResult = ReturnType<typeof useSettingsUndoExpireApiKeyMutation>;
export type SettingsUndoExpireApiKeyMutationResult = Apollo.MutationResult<SettingsUndoExpireApiKeyMutation>;
export type SettingsUndoExpireApiKeyMutationOptions = Apollo.BaseMutationOptions<SettingsUndoExpireApiKeyMutation, SettingsUndoExpireApiKeyMutationVariables>;
export const SettingsPatientSharingDocument = gql`
    query SettingsPatientSharing {
  shareTokens {
    ...SettingsShareToken
  }
}
    ${SettingsShareTokenFragmentDoc}`;

/**
 * __useSettingsPatientSharingQuery__
 *
 * To run a query within a React component, call `useSettingsPatientSharingQuery` and pass it any options that fit your needs.
 * When your component renders, `useSettingsPatientSharingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSettingsPatientSharingQuery({
 *   variables: {
 *   },
 * });
 */
export function useSettingsPatientSharingQuery(baseOptions?: Apollo.QueryHookOptions<SettingsPatientSharingQuery, SettingsPatientSharingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SettingsPatientSharingQuery, SettingsPatientSharingQueryVariables>(SettingsPatientSharingDocument, options);
      }
export function useSettingsPatientSharingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SettingsPatientSharingQuery, SettingsPatientSharingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SettingsPatientSharingQuery, SettingsPatientSharingQueryVariables>(SettingsPatientSharingDocument, options);
        }
export type SettingsPatientSharingQueryHookResult = ReturnType<typeof useSettingsPatientSharingQuery>;
export type SettingsPatientSharingLazyQueryHookResult = ReturnType<typeof useSettingsPatientSharingLazyQuery>;
export type SettingsPatientSharingQueryResult = Apollo.QueryResult<SettingsPatientSharingQuery, SettingsPatientSharingQueryVariables>;
export const UserSettingsUsersDocument = gql`
    query UserSettingsUsers {
  userOrganizations {
    ...UserSettings
  }
}
    ${UserSettingsFragmentDoc}`;

/**
 * __useUserSettingsUsersQuery__
 *
 * To run a query within a React component, call `useUserSettingsUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserSettingsUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserSettingsUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserSettingsUsersQuery(baseOptions?: Apollo.QueryHookOptions<UserSettingsUsersQuery, UserSettingsUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserSettingsUsersQuery, UserSettingsUsersQueryVariables>(UserSettingsUsersDocument, options);
      }
export function useUserSettingsUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserSettingsUsersQuery, UserSettingsUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserSettingsUsersQuery, UserSettingsUsersQueryVariables>(UserSettingsUsersDocument, options);
        }
export type UserSettingsUsersQueryHookResult = ReturnType<typeof useUserSettingsUsersQuery>;
export type UserSettingsUsersLazyQueryHookResult = ReturnType<typeof useUserSettingsUsersLazyQuery>;
export type UserSettingsUsersQueryResult = Apollo.QueryResult<UserSettingsUsersQuery, UserSettingsUsersQueryVariables>;
export const UnenrollFromTwoFactorAuthDocument = gql`
    mutation unenrollFromTwoFactorAuth($userId: ID!) {
  unenrollFromTwoFactorAuth(userId: $userId)
}
    `;
export type UnenrollFromTwoFactorAuthMutationFn = Apollo.MutationFunction<UnenrollFromTwoFactorAuthMutation, UnenrollFromTwoFactorAuthMutationVariables>;

/**
 * __useUnenrollFromTwoFactorAuthMutation__
 *
 * To run a mutation, you first call `useUnenrollFromTwoFactorAuthMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnenrollFromTwoFactorAuthMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unenrollFromTwoFactorAuthMutation, { data, loading, error }] = useUnenrollFromTwoFactorAuthMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useUnenrollFromTwoFactorAuthMutation(baseOptions?: Apollo.MutationHookOptions<UnenrollFromTwoFactorAuthMutation, UnenrollFromTwoFactorAuthMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UnenrollFromTwoFactorAuthMutation, UnenrollFromTwoFactorAuthMutationVariables>(UnenrollFromTwoFactorAuthDocument, options);
      }
export type UnenrollFromTwoFactorAuthMutationHookResult = ReturnType<typeof useUnenrollFromTwoFactorAuthMutation>;
export type UnenrollFromTwoFactorAuthMutationResult = Apollo.MutationResult<UnenrollFromTwoFactorAuthMutation>;
export type UnenrollFromTwoFactorAuthMutationOptions = Apollo.BaseMutationOptions<UnenrollFromTwoFactorAuthMutation, UnenrollFromTwoFactorAuthMutationVariables>;
export const SettingsCreateApiKeyDocument = gql`
    mutation SettingsCreateApiKey {
  createIntegrationApiKey {
    ...CreatedApiKey
  }
}
    ${CreatedApiKeyFragmentDoc}`;
export type SettingsCreateApiKeyMutationFn = Apollo.MutationFunction<SettingsCreateApiKeyMutation, SettingsCreateApiKeyMutationVariables>;

/**
 * __useSettingsCreateApiKeyMutation__
 *
 * To run a mutation, you first call `useSettingsCreateApiKeyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSettingsCreateApiKeyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [settingsCreateApiKeyMutation, { data, loading, error }] = useSettingsCreateApiKeyMutation({
 *   variables: {
 *   },
 * });
 */
export function useSettingsCreateApiKeyMutation(baseOptions?: Apollo.MutationHookOptions<SettingsCreateApiKeyMutation, SettingsCreateApiKeyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SettingsCreateApiKeyMutation, SettingsCreateApiKeyMutationVariables>(SettingsCreateApiKeyDocument, options);
      }
export type SettingsCreateApiKeyMutationHookResult = ReturnType<typeof useSettingsCreateApiKeyMutation>;
export type SettingsCreateApiKeyMutationResult = Apollo.MutationResult<SettingsCreateApiKeyMutation>;
export type SettingsCreateApiKeyMutationOptions = Apollo.BaseMutationOptions<SettingsCreateApiKeyMutation, SettingsCreateApiKeyMutationVariables>;
export const SettingsExpireApiKeyDocument = gql`
    mutation SettingsExpireApiKey($id: ID!, $expiresAt: Date) {
  expireIntegrationApiKey(id: $id, at: $expiresAt)
}
    `;
export type SettingsExpireApiKeyMutationFn = Apollo.MutationFunction<SettingsExpireApiKeyMutation, SettingsExpireApiKeyMutationVariables>;

/**
 * __useSettingsExpireApiKeyMutation__
 *
 * To run a mutation, you first call `useSettingsExpireApiKeyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSettingsExpireApiKeyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [settingsExpireApiKeyMutation, { data, loading, error }] = useSettingsExpireApiKeyMutation({
 *   variables: {
 *      id: // value for 'id'
 *      expiresAt: // value for 'expiresAt'
 *   },
 * });
 */
export function useSettingsExpireApiKeyMutation(baseOptions?: Apollo.MutationHookOptions<SettingsExpireApiKeyMutation, SettingsExpireApiKeyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SettingsExpireApiKeyMutation, SettingsExpireApiKeyMutationVariables>(SettingsExpireApiKeyDocument, options);
      }
export type SettingsExpireApiKeyMutationHookResult = ReturnType<typeof useSettingsExpireApiKeyMutation>;
export type SettingsExpireApiKeyMutationResult = Apollo.MutationResult<SettingsExpireApiKeyMutation>;
export type SettingsExpireApiKeyMutationOptions = Apollo.BaseMutationOptions<SettingsExpireApiKeyMutation, SettingsExpireApiKeyMutationVariables>;
export const UpdateUserOrganizationDocument = gql`
    mutation UpdateUserOrganization($userId: ID!, $roles: [String!]!) {
  updateUserOrganization(userId: $userId, roles: $roles) {
    user {
      id
    }
  }
}
    `;
export type UpdateUserOrganizationMutationFn = Apollo.MutationFunction<UpdateUserOrganizationMutation, UpdateUserOrganizationMutationVariables>;

/**
 * __useUpdateUserOrganizationMutation__
 *
 * To run a mutation, you first call `useUpdateUserOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserOrganizationMutation, { data, loading, error }] = useUpdateUserOrganizationMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      roles: // value for 'roles'
 *   },
 * });
 */
export function useUpdateUserOrganizationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserOrganizationMutation, UpdateUserOrganizationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserOrganizationMutation, UpdateUserOrganizationMutationVariables>(UpdateUserOrganizationDocument, options);
      }
export type UpdateUserOrganizationMutationHookResult = ReturnType<typeof useUpdateUserOrganizationMutation>;
export type UpdateUserOrganizationMutationResult = Apollo.MutationResult<UpdateUserOrganizationMutation>;
export type UpdateUserOrganizationMutationOptions = Apollo.BaseMutationOptions<UpdateUserOrganizationMutation, UpdateUserOrganizationMutationVariables>;
export const GetVirtualWardFilterValuesDocument = gql`
    query GetVirtualWardFilterValues($isActingOrgPractice: Boolean!, $wardsEnabled: Boolean!) {
  me {
    wards @include(if: $wardsEnabled) {
      ...UserWard
    }
    actingOrganization {
      neighbors @include(if: $isActingOrgPractice) {
        ...UserNeighbor
      }
    }
  }
}
    ${UserWardFragmentDoc}
${UserNeighborFragmentDoc}`;

/**
 * __useGetVirtualWardFilterValuesQuery__
 *
 * To run a query within a React component, call `useGetVirtualWardFilterValuesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVirtualWardFilterValuesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVirtualWardFilterValuesQuery({
 *   variables: {
 *      isActingOrgPractice: // value for 'isActingOrgPractice'
 *      wardsEnabled: // value for 'wardsEnabled'
 *   },
 * });
 */
export function useGetVirtualWardFilterValuesQuery(baseOptions: Apollo.QueryHookOptions<GetVirtualWardFilterValuesQuery, GetVirtualWardFilterValuesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetVirtualWardFilterValuesQuery, GetVirtualWardFilterValuesQueryVariables>(GetVirtualWardFilterValuesDocument, options);
      }
export function useGetVirtualWardFilterValuesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetVirtualWardFilterValuesQuery, GetVirtualWardFilterValuesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetVirtualWardFilterValuesQuery, GetVirtualWardFilterValuesQueryVariables>(GetVirtualWardFilterValuesDocument, options);
        }
export type GetVirtualWardFilterValuesQueryHookResult = ReturnType<typeof useGetVirtualWardFilterValuesQuery>;
export type GetVirtualWardFilterValuesLazyQueryHookResult = ReturnType<typeof useGetVirtualWardFilterValuesLazyQuery>;
export type GetVirtualWardFilterValuesQueryResult = Apollo.QueryResult<GetVirtualWardFilterValuesQuery, GetVirtualWardFilterValuesQueryVariables>;
export const GetVirtualWardPatientsDocument = gql`
    query GetVirtualWardPatients($wardIds: [ID!]!, $neighborIds: [ID!], $take: Int, $skip: Int, $nameOrNhsNumber: String, $withCheckupsInLastHours: Int, $sort: [VirtualWardPatientsSortInput]) {
  virtualWardPatients(
    wardIds: $wardIds
    neighborIds: $neighborIds
    take: $take
    skip: $skip
    nameOrNhsNumber: $nameOrNhsNumber
    withCheckupsInLastHours: $withCheckupsInLastHours
    sort: $sort
  ) {
    patients {
      ...VirtualWardPatientItem
    }
    total
  }
}
    ${VirtualWardPatientItemFragmentDoc}`;

/**
 * __useGetVirtualWardPatientsQuery__
 *
 * To run a query within a React component, call `useGetVirtualWardPatientsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVirtualWardPatientsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVirtualWardPatientsQuery({
 *   variables: {
 *      wardIds: // value for 'wardIds'
 *      neighborIds: // value for 'neighborIds'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *      nameOrNhsNumber: // value for 'nameOrNhsNumber'
 *      withCheckupsInLastHours: // value for 'withCheckupsInLastHours'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useGetVirtualWardPatientsQuery(baseOptions: Apollo.QueryHookOptions<GetVirtualWardPatientsQuery, GetVirtualWardPatientsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetVirtualWardPatientsQuery, GetVirtualWardPatientsQueryVariables>(GetVirtualWardPatientsDocument, options);
      }
export function useGetVirtualWardPatientsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetVirtualWardPatientsQuery, GetVirtualWardPatientsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetVirtualWardPatientsQuery, GetVirtualWardPatientsQueryVariables>(GetVirtualWardPatientsDocument, options);
        }
export type GetVirtualWardPatientsQueryHookResult = ReturnType<typeof useGetVirtualWardPatientsQuery>;
export type GetVirtualWardPatientsLazyQueryHookResult = ReturnType<typeof useGetVirtualWardPatientsLazyQuery>;
export type GetVirtualWardPatientsQueryResult = Apollo.QueryResult<GetVirtualWardPatientsQuery, GetVirtualWardPatientsQueryVariables>;
export const GetAlertsDocument = gql`
    query GetAlerts($patientId: ID!, $alertRuleId: ID, $checkupScheduleId: ID, $pacsanaEventId: Int, $type: String) {
  alerts(
    patientId: $patientId
    includeRecentlyClosed: true
    alertRuleId: $alertRuleId
    checkupScheduleId: $checkupScheduleId
    pacsanaEventId: $pacsanaEventId
    type: $type
  ) {
    id
    type
    status
    startedAt
    alertRule {
      id
      name
      type
      description
    }
    checkupScheduleEvent {
      checkupExpectedAt
      checkupSchedule {
        id
        tolerance {
          early
          late
        }
      }
    }
    checkup {
      id
      endedAt
    }
    continuousMonitoring {
      bucketEndAt
    }
    pacsanaEvent {
      eventKey
    }
  }
}
    `;

/**
 * __useGetAlertsQuery__
 *
 * To run a query within a React component, call `useGetAlertsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAlertsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAlertsQuery({
 *   variables: {
 *      patientId: // value for 'patientId'
 *      alertRuleId: // value for 'alertRuleId'
 *      checkupScheduleId: // value for 'checkupScheduleId'
 *      pacsanaEventId: // value for 'pacsanaEventId'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useGetAlertsQuery(baseOptions: Apollo.QueryHookOptions<GetAlertsQuery, GetAlertsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAlertsQuery, GetAlertsQueryVariables>(GetAlertsDocument, options);
      }
export function useGetAlertsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAlertsQuery, GetAlertsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAlertsQuery, GetAlertsQueryVariables>(GetAlertsDocument, options);
        }
export type GetAlertsQueryHookResult = ReturnType<typeof useGetAlertsQuery>;
export type GetAlertsLazyQueryHookResult = ReturnType<typeof useGetAlertsLazyQuery>;
export type GetAlertsQueryResult = Apollo.QueryResult<GetAlertsQuery, GetAlertsQueryVariables>;