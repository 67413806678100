import React from 'react';

import { Chip, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import FeebrisCircleIcon from '@/components/svgs/FeebrisCircleIcon.svg?react';

import { formatUserName, PartialOrganization, PartialUser } from '@/helpers/formatUserName';

interface UserNameProps {
  user: PartialUser;
  userActingOrganization?: PartialOrganization;
}

export function UserName(props: UserNameProps) {
  const classes = useStyles();

  return (
    <Typography component="span" className={classes.wrapper}>
      {/* TODO: In the future user profile pictures could be used in place of AccountCircleIcon.
                However, for now this placeholder helps to make the UX clear that this is Feebris
                user's name */}
      {props.user?.email === 'api-service-account@feebris.com' ? (
        <FeebrisCircleIcon className={classes.icon} />
      ) : (
        <AccountCircleIcon className={classes.icon} />
      )}
      <span className={classes.userName}>
        {formatUserName(props.user, props.userActingOrganization)}
      </span>
      {/* TODO: At some point we may want a chip here to call out ShareToken users */}
      {props.user?.isSelfCare ? <Chip label="Patient" size="small" /> : null}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'inline-flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: theme.spacing(0.5),
  },
  icon: {
    width: 24,
    height: 24,
    color: 'rgba(0, 0, 0, 0.26)',
  },
  userName: {
    paddingRight: 2, // To compensate for the MUI icon (on the left) having 2px internal SVG padding
  },
}));
