import React from 'react';

import { format, isFuture } from 'date-fns';
import { Helmet } from 'react-helmet-async';

import {
  ApiKeyFragment,
  useSettingsApiKeysQuery,
  useSettingsUndoExpireApiKeyMutation,
} from '@/generated/graphql';
import { gql } from '@apollo/client';
import { Column } from '@material-table/core';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import DeleteIcon from '@mui/icons-material/Delete';
import UndoIcon from '@mui/icons-material/Undo';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { toast } from 'sonner';
import { useConfirm } from 'material-ui-confirm';

import { ErrorDisplay } from '@/components/ErrorDisplay';
import MaterialTableWithIcons from '@/components/MaterialTableWithIcons';
import { useCreateApiKeyModal } from './components/CreateApiKeyModal';
import { useExpireApiKeyModal } from './components/ExpireApiKeyModal';
import { useMePermission } from '@/hooks/useAuth';
import { UserName } from '@/components/UserName';

export const QUERY_SETTINGS_API_KEYS = gql`
  fragment ApiKey on IntegrationApiKey {
    id
    apiKey
    deletedAt
    createdAt
    createdBy {
      email
      firstName
      lastName
    }
  }

  query SettingsApiKeys {
    integrationApiKeys {
      ...ApiKey
    }
  }
`;

export const MUTATION_SETTINGS_UNDO_EXPIRE_API_KEY = gql`
  mutation SettingsUndoExpireApiKey($id: ID!) {
    undoExpireIntegrationApiKey(id: $id)
  }
`;

function ApiKeys() {
  const canCreateIntegrationApiKeys = useMePermission('create_integration_api_keys');
  const canDeleteIntegrationApiKeys = useMePermission('delete_integration_api_keys');

  const { data, refetch, error } = useSettingsApiKeysQuery({
    onError: () => toast.error('An error occurred while loading API keys'),
  });
  const { t } = useTranslation();
  const { showCreateApiKeyModal } = useCreateApiKeyModal({
    refetch,
  });
  const { showExpireApiKeyModal } = useExpireApiKeyModal({
    refetch,
  });
  const [undoExpireApiKeyMutation] = useSettingsUndoExpireApiKeyMutation({
    onError: (error) => {
      if (error.message.match(/Maximum number of active API keys exceeded/i)) {
        toast.error(error.message);
      } else {
        toast.error('An error occurred while creating API key');
      }
    },
  });
  const confirm = useConfirm();

  const undoExpire = async (rowData: ApiKeyFragment) => {
    try {
      await confirm({
        content: (
          <>
            <Typography gutterBottom>
              {t('Are you sure you want to undo expiry of this API key?')}
            </Typography>
            <Typography>
              {t(
                'This will make the API key active again enabling it to be used to access the Feebris Integrations API.',
              )}
            </Typography>
          </>
        ),
      });
      await undoExpireApiKeyMutation({ variables: { id: rowData.id } });
      await refetch();
    } catch {
      //
    }
  };

  const columns: Column<ApiKeyFragment>[] = [
    {
      field: 'apiKey',
      title: 'Api Key',
    },
    {
      field: 'encryptedSecret',
      title: 'HMAC Secret',
      render: () => '*'.repeat(24),
    },
    {
      field: 'createdBy',
      title: 'Created By',
      // NOTE: Normally the userActingOrganization prop is required for the UserName component, but
      //       in this case it's not possible for users to see another organisations API keys. So
      //       for that reason the rowData.createdBy will always be in the same org as loggedInUser
      render: (rowData) => <UserName user={rowData.createdBy} />,
    },
    {
      field: 'createdAt',
      title: 'Created At',
      render: (rowData) => format(new Date(rowData.createdAt), "yyyy-MM-dd 'at' HH:mm"),
    },
    {
      field: 'deletedAt',
      title: 'Expires At',
      render: (rowData) => {
        const deletedAt = rowData.deletedAt ? new Date(rowData.deletedAt) : null;
        return deletedAt ? (
          isFuture(deletedAt) ? (
            format(deletedAt, "yyyy-MM-dd 'at' HH:mm")
          ) : (
            <strong>{t('Expired')}</strong>
          )
        ) : (
          t('Never')
        );
      },
    },
  ];

  return (
    <>
      <Helmet>
        <title>Manage Api Keys</title>
      </Helmet>
      <Box className="e2e__apikeystable">
        <MaterialTableWithIcons
          title="Api Keys"
          columns={columns}
          data={data?.integrationApiKeys ?? []}
          options={{ search: false }}
          localization={{
            body: {
              emptyDataSourceMessage: error ? (
                <ErrorDisplay
                  message="Failed to API keys. Press retry to try again."
                  retry={refetch}
                />
              ) : (
                t('Organization has no API keys')
              ),
            },
          }}
          actions={[
            {
              icon: () => <VpnKeyIcon className="e2e__addapikeybutton" />,
              tooltip: 'Add API key',
              isFreeAction: true,
              onClick: () => showCreateApiKeyModal(),
              hidden: !canCreateIntegrationApiKeys,
            },
            (rowData: ApiKeyFragment) => ({
              icon: () => <DeleteIcon />,
              tooltip: 'Expire API key',
              onClick: (event, rowData) => showExpireApiKeyModal(rowData as ApiKeyFragment),
              hidden: Boolean(rowData.deletedAt) || !canDeleteIntegrationApiKeys,
            }),
            (rowData: ApiKeyFragment) => ({
              icon: () => <UndoIcon />,
              tooltip: 'Undo API key expiration',
              onClick: (event, rowData) => undoExpire(rowData as ApiKeyFragment),
              hidden: !rowData.deletedAt || !canDeleteIntegrationApiKeys,
            }),
          ]}
        />
      </Box>
    </>
  );
}

export default ApiKeys;
