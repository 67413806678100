import React from 'react';

import { SettingsCreateApiKeyMutation, useSettingsCreateApiKeyMutation } from '@/generated/graphql';
import { gql } from '@apollo/client';
import {
  Stack,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  TextField,
  Typography,
  Alert,
} from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useTranslation } from 'react-i18next';
import { toast } from 'sonner';
import { ShowFnOutput, useModal } from 'mui-modal-provider';
import { useCopyToClipboard } from '@/hooks/useCopyToClipboard';

export const MUTATION_SETTINGS_CREATE_API_KEY = gql`
  fragment CreatedApiKey on IntegrationApiKeyWithSecret {
    apiKey
    secret
  }

  mutation SettingsCreateApiKey {
    createIntegrationApiKey {
      ...CreatedApiKey
    }
  }
`;

interface CreateApiKeyModalProps {
  open: boolean;
  onClose: () => void;
  refetch: () => Promise<unknown>;
}

export default function CreateApiKeyModal(props: CreateApiKeyModalProps) {
  const { t } = useTranslation();
  const [createApiKeyMutation, { loading, data }] = useSettingsCreateApiKeyMutation({
    onError: (error) => {
      if (error.message.match(/Maximum number of active API keys exceeded/i)) {
        toast.error(error.message);
        props.onClose();
      } else {
        toast.error('An error occurred while creating API key');
      }
    },
    update: () => {
      // Intentionally do not update the apollo cache from the fetch
      // we don't want the HMAC secrets hanging around in apollo's cache.
    },
  });

  const onClose = (_event: unknown, reason: string) => {
    // Don't close due to anything except the Close button being clicked
    if (['escapeKeyDown', 'backdropClick'].includes(reason)) {
      return;
    }
    props.onClose();
  };

  const onCreate = async () => {
    await createApiKeyMutation();
    await props.refetch();
  };

  const isStepOne = !data && !loading;

  return (
    <Dialog
      className="e2e__create_api_key_modal"
      open={props.open}
      onClose={onClose}
      fullWidth={true}
      maxWidth="sm"
      aria-labelledby="create-api-key-modal-title"
      aria-describedby="create-api-key-modal-description">
      {isStepOne ? (
        <CreateApiKeyModalStepOne />
      ) : (
        <CreateApiKeyModalStepTwo loading={loading} data={data} />
      )}
      <DialogActions>
        <Button onClick={props.onClose} autoFocus>
          {isStepOne ? t('Cancel') : t('Close')}
        </Button>
        {isStepOne ? (
          <Button
            className="e2e_modal_confirm_btn"
            onClick={onCreate}
            color="primary"
            variant="contained">
            {t('Create')}
          </Button>
        ) : null}
      </DialogActions>
    </Dialog>
  );
}

function CreateApiKeyModalStepOne() {
  const { t } = useTranslation();
  return (
    <>
      <DialogTitle id="create-api-key-modal-title">{t('Create new API key')}</DialogTitle>
      <DialogContent>
        <DialogContentText id="create-api-key-modal-description">
          {t('Generate a new API key and secret for accessing the Feebris Integrations API.')}
        </DialogContentText>
      </DialogContent>
    </>
  );
}

function CreateApiKeyModalStepTwo(props: {
  loading: boolean;
  data: SettingsCreateApiKeyMutation | null | undefined;
}) {
  const { t } = useTranslation();

  const { canCopy, copyToClipboard } = useCopyToClipboard();

  return (
    <>
      <DialogTitle id="create-api-key-modal-title">{t('API key successfully created')}</DialogTitle>
      <DialogContent>
        <DialogContentText id="create-api-key-modal-description">
          <Stack spacing={2}>
            <Typography>
              {t(
                'This is your API key and HMAC secret needed to communicate with the Feebris Integrations API.',
              )}
            </Typography>
            <Alert severity="warning">
              {t(
                'Ensure you copy the HMAC secret and store it safely. Once this dialog is closed the HMAC secret is no longer available for copy.',
              )}
            </Alert>
            <div>
              <TextField
                label={t('API key')}
                value={props.data?.createIntegrationApiKey.apiKey}
                variant="filled"
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  readOnly: true,
                  endAdornment: canCopy && (
                    <IconButton
                      onClick={() =>
                        copyToClipboard(props.data?.createIntegrationApiKey.apiKey ?? '')
                      }>
                      <ContentCopyIcon />
                    </IconButton>
                  ),
                }}
                fullWidth
              />
            </div>
            <div>
              <TextField
                label={t('HMAC secret')}
                value={props.data?.createIntegrationApiKey.secret}
                style={{
                  wordWrap: 'break-word',
                  wordBreak: 'break-all',
                }}
                variant="filled"
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  readOnly: true,
                  endAdornment: canCopy && (
                    <IconButton
                      onClick={() =>
                        copyToClipboard(props.data?.createIntegrationApiKey.secret ?? '')
                      }>
                      <ContentCopyIcon />
                    </IconButton>
                  ),
                }}
                fullWidth
                multiline
              />
            </div>
          </Stack>
        </DialogContentText>
      </DialogContent>
    </>
  );
}

type UseCreateApiKeyModalProps = Pick<CreateApiKeyModalProps, 'refetch'>;

export function useCreateApiKeyModal({ refetch }: UseCreateApiKeyModalProps) {
  const { showModal } = useModal();

  return {
    showCreateApiKeyModal: () => {
      const modal: ShowFnOutput<CreateApiKeyModalProps> = showModal(
        CreateApiKeyModal,
        {
          refetch,
          onClose: () => modal.hide(),
        },
        { destroyOnClose: true },
      );

      return modal;
    },
  };
}
