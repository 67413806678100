export default class GoogleAnalytics {
  static initialize() {
    const gaScript = document.createElement('script');

    gaScript.setAttribute('async', 'true');
    gaScript.setAttribute(
      'src',
      `https://www.googletagmanager.com/gtag/js?id=${process.env.REACT_APP_GOOGLE_ANALYTICS_PROPERTY_ID}`,
    );
    document.head.appendChild(gaScript);

    window.dataLayer = window.dataLayer || [];
    function gtag() {
      // don't change this function as is required to have this structure
      // eslint-disable-next-line prefer-rest-params
      window.dataLayer.push(arguments);
    }
    gtag('js', new Date());
    gtag('config', process.env.REACT_APP_GOOGLE_ANALYTICS_PROPERTY_ID);
    window.gtag = gtag;
  }
}
