import React, { useState } from 'react';

import { gql } from '@apollo/client';
import {
  Box,
  Paper,
  FormControlLabel,
  TextField,
  Typography,
  Switch,
  Chip,
  Grid,
  Tooltip,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { format } from 'date-fns';
import { useParams } from 'react-router-dom';

import {
  PatientSearchItemFragmentInternal,
  useSearchPatientsQueryInternal,
} from '@/generated/graphql-internal';

import { ErrorDisplay } from '@/components/ErrorDisplay';
import { useDebounce } from '@/hooks/useDebounce';
import { isDefined } from '@/helpers/isDefined';
import NhsNumber from '@/components/NhsNumber';
import { VivalinkTroubleshooting } from './components/VivalinkTroubleshooting';
import { TimeAgo } from './components/TimeAgo';
import { stripSpaces } from '@/helpers/stripSpaces';
import { PageContainer } from '@/components/PageContainer';
import { PageTitle } from '@/components/PageTitle';

export const QUERY_PATIENTS_BY_ID_OR_NHS_NUMBER = gql`
  fragment PatientSearchItem on Patient {
    id
    firstName
    lastName
    nhsNumber
    telephone
    address {
      address
      postcode
    }
    deletedAt
    latestContinuousMonitoringSession {
      kitId: vivalinkSubjectId
      createdAt
      endedAt
      earliestBySensor {
        temperature {
          bucketEndAt
          battery {
            temperature
          }
        }
        ecg {
          bucketEndAt
          battery {
            ecg
          }
        }
        spo2 {
          bucketEndAt
          battery {
            spo2
          }
        }
      }
      latestBySensor {
        temperature {
          bucketEndAt
          battery {
            temperature
          }
        }
        ecg {
          bucketEndAt
          battery {
            ecg
          }
        }
        spo2 {
          bucketEndAt
          battery {
            spo2
          }
        }
      }
    }
    wardAdmissionForOrganization(organizationId: $organizationId) {
      admittedAt
      ward {
        id
        name
      }
      carePathway {
        id
        name
      }
    }
  }

  query SearchPatients($organizationId: ID!, $query: String!, $includeDeleted: Boolean!) {
    searchPatients(
      organizationId: $organizationId
      query: $query
      includeDeleted: $includeDeleted
    ) {
      ...PatientSearchItem
    }
  }
`;

export function PatientSearch() {
  const classes = useStyles();

  const { organizationId } = useParams<{ organizationId: string }>();

  const [query, setQuery] = useState<string>('');
  const [includeDeleted, setIncludeDeleted] = useState(false);

  const debouncedQuery = useDebounce(query, 500);

  const { data, loading, error, refetch } = useSearchPatientsQueryInternal({
    variables: {
      organizationId,
      query: stripSpaces(debouncedQuery) ?? '',
      includeDeleted: includeDeleted,
    },
    skip: debouncedQuery.length < 3,
  });

  const debouncedResult = useDebounce(data?.searchPatients, 200);

  return (
    <PageContainer>
      <PageTitle
        title="Patient Search"
        subtitle="Search for a patient by Feebris ID, NHS number, or Feebris Kit ID. Toggle the switch to
        include patients deleted in the last 30 days"
      />
      <TextField
        className={clsx(classes.searchField, loading && classes.searchFieldLoading)}
        fullWidth
        name="query"
        placeholder="Type Patient Feebris ID, NHS number, or Feebris Kit ID"
        variant="standard"
        required
        autoComplete="off"
        value={query}
        onChange={(e) => setQuery(e.target.value)}
        inputProps={{
          'aria-label': 'Patient ID or NHS number',
        }}
        InputProps={{
          disableUnderline: true,
          'aria-label': 'Patient ID or NHS number',
          endAdornment: (
            <FormControlLabel
              control={
                <Switch
                  checked={includeDeleted}
                  onChange={(e) => setIncludeDeleted(e.target.checked)}
                  name="includeDeleted"
                  color="primary"
                />
              }
              label={<div className={classes.toggleLabel}>Include Deleted</div>}
            />
          ),
        }}
        onKeyUp={(e) => {
          if (e.key === 'Escape') {
            setQuery('');
            (e.target as HTMLInputElement).blur();
          }
        }}
      />
      <Box marginTop={2}>
        {error ? (
          <ErrorDisplay
            message="Failed to search patients. Press retry to try again."
            retry={() => {
              refetch();
            }}
            showIcon
          />
        ) : (
          <>
            {debouncedResult?.map((patient) => (
              <PatientSearchItem key={patient.id} patient={patient} />
            ))}
            {debouncedResult?.length === 0 && (
              <Typography variant="h5" align="center" className={classes.noResultsMessage}>
                Found no patients matching the search terms within this organisation
              </Typography>
            )}
          </>
        )}
      </Box>
    </PageContainer>
  );
}

interface PatientSearchItemProps {
  patient: PatientSearchItemFragmentInternal;
}

type SensorTypes = 'ecg' | 'spo2' | 'temperature';
const sensorTypes = ['ecg', 'spo2', 'temperature'] as const;

function PatientSearchItem({ patient }: PatientSearchItemProps) {
  const classes = usePatientSearchItemStyles();

  return (
    <Paper elevation={2}>
      <Box padding={3}>
        <Box display="flex" justifyContent="space-between">
          <Typography variant="h6" gutterBottom>
            {patient.firstName} {patient.lastName}
          </Typography>
          {patient.deletedAt && (
            <Chip
              className={classes.deletedTag}
              label={`Deleted ${format(new Date(patient.deletedAt), 'MMM do yyyy')}`}
            />
          )}
        </Box>
        <Typography gutterBottom>
          <span className={classes.label}>Feebris Identifer:</span> {patient.id}
        </Typography>
        <Typography gutterBottom>
          <Box display="flex" gap={4}>
            <span className={classes.label}>NHS Number:</span>
            {patient.nhsNumber ? (
              <NhsNumber nhsNumber={patient.nhsNumber} patientId={patient.id} />
            ) : (
              'Not provided'
            )}
          </Box>
        </Typography>
        <Typography gutterBottom>
          <span className={classes.label}>Telephone:</span> {patient.telephone ?? 'Not provided'}
        </Typography>
        <Typography gutterBottom>
          <span className={classes.label}>Address:</span>{' '}
          {[patient.address?.address, patient.address?.postcode].join(', ')}
        </Typography>
        <Typography gutterBottom>
          <span className={classes.label}>Ward:</span>{' '}
          {isDefined(patient.wardAdmissionForOrganization) ? (
            <>
              {patient.wardAdmissionForOrganization.ward.name} - Admitted{' '}
              {format(
                new Date(patient.wardAdmissionForOrganization.admittedAt),
                'MMM do yyyy, HH:mm a',
              )}
            </>
          ) : (
            'No active ward admission'
          )}
        </Typography>
        {isDefined(patient.wardAdmissionForOrganization?.carePathway) && (
          <Typography gutterBottom>
            <span className={classes.label}>Care Pathway: </span>{' '}
            {patient.wardAdmissionForOrganization?.carePathway?.name}
          </Typography>
        )}
        {isDefined(patient.latestContinuousMonitoringSession) && (
          <Box marginTop={2}>
            <Typography className={classes.sectionHeader} gutterBottom>
              Latest Continuous Monitoring Session
            </Typography>
            <Typography gutterBottom>
              <span className={classes.label}>Kit Id:</span>{' '}
              {patient.latestContinuousMonitoringSession.kitId}
            </Typography>
            <Typography gutterBottom>
              <span className={classes.label}>Started:</span>{' '}
              {format(
                new Date(patient.latestContinuousMonitoringSession.createdAt),
                'MMM do yyyy, HH:mm a',
              )}
            </Typography>
            <Typography gutterBottom>
              <span className={classes.label}>Ended:</span>{' '}
              {patient.latestContinuousMonitoringSession.endedAt
                ? format(
                    new Date(patient.latestContinuousMonitoringSession.endedAt),
                    'MMM do yyyy, HH:mm a',
                  )
                : 'Ongoing'}
            </Typography>

            <br />

            <Grid container spacing={2}>
              {sensorTypes.map((sensorType) => (
                <Grid item xs={4} key={sensorType}>
                  <SensorData sensorType={sensorType} patient={patient} />
                </Grid>
              ))}
            </Grid>

            <br />

            <VivalinkTroubleshooting patient={patient} />
          </Box>
        )}
      </Box>
    </Paper>
  );
}

function SensorData({ patient, sensorType }: PatientSearchItemProps & { sensorType: SensorTypes }) {
  const classes = usePatientSearchItemStyles();

  const earliest = patient.latestContinuousMonitoringSession?.earliestBySensor?.[sensorType];
  const latest = patient.latestContinuousMonitoringSession?.latestBySensor?.[sensorType];
  // @ts-expect-error: Not sure how to TS this...
  const latestBattery = latest?.battery?.[sensorType];

  return (
    <>
      <Typography gutterBottom variant="subtitle1" className={classes.sensorTypeTitle}>
        {sensorType} Sensor
      </Typography>
      <Typography gutterBottom>
        <Tooltip title="The timestamp of the earliest aggregate we have on file in the Feebris database">
          <span className={classes.label}>Earliest aggregate:</span>
        </Tooltip>{' '}
        <TimeAgo dateString={earliest?.bucketEndAt} />
      </Typography>
      <Typography gutterBottom>
        <Tooltip title="The timestamp of the latest aggregate we have on file in the Feebris database">
          <span className={classes.label}>Latest aggregate:</span>
        </Tooltip>{' '}
        <TimeAgo dateString={latest?.bucketEndAt} />
      </Typography>
      <Typography gutterBottom>
        <Tooltip title="The battery value from the latest aggregate we have on file in the Feebris database">
          <span className={classes.label}>Latest aggregate battery:</span>
        </Tooltip>{' '}
        {latestBattery ? Math.floor(latestBattery) + '%' : 'No data'}
      </Typography>
    </>
  );
}

const usePatientSearchItemStyles = makeStyles((theme) => ({
  label: {
    fontWeight: 500,
  },
  deletedTag: {
    fontWeight: 500,
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
  },
  sectionHeader: {
    fontWeight: 500,
    fontSize: theme.typography.pxToRem(18),
    color: theme.palette.primary.dark,
  },
  sensorTypeTitle: { textTransform: 'uppercase' },
}));

const useStyles = makeStyles((theme) => ({
  toggleLabel: {
    whiteSpace: 'nowrap',
  },
  searchField: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2),
    paddingLeft: theme.spacing(3),
    borderRadius: theme.shape.borderRadius * 2,
    transition: 'box-shadow 0.2s ease-in-out',
    boxShadow: 'rgba(0, 0, 0, 0.05) 0px 3px 8px 0px',
    '&:hover': {
      boxShadow: 'rgba(0, 0, 0, 0.1) 0px 5px 10px 0px',
    },
    '&:focus-within': {
      boxShadow: 'rgba(0, 0, 0, 0.1) 0px 5px 10px 0px',
    },
  },
  // pulse animation of the outline
  searchFieldLoading: {
    outline: '2px solid transparent',
    animation: '$pulse-outline 3s infinite ease-in-out',
  },
  noResultsMessage: {
    color: theme.palette.grey[600],
  },
  '@keyframes pulse-outline': {
    '0%': {
      outlineColor: 'transparent',
    },
    '50%': {
      outlineColor: theme.palette.primary.main,
    },
    '100%': {
      outlineColor: 'transparent',
    },
  },
}));
