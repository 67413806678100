import { Box, Paper } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Skeleton } from '@mui/material';
import React from 'react';

export function SkeletonPatientListItem() {
  const classes = useStyles();

  return (
    <Paper elevation={2} className={classes.root}>
      <Box display="flex" alignItems="center">
        <div className={classes.statusLine}></div>
        {/* Name */}
        <Box display="flex" flexDirection="column" marginLeft={0.5} marginRight={2.25}>
          <Skeleton style={{ marginBottom: 2 }} height={22} width={155} />
          <Skeleton style={{ marginBottom: 1 }} height={20} width={80} />
        </Box>
        {/* News level */}
        <Skeleton variant="circular" style={{ margin: '0.5rem' }} height={42} width={42} />
        {/* Checkup time Signs */}
        <Box display="flex" flexDirection="column" marginLeft={1.25} marginRight={3.25}>
          <Skeleton style={{ marginBottom: 2 }} height={22} width={115} />
          <Skeleton style={{ marginBottom: 1 }} height={20} width={160} />
        </Box>
        {/* Blood Pressure */}
        <Skeleton
          variant="rectangular"
          className={classes.readingSkeleton}
          height={54}
          width={75}
        />
        {/* SpO2 */}
        <Skeleton
          variant="rectangular"
          className={classes.readingSkeleton}
          height={54}
          width={65}
        />
        {/* Resp Rate */}
        <Skeleton
          variant="rectangular"
          className={classes.readingSkeleton}
          height={54}
          width={65}
        />
        {/* Heart Rate */}
        <Skeleton
          variant="rectangular"
          className={classes.readingSkeleton}
          height={54}
          width={65}
        />
        {/* Temp */}
        <Skeleton
          variant="rectangular"
          className={classes.readingSkeleton}
          height={54}
          width={70}
        />
        {/* Consciousness */}
        <Skeleton
          variant="rectangular"
          className={classes.readingSkeleton}
          height={54}
          width={65}
        />
        {/* Selected action */}
        <Skeleton
          variant="rectangular"
          className={classes.readingSkeleton}
          height={54}
          width={65}
        />
      </Box>
    </Paper>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    height: '80px',
    borderRadius: theme.shape.borderRadius * 2,
    display: 'flex',
  },
  readingSkeleton: {
    marginRight: '1rem',
    background: theme.palette.grey[100],
    borderRadius: theme.shape.borderRadius * 2,
    [theme.breakpoints.up('xl')]: {
      marginRight: '1.5rem',
    },
  },
  statusLine: {
    alignSelf: 'stretch',
    width: 5,
    borderRadius: 5,
    margin: theme.spacing(1.5),
    backgroundColor: theme.palette.grey.A100,
  },
}));
