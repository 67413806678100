import React, { useState } from 'react';

import { Box, Typography, Popover } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';

import { isDefined } from '@/helpers/isDefined';
import { newsPaletteForScore } from '@/styles/NEWSColors';

interface CheckupValueProps {
  value: number | string | null | undefined;
  longValue?: string | null | undefined;
  shortLabel: string;
  longLabel?: string;
  score?: number | null;
  width?: number;
  smallValue?: boolean;
  popoverFields?: { label: string; value: string }[];
  alwaysOutline?: boolean;
  slimMargin?: boolean;
}

export function CheckupValue({
  value,
  longValue,
  shortLabel,
  longLabel,
  width = 65,
  smallValue,
  score,
  popoverFields,
  alwaysOutline,
  slimMargin,
}: CheckupValueProps) {
  const classes = useStyles();

  const hasValue = isDefined(value);

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  return (
    <>
      <Box
        onMouseEnter={(e) => setAnchorEl(e.currentTarget)}
        onMouseLeave={() => setAnchorEl(null)}
        width={width}
        style={isDefined(score) && score > 0 ? newsPaletteForScore(score) : undefined}
        className={clsx(
          classes.root,
          slimMargin && classes.slim,
          alwaysOutline && classes.outlined,
        )}>
        <Typography className={classes.label}>{shortLabel}</Typography>
        <Typography
          className={clsx(
            classes.value,
            smallValue && classes.smallValue,
            isDefined(score) && score > 0 && classes.strongValue,
          )}
          data-testid={`${shortLabel}tile-value`}>
          {hasValue ? value : '-'}
        </Typography>
      </Box>
      <Popover
        className={classes.valueDetailsPopover}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        disableRestoreFocus>
        <Box margin={2}>
          <Typography className={classes.popoverTitle}>{longLabel ?? shortLabel}</Typography>
          {!hasValue && <Typography gutterBottom>No measurement taken</Typography>}
          {longValue && <Typography gutterBottom>{longValue}</Typography>}
          {isDefined(score) && (
            <Typography gutterBottom>
              <span className={classes.popoverDetailLabel}>EWS</span> {score}
            </Typography>
          )}
          {popoverFields?.map(({ label, value }) => (
            <Typography key={label} gutterBottom>
              <span className={classes.popoverDetailLabel}>{label}</span> {value}
            </Typography>
          ))}
        </Box>
      </Popover>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    alignSelf: 'stretch',
    padding: theme.spacing(0.75, 1),
    margin: theme.spacing(0.5, 0.5),
    borderRadius: theme.shape.borderRadius * 2,
    border: '1px solid transparent',
    [theme.breakpoints.up('md')]: {
      margin: theme.spacing(0.5, 1),
    },
    [theme.breakpoints.up('xl')]: {
      margin: theme.spacing(0.5, 1.5),
    },
  },
  slim: {
    margin: theme.spacing(0.5, 0.75),
  },
  outlined: {
    borderColor: theme.palette.grey[800],
  },
  label: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 500,
    lineHeight: 1,
  },
  smallValue: {
    marginTop: theme.spacing(0.25),
    whiteSpace: 'nowrap',
    fontSize: theme.typography.pxToRem(14),
  },
  value: {
    marginTop: theme.spacing(0.125),
    whiteSpace: 'nowrap',
    fontWeight: 'inherit',
  },
  strongValue: {
    fontWeight: 500,
  },
  valueDetailsPopover: {
    pointerEvents: 'none',
    marginTop: theme.spacing(1),
  },
  popoverTitle: {
    fontWeight: 500,
    marginBottom: theme.spacing(1),
  },
  popoverDetailLabel: {
    fontWeight: 500,
  },
}));
