'use strict';

/**
 * Formatter class to handle different units for temperature, weight, and glucose based on region.
 */
class FeebrisFormatter {
  /**
   * @param {() => boolean} isUSA - Determines if the formatter should use US customary units.
   */
  constructor(isUSA) {
    this.usingFahrenheit = isUSA;
    // HACK: Coupling the weight units (kg vs. lbs) to being in the USA is not going to scale.
    //       Eventually this will need to be a setting that is saved somewhere (eg: per-User).
    this.usingPounds = isUSA;

    // Glucose units: UK: mmol/L USA: mg/dL
    this.usingGlucoseMG_DL = isUSA;
  }

  /*
   *  _______                                  _
   * |__   __|                                | |
   *    | | ___ _ __ ___  _ __   ___ _ __ __ _| |_ _   _ _ __ ___
   *    | |/ _ \ '_ ` _ \| '_ \ / _ \ '__/ _` | __| | | | '__/ _ \
   *    | |  __/ | | | | | |_) |  __/ | | (_| | |_| |_| | | |  __/
   *    |_|\___|_| |_| |_| .__/ \___|_|  \__,_|\__|\__,_|_|  \___|
   *                     | |
   *                     |_|
   */

  /**
   * Get the temperature units.
   * @returns {'°F' | '°C'} The temperature units based on the region.
   */
  temperatureUnits() {
    return this.usingFahrenheit() ? '°F' : '°C';
  }

  /**
   * Format the temperature value with units.
   * @param {number|string} value - The temperature value to format.
   * @returns {string} The formatted temperature value with units.
   */
  formatTemperatureWithUnits(value) {
    const floatValue = parseFloat(value);
    const numDecimalPlaces = 1;
    // Ignore anything non-parseable (eg: undefined, null)
    if (isNaN(floatValue)) {
      return value;
    }
    if (this.usingFahrenheit()) {
      return `${this.celsius2Fahrenheit(floatValue, numDecimalPlaces)} °F`;
    } else {
      return `${this.roundTo(floatValue, numDecimalPlaces)} °C`;
    }
  }

  /**
   * Format the temperature value without units.
   * @param {number|string} value - The temperature value to format.
   * @returns {string} The formatted temperature value.
   */
  formatTemperature(value) {
    const floatValue = parseFloat(value);
    const numDecimalPlaces = 1;
    // Ignore anything non-parseable (eg: undefined, null)
    if (isNaN(floatValue)) {
      return value;
    }
    if (this.usingFahrenheit()) {
      return this.celsius2Fahrenheit(floatValue, numDecimalPlaces).toString();
    } else {
      return this.roundTo(value, numDecimalPlaces).toString();
    }
  }

  /**
   * Convert an input temperature string to the opposite unit system.
   * @param {string} stringValue - The input temperature as a string.
   * @returns {string} The converted temperature value as a string.
   */
  inputTemperature(stringValue) {
    const floatValue = parseFloat(stringValue);
    // Note, 2 points of precision to ensure Fahrenheit -> Celsius -> Fahrenheit is lossless
    const numDecimalPlaces = 2;
    // Ignore anything non-parseable (eg: undefined, null)
    if (isNaN(floatValue)) {
      return stringValue;
    }
    if (this.usingFahrenheit()) {
      return this.fahrenheit2Celsius(floatValue, numDecimalPlaces).toString();
    } else {
      return this.roundTo(floatValue, numDecimalPlaces).toString();
    }
  }

  /**
   * Convert Celsius to Fahrenheit.
   * @param {number} value - The temperature in Celsius.
   * @param {number} numDecimalPlaces - The number of decimal places to round to.
   * @returns {string} The temperature in Fahrenheit.
   */
  celsius2Fahrenheit(value, numDecimalPlaces) {
    return this.roundTo(value * 1.8 + 32, numDecimalPlaces);
  }

  /**
   * Convert Fahrenheit to Celsius.
   * @param {number} value - The temperature in Fahrenheit.
   * @param {number} numDecimalPlaces - The number of decimal places to round to.
   * @returns {string} The temperature in Celsius.
   */
  fahrenheit2Celsius(value, numDecimalPlaces) {
    return this.roundTo((value - 32) / 1.8, numDecimalPlaces);
  }

  /*
   * __          __  _       _     _
   * \ \        / / (_)     | |   | |
   *  \ \  /\  / /__ _  __ _| |__ | |_
   *   \ \/  \/ / _ \ |/ _` | '_ \| __|
   *    \  /\  /  __/ | (_| | | | | |_
   *     \/  \/ \___|_|\__, |_| |_|\__|
   *                    __/ |
   *                   |___/
   */

  /**
   * Get the weight units.
   * @returns { 'Lbs' | 'Kg'} The weight units based on the region.
   */
  weightUnits() {
    return this.usingPounds() ? 'Lbs' : 'Kg';
  }

  /**
   * Format the weight value with units.
   * @param {number|string} value - The weight value to format.
   * @returns {string} The formatted weight value with units.
   */
  formatWeightWithUnits(value) {
    const floatValue = parseFloat(value);
    const numDecimalPlaces = 1;
    // Ignore anything non-parseable (eg: undefined, null)
    if (isNaN(floatValue)) {
      return value;
    }
    if (this.usingPounds()) {
      return `${this.kilograms2Pounds(floatValue, numDecimalPlaces)} Lbs`;
    } else {
      return `${this.roundTo(floatValue, numDecimalPlaces)} Kg`;
    }
  }

  /**
   * Format the weight value without units.
   * @param {number|string} value - The weight value to format.
   * @returns {string} The formatted weight value.
   */
  formatWeight(value) {
    const floatValue = parseFloat(value);
    const numDecimalPlaces = 1;
    // Ignore anything non-parseable (eg: undefined, null)
    if (isNaN(floatValue)) {
      return value;
    }
    if (this.usingPounds()) {
      return this.kilograms2Pounds(floatValue, numDecimalPlaces).toString();
    } else {
      return this.roundTo(value, numDecimalPlaces).toString();
    }
  }

  /**
   * Convert an input weight string to the opposite unit system.
   * @param {string} stringValue - The input weight as a string.
   * @returns {string} The converted weight value as a string.
   */
  inputWeight(stringValue) {
    const floatValue = parseFloat(stringValue);
    // Note, 2 points of precision to ensure Lbs -> Kg -> Lbs is lossless.
    const numDecimalPlaces = 2;
    // Ignore anything non-parseable (eg: undefined, null)
    if (isNaN(floatValue)) {
      return stringValue;
    }
    if (this.usingPounds()) {
      return this.pounds2Kilograms(floatValue, numDecimalPlaces).toString();
    } else {
      return this.roundTo(floatValue, numDecimalPlaces).toString();
    }
  }

  /**
   * Convert pounds to kilograms.
   * @param {number} value - The weight in pounds.
   * @param {number} numDecimalPlaces - The number of decimal places to round to.
   * @returns {string} The weight in kilograms.
   */
  pounds2Kilograms(value, numDecimalPlaces) {
    return this.roundTo(value / 2.2046, numDecimalPlaces);
  }

  /**
   * Convert kilograms to pounds.
   * @param {number} value - The weight in kilograms.
   * @param {number} numDecimalPlaces - The number of decimal places to round to.
   * @returns {string} The weight in pounds.
   */
  kilograms2Pounds(value, numDecimalPlaces) {
    return this.roundTo(value * 2.2046, numDecimalPlaces);
  }

  /*
   *    ________
   *   / ____/ /_  ___________  ________
   *  / / __/ / / / / ___/ __ \/ ___/ _ \
   * / /_/ / / /_/ / /__/ /_/ (__  )  __/
   * \____/_/\__,_/\___/\____/____/\___/
   */

  /**
   * Get the glucose units.
   * @returns {'mg/dL' | 'mmol/L'} The glucose units based on the region.
   */
  glucoseUnits() {
    return this.usingGlucoseMG_DL() ? 'mg/dL' : 'mmol/L';
  }

  /**
   * Format the glucose value with units.
   * @param {number|string} value - The glucose value to format.
   * @returns {string} The formatted glucose value with units.
   */
  formatGlucoseWithUnits(value) {
    const floatValue = parseFloat(value);
    const numDecimalPlaces = 1;
    // Ignore anything non-parseable (eg: undefined, null)
    if (isNaN(floatValue)) {
      return value;
    }
    if (this.usingGlucoseMG_DL()) {
      return `${this.mmoll2mgdl(floatValue, numDecimalPlaces)} mg/dL`;
    } else {
      return `${this.roundTo(floatValue, numDecimalPlaces)} mmol/L`;
    }
  }

  /**
   * Format the glucose value without units.
   * @param {number|string} value - The glucose value to format.
   * @returns {string} The formatted glucose value.
   */
  formatGlucose(value) {
    const floatValue = parseFloat(value);
    const numDecimalPlaces = 1;
    // Ignore anything non-parseable (eg: undefined, null)
    if (isNaN(floatValue)) {
      return value;
    }
    if (this.usingGlucoseMG_DL()) {
      return this.mmoll2mgdl(floatValue, numDecimalPlaces).toString();
    } else {
      return this.roundTo(value, numDecimalPlaces).toString();
    }
  }

  /**
   * Convert an input glucose string to the opposite unit system.
   * @param {string} stringValue - The input glucose as a string.
   * @returns {string} The converted glucose value as a string.
   */
  inputGlucose(stringValue) {
    const floatValue = parseFloat(stringValue);
    // Note, 2 points of precision to ensure Lbs -> Kg -> Lbs is lossless.
    const numDecimalPlaces = 3;
    // Ignore anything non-parseable (eg: undefined, null)
    if (isNaN(floatValue)) {
      return stringValue;
    }
    if (this.usingGlucoseMG_DL()) {
      return this.mgdl2mmoll(floatValue, numDecimalPlaces).toString();
    } else {
      return this.roundTo(floatValue, numDecimalPlaces).toString();
    }
  }

  /**
   * Convert mg/dL to mmol/L.
   * @param {number} value - The glucose level in mg/dL.
   * @param {number} numDecimalPlaces - The number of decimal places to round to.
   * @returns {string} The glucose level in mmol/L.
   */
  mgdl2mmoll(value, numDecimalPlaces) {
    return this.roundTo(value / 18, numDecimalPlaces);
  }

  /**
   * Convert mmol/L to mg/dL.
   * @param {number} value - The glucose level in mmol/L.
   * @param {number} numDecimalPlaces - The number of decimal places to round to.
   * @returns {string} The glucose level in mg/dL.
   */
  mmoll2mgdl(value, numDecimalPlaces) {
    return this.roundTo(value * 18, numDecimalPlaces);
  }

  /**
   * Round a number to a specified number of decimal places.
   * @param {number} value - The number to round.
   * @param {number} numDecimalPlaces - The number of decimal places to round to.
   * @returns {string} The rounded number as a string.
   */
  roundTo(value, numDecimalPlaces) {
    const multiplier = Math.pow(10, numDecimalPlaces);
    return (Math.round(value * multiplier) / multiplier).toFixed(numDecimalPlaces);
  }
}

module.exports = FeebrisFormatter;
