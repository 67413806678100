import React, { useMemo, useState } from 'react';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Popover from '@mui/material/Popover';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { IconButtonProps, ListItemIcon, ListSubheader, Tooltip } from '@mui/material';
import { gql } from '@apollo/client';
import {
  PatientDetailsFragment,
  useGetContinuousMonitoringSessionQuery,
  useGetActivityMonitoringSessionQuery,
} from '@/generated/graphql';
import {
  Delete,
  Edit,
  VideoCall,
  WifiTethering,
  LockOpen,
  DirectionsWalk,
} from '@mui/icons-material';
import makeStyles from '@mui/styles/makeStyles';
import { useVideoCallModal } from '@/components/VideoCallModal';
import { useStartMonitoringSessionModal } from '@/pages/PatientDetail/components/StartMonitoringSessionModal';
import { useEndMonitoringSessionModal } from '@/pages/PatientDetail/components/EndMonitoringSessionModal';
import { useStartActivityMonitoringSessionModal } from '@/pages/PatientDetail/components/StartActivityMonitoringSessionModal';
import { useEndActivityMonitoringSessionModal } from '@/pages/PatientDetail/components/EndActivityMonitoringSessionModal';
import { useEditPatientModal } from '@/pages/ManagePatients/components/PatientFormModal';
import { useDeletePatientModal } from '@/pages/ManagePatients/components/DeletePatientModal';
import { useHistory } from 'react-router-dom';
import { useSetPasswordModal } from '@/pages/ManagePatients/components/SetPasswordModal';
import { isDefined } from '@/helpers/isDefined';
import { useMeActingOrganizationFeature, useMePermissions } from '@/hooks/useAuth';

export const GET_ACTIVITY_MONITORING_SESSION = gql`
  fragment PacsanaSessionItem on PacsanaSession {
    id
  }

  query GetActivityMonitoringSession($patientId: ID!) {
    pacsanaSession(patientId: $patientId) {
      ...PacsanaSessionItem
    }
  }
`;

interface PatientHeaderActionsProps {
  patient: PatientDetailsFragment;
  refresh: () => void;
}

function usePatientHeaderActions({
  patient,
  closePopover,
  refresh,
}: {
  patient: PatientDetailsFragment;
  closePopover: () => void;
  refresh: () => void;
}) {
  const history = useHistory();

  const { id: patientId, telephone, selfCare } = patient;

  const orgHasContinuousMonitoring = useMeActingOrganizationFeature('continuousMonitoring');
  const orgHasActivityMonitoring = useMeActingOrganizationFeature('activityMonitoring');
  const permissions = useMePermissions();
  const hasVideoCallFeature = Boolean(patient.features.videoCall);

  const {
    data: { continuousMonitoringSession } = {},
    loading: continuousMonitoringIsLoading,
    refetch: refreshContinuousMonitoring,
  } = useGetContinuousMonitoringSessionQuery({
    variables: { patientId: patient.id },
    skip: !orgHasContinuousMonitoring,
    // For now, just dump errors to the console as there's nothing for the user to do
    onError: console.error,
  });

  const hasExistingContinuousMonitoring = isDefined(continuousMonitoringSession);

  const {
    data: { pacsanaSession: activityMonitoringSession } = {},
    loading: activityMonitoringIsLoading,
    refetch: refreshActivityMonitoring,
  } = useGetActivityMonitoringSessionQuery({
    variables: { patientId: patient.id },
    skip: !orgHasActivityMonitoring,
    // For now, just dump errors to the console as there's nothing for the user to do
    onError: console.error,
  });

  const hasExistingActivityMonitoring = isDefined(activityMonitoringSession);

  const { showVideoCallModal } = useVideoCallModal();
  const { showStartMonitoringSessionModal } = useStartMonitoringSessionModal({
    onAdded: () => {
      refreshContinuousMonitoring();
      refresh();
    },
  });
  const { showEndMonitoringSessionModal } = useEndMonitoringSessionModal({
    onAdded: () => {
      refreshContinuousMonitoring();
      refresh();
    },
  });
  const { showStartActivityMonitoringSessionModal } = useStartActivityMonitoringSessionModal({
    onAdded: () => {
      refreshActivityMonitoring();
      refresh();
    },
  });
  const { showEndActivityMonitoringSessionModal } = useEndActivityMonitoringSessionModal({
    onAdded: () => {
      refreshActivityMonitoring();
      refresh();
    },
  });
  const { showEditPatientModal } = useEditPatientModal({
    onComplete: () => {
      // ideally we would just use this response to update the UI, but for now just refresh
      refresh();
    },
  });
  const { showDeletePatientModal } = useDeletePatientModal({
    onDelete: () => {
      history.push('/patients');
    },
  });
  const { showSetPasswordModal } = useSetPasswordModal();

  const startVideoCall =
    permissions['view_patients'] && hasVideoCallFeature && isDefined(telephone)
      ? ({
          icon: <VideoCall />,
          text: 'Start Video Call',
          state: 'ready',
          style: 'normal',
          onClick: () => {
            showVideoCallModal(patientId, telephone);
          },
        } satisfies PatientActionsListItemProps)
      : null;
  const startContinuousMonitoring =
    permissions['edit_patients'] && orgHasContinuousMonitoring && !hasExistingContinuousMonitoring
      ? ({
          icon: <WifiTethering />,
          text: 'Start Continuous Monitoring',
          state: continuousMonitoringIsLoading ? 'loading' : 'ready',
          style: 'normal',
          onClick: () => showStartMonitoringSessionModal(patientId),
        } satisfies PatientActionsListItemProps)
      : null;
  const endContinuousMonitoring =
    orgHasContinuousMonitoring && hasExistingContinuousMonitoring
      ? ({
          icon: <WifiTethering />,
          text: 'End Continuous Monitoring',
          state: continuousMonitoringIsLoading ? 'loading' : 'ready',
          style: 'normal',
          onClick: () => showEndMonitoringSessionModal(patientId),
        } satisfies PatientActionsListItemProps)
      : null;
  const startActivityMonitoring =
    permissions['edit_patients'] && orgHasActivityMonitoring && !hasExistingActivityMonitoring
      ? ({
          icon: <DirectionsWalk />,
          text: 'Start Activity Monitoring',
          state: activityMonitoringIsLoading ? 'loading' : 'ready',
          style: 'normal',
          onClick: () => showStartActivityMonitoringSessionModal(patientId),
        } satisfies PatientActionsListItemProps)
      : null;
  const endActivityMonitoring =
    permissions['edit_patients'] && orgHasActivityMonitoring && hasExistingActivityMonitoring
      ? ({
          icon: <DirectionsWalk />,
          text: 'End Activity Monitoring',
          state: activityMonitoringIsLoading ? 'loading' : 'ready',
          style: 'normal',
          onClick: () => showEndActivityMonitoringSessionModal(patientId),
        } satisfies PatientActionsListItemProps)
      : null;
  const resetPassword =
    selfCare && permissions['create_patients']
      ? ({
          icon: <LockOpen />,
          text: 'Reset Password',
          state: selfCare.canResetPassword ? 'ready' : 'disabled',
          tooltip: selfCare.canResetPassword
            ? undefined
            : 'Cannot reset password because the email address used by this patient is associated with a Feebris login in another organisation',
          style: 'normal',
          onClick: () => {
            showSetPasswordModal({
              user: selfCare,
            });
          },
        } satisfies PatientActionsListItemProps)
      : null;
  const editPatient = permissions['edit_patients']
    ? ({
        icon: <Edit />,
        text: 'Edit Patient',
        state: 'ready',
        style: 'normal',
        onClick: () => showEditPatientModal(patient),
      } satisfies PatientActionsListItemProps)
    : null;
  const deletePatient = permissions['delete_patients']
    ? ({
        icon: <Delete />,
        text: 'Remove Patient',
        tooltip: 'Removes a Patient from the Feebris system',
        state: 'ready',
        style: 'danger',
        onClick: () => {
          showDeletePatientModal(patient);
        },
      } satisfies PatientActionsListItemProps)
    : null;

  const possibleActions = [
    startVideoCall,
    startContinuousMonitoring,
    endContinuousMonitoring,
    startActivityMonitoring,
    endActivityMonitoring,
    resetPassword,
    editPatient,
    deletePatient,
  ];

  return possibleActions.filter(isDefined).map((action) => beforeOnClick(closePopover, action));
}

function beforeOnClick<
  T extends {
    onClick: () => void;
  },
>(beforeAction: () => void, { onClick, ...rest }: T) {
  return {
    ...rest,
    onClick: () => {
      beforeAction();
      onClick();
    },
  };
}

function usePopover() {
  const [anchorEl, setAnchorEl] = useState<null | HTMLButtonElement>(null);

  return useMemo(() => {
    const onClickOpen: IconButtonProps['onClick'] = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const close = () => {
      setAnchorEl(null);
    };

    const isOpen = Boolean(anchorEl);

    return { anchorEl, setRef: setAnchorEl, onClickOpen, close, isOpen };
  }, [anchorEl, setAnchorEl]);
}

export function PatientHeaderActionsDropdown({ patient, refresh }: PatientHeaderActionsProps) {
  const popover = usePopover();
  const actions = usePatientHeaderActions({
    patient: patient,
    closePopover: popover.close,
    refresh,
  });

  if (actions.length === 0) {
    return null;
  }

  return (
    <div>
      <IconButton onClick={popover.onClickOpen} aria-label="Patient actions" size="large">
        <MoreVertIcon />
      </IconButton>
      <Popover
        id={popover.isOpen ? 'simple-popover' : undefined}
        open={popover.isOpen}
        anchorEl={popover.anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        onClose={popover.close}>
        <List subheader={<ListSubheader color="primary">Patient Actions</ListSubheader>}>
          {actions.map((props, i) => (
            <PatientActionsListItem key={`action-${i}`} {...props} />
          ))}
        </List>
      </Popover>
    </div>
  );
}

interface PatientActionsListItemProps {
  icon: React.ReactNode;
  text: string;
  tooltip?: string;
  state: 'loading' | 'ready' | 'disabled';
  style: 'normal' | 'danger';
  onClick: () => void;
}

function PatientActionsListItem({
  icon,
  onClick,
  style,
  text,
  tooltip,
  state,
}: PatientActionsListItemProps) {
  const classes = useListItemStyles();
  const isDanger = style === 'danger';
  const isLoading = state === 'loading';
  const isDisabled = state === 'disabled';

  const content = (
    <span>
      <ListItem
        button
        onClick={state === 'ready' ? onClick : undefined}
        className={isLoading || isDisabled ? classes.disabled : undefined}
        aria-label={text}>
        <ListItemIcon className={isDanger ? classes.dangerIcon : undefined}>{icon}</ListItemIcon>
        <ListItemText
          primary={text}
          primaryTypographyProps={{
            color: isDanger ? 'error' : 'initial',
          }}
        />
      </ListItem>
    </span>
  );

  return tooltip ? <Tooltip title={tooltip}>{content}</Tooltip> : content;
}

const useListItemStyles = makeStyles((theme) => ({
  dangerIcon: {
    color: theme.palette.error.main,
  },
  disabled: {
    opacity: 0.2,
    pointerEvents: 'none',
  },
}));
