/* eslint-disable @typescript-eslint/no-explicit-any,@typescript-eslint/no-non-null-assertion */
import React from 'react';
import _ from 'lodash';
import BlockReadings from '../blocks/BlockReadings';
import BlockQuestionnaire from '../blocks/BlockQuestionnaire';
import BlockNotes from '../blocks/BlockNotes';
import BlockConsciousness from '../blocks/BlockConsciousness';
import BlockPulseOx from '../blocks/BlockPulseOx';
import BlockWeight from '../blocks/BlockWeight';
import BlockGlucose from '../blocks/BlockGlucose';
import BlockStethPlayer from '../blocks/BlockStethPlayer';
import BlockPictures from '../blocks/BlockPictures';
import Loading from '@/components/Loading';
import PatientHeader from '@/components/PatientHeader';
import { Box } from '@mui/material';
import CheckupActionSelector from './CheckupActionSelector';
import { WithTranslation } from 'react-i18next';
import { CheckupDetailsFragment, PatientDetailsFragment } from '@/generated/graphql';
import '@/styles/checkup-details.scss';
import '@/styles/print.scss';
import { QuestionnaireQuestion } from '@/helpers/questionnaire';
import { ErrorDisplay } from '@/components/ErrorDisplay';

// utility types, if needed elsewhere, extract to helpers
type DeepPartial<T> = T extends object
  ? {
      [K in keyof T]?: DeepPartial<T[K]>;
    }
  : T;

const initialState = {
  shouldShowStethPlayer: false,
};

interface Props extends WithTranslation {
  patientId: string;
  checkupId: string;

  navigateToPatient(): void;
  refetch(): Promise</* TODO: make this return void and use the props to pass this data rather than return value of this function */
  {
    patient: null | PatientDetailsFragment;
    checkup: null | CheckupDetailsFragment;
  }>;

  // TODO: exclusively use these props rather than data from the state
  checkup: null | CheckupDetailsFragment;
  patient: null | PatientDetailsFragment;
  error: {
    checkup?: Error;
    patient?: Error;
  };
}
type State = typeof initialState;

export class CheckupDetails extends React.Component<Props, State> {
  #elBlockStethInput: null | {
    setData: typeof BlockStethPlayer.prototype.setData;
  } = null;

  constructor(props: Props) {
    super(props);
    this.state = _.cloneDeep(initialState);
  }

  componentDidMount() {
    void this.fetch();
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.t !== prevProps.t) {
      void this.fetch();
    }
  }

  async fetch() {
    /*
    TODO: this component uses refs to call methods directly on its children.
    This goes against the 1-way data flow pattern of React, and we should refactor.
     */
    const { checkup } = await this.props.refetch();

    this.setState({
      shouldShowStethPlayer: checkup?.lungSounds != null,
    });

    await this.#setStethAudioData();
  }

  async #setStethAudioData(serverData?: Parameters<typeof getStethAudioData>[1]) {
    await this.#elBlockStethInput?.setData({
      audioData: getStethAudioData(this.#lungSoundUrls ?? [], serverData),
      gender: this.props.patient?.gender,
      referralId: this.props.checkupId,
    });
  }

  get #lungSoundUrls() {
    return this.props.checkup?.lungSounds?.map((sound) => sound.url);
  }

  #renderVitalsOrRespiratoryCheckupBlocks(checkup: CheckupDetailsFragment) {
    return (
      <div className="checkup-details">
        <div className="checkup-details__blocks">
          <div className="checkup-details__blocks__col">
            <BlockReadings checkup={checkup} />

            {checkup.notes?.length ? <BlockNotes notes={checkup.notes} /> : null}
            <BlockQuestionnaire questionnaireData={getQuestionnaireQuestions(this.props.checkup)} />

            <BlockConsciousness checkup={checkup} />
            <BlockPulseOx checkup={checkup} />
            <BlockWeight checkup={checkup} />
            <BlockGlucose checkup={checkup} />
          </div>
          <div className="checkup-details__blocks__col">
            {this.state.shouldShowStethPlayer === true && (
              <BlockStethPlayer
                ref={(child) => {
                  this.#elBlockStethInput = child;
                }}
              />
            )}
            <BlockPictures checkup={checkup} />
          </div>
        </div>
      </div>
    );
  }

  handlePrint = () => {
    window.print();
    (window as any).gtag?.('event', 'print');
  };

  refresh = () => {
    // strictly speaking we only need to refetch the patient here, we could optimise the gql queries to perform this
    void this.fetch();
  };

  render() {
    return (
      <React.Fragment>
        <PatientHeader
          patient={this.props.patient}
          checkup={this.props.checkup}
          refresh={this.refresh}
        />
        <Box className="action-header" displayPrint="none">
          <div className="action-selector">
            <CheckupActionSelector
              print={this.handlePrint}
              isSoftSignCheckup={this.props.checkup?.subtype === 'soft-signs'}
            />
          </div>
        </Box>

        {this.props.error.checkup ? (
          <ErrorDisplay message={this.props.error?.checkup?.message} />
        ) : (
          <Loading showLoading={this.props.checkup == null}>
            {() => this.#renderVitalsOrRespiratoryCheckupBlocks(this.props.checkup!)}
          </Loading>
        )}
      </React.Fragment>
    );
  }
}

function getQuestionnaireQuestions(checkup: CheckupDetailsFragment | null | undefined) {
  return checkup?.questionnaire == null
    ? undefined
    : /* TODO: remove this `as` by better typing the graphql response */
      (checkup.questionnaire as QuestionnaireQuestion[]);
}

// These types are used for the steth audio data component, and should be defined there
const POSITION = {
  UPPER_LEFT: 'upper_left',
  UPPER_RIGHT: 'upper_right',
  LOWER_LEFT: 'lower_left',
  LOWER_RIGHT: 'lower_right',
  LUNG_POSITION_FRONT: 'lung_front',
  LUNG_POSITION_BACK: 'lung_back',
} as const;

const LUNG_POSITIONS = [POSITION.LUNG_POSITION_FRONT, POSITION.LUNG_POSITION_BACK] as const;
type LungPosition = (typeof LUNG_POSITIONS)[number];

const RENDER_POSITIONS = [
  POSITION.UPPER_LEFT,
  POSITION.UPPER_RIGHT,
  POSITION.LOWER_LEFT,
  POSITION.LOWER_RIGHT,
] as const;
type RenderPosition = (typeof RENDER_POSITIONS)[number];

interface StethAudioPosition {
  audio: null | string;
  resultLabel: string;
  isPlaying: boolean;
}

type StethAudioData = Record<LungPosition, Record<RenderPosition, StethAudioPosition>>;

// will generate an object that the steth expects
// to cycle through and play the recordings
function getStethAudioData(
  s3Contents: string[],
  /* TODO: is this the correct type? */ serverData?: Record<string, string>,
) {
  const result: DeepPartial<StethAudioData> = {};

  LUNG_POSITIONS.forEach((lungPos) => {
    result[lungPos] = {};
    RENDER_POSITIONS.forEach((renderPos) => {
      // TODO: This is an odd way of doing it, but works. Ideally each lung sound should be mapped in the database to a lung render position. It might already be, but at the moment that's difficult to see.
      const serverLungLabel =
        serverData && serverData[`${lungPos}^${renderPos}`]
          ? serverData[`${lungPos}^${renderPos}`]
          : '';

      for (const audio of s3Contents) {
        if (audio.indexOf(`${lungPos}_${renderPos}`) > -1) {
          if (!result[lungPos]) {
            result[lungPos] = {};
          }
          return (result[lungPos]![renderPos] = {
            audio,
            resultLabel: serverLungLabel,
            isPlaying: false,
          });
        }
      }

      result[lungPos]![renderPos] = {
        audio: null,
        resultLabel: serverLungLabel,
        isPlaying: false,
      };
    });
  });

  return result as StethAudioData;
}
