import React from 'react';
import { gql } from '@apollo/client';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogProps,
  DialogTitle,
  CircularProgress,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Alert } from '@mui/material';
import { ShowFnOutput, useModal } from 'mui-modal-provider';
import { toast } from 'sonner';

import { useCreateVideoCallRoomAndSendSmsMutation } from '@/generated/graphql';

interface VideoCallModalProps extends DialogProps {
  patientId: string;
  patientTelephone: string;
  onClose: () => void;
}

export const QUERY_CREATE_VIDEOCALL = gql`
  mutation createVideoCallRoomAndSendSMS($patientId: ID!) {
    createVideoCallRoomAndSendSMS(patientId: $patientId) {
      hostRoomUrl
      patientRoomUrl
    }
  }
`;

export const VideoCallModal = ({
  open,
  patientId,
  patientTelephone,
  onClose,
}: VideoCallModalProps) => {
  const classes = useStyles();

  const [createVideoCallRoomAndSendSmsMutation, { data, loading, error }] =
    useCreateVideoCallRoomAndSendSmsMutation({
      variables: { patientId: patientId },
      onError: () => toast.error('An error occurred when trying to start a video call'),
    });

  const onSubmit = () => createVideoCallRoomAndSendSmsMutation();

  return (
    <Dialog open={open}>
      <DialogTitle>Start a video call with the patient</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Press the button below to start a video call with the patient. An SMS message will be sent
          to the patient&rsquo;s telephone number with a link to join the call.
          {!patientTelephone ? (
            <Alert severity="info" className={classes.gutter}>
              The patient does not have a telephone number but you can still ask them to join by
              sharing the link provided below.
            </Alert>
          ) : null}
          <div className={classes.gutter}>
            {data && (
              <>
                <Alert severity="success">
                  Join the call with the patient here:{' '}
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={data.createVideoCallRoomAndSendSMS.hostRoomUrl}>
                    {data.createVideoCallRoomAndSendSMS.patientRoomUrl}
                  </a>
                  <div className={classes.gutter}>
                    Please click this link above before you close this dialog as this link will not
                    be shown again.
                  </div>
                </Alert>
              </>
            )}
            {loading && <CircularProgress className={classes.alignCenter} />}
            {!data && !loading && (
              <Button
                onClick={onSubmit}
                color="primary"
                variant="contained"
                className={classes.alignCenter}>
                Start Call
              </Button>
            )}
            {error ? (
              <Alert severity="warning" className={classes.gutter}>
                Something went wrong when trying to start a video call.
              </Alert>
            ) : null}
          </div>
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export const useVideoCallModal = () => {
  const { showModal } = useModal();

  return {
    showVideoCallModal: (patientId: string, patientTelephone: string) => {
      const modal: ShowFnOutput<VideoCallModalProps> = showModal(
        VideoCallModal,
        {
          patientId,
          patientTelephone,
          onClose: () => {
            return modal.hide();
          },
        },
        { destroyOnClose: true },
      );
      return modal;
    },
  };
};

const useStyles = makeStyles((theme) => ({
  alignCenter: {
    display: 'block',
    margin: '0 auto',
  },
  gutter: {
    marginTop: theme.spacing(2),
  },
}));
