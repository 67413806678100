import React, { useState } from 'react';

import {
  Box,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Link,
  FormControlLabel,
  Switch,
  TableSortLabel,
  IconButton,
  Tooltip,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Pagination, Skeleton } from '@mui/material';
import { gql } from '@apollo/client';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import RefreshIcon from '@mui/icons-material/Refresh';
import { range } from 'lodash';

import {
  EhrIntegrationEventQuerySort,
  EhrIntegrationEventQuerySortField,
  EhrIntegrationStatus,
  SortDirection,
  useGetIntegrationEventsQuery,
} from '@/generated/graphql';

import { useDebounce } from '@/hooks/useDebounce';
import { createTapProps } from '@/helpers/createTapProps';

import { StatusChip } from './StatusChip';
import { useIntegrationEventModal } from './IntegrationEventModal';
import {
  formatIntegrationTriggerType,
  formatIntegrationType,
} from '@/components/EhrIntegrations/format';
import { PageTitle } from '@/components/PageTitle';
import { PageContainer } from '@/components/PageContainer';
import { toast } from 'sonner';
import { RestrictedRouteTester } from '@/components/ProtectedRoute';

const TERMINAL_STATUSES = [EhrIntegrationStatus.Success, EhrIntegrationStatus.Rejected];

export const GET_INTEGRATION_EVENTS = gql`
  fragment FormattableIntegrationTriggerConfig on EhrIntegrationTriggerConfig {
    __typename
    ... on EhrIntegrationPeriodicTriggerConfig {
      rrule
    }
  }

  fragment IntegrationEventItem on EhrIntegrationEvent {
    __typename
    id
    status
    isRetryable
    response {
      message
      innerError
    }
    createdAt
    updatedAt
    requestBody {
      consultationNote
      codedDataItems {
        code
        value
        units
        additionalText
      }
    }
    patient {
      id
      firstName
      lastName
      nhsNumber
      birthDate
    }
    integration {
      integrationType
      triggerType
      triggerConfig {
        ...FormattableIntegrationTriggerConfig
      }
    }
    summaryPeriod {
      from
      to
    }
    checkup {
      id
      type
      endedAt
    }
    continuousMonitoring {
      bucketStartAt
      bucketEndAt
    }
  }

  query GetIntegrationEvents(
    $take: Int!
    $skip: Int!
    $status: [EhrIntegrationStatus!]
    $sort: EhrIntegrationEventQuerySort
  ) {
    ehrIntegrationEvents(take: $take, skip: $skip, status: $status, sort: $sort) {
      events {
        ...IntegrationEventItem
      }
      totalCount
    }
  }
`;

const sortDirectionToMuiTableSortOrder = (direction: SortDirection) => {
  switch (direction) {
    case SortDirection.Asc:
      return 'asc';
    case SortDirection.Desc:
      return 'desc';
  }
};

export const canAccessIntegrationEventsPage: RestrictedRouteTester = ({
  featureFlags,
  permissions,
}) => featureFlags?.ehrIntegrations === true && permissions.view_ehr_integration_events;

export function IntegrationsScreen() {
  const classes = useStyles();
  const { t } = useTranslation();

  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  const [selectedStatuses, setSelectedStatuses] = useState<EhrIntegrationStatus[]>([
    EhrIntegrationStatus.Pending,
    EhrIntegrationStatus.Submitted,
    EhrIntegrationStatus.ActionRequired,
    EhrIntegrationStatus.Error,
  ]);

  const isIncludingCompletedStatuses = selectedStatuses.some((s) => TERMINAL_STATUSES.includes(s));

  const [sort, setSort] = useState<EhrIntegrationEventQuerySort>({
    field: EhrIntegrationEventQuerySortField.CreatedAt,
    direction: SortDirection.Desc,
  });

  const handleSortClick = (field: EhrIntegrationEventQuerySortField) => {
    setSort((prev) => ({
      field,
      direction:
        prev.field === field && prev.direction === SortDirection.Desc
          ? SortDirection.Asc
          : SortDirection.Desc,
    }));
  };

  const {
    data,
    previousData,
    loading: isFetchingEvents,
    refetch,
  } = useGetIntegrationEventsQuery({
    variables: {
      take: pageSize,
      skip: (currentPage - 1) * pageSize,
      status: selectedStatuses,
      sort,
    },
    onError: () => {
      toast.error('Failed to fetch integration events');
    },
  });

  // When the query changes the data will be nulled out, so we need to use the previous data
  // until the new data is fetched in order to stop the table from flickering
  const latestData = data ?? previousData;
  const isFetchingDebounced = useDebounce(isFetchingEvents, 500);

  const { showIntegrationEventModal } = useIntegrationEventModal();

  const totalCount = latestData?.ehrIntegrationEvents?.totalCount || 0;

  const sortableCell = (field: EhrIntegrationEventQuerySortField, label: string) => (
    <TableCell
      sortDirection={
        sort.field === field ? sortDirectionToMuiTableSortOrder(sort.direction) : false
      }>
      <TableSortLabel
        active={sort.field === field}
        direction={sortDirectionToMuiTableSortOrder(sort.direction)}
        onClick={() => handleSortClick(field)}
        aria-label={`sort by ${label} ${
          sort.direction === SortDirection.Desc ? 'descending' : 'ascending'
        }`}>
        {label}
      </TableSortLabel>
    </TableCell>
  );

  return (
    <PageContainer>
      <PageTitle
        title="Integration Queue"
        subtitle="View and manage active Electronic Health Record (EHR) integration events"
      />
      <TableContainer component={Paper} className={classes.tablePaper}>
        <Box marginLeft={2} marginTop={1} display="flex" justifyContent="flex-end">
          <FormControlLabel
            label="Show completed"
            control={
              <Switch
                checked={isIncludingCompletedStatuses}
                onChange={() => {
                  setSelectedStatuses((prev) =>
                    prev.some((s) => TERMINAL_STATUSES.includes(s))
                      ? prev.filter((status) => TERMINAL_STATUSES.includes(status) === false)
                      : [...prev, ...TERMINAL_STATUSES],
                  );
                }}
                color="primary"
              />
            }
          />
          <Tooltip title="Refresh event list" enterDelay={800}>
            <IconButton aria-label="Refresh event list" onClick={() => refetch()} size="large">
              <RefreshIcon />
            </IconButton>
          </Tooltip>
        </Box>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Type</TableCell>
              <TableCell>Trigger</TableCell>
              <TableCell>Patient</TableCell>
              {sortableCell(EhrIntegrationEventQuerySortField.CreatedAt, 'Created')}
              {sortableCell(EhrIntegrationEventQuerySortField.UpdatedAt, 'Updated')}
              {sortableCell(EhrIntegrationEventQuerySortField.Status, 'Status')}
            </TableRow>
          </TableHead>
          <TableBody>
            {!isFetchingDebounced && totalCount === 0 && (
              <TableRow>
                <TableCell colSpan={6} align="center">
                  {isIncludingCompletedStatuses
                    ? 'No integration events found'
                    : 'No active integration events found'}
                </TableCell>
              </TableRow>
            )}
            {isFetchingDebounced &&
              totalCount === 0 &&
              range(3).map((row) => (
                <TableRow key={row}>
                  <TableCell align="center">
                    <Skeleton height={32} width={125} />
                  </TableCell>
                  <TableCell align="center">
                    <Skeleton height={32} width={125} />
                  </TableCell>
                  <TableCell align="center">
                    <Skeleton height={32} width={130} />
                  </TableCell>
                  <TableCell align="center">
                    <Skeleton height={32} width={145} />
                  </TableCell>
                  <TableCell align="center">
                    <Skeleton height={32} width={145} />
                  </TableCell>
                  <TableCell align="center">
                    <Skeleton height={32} width={75} />
                  </TableCell>
                </TableRow>
              ))}

            {latestData?.ehrIntegrationEvents?.events?.map((event) => (
              <TableRow
                key={event.id}
                className={classes.tableRow}
                {...createTapProps({
                  onTap: () => {
                    showIntegrationEventModal({ integrationEvent: event });
                  },
                })}>
                <TableCell>{formatIntegrationType(event.integration.integrationType)}</TableCell>
                <TableCell>
                  {formatIntegrationTriggerType(
                    event.integration.triggerType,
                    event.integration.triggerConfig,
                  )}
                </TableCell>
                <TableCell>
                  <Link component={RouterLink} to={`/patient/${event.patient.id}`}>
                    {event.patient.firstName} {event.patient.lastName}
                  </Link>
                </TableCell>
                <TableCell>
                  {t('DATETIME_SHORT', {
                    val: new Date(event.createdAt),
                  })}
                </TableCell>
                <TableCell>
                  {t('DATETIME_SHORT', {
                    val: new Date(event.updatedAt),
                  })}
                </TableCell>
                <TableCell>
                  <StatusChip status={event.status} response={event.response} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <Box display="flex" justifyContent="end" marginTop={2}>
          <Select
            value={pageSize}
            disableUnderline
            displayEmpty
            style={{ fontSize: '14px' }}
            onChange={({ target: { value } }) => setPageSize(value as number)}>
            <MenuItem value={10}>10 Rows</MenuItem>
            <MenuItem value={25}>25 Rows</MenuItem>
            <MenuItem value={50}>50 Rows</MenuItem>
          </Select>
          <Pagination
            page={currentPage}
            onChange={(_, page) => setCurrentPage(page)}
            count={Math.ceil(totalCount / pageSize)}
            showFirstButton
            showLastButton
          />
        </Box>
      </TableContainer>
    </PageContainer>
  );
}

const useStyles = makeStyles((theme) => ({
  title: {
    color: theme.palette.primary.dark,
  },
  subTitle: {
    color: theme.palette.grey[600],
  },
  tablePaper: {
    borderRadius: theme.shape.borderRadius * 2,
    padding: theme.spacing(1, 2, 2, 2),
  },
  tableRow: {
    cursor: 'pointer',
    transition: theme.transitions.create(['background-color']),
    '&:focus-visible': {
      outline: `2px solid ${theme.palette.primary.main}`,
    },
    '&:hover': {
      backgroundColor: theme.palette.grey[100],
    },
    '& td': {
      margin: theme.spacing(1, 0),
    },
  },
}));
