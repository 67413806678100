import React from 'react';

import { Button } from '@mui/material';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';

import { usePatientWardAdmissionModal } from './PatientWardAdmissionModal';

interface PatientWardAdmissionButtonProps {
  patientId: string;
  patientName: string;
  onAdmitted: () => void;
}

export function PatientWardAdmissionButton({
  patientId,
  patientName,
  onAdmitted,
}: PatientWardAdmissionButtonProps) {
  const { showAdmitPatientModal } = usePatientWardAdmissionModal({
    patientId,
    patientName,
    onAdmitted,
  });

  return (
    <Button
      startIcon={<AssignmentIndIcon />}
      size="small"
      variant="contained"
      color="primary"
      onClick={() => showAdmitPatientModal()}>
      Admit to ward
    </Button>
  );
}
