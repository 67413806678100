import auth from '@/controllers/Auth';

import { Organization, User, MakeOptional } from '@/generated/graphql';

/**
 * Format a user's name for display.
 *
 * When possible, the user's first and last name will be used. If either is missing, the user's email
 * will be used. If the user is missing entirely, a fallback text will be used.
 *
 * For the special snowflake case of the system user, the text "System" will be returned.
 */
export function formatUserName(
  user: PartialUser,
  userActingOrganization?: PartialOrganization,
  fallbackText = 'Deleted User',
): string {
  if (!user) {
    return fallbackText;
  }

  if (user.email === 'api-service-account@feebris.com') {
    return 'Feebris System';
  }

  // Users from different organisations than our own should not have their names displayed, instead
  // we will render something like "user of Example Hospital Trust". Except for self-care users,
  // because they really just "the patient", we should display their name to anyone in any org.
  if (!user.isSelfCare) {
    const loggedInUserActingOrganizationId = auth.me('actingOrganization.id');
    if (userActingOrganization && userActingOrganization.id != loggedInUserActingOrganizationId) {
      // TODO: FEP-3258, this would be an ideal place to use the org's short name
      return `User from ${userActingOrganization.name}`;
    }
  }

  if (!user.firstName || !user.lastName) {
    return user.email;
  }

  return `${user.firstName} ${user.lastName}`;
}

export type PartialUser = Maybe<
  MakeOptional<Pick<User, 'firstName' | 'lastName' | 'email' | 'isSelfCare'>, 'isSelfCare'>
>;

export type PartialOrganization = Maybe<Pick<Organization, 'id' | 'name'>>;
