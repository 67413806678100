import React, { useEffect, useState } from 'react';

import { gql } from '@apollo/client';
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Chip,
  Divider,
  Paper,
  Skeleton,
  Stack,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import { toast } from 'sonner';

import {
  useAdminDefaultFeaturesQueryInternal,
  useUpdateOrganizationFeaturesMutationInternal,
} from '@/generated/graphql-internal';

import { PageContainer } from '@/components/PageContainer';
import { PageTitle } from '@/components/PageTitle';
import { OrganizationFeatureFlags } from '@/hooks/useAuth';

interface OrganizationConfigProps {
  organizationId: string;
  features: OrganizationFeatureFlags;
}

export const QUERY_DEFAULT_FEATURES = gql`
  query AdminDefaultFeatures {
    globalState(key: "default_features") {
      key
      value
    }
  }
`;

export const UPDATE_FEATURES = gql`
  mutation UpdateOrganizationFeatures($organizationId: ID!, $features: JSONObject!) {
    updateOrganization(organization: { id: $organizationId, features: $features }) {
      ...OrganizationAdminView
    }
  }
`;

export function OrganizationSettings({ features, organizationId }: OrganizationConfigProps) {
  const [currentFeatureState, setCurrentFeatureState] =
    useState<OrganizationFeatureFlags>(features);

  const { data: defaultFeaturesData, loading: loadingDefaultFeatures } =
    useAdminDefaultFeaturesQueryInternal();
  const defaultFeatures = defaultFeaturesData?.globalState?.[0]?.value;

  const [updateFeatures, { loading: updatingFeatureFlags }] =
    useUpdateOrganizationFeaturesMutationInternal({
      onCompleted: () => {
        toast.success('Organization settings updated');
      },
      onError: () => {
        toast.error('Failed to update organization settings');
      },
    });

  useEffect(() => {
    setCurrentFeatureState(features);
  }, [features]);

  const setFeatureFlag = (flag: keyof OrganizationFeatureFlags, value: boolean | number) => {
    setCurrentFeatureState((state) => ({ ...state, [flag]: value }));
  };

  const isFlagPendingChange = (flag: keyof OrganizationFeatureFlags) =>
    currentFeatureState[flag] !== features[flag];

  const hasPendingChanges = Object.keys(currentFeatureState).some((key) =>
    isFlagPendingChange(key as keyof OrganizationFeatureFlags),
  );

  const formatFeatureFlagDefaultValue = (flag: keyof OrganizationFeatureFlags) => {
    const meta = featureFlagMeta[flag];
    return meta.type === 'boolean'
      ? defaultFeatures?.[flag]
        ? 'enabled'
        : 'disabled'
      : (meta.scale ?? 1) * defaultFeatures?.[flag];
  };

  const deviatedFromDefault = (flag: keyof OrganizationFeatureFlags) =>
    features[flag] !== defaultFeatures?.[flag];

  const resetChanges = () => setCurrentFeatureState(features);

  const saveChanges = () => {
    updateFeatures({
      variables: {
        organizationId,
        features: currentFeatureState,
      },
    });
  };

  return (
    <PageContainer>
      <div>
        <PageTitle
          title="Organisation Settings"
          subtitle="Manage feature flags for an organisation"
        />

        <Paper sx={{ paddingY: 4, paddingX: 5, width: '100%' }}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            marginBottom={2}>
            <Typography variant="h6">Feature flags</Typography>
            {hasPendingChanges ? (
              <Chip label="unsaved changes" color="warning" size="small" />
            ) : null}
          </Stack>
          <Alert severity="info" sx={{ marginBottom: 2 }}>
            <AlertTitle>
              Changes to features will not take effect immediately for all users
            </AlertTitle>
            Portal users will need to do a full page refresh and mobile app users will need to
            refresh their patients list to receive feature switch changes.
          </Alert>
          {Object.entries(featureFlagMeta).map(([_flag, meta], i) => {
            const flag = _flag as keyof OrganizationFeatureFlags;
            const isLast = i === Object.keys(featureFlagMeta).length - 1;
            return (
              <Box
                key={flag}
                sx={{
                  borderRadius: 2,
                  '&:hover': {
                    backgroundColor: 'grey.100',
                  },
                }}>
                <Stack
                  paddingX={2}
                  paddingTop={2}
                  paddingBottom={isLast ? 2 : 0}
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center">
                  <Stack>
                    <Stack direction="row" alignItems="flex-start" gap={1}>
                      <Typography
                        sx={(t) => ({
                          fontSize: t.typography.pxToRem(18),
                        })}
                        color="primary.dark">
                        {meta.title}
                      </Typography>
                      {loadingDefaultFeatures ? (
                        <Skeleton width={50} sx={{ display: 'inline-block' }} />
                      ) : deviatedFromDefault(flag) ? (
                        <Chip
                          sx={{ marginLeft: 1 }}
                          size="small"
                          variant="outlined"
                          label={`Changed from default: ${formatFeatureFlagDefaultValue(flag)}`}
                        />
                      ) : null}
                      {isFlagPendingChange(flag) && (
                        <Chip label="pending" color="warning" size="small" />
                      )}
                    </Stack>
                    <Typography variant="body2" color="grey.700">
                      {meta.description}
                    </Typography>
                  </Stack>
                  <div>
                    {meta.type === 'boolean' ? (
                      <BooleanFeatureFlagSetting
                        type="boolean"
                        name={flag}
                        defaultValue={defaultFeatures?.[flag] as boolean}
                        value={currentFeatureState[flag] as boolean}
                        onChange={(value) => setFeatureFlag(flag, value)}
                      />
                    ) : (
                      <NumericFeatureFlagSetting
                        type="number"
                        name={flag}
                        defaultValue={defaultFeatures?.[flag] as number}
                        value={currentFeatureState[flag] as number}
                        onChange={(value) => setFeatureFlag(flag, value)}
                        scale={meta.scale}
                        min={meta.min}
                        max={meta.max}
                        step={meta.step}
                      />
                    )}
                  </div>
                </Stack>
                {!isLast && <Divider sx={{ marginTop: 2, marginX: 2 }} />}
              </Box>
            );
          })}
          <Stack direction="row" justifyContent="flex-end" gap={2} marginTop={2}>
            <Button
              onClick={() => resetChanges()}
              disabled={!hasPendingChanges || updatingFeatureFlags}>
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={() => saveChanges()}
              disabled={!hasPendingChanges || updatingFeatureFlags}>
              Save changes
            </Button>
          </Stack>
        </Paper>
      </div>
    </PageContainer>
  );
}

interface BooleanFeatureFlagSettingProps {
  type: 'boolean';
  name: string;
  defaultValue: Maybe<boolean>;
  value: boolean;
  onChange: (value: boolean) => void;
}

interface NumericFeatureFlagSettingProps {
  type: 'number';
  name: string;
  defaultValue: Maybe<number>;
  value: number;
  format?: (value: number) => number;
  onChange: (value: number) => void;
  scale?: number;
  min?: number;
  max?: number;
  step?: number;
}

function BooleanFeatureFlagSetting({ name, value, onChange }: BooleanFeatureFlagSettingProps) {
  return <Switch checked={value} onChange={(e) => onChange(e.target.checked)} name={name} />;
}

function NumericFeatureFlagSetting({
  name,
  value,
  onChange,
  scale = 1,
  min,
  max,
  step,
}: NumericFeatureFlagSettingProps) {
  return (
    <TextField
      name={name}
      type="number"
      variant="outlined"
      sx={{ width: 80 }}
      value={value * scale}
      inputProps={{ min, max, step }}
      onChange={(e) => onChange(Number(e.target.value) / scale)}
    />
  );
}

interface NumbericFeatureFlagMeta {
  title: string;
  description: string;
  scale?: number;
  type: 'number';
  min?: number | undefined;
  max?: number | undefined;
  step?: number | undefined;
}

interface BooleanFeatureFlagMeta {
  title: string;
  description: string;
  type: 'boolean';
}

type FeatureFlagMeta = NumbericFeatureFlagMeta | BooleanFeatureFlagMeta;
const featureFlagMeta: Record<keyof OrganizationFeatureFlags, FeatureFlagMeta> = {
  nhsNumberRetrieval: {
    title: 'NHS Number',
    description:
      'Enables NHS Number input and automatic retrieval from the NHS Spine when creating / updating patients',
    type: 'boolean',
  },
  stethoscopeQualityEnabled: {
    title: 'Stethoscope Quality',
    description: 'Enable Stethoscope Quality for patients',
    type: 'boolean',
  },
  skipConsent: {
    title: 'Skip Consent',
    description: 'Skip the consent step when submitting the first checkup for a new patient',
    type: 'boolean',
  },
  intercom: {
    title: 'Intercom',
    description: 'Enable Intercom integration for the organisation',
    type: 'boolean',
  },
  selfCare: {
    title: 'Self Care Patients',
    description: 'Enable creation of self-care patients',
    type: 'boolean',
  },
  skipNextAction: {
    title: 'Skip Next Action',
    description: 'Skip selected action and patient stability screens when running a checkup',
    type: 'boolean',
  },
  automaticLogoutAlert: {
    title: 'Automatic Logout Alert',
    description: 'Portal automatic logout time in minutes (0 to disable)',
    type: 'number',
    scale: 1 / (1_000 * 60),
    step: 1,
    min: 0,
  },
  wards: {
    title: 'Wards',
    description: 'Enable creation and management of wards',
    type: 'boolean',
  },
  videoCall: {
    title: 'Video Call',
    description: 'Enable video calls for patients',
    type: 'boolean',
  },
  virtualWardAllPatientsLegacyBehavior: {
    title: 'Show all patients on virtual ward',
    description:
      'Show all patients on virtual ward, regardless of ward assignment (this is a legacy behavior and should be disabled for care homes)',
    type: 'boolean',
  },
  checkupSchedule: {
    title: 'Checkup Schedule',
    description: 'Enable Checkup Schedules against checkup types (with alerts when overdue)',
    type: 'boolean',
  },
  continuousMonitoring: {
    title: 'Continuous Monitoring',
    description: 'Enable Continuous Monitoring (Vivalink) for patients',
    type: 'boolean',
  },
  continuousMonitoringSpo2FromMax: {
    title: 'Continuous Monitoring SpO2 From Max',
    description: 'Source SpO2 from the maximum value in a 15 minute bucket, otherwise the median',
    type: 'boolean',
  },
  activityMonitoring: {
    title: 'Activity Monitoring',
    description: 'Enable Activity Monitoring (Pacsana) for patients',
    type: 'boolean',
  },
  ehrIntegrations: {
    title: 'EHR Integrations',
    description: 'Enable SystmOne and EMIS integrations on pathways',
    type: 'boolean',
  },
  integrationApi: {
    title: 'Integration API',
    description: 'Enable Integration API for patients',
    type: 'boolean',
  },
  cleoIntegrationApi: {
    title: 'Cleo Integration API',
    description: 'Enable Cleo Integration API for patients',
    type: 'boolean',
  },
  globalPatientRecord: {
    title: 'Global Patient Record Search',
    description:
      'Changes the patient admit flow to search patients across all organisations (in development)',
    type: 'boolean',
  },
};
