import React from 'react';
import { Box } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

import { range } from 'lodash';
import { useVirtualWardContext } from '../VirtualWardContext';
import { PatientListItem } from './PatientListItem';
import { PatientListPagination } from './PatientListPagination';
import { SkeletonPatientListItem } from './SkeletonPatientListItem';
import { SortMenu } from './SortMenu';
import { useDebounce } from '@/hooks/useDebounce';
import { NoResults } from './NoResults';
import { isDefined } from '@/helpers/isDefined';
import { ErrorDisplay } from '@/components/ErrorDisplay';
import { FilterButton } from './FilterButton';
import { FilterTags } from './FilterTags';

export function PatientList() {
  const classes = useStyles();

  const {
    patients,
    isLoadingPatientsFirstTime,
    isLoadingPatients,
    pageSize,
    patientFetchError,
    refresh,
  } = useVirtualWardContext();
  const [expandedPatientIds, setExpandedPatientIds] = React.useState<string[]>([]);

  const debouncedIsLoadingPatientsFirstTime = useDebounce(isLoadingPatientsFirstTime, 100);
  const skeletonLength = Math.min(10, pageSize);

  /**
   * Show the full error screen if we haven't successfully loaded any patients yet
   * TODO: add toast / snackbars for failed requests after the initial load
   */
  const showError =
    !debouncedIsLoadingPatientsFirstTime && !patients?.length && isDefined(patientFetchError);

  const handlePatientExpandedToggle = (patientId: string) => {
    setExpandedPatientIds((prevExpandedPatientIds) => {
      if (prevExpandedPatientIds.includes(patientId)) {
        return prevExpandedPatientIds.filter((id) => id !== patientId);
      } else {
        return [...prevExpandedPatientIds, patientId];
      }
    });
  };

  return (
    <Box display="flex" justifyContent="center" margin={2} flexGrow={1}>
      <Box
        display="flex"
        flexDirection="column"
        maxWidth={1440}
        flexBasis={1440}
        flexGrow={1}
        flexShrink={1}>
        <Box display="flex" justifyContent="space-between" alignItems="flex-end">
          <div className={classes.customiseSection}>
            <FilterButton />
            <SortMenu />
          </div>
          <PatientListPagination />
        </Box>
        <Box marginTop={1}>
          <FilterTags />
        </Box>
        <Box flexGrow={1} role="list" aria-busy={isLoadingPatients}>
          {debouncedIsLoadingPatientsFirstTime &&
            range(0, skeletonLength).map((_, i) => (
              <Box key={i} marginTop={2} marginBottom={2}>
                <SkeletonPatientListItem />
              </Box>
            ))}
          {showError && (
            <ErrorDisplay
              message="Failed to fetch patients from the server. Press retry to try again."
              retry={refresh}
              showIcon
            />
          )}
          {!debouncedIsLoadingPatientsFirstTime && patients?.length
            ? patients.map((patient) => (
                <Box
                  display="flex"
                  key={patient.id}
                  marginTop={2}
                  marginBottom={2}
                  role="presentation">
                  <PatientListItem
                    item={patient}
                    isExpanded={expandedPatientIds.includes(patient.id)}
                    onExpandToggle={handlePatientExpandedToggle}
                  />
                </Box>
              ))
            : null}
          {!debouncedIsLoadingPatientsFirstTime && patients?.length === 0 && <NoResults />}
        </Box>
        <PatientListPagination />
      </Box>
    </Box>
  );
}

const useStyles = makeStyles((theme) => ({
  customiseSection: {
    display: 'flex',
    gap: theme.spacing(1),
  },
}));
