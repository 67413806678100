import React, { useState } from 'react';

import PatientBulkUploadModal from '@/components/PatientBulkUploadModal';
import { useHistory } from 'react-router-dom';
import { gql } from '@apollo/client';
import { toast } from 'sonner';

import { ManagePatientItemFragment, useGetPatientsQuery } from '@/generated/graphql';

import PatientList from './PatientList';
import { PageTitle } from '@/components/PageTitle';
import { RestrictedRouteTester } from '@/components/ProtectedRoute';
import { PageContainer } from '@/components/PageContainer';

export const canAccessManagePatientsPage: RestrictedRouteTester = ({ permissions }) =>
  permissions['view_patient_archive'];

export const QUERY_PATIENTS = gql`
  fragment ManagePatientItem on Patient {
    id
    createdAt
    firstName
    lastName
    gender
    birthDate
    address {
      address
      postcode
    }
    telephone
    wards {
      id
      name
      createdAt
    }
    practices: organizations(type: "practice") {
      ...PatientPracticeAssignment
    }
    preExistingConditions
    nhsNumber
    selfCare {
      id
      email
      canResetPassword
    }
  }

  query GetPatients {
    patients(isTestPatient: false) {
      ...ManagePatientItem
    }
  }
`;

const ManagePatients = () => {
  const { push } = useHistory();

  const { data, refetch: reloadPatients } = useGetPatientsQuery({
    onError: () => toast.error('An error occurred while loading patients'),
  });

  const patients = data?.patients;

  const [open, setOpen] = useState(false);

  return (
    <>
      <PageContainer>
        <PageTitle
          title="Manage Patients"
          subtitle="Add or update patients and manage clinical organisation assignments"
        />
        <PatientList
          patients={patients ?? []}
          isLoading={!patients}
          reloadPatients={reloadPatients}
          openPatientBulkUploadModal={() => setOpen(true)}
          onRowClick={(rowData: ManagePatientItemFragment) => push(`/patient/${rowData.id}`)}
        />
      </PageContainer>
      <PatientBulkUploadModal
        open={open}
        onClose={() => setOpen(false)}
        reloadPatients={reloadPatients}
      />
    </>
  );
};

export default ManagePatients;
