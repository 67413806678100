import React from 'react';

import { Box, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation, TFunction } from 'react-i18next';
import { feebrisFormatter } from '@/helpers/LocaleFormatting';
import { isDefined } from '@/helpers/isDefined';
import { QuestionnaireQuestion } from '@/helpers/questionnaire';
import dateTimeFormatter from '@/helpers/LocaleFormatting';
import { SoftSignsChips } from '@/components/SoftSignsChips';

import { VirtualWardPatientCheckupFragment } from '@/generated/graphql';

import { CheckupValue } from './CheckupValue';
import { formatCheckupTypeName } from '../formatters';

interface LatestCheckupValuesProps {
  checkup: VirtualWardPatientCheckupFragment;
}

export function LatestCheckupValues({ checkup }: LatestCheckupValuesProps) {
  const classes = useStyles();

  const { t } = useTranslation();

  const hasVitals = Object.values(checkup?.ewsScores ?? {}).some((score) => score !== null);

  const formattedCheckupEndedAt = dateTimeFormatter.formatCheckupTimeLongWithoutWeekDay(
    checkup.endedAt,
  );

  return (
    <>
      <Typography className={classes.header}>
        Latest Checkup:{' '}
        <span className={classes.checkupType}>{formatCheckupTypeName(checkup)}</span> (
        {formattedCheckupEndedAt})
      </Typography>
      <Box display="flex">
        <Box display="flex" flexDirection="column" marginTop={2} marginRight={2} flex="1">
          <Typography className={classes.vitalsSubHeader}>Vitals</Typography>
          {hasVitals ? (
            <Box display="flex" flexWrap="wrap" data-testid="latestCheckupVitals">
              {checkup.bloodPressureData && (
                <CheckupValue
                  shortLabel="BP"
                  longLabel="Blood Pressure"
                  width={75}
                  value={`${checkup.bloodPressureData.systolic}/${checkup.bloodPressureData.diastolic}`}
                  score={checkup?.ewsScores?.BPScore}
                  popoverFields={checkupPopoverFields(t, checkup, 'bloodPressureData', [
                    checkup?.bloodPressureData
                      ? {
                          label: 'Position',
                          value: checkup?.bloodPressureData?.position
                            ? t(`BLOOD_PRESSURE_POSITION.${checkup?.bloodPressureData?.position}`)
                            : '-',
                        }
                      : undefined,
                  ])}
                  alwaysOutline
                  slimMargin
                />
              )}
              {checkup.pulseOxiData && (
                <CheckupValue
                  shortLabel="SpO2"
                  longLabel="Oxygen Saturation"
                  width={75}
                  value={checkup.pulseOxiData.averageSpO2}
                  score={checkup?.ewsScores?.SpO2Score}
                  popoverFields={checkupPopoverFields(t, checkup, 'pulseOxiData')}
                  alwaysOutline
                  slimMargin
                />
              )}
              {checkup.respiratoryRate && (
                <CheckupValue
                  shortLabel="RR"
                  longLabel="Respiratory Rate"
                  width={75}
                  value={checkup.respiratoryRate?.value}
                  score={checkup?.ewsScores?.RRScore}
                  popoverFields={checkupPopoverFields(t, checkup, 'respiratoryRate', [
                    checkup?.respiratoryRate?.source
                      ? {
                          label: 'Source',
                          value: t(`RESPIRATORY_RATE_SOURCE.${checkup?.respiratoryRate.source}`),
                        }
                      : undefined,
                  ])}
                  alwaysOutline
                  slimMargin
                />
              )}
              {checkup.pulseRate && (
                <CheckupValue
                  shortLabel="Pulse"
                  width={75}
                  value={checkup.pulseRate.value}
                  score={checkup?.ewsScores?.HRScore}
                  popoverFields={checkupPopoverFields(t, checkup, 'pulseRate', [
                    checkup?.pulseRate?.source && {
                      label: 'Source',
                      value: t(`PULSE_RATE_SOURCE.${checkup?.pulseRate.source}`),
                    },
                    checkup?.pulseRate?.isManual
                      ? {
                          label: 'Manual reading',
                          value: '',
                        }
                      : undefined,
                  ])}
                  alwaysOutline
                  slimMargin
                />
              )}
              {checkup.temperature && (
                <CheckupValue
                  shortLabel="Temp"
                  longLabel="Temperature"
                  width={75}
                  value={feebrisFormatter.formatTemperature(checkup.temperature)}
                  score={checkup?.ewsScores?.tempScore}
                  popoverFields={checkupPopoverFields(t, checkup, 'temperature', [
                    {
                      label: 'Units',
                      value: feebrisFormatter.temperatureUnits(),
                    },
                  ])}
                  alwaysOutline
                  slimMargin
                />
              )}
              {checkup.consciousness && (
                <CheckupValue
                  shortLabel="LOC"
                  longLabel="Level of Consciousness"
                  width={75}
                  value={checkup.consciousness}
                  longValue={`${t(`CONSCIOUSNESS_SHORT.${checkup.consciousness}`)} - ${t(
                    `CONSCIOUSNESS_LONG.${checkup.consciousness}`,
                  )}`}
                  score={checkup?.ewsScores?.consciousnessScore}
                  popoverFields={checkupPopoverFields(t, checkup, 'consciousness')}
                  alwaysOutline
                  slimMargin
                />
              )}
              {checkup.weight && (
                <CheckupValue
                  shortLabel="Weight"
                  longLabel="Weight"
                  width={75}
                  value={feebrisFormatter.formatWeight(checkup.weight)}
                  popoverFields={checkupPopoverFields(t, checkup, 'weight', [
                    {
                      label: 'Units',
                      value: feebrisFormatter.weightUnits(),
                    },
                  ])}
                  alwaysOutline
                  slimMargin
                />
              )}
              {checkup.glucose && (
                <CheckupValue
                  shortLabel="Glucose"
                  longLabel="Glucose"
                  width={75}
                  value={feebrisFormatter.formatGlucose(checkup.glucose.reading)}
                  popoverFields={checkupPopoverFields(t, checkup, 'glucose', [
                    {
                      label: 'Units',
                      value: feebrisFormatter.glucoseUnits(),
                    },
                  ])}
                  alwaysOutline
                  slimMargin
                />
              )}
            </Box>
          ) : (
            <Typography className={classes.noVitalsMessage}>
              No readings have been taken for this checkup
            </Typography>
          )}
        </Box>
        {checkup.questionnaire && (
          <Box marginTop={2} flex="2">
            <Typography className={classes.vitalsSubHeader}>Soft Signs</Typography>
            <SoftSignsChips
              questionnaire={(checkup.questionnaire as QuestionnaireQuestion[]) ?? []}
              showHeader={false}
            />
          </Box>
        )}
      </Box>
    </>
  );
}

function checkupPopoverFields(
  t: TFunction,
  checkup: VirtualWardPatientCheckupFragment,
  checkupKey:
    | 'bloodPressureData'
    | 'pulseOxiData'
    | 'respiratoryRate'
    | 'pulseRate'
    | 'temperature'
    | 'consciousness'
    | 'weight'
    | 'glucose',
  extraFields: ({ label: string; value: string } | undefined)[] = [],
): { label: string; value: string }[] {
  return [
    ...extraFields,
    checkup?.[checkupKey]
      ? {
          label: t('Captured on'),
          value: dateTimeFormatter.formatCheckupTimeLongWithoutWeekDay(checkup.endedAt),
        }
      : undefined,
  ].filter(isDefined);
}

const useStyles = makeStyles((theme) => ({
  header: {
    fontSize: theme.typography.body1.fontSize,
    fontWeight: 500,
  },
  checkupType: {
    textTransform: 'capitalize',
  },
  vitalsSubHeader: {
    marginBottom: theme.spacing(1.5),
    fontSize: theme.typography.body2.fontSize,
    fontWeight: 500,
  },
  noVitalsMessage: {
    marginTop: theme.spacing(1.5),
    marginLeft: theme.spacing(1),
    fontSize: theme.typography.body2.fontSize,
  },
}));
