import React from 'react';

import '@/styles/components/blocks/block-steth-player.scss';

import IconButton from '@mui/material/IconButton';
import PlayIcon from '@mui/icons-material/PlayCircleOutline';

import WaveSurfer from 'wavesurfer.js';

// Position constants
const POSITION = {
  UPPER_LEFT: 'upper_left',
  UPPER_RIGHT: 'upper_right',
  LOWER_LEFT: 'lower_left',
  LOWER_RIGHT: 'lower_right',
  LUNG_POSITION_FRONT: 'lung_front',
  LUNG_POSITION_BACK: 'lung_back',
};

import maleReadingFrontImage from './images/steth-outlays/male/Male_Reading_Front.png';
import maleReadingBackImage from './images/steth-outlays/male/Male_Reading_Back.png';
import femaleReadingFrontImage from './images/steth-outlays/female/Female_Reading_Front.png';
import femaleReadingBackImage from './images/steth-outlays/female/Female_Reading_Back.png';

import maleToggleFrontImage from './images/steth-outlays/male/male_toggle_front.png';
import maleToggleBackImage from './images/steth-outlays/male/male_toggle_back.png';
import femaleToggleFrontImage from './images/steth-outlays/female/female_toggle_front.png';
import femaleToggleBackImage from './images/steth-outlays/female/female_toggle_back.png';

import inactiveUpperRightImage from './images/lung-quadrants/inactive/UpperRight.png';
import activeUpperRightImage from './images/lung-quadrants/active/UpperRight.png';
import inactiveLowerLeftImage from './images/lung-quadrants/inactive/LowerLeft.png';
import activeLowerLeftImage from './images/lung-quadrants/active/LowerLeft.png';
import inactiveLowerRightImage from './images/lung-quadrants/inactive/LowerRight.png';
import activeLowerRightImage from './images/lung-quadrants/active/LowerRight.png';
import inactiveUpperLeftImage from './images/lung-quadrants/inactive/UpperLeft.png';
import activeUpperLeftImage from './images/lung-quadrants/active/UpperLeft.png';

const stethMainOutlays = {
  male: {
    front: maleReadingFrontImage,
    back: maleReadingBackImage,
  },
  female: {
    front: femaleReadingFrontImage,
    back: femaleReadingBackImage,
  },
};

const stethToggleImages = {
  male: {
    front: maleToggleFrontImage,
    back: maleToggleBackImage,
  },
  female: {
    front: femaleToggleFrontImage,
    back: femaleToggleBackImage,
  },
};

class BlockStethPlayer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      lungPosition: 'lung_front',
      currentlyPlayingUrl: '',
      isFemale: false,

      audioStateReady: false, // Display lung controls
      audioPlayerReady: false, // Display wave player and controls

      audioData: {},
      referralId: '',
      cloudLungRecsLength: 0, // total no of files uploaded to the cloud against this checkup
      isPlaying: false,
    };
  }

  async handleClickLungPosition(renderPosition) {
    const lungPosition = this.state.lungPosition;

    const audioUrl = this.state.audioData[lungPosition][renderPosition].audio;

    if (audioUrl) {
      this.setState(
        {
          activeRenderPosition: renderPosition,
          currentlyPlayingUrl: this.state.audioData[lungPosition][renderPosition].audio,
        },
        () => {
          this.wavesurfer.load(this.state.currentlyPlayingUrl);
        },
      );
    }
  }

  renderStethStartPlaybackIcon(renderPosition) {
    const lungPosition = this.state.lungPosition;

    const iconToRender = 'play-icon';

    let iconColor = '#383838';
    let iconDisabled = false;
    let opacity = 1;

    if (this.state.audioData[lungPosition][renderPosition].audio) {
      iconColor = '#383838';
    } else {
      iconColor = '#383838';
      iconDisabled = true;
      opacity = 0.2;
    }

    return (
      <div className={`play-icon__${renderPosition.replace('_', '__')}`}>
        <IconButton
          aria-label={iconToRender}
          disabled={iconDisabled}
          onClick={() => this.handleClickLungPosition(renderPosition)}
          size="large">
          <PlayIcon
            fontSize="large"
            style={{
              color: iconColor,
              opacity: opacity,
            }}
          />
        </IconButton>
      </div>
    );
  }

  renderQuadrantOutlay(renderPosition) {
    // RN forces us to write it this way, lack of dynamic require statements
    // Super UGLY and inefficient!!!
    let inactiveImgUrl;
    let activeImgUrl;

    switch (renderPosition) {
      case POSITION.UPPER_RIGHT:
        inactiveImgUrl = inactiveUpperRightImage;
        activeImgUrl = activeUpperRightImage;
        break;
      case POSITION.LOWER_LEFT:
        inactiveImgUrl = inactiveLowerLeftImage;
        activeImgUrl = activeLowerLeftImage;
        break;
      case POSITION.LOWER_RIGHT:
        inactiveImgUrl = inactiveLowerRightImage;
        activeImgUrl = activeLowerRightImage;
        break;
      default:
        inactiveImgUrl = inactiveUpperLeftImage;
        activeImgUrl = activeUpperLeftImage;
    }

    let finalImage = inactiveImgUrl;

    if (this.state.activeRenderPosition === renderPosition) {
      finalImage = activeImgUrl;
    }

    return <img src={finalImage} alt="" />;
  }

  renderStethMainOutlay() {
    const patientGender = this.state.isFemale === true ? 'female' : 'male';
    const lungPosition = this.state.lungPosition === 'lung_front' ? 'front' : 'back';
    let stethOutlayToUse = stethMainOutlays[patientGender][lungPosition];
    return <img src={stethOutlayToUse} alt="" />;
  }

  toggleLungPosition() {
    const dupState = { ...this.state };

    const lungPosition = this.state.lungPosition;
    let newLungPosition = POSITION.LUNG_POSITION_BACK;

    if (lungPosition === POSITION.LUNG_POSITION_FRONT) {
      newLungPosition = POSITION.LUNG_POSITION_BACK;
    } else if (lungPosition === POSITION.LUNG_POSITION_BACK) {
      newLungPosition = POSITION.LUNG_POSITION_FRONT;
    }

    dupState.lungPosition = newLungPosition;

    this.setState(dupState);
  }

  renderStethToggle() {
    const patientGender = this.state.isFemale === true ? 'female' : 'male';
    const lungPosition = this.state.lungPosition === 'lung_front' ? 'back' : 'front';
    const prettyLungPosName = this.state.lungPosition === 'lung_front' ? 'Back' : 'Front';

    const toggleImage = stethToggleImages[patientGender][lungPosition];

    return (
      <div className="block--steth-player__toggle" onClick={this.toggleLungPosition.bind(this)}>
        <img src={toggleImage} alt="" />
        <p>{prettyLungPosName}</p>
      </div>
    );
  }

  async setData(data) {
    let isFemale = true;

    if (data.gender === 'male') {
      isFemale = false;
    }

    this.setState({
      audioData: data.audioData,
      isFemale,
      referralId: data.referralId,
      cloudLungRecsLength: Number(data.cloudLungRecsLength),
    });

    if (!this.state.audioStateReady) {
      this.setState(
        {
          audioStateReady: true,
          audioPlayerReady: false,
        },
        () => {
          this.initAudioPLayer();
        },
      );
    }
  }

  initAudioPLayer() {
    if (this.wavesurfer) {
      this.wavesurfer.destroy();
    }

    this.setState({
      audioPlayerReady: false,
      currentlyPlayingUrl: '',
    });

    // The "backend: MediaElement" was added to try to solve the issue of the black box in Windows browsers
    // however, it's possible that the fix is the upgrade to version 3.3.3
    // TODO: try removing it and make sure it still works
    this.wavesurfer = WaveSurfer.create({
      backend: 'MediaElement',
      container: '#waveplayer',
      progressColor: 'rgba(0, 0, 0, .8)',
      waveColor: 'rgba(0, 0, 0, .2)',
    });

    this.wavesurfer.on('ready', () => {
      const duration = this.calculateCurrentValue(this.wavesurfer.getCurrentTime());

      this.setState({
        audioPlayerReady: true,
        playerDuration: duration,
        playerTime: '00:00:00',
      });
    });

    this.wavesurfer.on('play', () => {
      window.gtag('event', 'play_lung_sound');
      this.setState({
        isPlaying: true,
      });
    });

    this.wavesurfer.on('pause', () => {
      this.setState({
        isPlaying: false,
      });
    });

    this.wavesurfer.on('seek', () => {
      const time = this.calculateCurrentValue(this.wavesurfer.getCurrentTime());

      this.setState({
        playerTime: time,
      });
    });

    this.wavesurfer.on('audioprocess', () => {
      const time = this.calculateCurrentValue(this.wavesurfer.getCurrentTime());

      this.setState({
        playerTime: time,
      });
    });
  }

  calculateCurrentValue(timeInSeconds) {
    const pad = function (num, size) {
      return ('000' + num).slice(size * -1);
    };

    const time = parseFloat(timeInSeconds).toFixed(3);
    const minutes = Math.floor(time / 60) % 60;
    const seconds = Math.floor(time - minutes * 60);
    const milliseconds = time.slice(-3);

    return pad(minutes, 2) + ':' + pad(seconds, 2) + ':' + pad(milliseconds, 3);
  }

  handleToggle() {
    if (!this.state.audioPlayerReady) {
      return;
    }

    if (this.wavesurfer.isPlaying()) {
      this.wavesurfer.pause();
    } else {
      this.wavesurfer.play();
    }
  }

  render() {
    return (
      <div className="block block--steth-player no-print">
        <div className="block__title">Breath Sounds</div>

        <div className="block--steth-player__inner">
          {this.renderStethToggle()}

          <div className="block--steth-player__body">
            {this.renderStethMainOutlay()}

            <div style={{ top: '54%', left: '25%', position: 'absolute' }}>
              {this.renderQuadrantOutlay(POSITION.UPPER_LEFT)}
              {this.state.audioStateReady === true &&
                this.renderStethStartPlaybackIcon(POSITION.UPPER_LEFT)}
            </div>

            <div style={{ top: '54%', right: '25%', position: 'absolute' }}>
              {this.renderQuadrantOutlay(POSITION.UPPER_RIGHT)}
              {this.state.audioStateReady === true &&
                this.renderStethStartPlaybackIcon(POSITION.UPPER_RIGHT)}
            </div>
            <div style={{ top: '76%', left: '25%', position: 'absolute' }}>
              {this.renderQuadrantOutlay(POSITION.LOWER_LEFT)}
              {this.state.audioStateReady === true &&
                this.renderStethStartPlaybackIcon(POSITION.LOWER_LEFT)}
            </div>
            <div style={{ top: '76%', right: '25%', position: 'absolute' }}>
              {this.renderQuadrantOutlay(POSITION.LOWER_RIGHT)}
              {this.state.audioStateReady === true &&
                this.renderStethStartPlaybackIcon(POSITION.LOWER_RIGHT)}
            </div>
          </div>
        </div>

        <div
          className={`block--steth-player__player${
            this.state.audioPlayerReady === true ? ' is-active' : ''
          }`}>
          <div className="block--steth-player__player__controls">
            <button className="play_pause_button" onClick={() => this.handleToggle()}>
              <i className={this.state.isPlaying ? 'ri-pause-fill' : 'ri-play-fill'}></i>
            </button>
            <div className="time">{this.state.playerTime}</div>
          </div>
          <div id="waveplayer"></div>
          <div id="wave-spectrogram"></div>
        </div>
      </div>
    );
  }
}

export default BlockStethPlayer;
