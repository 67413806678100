import { useJourneyModalContext } from '@/components/JourneyModal/JourneyModal';
import { Patient, PatientLookupViewFragment } from '@/generated/graphql';

export interface AdmitPatientJourneyContext {
  patient: PatientLookupViewFragment | null;
  contactUpdated: boolean;
  patientCreated: boolean;
  selfCareLoginCreated: boolean;
  patientSearchForm: Pick<Patient, 'nhsNumber' | 'birthDate' | 'firstName' | 'lastName'> | null;
  pendingWardAdmission: {
    wardId: string;
    wardName: string;
    carePathwayId: string;
    carePathwayName: string;
    isSelfCare: boolean;
  } | null;
}

export type AdmitPatientJourneyStep =
  | 'search'
  | 'confirmOrCreatePatient'
  | 'addContactDetails'
  | 'setupSelfCare'
  | 'admissionDetails'
  | 'confirm'
  | 'success';

export const useAdmitPatientJourneyContext = () =>
  useJourneyModalContext<AdmitPatientJourneyContext, AdmitPatientJourneyStep>();
