import React, { useCallback, useState } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import makeStyles from '@mui/styles/makeStyles';
import { Formik } from 'formik';
import _ from 'lodash';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import auth from '@/controllers/Auth';
import { gql } from '@apollo/client';
import {
  CreatePatientInput,
  InputAddress,
  PatientDetailsFragment,
  UpdatePatientInput,
  useCreatePatientMutation,
  useNhsNumberEditableQuery,
  useUpdatePatientMutation,
} from '@/generated/graphql';
import { getMutationErrors } from '@/AuthorizedApolloClientProvider';
import { Alert, AlertTitle } from '@mui/material';
import { useModal } from 'mui-modal-provider';
import { toast } from 'sonner';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  formError: {
    color: theme.palette.error.main,
  },
  editPatientButton: {
    marginLeft: theme.spacing(26),
  },
  cancelPatientButton: {
    marginLeft: theme.spacing(4),
  },
  textField: {
    display: 'block',
    marginBottom: theme.spacing(2),
  },
  actions: {
    marginBottom: theme.spacing(2),
  },
}));

interface PatientFormModalProps extends PatientFormProps {
  open: boolean;
  onClose: () => void;
}

export default function PatientFormModal({ open, onClose, ...rest }: PatientFormModalProps) {
  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title">
      <PatientForm onClose={onClose} {...rest} />
    </Dialog>
  );
}

export const GET_NHS_NUMBER_EDITABLE = gql`
  query NhsNumberEditable($patientId: ID!) {
    patient(id: $patientId) {
      nhsNumberResponseDetails {
        editable
      }
    }
  }
`;

interface PatientFormProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  patient: any;
  onComplete: () => void;
  onClose: () => void;
  setFlashMessage: (message: string) => void;
}

export const UPDATE_PATIENT = gql`
  mutation UpdatePatient($patient: UpdatePatientInput!) {
    patient: updatePatient(patient: $patient) {
      ...PatientDetails
    }
  }
`;

export const CREATE_PATIENT = gql`
  mutation CreatePatient($patient: CreatePatientInput!) {
    patient: createPatient(patient: $patient) {
      ...PatientDetails
    }
  }
`;

function PatientForm({ patient, onComplete, onClose, setFlashMessage }: PatientFormProps) {
  const { t } = useTranslation();
  const classes = useStyles();
  const [updatePatientMutation] = useUpdatePatientMutation();
  const [createPatientMutation] = useCreatePatientMutation();

  const isNewPatient = !patient.id;
  const { data: nhsNumberEditableResponse, loading: loadingIsEditable } = useNhsNumberEditableQuery(
    {
      skip: isNewPatient,
      variables: { patientId: patient.id },
    },
  );
  const nhsNumberEditable =
    isNewPatient ||
    (nhsNumberEditableResponse?.patient?.nhsNumberResponseDetails?.editable ?? true);

  const canCreateSelfCarePatient =
    auth.me('actingOrganization.features.selfCare', false) &&
    auth.me('permissions.create_patients');

  const [nhsNumberDisabled, setNhsNumberDisabled] = useState(true);
  const [isSelfCarePatient, setIsSelfCarePatient] = useState(
    isNewPatient ? canCreateSelfCarePatient : patient?.selfCare?.email,
  );
  const formTitle = patient.id ? 'Edit Patient' : 'Add Patient';
  const submitButtonTitle = patient.id ? 'Update Patient' : 'Add Patient';

  return (
    <Formik
      initialValues={{ patient }}
      onSubmit={(data, { setSubmitting, setErrors }) => {
        (async () => {
          try {
            setSubmitting(true);
            if (patient.id) {
              await updatePatientMutation({
                variables: {
                  patient: toUpdatePatientInput(data),
                },
              });
            } else {
              await createPatientMutation({
                variables: {
                  patient: toCreatePatientInput(data),
                },
              });
            }

            onComplete();
            onClose();
            if (patient.id) {
              setFlashMessage('Patient Updated');
            } else {
              setFlashMessage('Patient Added');
            }
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
          } catch (error: any) {
            const invalidArgsError = _.get(getMutationErrors(error), 'argErrors');
            if (invalidArgsError) {
              setErrors(invalidArgsError);
            } else {
              toast.error("An error occurred when saving the patient's details");
            }
          } finally {
            setSubmitting(false);
          }
        })();
      }}>
      {({
        errors,
        values,
        isSubmitting,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
      }) => {
        return (
          <form onSubmit={handleSubmit}>
            <DialogTitle id="form-dialog-title">{formTitle}</DialogTitle>
            <DialogContent>
              <Grid container>
                <Grid item xs={12} container className={classes.container} spacing={2}>
                  {auth.me('actingOrganization.features.nhsNumberRetrieval', false) && (
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        name="patient.nhsNumber"
                        value={values.patient.nhsNumber}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        label="NHS Number"
                        className={classes.textField}
                        error={_.has(errors, 'patient.nhsNumber')}
                        helperText={_.get(
                          errors,
                          'patient.nhsNumber',
                          loadingIsEditable
                            ? ''
                            : nhsNumberEditable
                            ? 'Optional, manually set the NHS number. If left blank the NHS number will be automatically fetched from the NHS Personal Demographics Service.'
                            : 'This NHS number was set by the Personal Demographics Service (PDS). If the NHS number is incorrect please contact Feebris support.',
                        )}
                        disabled={nhsNumberDisabled}
                        InputProps={{
                          endAdornment: loadingIsEditable ? (
                            <CircularProgress size={16} />
                          ) : nhsNumberDisabled && nhsNumberEditable ? (
                            <InputAdornment position="end">
                              <IconButton onClick={() => setNhsNumberDisabled(false)} size="large">
                                <EditIcon />
                              </IconButton>
                            </InputAdornment>
                          ) : null,
                        }}
                      />
                    </Grid>
                  )}
                  {canCreateSelfCarePatient && !patient.id && (
                    <Grid item xs={12}>
                      <FormControl component="fieldset">
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Switch
                                checked={isSelfCarePatient}
                                onChange={(e) => {
                                  setIsSelfCarePatient(e.target.checked);
                                  setFieldValue('patient.selfCare.email', undefined);
                                }}
                                color="primary"
                                disabled={Boolean(patient.id)}
                              />
                            }
                            label="Self-care?"
                          />
                        </FormGroup>
                        <Box marginTop={1.5} marginBottom={2.5}>
                          <Alert severity="info">
                            <AlertTitle>
                              Configure your patient for self-care using Feebris
                            </AlertTitle>
                            This toggle should be on for patients who will be recording their vitals
                            and symptoms at home via the Feebris mobile app. This ensures your
                            patient can log in to the mobile app, receive calls, and have devices
                            collected.
                          </Alert>
                        </Box>
                      </FormControl>

                      {isSelfCarePatient && (
                        <TextField
                          fullWidth
                          name="patient.selfCare.email"
                          value={values.patient?.selfCare?.email}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          label="Patient's email address"
                          className={classes.textField}
                          error={_.has(errors, 'patient.selfCare.email')}
                          required={isSelfCarePatient}
                          variant="filled"
                          helperText={_.get(
                            errors,
                            'patient.selfCare.email',
                            "A username in the email address format is required to log in. If the patient doesn't have an email, please refer to your organisation for how to create one.",
                          )}
                        />
                      )}
                    </Grid>
                  )}
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      name="patient.firstName"
                      value={values.patient.firstName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      label="First Name"
                      className={classes.textField}
                      error={_.has(errors, 'patient.firstName')}
                      helperText={_.get(errors, 'patient.firstName')}
                      inputProps={{ tabIndex: 1 }}
                      autoFocus
                      required
                      variant="filled"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      label="Last Name"
                      name="patient.lastName"
                      value={values.patient.lastName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className={classes.textField}
                      error={_.has(errors, 'patient.lastName')}
                      helperText={_.get(errors, 'patient.lastName')}
                      inputProps={{ tabIndex: 2 }}
                      required
                      variant="filled"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      name="patient.birthDate"
                      label="Date of Birth"
                      type="date"
                      className={classes.textField}
                      value={values.patient.birthDate}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      error={_.has(errors, 'patient.birthDate')}
                      helperText={_.get(errors, 'patient.birthDate')}
                      inputProps={{ tabIndex: 3, format: 'dd/mm/yyyy' }}
                      required
                      variant="filled"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl
                      className={clsx(classes.textField, 'e2e__patientgender')}
                      error={_.has(errors, 'patient.gender')}
                      required
                      variant="filled">
                      <InputLabel>Gender</InputLabel>
                      <Select
                        fullWidth
                        variant="filled"
                        name="patient.gender"
                        value={values.patient.gender || ''}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        inputProps={{ tabIndex: 4 }}>
                        <MenuItem value="male">Male</MenuItem>
                        <MenuItem value="female">Female</MenuItem>
                      </Select>
                      <FormHelperText>{_.get(errors, 'patient.gender')}</FormHelperText>
                    </FormControl>
                  </Grid>
                  {isSelfCarePatient && (
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        label="Telephone"
                        name="patient.telephone"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className={classes.textField}
                        value={_.get(values, 'patient.telephone')}
                        error={_.has(errors, 'patient.telephone')}
                        helperText={_.get(errors, 'patient.telephone', t('Phone Number Hint'))}
                        inputProps={{ tabIndex: 5 }}
                        required={isSelfCarePatient}
                        variant="filled"
                      />
                    </Grid>
                  )}
                  <Grid item xs={8}>
                    <TextField
                      fullWidth
                      label="Address"
                      name="patient.address.address"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className={classes.textField}
                      value={_.get(values, 'patient.address.address')}
                      error={_.has(errors, 'patient.address.address')}
                      helperText={_.get(errors, 'patient.address.address')}
                      inputProps={{ tabIndex: 6 }}
                      required={isSelfCarePatient}
                      variant="filled"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      fullWidth
                      label="Postcode"
                      name="patient.address.postcode"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className={classes.textField}
                      value={_.get(values, 'patient.address.postcode')}
                      error={_.has(errors, 'patient.address.postcode')}
                      helperText={_.get(errors, 'patient.address.postcode')}
                      inputProps={{ tabIndex: 7 }}
                      required={isSelfCarePatient}
                      variant="filled"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Pre-Existing Conditions"
                      name="patient.preExistingConditions"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      multiline
                      rows={4}
                      className={classes.textField}
                      value={_.get(values, 'patient.preExistingConditions')}
                      error={_.has(errors, 'patient.preExistingConditions')}
                      helperText={_.get(errors, 'patient.preExistingConditions')}
                      inputProps={{ tabIndex: 8 }}
                      variant="filled"
                    />
                  </Grid>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button className={classes.cancelPatientButton} onClick={onClose} tabIndex={9}>
                Cancel
              </Button>
              <Button
                className={clsx(classes.editPatientButton, 'e2e__patientsubmit')}
                color="primary"
                variant="contained"
                type="submit"
                disabled={isSubmitting}
                tabIndex={10}>
                {submitButtonTitle}
              </Button>
            </DialogActions>
          </form>
        );
      }}
    </Formik>
  );
}

// TODO: strongly type the formik input

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function toUpdatePatientInput(data: { patient: any }) {
  return {
    nhsNumber: tidyNhsNumber(data.patient.nhsNumber),
    ..._.pick(data.patient, [
      'id',
      'firstName',
      'lastName',
      'birthDate',
      'gender',
      'preExistingConditions',
      'telephone',
    ]),
    address: toInputAddress(data.patient?.address as InputAddress | null),
  } satisfies UpdatePatientInput;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function toCreatePatientInput(data: { patient: any }) {
  const selfCare = data.patient.selfCare?.email
    ? {
        selfCare: {
          email: data.patient.selfCare?.email,
        },
      }
    : null;
  return {
    type: 'elderly',
    nhsNumber: tidyNhsNumber(data.patient.nhsNumber),
    ...selfCare,
    ..._.pick(data.patient, [
      'firstName',
      'lastName',
      'birthDate',
      'gender',
      'preExistingConditions',
      'telephone',
    ]),
    address: toInputAddress(data.patient?.address as InputAddress | null),
  } satisfies CreatePatientInput;
}

/**
 * This is necessary to ensure the patient address is either a complete object, of two strings, or
 * null.
 *
 * Formik has a habit of collapsing empty form fields into `undefined` which causes them to
 * be removed by JSON serialisation when the graphql query is submitted.
 */
function toInputAddress(address: InputAddress | null) {
  return address?.address || address?.postcode
    ? {
        address: address?.address ?? '',
        postcode: address?.postcode ?? '',
      }
    : undefined;
}

function tidyNhsNumber(nhsNumber: null | string) {
  if (!nhsNumber) return null;
  return nhsNumber.replace(/\s/g, '');
}

export const useEditPatientModal = ({
  setFlashMessage,
  onComplete,
}: {
  setFlashMessage?: (message: string) => void;
  onComplete?: () => void;
} = {}) => {
  const { showModal } = useModal();

  const showEditPatientModal = useCallback(
    (patient: PatientDetailsFragment) => {
      const modal = showModal(
        PatientFormModal,
        {
          patient,
          onClose: () => {
            modal.hide();
          },
          onComplete: () => {
            modal.hide();
            onComplete?.();
          },
          setFlashMessage: (message: string) => {
            setFlashMessage?.(message);
          },
        },
        {
          destroyOnClose: true,
        },
      );

      return modal;
    },
    [onComplete, setFlashMessage, showModal],
  );

  return { showEditPatientModal };
};
